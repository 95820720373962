import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PageWrapper from './PageWrapper'
import { AlertIcon } from '../images/svg-icons'
import './ErrorNotice.scss'

export class ErrorNotice extends Component {
  componentDidMount() {
    this.props.setWrapperClassName('portal error')
    window.scrollTo(0, 0)
  }
  componentWillUnmount() {
    this.props.setWrapperClassName('')
  }
  render() {
    const { title, content, children } = this.props
    return (
      <PageWrapper className='error-notice-page'>
        <div className='v2-blue-bkg'>
          <div className='page-header'>
            <h1>{title}</h1>
          </div>
          <div className='page-body'>
            <div className='icon'>
              <AlertIcon className='svg-icon' />
            </div>
            <div className='text'>
              {content && <p>{content}</p>}
              {children}
            </div>
          </div>
        </div>
      </PageWrapper>
    )
  }
}
ErrorNotice.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  children: PropTypes.any,
  setWrapperClassName: PropTypes.func,
}
ErrorNotice.defaultProps = {
  setWrapperClassName: () => { }
}

const mapDispatchToProps = (dispatch) => ({
  setWrapperClassName: (value) => dispatch({ type: 'SET_WRAPPER_CLASS_NAME', value })
})

export default connect(() => ({}), mapDispatchToProps)(ErrorNotice)
