import { createBrowserHistory } from 'history'
import { URLSearchParams, utils } from '@pan/cloud-appframework'
import { toAppId } from '../../utils/common'
import { Message } from '@pan/cloud-base'

const PARAM_NAME = 'autoredirect'
const urlQueries = new URLSearchParams(window.location.search)
const initState = urlQueries.get(PARAM_NAME) || (urlQueries.has(PARAM_NAME) && '')

const removeSearch = () => {
  if (urlQueries.has(PARAM_NAME)) {
    urlQueries.delete(PARAM_NAME)
    const history = createBrowserHistory()
    history.replace(`${history.location.pathname}?${urlQueries.toString()}`)
  }
}

const redirectToLogin = () => {
  const { pathname } = window.location
  urlQueries.delete(PARAM_NAME)
  let search = urlQueries.toString()
  search = search && !search.startsWith('?') ? `?${search}` : search
  utils.redirectToLogin({ pathname, search })
}

const redirect = (name, apps) => {
  removeSearch()
  if (!name) {
    return
  }
  const app = apps[name] || Object.values(apps).find(app =>
    app.name === name || app.app_id === name || toAppId(app.display_name || app.name) === name
  )
  if (!app) {
    return
  }
  const { tenants = [], display_name = name } = app
  const instances = tenants.filter(t => t.url)
  if (instances.length === 1) { // has only single tenant
    const { url } = instances[0]
    Message.loading(`Redirecting to ${display_name} ...`, 10)
    window.location.href = url // redirect
  }
}

const autoRedirect = (state = initState, action) => {
  if (action.type === 'FETCH_CREDENTIALS_NO_AUTH') {
    // not logged in yet
    if (state || state === '') {
      redirectToLogin()
    }
    return state
  }
  else if (action.type === 'SET_ENTITLED_APPS') {
    redirect(state, action.apps)
    return ''
  }
  return state
}

export default autoRedirect
