/**
 *
 * Created by vsuthar on 10/30/18 File InputSearchBar
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import { Input } from '@pan/cloud-base'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'

class InputSearchBar extends PureComponent {
  constructor() {
    super()
    this.state = {
      value: '',
      searching: false
    }
    this.throttleAutoComplte = debounce(this.makeAutoComplete.bind(this), 600)
    this.handleAutoCompleteChange = this.handleAutoCompleteChange.bind(this)
  }

  componentDidMount() {
    const { searchTerm } = this.props
    this.setState({
      value: searchTerm
    })
  }

  makeAutoComplete(value) {
    this.setState({
      searching: false
    })
    this.props.handleInputSearch(value)
  }

  handleAutoCompleteChange(e) {
    /**
     * when value is empty clear selection
     */
    const { value } = e.target
    this.setState({
      value,
      searching: true
    }, () => {
      this.throttleAutoComplte(value)
    })

  }

  render() {
    const { searching, value } = this.state
    const { handleInputSearch, searchTerm, v2, prefixIcon, handleSuffixIcon, ...others } = this.props
    return (
      <div className='input-search-wrapper' style={{ position: 'relative' }}>
        <Input
          v2={v2}
          value={value}
          prefix={prefixIcon}
          suffix={handleSuffixIcon(searching)}
          onChange={this.handleAutoCompleteChange} {...others} />
      </div>
    )
  }
}



/**
 *
 * @type {{handleInputSearch: *, searchTerm: *}}
 */
InputSearchBar.propTypes = {
  handleInputSearch: PropTypes.func,
  searchTerm: PropTypes.string,
  v2: PropTypes.bool,
  prefixIcon: PropTypes.string,
  handleSuffixIcon: PropTypes.func
}

const nope = () => {}
/**
 *
 * @type {{handleInputSearch: nope}}
 */
InputSearchBar.defaultProps = {
  handleInputSearch: nope,
  searchTerm: '', // when user type something in searchbar we need to preserve that from redux when user keep switch page
  v2: false,
  prefixIcon: '',
  handleSuffixIcon: () => ''
}

export default InputSearchBar
