import React from 'react'
import {
  ASSIGN_INSTANCE_ROLES,
  GET_RBAC_SCHEMA,
  GET_ROLE_DEF,
  OPEN_ASSIGN_CUSTOM_PERMISSIONS, RBAC_CONFIRMATION_DIALOG,
  RBAC_CREATE_CUSTOM,
  RBAC_CUSTOM_ROLE_DIALOG,
  RBAC_DELETE_CUSTOM_ROLE_ACTION, RBAC_EDIT_ROLE,
  RBAC_MERGE_ROLE_DEF, RBAC_ROLE_TABLE_LOADING,
  RBAC_SEARCH_ROLE,
  RBAC_SEARCH_ROLE_SEARCHING, RBAC_SELECT_APP_SUCCESS,
  RBAC_VIEW_ROLE,
  RBAC_SKU_LOOKUP,
} from '../ActionConstants'
import Service from '../../../utils/Service'
import {
  RBAC_CREATE_CUSTOM_ROLE,
  RBAC_GET_CUSTOM_ROLE,
  RBAC_GET_ROLE_DEF,
  RBAC_GET_SCHEMA,
  RBAC_ROLE_LOOKUP,
} from '../URLs'
import {
  R_ROLE,
  R_ROLE_LABEL,
  R_TYPE,
  R_USER,
} from '../../../constants/AppConstants'
import { get } from 'lodash'
import { sendNotification } from './../index'
import { KNOWN_ROLES_MAP } from '../../../utils/rbacUtils'


/**
 *
 * @param isOpen
 * @returns {{isOpen: *, type: *}}
 */
export const openAssignCustomPermission = (isOpen, tenant_id, tenantSkus) => {
  return {
    type: OPEN_ASSIGN_CUSTOM_PERMISSIONS,
    isOpen,
    userRoleTenant_id: tenant_id,
    tenantSkus
  }
}

/**
 * Get all the roles for selected app.
 * @param app_id
 * @param shouldMergeRole
 * @param obj
 * @returns {function(...[*]=)}
 */
export const getRolesDefPerApp = (app_id, shouldMergeRole, obj = {}) => async (dispatch, getState) => {
  if (app_id) {
    dispatch({
      type: RBAC_ROLE_TABLE_LOADING,
      isCustomRoleTableLoading: true
    })
    const req = Service.createAxiosRequest({ url: RBAC_GET_CUSTOM_ROLE, method: 'GET', params: { app_id } })
    const roleDef = await Service.getData(await Service.call(req)) || []
    dispatch({
      type: GET_ROLE_DEF,
      roleDef
    })
    // only merge role for page who needs to show predefined roles
    if (shouldMergeRole) {
      dispatch({
        type: RBAC_MERGE_ROLE_DEF,
        roleDef,
        app_id,
        roleObj: obj
      })
    }
    dispatch({
      type: RBAC_ROLE_TABLE_LOADING,
      isCustomRoleTableLoading: false
    })
  }
}

export const getRoleDef = () => async (dispatch) => {
  const req = Service.createAxiosRequest({ url: RBAC_GET_ROLE_DEF, method: 'GET' })
  await Service.call(req)
    .then((res) => {
      const { data = [] } = res
      data.forEach((each) => {
        const { role, role_label, app_id } = each
        KNOWN_ROLES_MAP.set(`${app_id}-${role}`, role_label)
      })
    })

}
/**
 * Get rbac schema for all the application. Reducer will filter schema for selected app. Check customRbacReducer for this logic
 * @param app
 * @returns {Function}
 */
export const getSchemaDef = (selectedApp) => async (dispatch, getState) => {
  let rbacSchema = getState().rbacCustomRole.rbacSchema || []
  if (rbacSchema.length === 0) {
    const req = Service.createAxiosRequest({ url: RBAC_GET_SCHEMA, method: 'GET' })
    rbacSchema = Service.getData(await Service.call(req)) || []
  }

  dispatch({
    type: GET_RBAC_SCHEMA,
    rbacSchema,
  })
  dispatch({
    type: RBAC_SELECT_APP_SUCCESS,
    selectedApp
  })

  if (!selectedApp) { // Only dispatch on page reload
    dispatch({
      type: RBAC_SKU_LOOKUP,
      rbacSchema
    })
  }

}

/**
 *
 * @param value
 * @returns {Function}
 */
export const searchRoles = (value) => async (dispatch) => {
  dispatch({
    type: RBAC_SEARCH_ROLE_SEARCHING,
    isRoleSearching: !!value.toLowerCase().trim().length
  })

  dispatch({
    type: RBAC_SEARCH_ROLE,
    value: value.toLowerCase().trim()
  })
}


export const createCustomRole = (customRole = {}) => async (dispatch, getState) => {
  let payload = {}
  const { tenant_id } = customRole
  const { rbac, rbacCustomRole } = getState()
  const { dialogActionType, schemaPerApp } = rbacCustomRole
  const schemaScopes = schemaPerApp?.scopes?.map(each => each.scope) || []
  /**
   * APPORTAL-3771
   * Remove scope if scope is no longer present in schema. This happen when any app delete their scope in schema.
   * Ex: custom role scope: app_view.setting.view now not preset in cortex_xdr schema then we will remove app_view.setting.view
   * from @customRole
   */
  if (schemaScopes.length) {
    customRole.scope = customRole.scope.filter(each => schemaScopes.includes(each))
  }
  if (customRole[R_TYPE] === R_USER) {
    /**
     * If type of the role is user then we need to create role with name of email address of that assignee.
     * So we need to get selected users for role and put to be as role:value. This will be bulk operation
     */
    payload.roles = rbac.selectedUsers.map(email => {
      return {
        ...customRole,
        [R_ROLE]: email,
        [R_ROLE_LABEL]: `${customRole?.scope?.length} Permissions`,
        [R_TYPE]: R_USER
      }
    })
  }
  else {
    payload = { roles: [customRole] }
  }

  /**
     * Create custom role
     * @type {AxiosInstance}
     */
  const req = Service.createAxiosRequest({ url: `${RBAC_CREATE_CUSTOM_ROLE}`, method: 'POST', data: payload })
  const customRoleSuccess = await Service.getData(await Service.call(req)) || []
  dispatch({
    type: RBAC_CREATE_CUSTOM,
    customRoleSuccess
  })

  if (customRoleSuccess && customRoleSuccess?.success?.length) {
    const line = dialogActionType === 'create' ? 'created' : 'edited'
    dispatch(sendNotification({ message: `${customRole.role_label} has been ${line} successfully.` }))
  }


  if (customRoleSuccess && customRoleSuccess.success && customRoleSuccess.success.length) {
    dispatch(openAssignCustomPermission(false))
    if (rbac?.selectedUsers && rbac?.selectedUsers?.length) {

      const _custom_role = payload?.roles[0] // This will be always one

      /**
       * Only x permission assign when user will have type is user
       * @TODO move this logic to ASSIGN_INSTANCE_ROLES action
       */
      dispatch(getRolesDefPerApp(_custom_role.app, true, { ..._custom_role }))
      /**
       * Set role to drop down when user save as new role
       */
      const rbac_entitlements = get(getState(), 'rbac.rbac_entitlements', [])
      if (rbac_entitlements && tenant_id) {
        const selectedApp = rbac_entitlements.find(eachApp => eachApp.app_id === _custom_role.app)
        const access = get(selectedApp, 'access', [])
        const findTenant = access.find(eachTenant => eachTenant.tenant_id === tenant_id)
        if (findTenant) {
          dispatch({
            type: ASSIGN_INSTANCE_ROLES,
            instanceSelection: {
              value: _custom_role.role,
              instanceObj: findTenant,
              appType: selectedApp.rbac_app_type,
              type: _custom_role.type,
            }
          })
        }
      }
    }

  }
}

export const openCloseCustomRoleDiag = isOpen => {
  return {
    type: RBAC_CUSTOM_ROLE_DIALOG,
    isOpen,
    dialogActionType: 'create'
  }
}

/**
 * Open Edit permission dialog
 * @param value
 * @param arg
 * @returns {Function}
 */
export const editPermission = (value, arg) => (dispatch, getState) => {
  const { rbac_role_selected, tenant_id, tenantSkus } = arg || {}
  if (rbac_role_selected) {
    const { roleDef } = getState().rbacCustomRole
    if (roleDef && Array.isArray(roleDef)) {
      /**
       * Find user role which have email and tenant_id
       */
      const findRole = roleDef.find(role => role.role === rbac_role_selected && role.tenant_id === tenant_id)
      dispatch({
        type: OPEN_ASSIGN_CUSTOM_PERMISSIONS,
        roleToBeView: findRole,
        isOpen: true,
        userRoleTenant_id: tenant_id,
        tenantSkus
      })
    }
  }
}


export const confirmationDialogAction = (args, obj) => async dispatch => {
  const { visible } = args
  /**
   * If delete role confirmation dialog is visible and @obj is preset then call the server to get how many user will
   * impacted if role is removed.
   */
  dispatch({
    type: RBAC_CONFIRMATION_DIALOG,
    confirmationDialogProps: args
  })
  if (visible && obj) {
    const { role } = obj
    const params = { filter: `(role eq "${role}") or (rolename eq "")` }
    const req = Service.createAxiosRequest({ url: RBAC_ROLE_LOOKUP, method: 'POST', data: params })
    const resp = await Service.getData(await Service.call(req))
    const { json = [] } = resp || {}
    let { confirmationBody } = args
    const body = json.length ? `${json.length} users who currently have this role will lose this roles permissions` : null
    confirmationBody = <span> {confirmationBody} <br/> {body}</span>
    dispatch({
      type: RBAC_CONFIRMATION_DIALOG,
      confirmationDialogProps: { ...args, okButtonProps: { loading: false }, confirmationBody }
    })

  }


}

export const handleCustomRoleAction = (roleObj, e) => async (dispatch) => {
  const { key } = e
  /**
   * We need to remove scope if scope is present in request.
   */
  if (roleObj?.sku) {
    delete roleObj['sku']
  }
  /**
   * Delete custom role
   */
  dispatch({
    type: RBAC_ROLE_TABLE_LOADING,
    isCustomRoleTableLoading: true
  })
  if (key === 'delete') {
    const req = Service.createAxiosRequest({ url: `${RBAC_CREATE_CUSTOM_ROLE}`, method: 'DELETE', data: { roles: [roleObj] } })
    const customRoleSuccess = await Service.getData(await Service.call(req)) || []
    const { success } = customRoleSuccess
    if (success && Array.isArray(success) && roleObj.hasOwnProperty('role')) {
      //take first o
      const deletedRoleObj = success.find(role => role.role === roleObj.role)
      dispatch({
        type: RBAC_DELETE_CUSTOM_ROLE_ACTION,
        deletedRoleObj
      })
      dispatch(confirmationDialogAction({ visible: false }))
    }
  }
  else if (key === 'clone') {
    const { role } = roleObj || {}
    dispatch(createCustomRole({ ...roleObj, role: `${role}_clone`, role_label: `${roleObj?.role_label} Clone` }))
  }
  else if (key === 'view_role') {
    dispatch(openCloseCustomRoleDiag(true))
    dispatch({
      type: RBAC_VIEW_ROLE,
      roleTobeView: roleObj
    })
  }
  else if (key === 'edit_permission') {
    dispatch(openCloseCustomRoleDiag(true))
    dispatch({
      type: RBAC_EDIT_ROLE,
      roleTobeView: roleObj
    })
  }
  dispatch({
    type: RBAC_ROLE_TABLE_LOADING,
    isCustomRoleTableLoading: false
  })
}

export const onTreeChecked = (roleViewObg, checkedNodes, e) => dispatch => {
  const { checked, node } = e
  const newScope = []
  e.checkedNodes.forEach(eachNode => {
    const scope = get(eachNode, 'props.dataRef.scope')
    if (scope) {
      newScope.push(scope)
    }
  })

  let roleToBeView = {}
  if (!checked) {
    /**
     * We needs to do this to merge keys
     */
    const treeNode = get(node, 'props.dataRef')
    const { key: removedKey, scope: removedScope, children } = treeNode
    const _scopes = [removedScope]
    const _keys = [removedKey]
    children.forEach(child => {
      const { key, scope } = child
      _scopes.push(scope)
      _keys.push(key)
    })
    const scope = roleViewObg.scope.filter(eachScope => {
      return _scopes.indexOf(eachScope) < 0
    })

    const checkedNode = roleViewObg.checkedNode.filter(eachKey => {
      return _keys.indexOf(eachKey) < 0
    })
    roleToBeView = {
      ...roleViewObg,
      scope,
      checkedNode
    }
  }
  else {
    roleToBeView = {
      ...roleViewObg,
      scope: [...roleViewObg.scope ? roleViewObg.scope : [], ...newScope],
      checkedNode: [...roleViewObg.checkedNode ? roleViewObg.checkedNode : [], ...checkedNodes]
    }
  }
  dispatch({
    type: 'TREE_NODE_CHECKED',
    roleToBeView
  })
}

export const createAsNewRole = (roleToBeView) => (dispatch, getState) => {

  dispatch({
    type: 'CREATE_AS_NEW_ROLE',
    roleToBeView: { ...roleToBeView, role: '', role_label: '' } // Tell user to write role
  })
}
