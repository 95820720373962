import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Tooltip, ProgressCircle } from '@pan/cloud-base'
import { CheckIcon, AlertIcon, PieIcon, HourglassIcon, ClockIcon, ExclamationIcon, StoppedIcon } from '../../images/svg-icons'

const statusIndexes = [
  'setup-pending',
  'queueing',
  'pending',
  'provisioning',
  'migrating',
  'registered',
  'running',
  'warning',
  'stopped',
  'waiting',
  'deleting',
  'deleted',
].reduce((o, s, i) => {
  o[s] = i + 1
  return o
}, {})
const mergeStatus = (instance_status, job_status) => {
  if (!job_status) {
    return instance_status
  }
  const statusIdx = statusIndexes[instance_status] || 0
  const jobStatusIdx = statusIndexes[job_status] || 0
  return jobStatusIdx > statusIdx ? job_status : instance_status
}
const StatusIcon = ({ status, ...props }) => {
  let Icon
  let color
  switch (status) {
    case 'running':
      Icon = CheckIcon
      color = 'green'
      break
    case 'expired':
      Icon = ClockIcon
      color = 'gray'
      break
    case 'stopped':
      Icon = StoppedIcon
      color = 'gray'
      break
    case 'registered':
      Icon = CheckIcon
      color = 'gray'
      break
    case 'warning':
      Icon = ExclamationIcon
      color = 'yellow'
      break
    case 'pending':
    case 'waiting':
    case 'migrating':
    case 'provisioning':
      Icon = HourglassIcon
      color = 'yellow'
      break
    case 'setup-pending':
      Icon = PieIcon
      color = 'yellow'
      break
    case 'deleting':
    case 'deleted':
      return null
    default:
      Icon = AlertIcon
      color = 'red'
      break
  }
  return (
    <i {...props} className={`instance-status status-color-${color}`}>
      <Icon className='status-icon' />
    </i>
  )
}

StatusIcon.propTypes = {
  status: PropTypes.string,
}

export const InstanceStatus = ({ instance_status, status_message, job_status, job_progress, url, expired }) => {
  let status = mergeStatus(instance_status, job_status)
  let title
  let progress
  const hasUrl = url && _.isString(url)
  if (hasUrl && expired) {
    status = 'expired'
    title = 'License Expired'
  }
  else if (status) {
    title = _.startCase(status.toLowerCase())
  }
  else {
    title = 'Unavailable'
  }
  switch (status) {
    case 'running':
      if (!hasUrl) {
        status = 'error'
      }
      break
    case 'deleting':
    case 'deleted':
      progress = _.round(+job_progress || 0)
      if (progress < 0) {
        status = 'waiting'
      }
      else {
        status = 'progress' // no icon, use progress instead
        title = progress > 0 && progress < 100 ? `${title} ${progress}%` : title
      }
      break
    case 'warning':
    case 'error':
      if (status_message) {
        title = status_message
      }
      break
    default:
      break
  }
  return <Tooltip
    title={`Status: ${title}`}
    placement='right'
    mouseEnterDelay={0.3}
    overlayClassName='instance-action-tooltip'
    getPopupContainer={node => node.parentNode}
  >
    {status !== 'progress' ?
      <StatusIcon status={status} /> : <ProgressCircle
        width={20}
        strokeWidth={15}
        type='circle'
        percent={+job_progress || 0}
        showInfo={job_progress === 100}
      />
    }
  </Tooltip>
}

InstanceStatus.propTypes = {
  instance_id: PropTypes.string,
  instance_status: PropTypes.string,
  status_message: PropTypes.string,
  job_status: PropTypes.string,
  job_progress: PropTypes.number,
  url: PropTypes.string,
  expired: PropTypes.bool,
}

const mapStateToProps = ({ jobs }) => ({
  jobs,
})

const mergeProps = ({ jobs }, ignored, ownProps) => {
  const { status: job_status, progress: job_progress } = (jobs && jobs.getByTenantId(ownProps.tenant_id)) || {}
  return {
    ...ownProps,
    job_status,
    job_progress,
  }
}

export default connect(mapStateToProps, null, mergeProps)(InstanceStatus)
