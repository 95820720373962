import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { Form, Button, Spin, Message } from '@pan/cloud-base'
import {
  getAppInfoMap,
  convertObjToString,
  genCancelSource,
  formItemLayout,
  hasFormErrors,
  useMemoForm,
  isNonemptyArray
} from '../../utils/activateUtils'
import InstanceNameField from './fields/InstanceNameField'
import DescriptionField from './fields/DescriptionField'
import SerialNumberField from './fields/SerialNumberField'
import RegionAssociationsFields, { EMPTY_ONLY_FIELDS } from './fields/RegionAssociationsFields'
import CustomFields from './fields/CustomFields'
import AppExtraFields from './fields/AppExtraFields'
import './EditForm.scss'

const getInstance = async ({ tenant_id, application_name, selectedAccount }, cancelToken) => {
  const resp = await axios.post('/hub/v2/instance/get', {
    selectedAccount,
    tenant_id,
    application_name,
  }, { cancelToken })
  if (resp.data.ok && resp.data.record) {
    return resp.data.record
  }
  throw new Error('Fail to load selected record.')
}

const update = async (values) => { // not cancelable
  // console.info('update', values)
  try {
    const resp = await axios.post('/hub/v2/instance/update', values)
    // console.info('update resp', resp.data)
    return resp.data
  }
  catch (err) {
    // console.error('update err', err, { ...err })
    if (!err.response) {
      throw err
    }
    const {
      error = err.message,
      statusCode: status = err?.response?.status || 0,
      message,
    } = err?.response?.data || err
    throw Object.assign(err, { message, error, status })
  }
}

const EMPTY_OBJ = {}

const EditForm = ({
  form,
  application_name,
  tenant_id,
  selectedAccount,
  entitledAppsList,
  onCancel,
  onFinished,
  onlyFields: rawOnlyFields,
}) => {
  const formMethods = useMemoForm(form)
  const {
    getFieldDecorator,
    validateFields,
    setFields,
    getFieldsError,
    isFieldsTouched,
  } = formMethods
  const onlyFields = useMemo(() => {
    if (!isNonemptyArray(rawOnlyFields)) {
      return EMPTY_ONLY_FIELDS
    }
    const set = new Set(rawOnlyFields)
    const has = set.has.bind(set)
    return Object.freeze(Object.defineProperties(rawOnlyFields, {
      has: { value: has },
    }))
  }, [rawOnlyFields])
  const [initialValues, setInitialValues] = useState(EMPTY_OBJ)
  const [isLoading, setLoading] = useState(true)
  const cancelSource = useMemo(genCancelSource, [])
  const updateHandler = useCallback((e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    setLoading(true)
    validateFields(async (err, values) => {
      if (err) {
        return
      }
      try {
        const data = {
          ...values,
          selectedAccount,
          application_name,
          tenant_id,
          associations: _.mapValues(values.associations, (v, k) => {
            return initialValues && v === convertObjToString(initialValues.associations[k]) ? undefined : (v || '')
          })
        }
        const resp = await update(data) // not cancelable
        // console.error('updated', data)
        Message.info(resp.cob === 202 ? 'Update accepted, changes will be applied within few minutes.' : 'Instance successfully updated.', 3)
        onFinished(resp)
      }
      catch (e) {
        if (e.message === 'Nothing changed') {
          Message.warn(e.message, 2)
        }
        else {
          Message.error(`Update failed. ${e.message}` || 'Update failed', 3)
        }
      }
      finally {
        if (!cancelSource.token.reason) { // unmounted
          setLoading(false)
        }
      }
    })
  }, [validateFields, selectedAccount, application_name, tenant_id, cancelSource, onFinished, initialValues])

  useEffect(() => {
    if (!tenant_id) {
      return
    }
    getInstance({ tenant_id, application_name, selectedAccount }, cancelSource.token).then((data) => {
      setFields({})
      setInitialValues(data)
      validateFields()
      setLoading(false)
    }, () => {
      Message.error('Fail to load selected record.', 3)
      setLoading(false)
    })
  }, [tenant_id, application_name, selectedAccount, cancelSource, validateFields, setFields])

  useEffect(() => cancelSource.unmountCallback, [cancelSource])

  const appInfoMap = useMemo(() => (
    getAppInfoMap(application_name && [application_name], entitledAppsList)
  ), [application_name, entitledAppsList])
  const hasErrors = hasFormErrors(getFieldsError)
  return <>
    <Spin spinning={isLoading}>
      <Form onSubmit={updateHandler} className='v2'>
        <div className='edit-form-body'>
          {(onlyFields.has('tenant_instance_name')) && <InstanceNameField
            {...formItemLayout}
            {...form}
            autoFocus
            appInfoMap={appInfoMap}
            selectedAccount={selectedAccount}
            initialValues={initialValues}
            editableOnly={true}
          />}
          {(onlyFields.has('description') && !appInfoMap.single?.flags.hide_desc_field) && <DescriptionField
            {...formItemLayout}
            getFieldDecorator={getFieldDecorator}
            initialValues={initialValues}
            editableOnly={true}
          />}
          <RegionAssociationsFields
            {...formItemLayout}
            {...form}
            appInfoMap={appInfoMap}
            selectedAccount={selectedAccount}
            initialValues={initialValues}
            editableOnly={true}
            onlyFields={onlyFields}
          />
          <CustomFields
            {...formItemLayout}
            {...formMethods}
            fields={appInfoMap.single?.developer_defined_fields}
            fieldNamePrefix='developer_defined_fields'
            requiredByDefault={true}
            editableOnly={true}
            initialValues={initialValues}
          />
          <AppExtraFields
            {...formItemLayout}
            {...formMethods}
            appInfoMap={appInfoMap}
            initialValues={initialValues}
            editableOnly={true}
          />
          {initialValues.serial_number && (onlyFields.has('serial_number')) && <SerialNumberField
            {...formItemLayout}
            serial_number={initialValues.serial_number}
          />}
        </div>
        <div className='form-footer edit-form-footer'>
          <div className='ant-form-item-required col-required-legend'></div>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            htmlType='submit'
            type='primary'
            disabled={hasErrors || !isFieldsTouched()}
          >OK</Button>
        </div>
      </Form>
    </Spin>
  </>
}

EditForm.propTypes = {
  tenant_id: PropTypes.string,
  application_name: PropTypes.string,
  history: PropTypes.object,
  form: PropTypes.object,
  entitledAppsList: PropTypes.array,
  selectedAccount: PropTypes.number,
  onlyFields: PropTypes.array,
  onCancel: PropTypes.func,
  onFinished: PropTypes.func,
}

EditForm.defaultProps = {}

export default Form.create()(EditForm)
