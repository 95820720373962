import _ from 'lodash'

const disabledTenants = (state = new Set(), action) => {
  switch (action.type) {
    case 'SET_DISABLED_TENANTS': {
      return _.isEqual(state, action.tenants) ? state : action.tenants // avoid unneeded change
    }
    case 'UPDATE_DISABLED_TENANTS': {
      const set = new Set(state)
      action.tenants.forEach((disabled, tid) => {
        if (disabled) {
          set.add(tid)
        }
        else {
          set.delete(tid)
        }
      })
      return _.isEqual(state, set) ? state : set // avoid unneeded change
    }
    default: {
      return state
    }
  }
}

export default disabledTenants
