import React, { useMemo, useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { Form, Select } from '@pan/cloud-base'
import {
  ACTIVATE,
  convertObjToString,
  allowAppAdmin,
  renderInstances,
} from '../../../utils/activateUtils'

import './AssociationInstanceField.scss'

const REGION_FIELD_NAME = 'region_data'

const convertValue = (obj) => {
  if (!obj || _.isEmpty(obj)) {
    return [undefined, undefined]
  }
  if (_.isString(obj)) {
    const [tenant_id] = obj.split('|', 2)
    return [obj, tenant_id]
  }
  return [convertObjToString(obj), obj.tenant_id]
}

export const getInitials = (fieldName, initialValues) => {
  const hasInitialValues = !initialValues.isEmpty
  const [initialValue, initialTenantId] = convertValue(_.get(initialValues, fieldName)) // fieldName has .
  return { initialValue, initialTenantId, hasInitialValues }
}

const AssociationInstanceField = ({
  initialValues,
  appInfoMap,
  config,
  selectedAccount,
  getFieldDecorator,
  getFieldValue,
  getFieldError,
  setFields,
  validateFields,
  instanceRegionFilter,
  isFieldValidating,
  isFieldTouched,
  editableOnly,
  regions,
  newActivate,
  ...restProps
}) => {
  const { app: appName, app_id } = config
  const fieldName = `associations.${app_id}`
  const { has_auth_code, isCob, role = 'ANY', isNova, isFake, display_name: displayName = appName } = appInfoMap.getAnyApp(appName) || {}
  const { initialValue, initialTenantId, hasInitialValues } = useMemo(() => (
    config.initials || getInitials(fieldName, initialValues)
  ), [config.initials, fieldName, initialValues])

  const [authcodeOption, setAuthCodeOption] = useState()
  const setField = useCallback((value, cb) => {
    setFields({ [fieldName]: { value } }, () => {
      validateFields([fieldName], cb)
    })
  }, [fieldName, setFields, validateFields])
  const clearField = useCallback(() => setField(), [setField])

  const showActivateHandler = useCallback((e, defaultValue) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    else if (editableOnly) {
      return false // skip
    }
    else if (e && e.key === '||') { // is new option
      setTimeout(() => setField(defaultValue || undefined), 100)
    }
    if (config.showActivateFn) {
      config.showActivateFn({
        config,
        appName,
        displayName,
        has_auth_code,
        isCob,
        isNova,
        setField,
        clearField,
        setAuthCodeOption,
      })
    }
    else {
      newActivate({ appName, displayName, has_auth_code, isCob, isNova })
    }
    return false
  }, [appName, clearField, config, displayName, editableOnly, has_auth_code, isCob, isNova, newActivate, setField])

  const instanceDecorator = useMemo(() => {
    const {
      required = false,
      region_assignment = false,
      region_assignment_cb,
      instanceDecoratorPreFn,
      instanceDecoratorConfig,
    } = config
    const requiredRule = required && { required: true, message: `${displayName} is required` }
    if (instanceDecoratorPreFn) {
      const cfg = instanceDecoratorPreFn({ config, requiredRule, clearField, setField, showActivateHandler })
      if (cfg) {
        return cfg
      }
    }

    const fieldDecoratorConfig = {
      initialValue,
      validateFirst: true,
      getValueProps: hasInitialValues && !editableOnly ? () => ({
        value: initialValue,
        disabled: true,
      }) : undefined,
      rules: [
        region_assignment && !editableOnly && !hasInitialValues && region_assignment_cb && {
          validator: (rule, value, callback) => {
            if (!value) {
              region_assignment_cb('', '', app_id)
            }
            else {
              const [tid, , region, authcode] = value.split('|')
              region_assignment_cb(region, tid || authcode, app_id)
            }
            callback() // always pass
          }
        },
        requiredRule,
      ].filter(Boolean),
    }

    if (!instanceDecoratorConfig) {
      return getFieldDecorator(fieldName, fieldDecoratorConfig)
    }

    return getFieldDecorator(fieldName, !_.isFunction(instanceDecoratorConfig) ? {
      ...fieldDecoratorConfig,
      ...instanceDecoratorConfig,
      rules: fieldDecoratorConfig.rules.concat(instanceDecoratorConfig.rules),
    } : instanceDecoratorConfig(fieldDecoratorConfig))
  }, [config, displayName, initialValue, hasInitialValues, editableOnly, getFieldDecorator, fieldName, clearField, setField, showActivateHandler, app_id])

  const instances = useMemo(() => {
    const renderOptions = tenants => tenants.map(({ key, group, display, extra, ...props }) => (
      <Select.Option {...props} key={key}>
        {display} {extra}
      </Select.Option>
    ))
    const renderOptionGroups = optionsGroups => optionsGroups.map(([region, tenants]) => {
      return !region ? renderOptions(tenants) : <Select.OptGroup key={region} label={region}>
        {renderOptions(tenants)}
      </Select.OptGroup>
    })
    return renderInstances({
      config,
      appInfoMap,
      regions,
      editableOnly,
      hasInitialValues,
      instanceRegionFilter,
      initialTenantId,
      displayName,
      renderOptions,
      renderOptionGroups,
    })
  }, [config, appInfoMap, regions, editableOnly, hasInitialValues, instanceRegionFilter, initialTenantId, displayName])
  const getFieldTouchedError = useCallback(fieldName => (
    ((initialValue || instances.onlyAvailableOptionValue || isFieldTouched(fieldName)) &&
      getFieldError(fieldName)) || ''
  ), [initialValue, instances.onlyAvailableOptionValue, isFieldTouched, getFieldError])
  const helpText = useMemo(() => {
    if (editableOnly) {
      return ''
    }
    if (config.helpTextFn) {
      const result = config.helpTextFn(config)
      if (result !== undefined) {
        return result
      }
    }
    if (config.activate_new_option || hasInitialValues) {
      return ''
    }
    if (has_auth_code) {
      return <>If not all {displayName} instances appear, you may need to {
        <Link to={ACTIVATE} onClick={showActivateHandler}>activate purchased licenses</Link>
      }.</>
    }
    else if (isNova) {
      if (appName === 'aperture') {
        return <a // APPORTAL-2017
          href='https://docs.paloaltonetworks.com/prisma/prisma-saas/prisma-saas-admin/get-started-with-prisma-saas/prisma-saas/activate-prisma-saas.html'
          rel='external noopener noreferrer'
          target='_blank'
        >Learn how to set up {displayName}</a>
      }
      return null
    }
    else if (isFake) {
      return null
    }
    const link = <Link to={ACTIVATE} onClick={showActivateHandler}>create an instance</Link>
    return <>If the desired {displayName} instance does not appear, you may need to {link}.</>
  }, [appName, config, displayName, editableOnly, has_auth_code, hasInitialValues, isFake, isNova, showActivateHandler])
  const authcodeOptionNode = useMemo(() => (
    authcodeOption && <Select.Option key={authcodeOption.key} value={authcodeOption.value}>{authcodeOption.display}</Select.Option>
  ), [authcodeOption])
  const activateNewOption = useMemo(() => (
    !editableOnly && config.activate_new_option && allowAppAdmin({ role }) && <Select.Option
      value='||'
      onClick={has_auth_code ? (e) => {
        showActivateHandler(e, instances.onlyAvailableOptionValue)
      } : undefined}
    >Activate new {displayName}</Select.Option>
  ), [editableOnly, config.activate_new_option, role, has_auth_code, displayName, showActivateHandler, instances.onlyAvailableOptionValue])
  const err = getFieldTouchedError(fieldName)

  // clean up when disable or region field value change
  const regionValue = (editableOnly && initialValues.region) || getFieldValue(REGION_FIELD_NAME)
  // APPORTAL-2358/CSL-788: do not put `hasInitialValues ||` below or the value of the field cannot be read
  const disabled = config.disabled || (config.isFieldDisabledFn || _.identity)(config.region_matching && !regionValue)
  useEffect(() => {
    const value = getFieldValue(fieldName)
    if (value && disabled) {
      setField()
    }
    else if (config.region_matching ? regionValue :
      !value && instances.onlyAvailableOptionValue) {
      setField(instances.onlyAvailableOptionValue)
    }
  }, [config.region_matching, instances.onlyAvailableOptionValue, disabled, regionValue, setField, getFieldValue, fieldName])
  // trigger onValueChange
  const fieldValue = getFieldValue(fieldName)
  const hasInstances = Boolean(instances.length)
  useEffect(() => { // it will trigger not only when value changed
    // also when value init, fn itself, or below params changed
    const fn = config.onFieldValueChange
    if (fn) {
      fn(fieldValue, { hasInstances, selectedAccount, hasInitialValues })
    }
  }, [fieldValue, selectedAccount, hasInstances, config.onFieldValueChange, hasInitialValues])
  // set initial value for editable mode
  useEffect(() => {
    if (editableOnly && initialValue) {
      setField(initialValue)
    }
  }, [initialValue, editableOnly, setField])
  // clear fields and authcode when account changed
  useEffect(() => (
    () => {
      setAuthCodeOption()
      clearField()
    }
  ), [clearField, selectedAccount])

  const validateStatus = isFieldValidating(fieldName) ? 'validating' : err ? 'error' : ''
  const placeholder = hasInitialValues && !editableOnly ? `No ${displayName} Instance Selected` : `Choose a ${displayName} Instance`
  const appRegionValue = initialValues?.region || getFieldValue('region_data')
  const crossRegionWarning = useMemo(() => {
    if (!config.cross_region_warning || !appRegionValue) {
      return
    }
    const appRegionName = appRegionValue.split('|', 2)[0]
    const associationRegionName = fieldValue?.split('|', 4)[2]

    const appRegion = appRegionName && regions?.find(r => r.name === appRegionName)
    const associationRegion = associationRegionName && appInfoMap.getAppRegions(app_id)?.get?.(associationRegionName)
    if (appRegionName && appRegion && associationRegionName &&
      (appRegionName !== associationRegionName && appRegion.logging_service_region !== associationRegionName)) {
      const message = config.cross_region_warning
        .replace('{region_display}', appRegion?.display || appRegionName)
        .replace('{asso_region_display}', associationRegion?.display || associationRegionName)
      return <span style={{ color: '#d84d50' }}>{message}</span>
    }
  }, [config.cross_region_warning, appRegionValue, fieldValue, regions, appInfoMap, app_id])

  const renderer = () => (
    <Form.Item
      {...restProps}
      label={displayName}
      validateStatus={validateStatus}
      help={validateStatus === 'error' ? err : crossRegionWarning || ''}
      extra={helpText}
      className={config.hidden ? 'instance-field-hidden' : ''}
    >
      {instanceDecorator(<Select
        className={'association-instance'}
        disabled={disabled}
        onSelect={config.onSelect}
        onChange={config.onChange}
        placeholder={placeholder}
        notFoundContent='No available instances'
        allowClear
      >{authcodeOptionNode}{instances}{activateNewOption}</Select>)}
    </Form.Item>
  )
  return config.renderer ? config.renderer({ instances, renderer }) : renderer()
}

AssociationInstanceField.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  config: PropTypes.object,
  editableOnly: PropTypes.bool,
  regions: PropTypes.arrayOf(PropTypes.object),
  selectedAccount: PropTypes.number,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  setFields: PropTypes.func,
  validateFields: PropTypes.func,
  instanceRegionFilter: PropTypes.func,
  isFieldValidating: PropTypes.func,
  isFieldTouched: PropTypes.func,
  newActivate: PropTypes.func,
}

AssociationInstanceField.defaultProps = {
  config: {},
  newActivate: _.noop,
}

export default AssociationInstanceField
