import React, { useRef, useMemo, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
// import _ from 'lodash'
import { Form, Input } from '@pan/cloud-base'
// import { remoteValidator } from '../../../utils/activateUtils'

const FIELD_NAME = 'tenant_instance_name'
const InstanceNameField = ({
  initialValues,
  defaultValue,
  appInfoMap,
  selectedAccount,
  getFieldDecorator,
  getFieldError,
  getFieldValue,
  setFieldsInitialValue,
  resetFields,
  isFieldValidating,
  setFields,
  autoFocus,
  editableOnly,
  ...restProps
}) => {
  const initialValue = initialValues[FIELD_NAME]
  const ref = useRef(null)
  const nameDecorator = useMemo(() => {
    if (initialValue && !editableOnly) {
      return getFieldDecorator(FIELD_NAME, {
        initialValue,
        getValueProps: () => ({
          value: initialValue,
          disabled: true,
        }),
        validate: [{
          rules: [{ required: true }],
        }]
      })
    }
    // console.info(FIELD_NAME, appInfoMap, initialValues)
    const { isBundle } = appInfoMap
    const DEFAULT_MAX = 63
    const getAppMaxLen = (app, defaults = DEFAULT_MAX) => app?.customizable_instance_name?.max_length ?? defaults
    const maxNameLen = isBundle ? Math.min(appInfoMap.map(app => {
      const maxLen = getAppMaxLen(app, 0)
      if (maxLen) { // if max_length is given, it should include the suffix, e.g. traps = 59
        const suffix = appInfoMap.getBundleSuffix(app.name)
        return maxLen - suffix.length
      } // by default, not include suffix, it is 63 for now
      return DEFAULT_MAX
    })) : getAppMaxLen(appInfoMap.single)
    const transform = v => (v ? v.trim() : '')

    return getFieldDecorator(FIELD_NAME, {
      initialValue,
      validateFirst: true,
      getValueProps: undefined,
      validate: [
        {
          trigger: ['onChange', 'onBlur'],
          validateFirst: true,
          rules: [
            { whitespace: true, transform, required: true, message: 'Name is required' },
            { max: maxNameLen, transform, message: `Name can have at most ${maxNameLen} characters` },
            { // new validator for check uniq name usage within account
              transform,
              validator: (rule, value, callback) => {
                const hasDup = appInfoMap.hasDupName(value, initialValue)
                callback(hasDup ? 'This name has been used' : undefined)
              }
            }
          ],
        }
      ]
    })
  }, [editableOnly, appInfoMap, getFieldDecorator, initialValue])

  useLayoutEffect(() => { // update default value for instance name unless user touched
    const curRef = ref.current
    if (selectedAccount >= 0 && !initialValue && defaultValue) {
      if (!curRef?.dirty || !getFieldValue(FIELD_NAME)) {
        setFieldsInitialValue({ [FIELD_NAME]: defaultValue })
        resetFields([FIELD_NAME])
      }
      return () => {
        if (curRef) {
          curRef.dirty = getFieldValue(FIELD_NAME) !== defaultValue
        }
      }
    }
  }, [initialValue, defaultValue, resetFields, selectedAccount, setFieldsInitialValue, getFieldValue, ref])

  if (!appInfoMap.hasInstanceNameField) {
    return null // no customizable instance name allowed
  }

  const err = getFieldError(FIELD_NAME)
  const validateStatus = isFieldValidating(FIELD_NAME) ? 'validating' : err ? 'error' : ''

  return <Form.Item
    {...restProps}
    label='Name'
    validateStatus={validateStatus}
    help={validateStatus === 'error' ? err : ''}
  >
    {nameDecorator(<Input
      ref={ref}
      autoFocus={autoFocus}
      placeholder='Name'
    />)}
  </Form.Item>
}

InstanceNameField.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  defaultValue: PropTypes.string,
  editableOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  selectedAccount: PropTypes.number,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  setFieldsInitialValue: PropTypes.func,
  resetFields: PropTypes.func,
  isFieldValidating: PropTypes.func,
  setFields: PropTypes.func,
}

export default InstanceNameField
