import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Footer from '@pan/cortex-panw-footer'

export default class PageWrapper extends Component {
  render() {
    const { id, className, excludeFooter, children } = this.props
    return (
      <main
        id={id}
        className={classNames(className, !excludeFooter && 'has-footer')}
      >
        {children}
        {excludeFooter ? ('') : <Footer />}
      </main>
    )
  }
}

PageWrapper.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  excludeFooter: PropTypes.bool
}

PageWrapper.defaultProps = {
  id: 'portal',
  excludeFooter: false
}
