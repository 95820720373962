import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Form, Input } from '@pan/cloud-base'
import { useDeepMemo } from '../../../utils/activateUtils'

const CustomFields = ({
  fields,
  fieldNamePrefix,
  requiredByDefault,
  editableOnly,
  initialValues,
  getFieldDecorator,
  getFieldError,
  isFieldValidating,
  isFieldTouched,
  ...restProps
}) => {
  const fieldDecorators = useDeepMemo(() => {
    if (!fieldNamePrefix || !fields?.length) {
      return []
    }
    const decorators = fields.map(field => {
      if (!field?.name) {
        return null
      }
      const fieldName = `${fieldNamePrefix}.${field.name}`
      const initialValue = _.get(initialValues, fieldName)
      const label = field.display || _.capitalize(field.name)
      const isRequired = requiredByDefault ? field.required !== false : field.required
      const decorator = getFieldDecorator(fieldName, {
        initialValue,
        rules: [
          { max: 255, message: `${label} can have at most 255 characters` },
          isRequired && { required: true, message: `${label} is required` }
        ] // .filter(Boolean)
      })
      return Object.assign(decorator, { fieldName, label })
    })
    return decorators
  }, [fieldNamePrefix, getFieldDecorator, fields, initialValues, requiredByDefault])

  return fieldDecorators.map(decorator => {
    const { fieldName, label } = decorator
    const err = getFieldError(fieldName)
    const validateStatus = isFieldValidating(fieldName) ? 'validating' : err ? 'error' : ''
    const errMsg = validateStatus === 'error' && (editableOnly || isFieldTouched(fieldName)) ? err : ''
    return <Form.Item
      key={fieldName}
      {...restProps}
      label={label}
      validateStatus={validateStatus}
      help={errMsg}
    >
      {decorator(<Input />)}
    </Form.Item>
  })
}

CustomFields.propTypes = {
  fields: PropTypes.array,
  fieldNamePrefix: PropTypes.string,
  requiredByDefault: PropTypes.bool,
  editableOnly: PropTypes.bool,
  initialValues: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  isFieldValidating: PropTypes.func,
}

export default CustomFields
