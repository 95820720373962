import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import AppDetails from './AppDetails'
import BodyRenderer from './BodyRenderer'

export const ManifestAppDetails = ({ app, isAuthenticated, history, setWrapperClassName, showAuthCodeModal, custom_activate_btn = '' }) => (
  <AppDetails
    app={app}
    name={app.display_name || app.name}
    logo={app.logo}
    developer={app.developer.name}
    developer_link={app.developer.link}
    social_media={app.developer.social_media}
    summary={app.marketplace_content.summary}
    product_link={app.marketplace_content.product_link}
    contact_link={app.marketplace_content.contact_link}
    privacy_link={app.marketplace_content.privacy_link}
    tac_link={app.marketplace_content.tac_link}
    video_url={app.marketplace_content.video_url}
    first_release={moment(app.marketplace_content.first_release).format('ll')}
    latest_release={moment(app.marketplace_content.latest_release).format('ll')}
    system_requirements={app.marketplace_content.system_requirements}
    gallery_image_urls={app.marketplace_content.gallery_image_urls}
    custom_activate_btn={custom_activate_btn}
    heading={app.marketplace_content.heading}
    isAuthenticated={isAuthenticated}
    setWrapperClassName={setWrapperClassName}
    showAuthCodeModal={showAuthCodeModal}
    history={history}
  >
    <BodyRenderer body={app.marketplace_content.body} />
  </AppDetails>
)

ManifestAppDetails.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string.isRequired,
    developer: PropTypes.object.isRequired,
    marketplace_content: PropTypes.object.isRequired,
    display_name: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  custom_activate_btn: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  history: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  setWrapperClassName: PropTypes.func,
  showAuthCodeModal: PropTypes.func,
}

export default ManifestAppDetails
