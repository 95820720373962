import React, { PureComponent } from 'react'
import { Row, Col } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import HeaderWithTip from '../../components/LoggingCalculator/HeaderWithTip'
import HelpText from './HelpText'
import { LSCLabel } from './LSCLabel'
import { RETENTION_PRD } from '../../constants/AppConstants'
import NumberInput from '../../components/LoggingCalculator/NumberInput'

class Traps extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      numberOfUsers: 0, // number of user set to 0
      retentionPrd: 30, // set retention period to 0
      effectiveLogRate: 0, // set effective Log rate to 0
      totalStorage: 0,
    }
  }

  /**
   *
   * @param type
   * @param e
   */

  handleChange(type, e) {
    let value = Boolean(e?.target?.value) ? e.target.value : e
    value = Math.abs(value) // we need un-sign int
    this.setState({
      [type]: isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10),
    }, () => {
      const { numberOfUsers = 0, retentionPrd = 0, numberOfXDRClients = 0, xdrRetentionPrd = 30 } = this.state

      /* @retentionPrd is 0 by default we have to account for global retention period. if global retention period is present
       and component level retention period is 0 then take that in account global retention period for calculation
       */


      /**
       * TODO: We should put this logic to center place. Currently same logic is used in redux when magnifier clicked
       */
      const primeRetentionPrd = retentionPrd === 0 && this.props.retentionPrd > 0 ? this.props.retentionPrd : retentionPrd
      const effectiveLogRate = (0.02 * numberOfUsers)
      const totalStorage = (numberOfUsers * 362880 * primeRetentionPrd) / Math.pow(1024, 3)
      const totalDataLakeStorage = numberOfXDRClients * (174.4 / Math.pow(1024, 1)) * xdrRetentionPrd
      this.setState({
        effectiveLogRate, totalStorage, totalDataLakeStorage
      }, this.reportToParent.bind(this))
    })
  }

  reportToParent() {
    this.props.totalData({ appId: 'traps', ...this.state })
  }

  render() {
    const tipStyle = { color: '#999999', fontSize: 14 }
    const { others } = this.props
    const { numberOfXDRClients = 0, xdrRetentionPrd = 30 } = others
    /**
     * if global retention period is present then we should use this.props.retentionPrd unless component overrides it
     */
    return (
      <Row type='flex' align='bottom'>
        <Col xs={24} sm={24} md={24} lg={12} style={{ marginTop: 10 }}>
          <HeaderWithTip>
            <LSCLabel style={{ whiteSpace: 'break-spaces' }} ><span>Total XDR Pro Endpoints <br /> Forwarding Cortex XDR Data</span></LSCLabel>
          </HeaderWithTip>
          <NumberInput value={numberOfXDRClients}
            defaultValue={0}
            className='logging-calc-ant-input'
            onChange={this.handleChange.bind(this, 'numberOfXDRClients')} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} style={{ marginTop: 10 }}>
          <HeaderWithTip tooltipProps={{ title: HelpText('retentionPrd') }} tipIconProps={{ style: tipStyle }}>
            <LSCLabel>{RETENTION_PRD}</LSCLabel>
          </HeaderWithTip>
          <NumberInput value={xdrRetentionPrd}
            suffix='day(s)'
            className='logging-calc-ant-input'
            onChange={this.handleChange.bind(this, 'xdrRetentionPrd')} />
        </Col>
      </Row>
    )
  }
}

/**
 *
 * @type {{totalData: *, retentionPrd: *, others: *}}
 */
Traps.propTypes = {
  totalData: PropTypes.func,
  retentionPrd: PropTypes.number,
  others: PropTypes.object
}

/**
 *
 * @type {{totalData: Traps.defaultProps.totalData, retentionPrd: number}}
 */
Traps.defaultProps = {
  totalData: () => {
  },
  retentionPrd: 0,
}

export default Traps
