import React, { useState } from 'react'
import {
  RBAC_ROLE_DEFINITION
} from '../../../constants/AppConstants'
import RoleDefPage from '../custom_role/RoleDefPage'
import ConfirmationDialog from '../../../components/common/ConfirmationDiaglog'
import './HeaderWithTip.scss'

export const HeaderWithTip = (props) => {
  const [isDialogOpen, setDialogOpen] = useState(false)
  return <div className={'hbox align-middle space-between'}>
    <span>Role</span>
    <a className={'header-tip-btn'} href={'#'} onClick={(e) => {
      e.preventDefault()
      setDialogOpen(true)
    }}>
      {RBAC_ROLE_DEFINITION}
    </a>
    <ConfirmationDialog width={'65%'}
      bodyStyle={{ display: 'flex', minHeight: 400, padding: 0 }}
      style={{ top: 80 }}
      visible={isDialogOpen}
      okButtonProps={{ style: { display: 'none' } }}
      onCancel={() => setDialogOpen(false)}
      title={RBAC_ROLE_DEFINITION} cancelText={'Close'}>
      <RoleDefPage/>
    </ConfirmationDialog>
  </div>
}
