import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { FT } from '../utils/activateUtils'

const Reload = ({ history }) => {
  useEffect(() => {
    if (FT('NO_CLASSIC_HUB') || FT('FEDRAMP_HIGH')) {
      window.location.href = '/hub'
    }
    else if (FT('HUB_TSG_IAM')) {
      window.location.reload()
    }
    else {
      history.replace('/')
    }
  }, [history])
  return 'Loading ...'
}

Reload.prpoTypes = {
  history: PropTypes.any
}

export default Reload
