import React, { PureComponent } from 'react'
import { Row, Col, Input, Select } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import HeaderWithTip from '../../components/LoggingCalculator/HeaderWithTip'
import { LSCLabel } from './LSCLabel'
import './LoggingCalculatorPage.css'
import HelpText from './HelpText'
import NumberInput from '../../components/LoggingCalculator/NumberInput'
import { FT } from '@pan/cloud-appframework'

const { Option } = Select
const nope = () => {}
const STANDARD = 'standard'
const CUSTOM = 'custom'
class GPCSRemoteOffice extends PureComponent {
  constructor(props) {
    super(props)
    this.standard = {
      busyHrsUtilization: 65,
      offHrsUtilization: 10,
      busyHrs: 10
    }
    this.state = {
      effectiveLogRate: 0,
      storageDetailLog: 0,
      totalStorage: 0,
      selection: STANDARD
    }
    this.handleSelectionChange = this.handleSelectionChange.bind(this)
  }

  handleChange(type, e) {
    let value = Boolean(e?.target?.value) ? e.target.value : e
    value = Math.abs(value) // we need un-sign int
    this.setState({
      [type]: isNaN(parseInt(value, 10)) ? 0 : parseInt(value, 10)
    }, this.calculateTotalStorage.bind(this))
  }
  handleSelectionChange(value) {
    const defaultStandard = value === STANDARD ? this.standard : {}
    this.setState({
      selection: value,
      ...defaultStandard
    }, this.calculateTotalStorage.bind(this))
  }

  calculateTotalStorage() {
    const {
      retentionPrd = 0,
      offHrsUtilization = 0,
      thrputPurchase = 0,
      busyHrs = 0,
      busyHrsUtilization = 0,
    } = { ...this.props.others, ...this.state }

    /* retentionPrd is 0 by default we have to account for global retention period. if global retention period is present
     and component level retention period is 0 then take that in account global retention period for calculation
     */

    /**
     * TODO: We should put this logic to center place. Currently same logic is used in redux when magnifier clicked
     */
    const primeRetentionPrd = retentionPrd === 0 && this.props.retentionPrd > 0 ? this.props.retentionPrd : retentionPrd
    const { isMagnifierChecked } = this.props

    const effectiveLogRate = isMagnifierChecked ? ((3 * (thrputPurchase * (busyHrsUtilization / 100)) * (busyHrs * 60 * 60)) + (3 * (thrputPurchase * (offHrsUtilization / 100)) * ((24 - busyHrs) * 60 * 60))) / 86400 :
      ((1.5 * (thrputPurchase * (busyHrsUtilization / 100)) * (busyHrs * Math.pow(60, 2))) + (1.5 * (thrputPurchase * (offHrsUtilization / 100)) * ((24 - busyHrs) * Math.pow(60, 2)))) / 86400

    const storageDetailLog = isMagnifierChecked ? (((effectiveLogRate / 2) * 86400 * primeRetentionPrd * 1500) + ((effectiveLogRate / 2) * 86400 * primeRetentionPrd * 600)) / (Math.pow(1024, 3)) :
      (effectiveLogRate * 86400 * primeRetentionPrd * 1500 / Math.pow(1024, 3))

    const totalStorage = FT('PA_CALC_UPDATE_FORMULA') ? storageDetailLog : (storageDetailLog / 0.6)

    this.setState({
      effectiveLogRate,
      storageDetailLog,
      totalStorage,
      retentionPrd: primeRetentionPrd
    }, this.reportToParent.bind(this))

  }

  reportToParent() {
    this.props.totalData({ appId: 'gpcsRemoteNetworks', ...this.state })
  }

  getTipData(type) {
    switch (type) {
      case 'utilization':
        return (
          <Row>
            <Col>
              <p><b>Utilization</b>: Estimate the usage of your branch office firewalls</p>
              <br/>
              <p>Standard represents a daily utilization of 65% over 10 hours (peak or business), and 10% over the remaining hours</p>
              <br/>
              <p>Select <b>Custom</b> if your branch offices are busy for more or less than 10 hours a day.</p>
            </Col>
          </Row>
        )
      default: return null
    }
  }

  render() {
    const { selection } = this.state
    const { others } = this.props
    const { thrputPurchase = 0, retentionPrd = 0, busyHrs = 0, busyHrsUtilization = 0, offHrsUtilization = 0 } = others
    /**
     * if global retention period is present then we should use this.props.retentionPrd unless component overrides it
     */
    const retentionP = this.props.retentionPrd > 0 && (retentionPrd === 0 || retentionPrd === null) ? this.props.retentionPrd : retentionPrd
    const tipStyle = { color: '#999999', fontSize: 14 }
    return (
      <Row type='flex' align='middle' justify={'space-between'} >
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <HeaderWithTip>
            <LSCLabel>Throughput Purchase</LSCLabel>
          </HeaderWithTip>
          <NumberInput value={thrputPurchase}
            defaultValue={0}
            className='logging-calc-ant-input'
            suffix='Mbps'
            onChange={this.handleChange.bind(this, 'thrputPurchase')}
            placeholder='5000 Mbps'/>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <HeaderWithTip tooltipProps={{ title: HelpText('retentionPrd') }} tipIconProps={ { style: tipStyle }}>
            <LSCLabel>Retention Period</LSCLabel>
          </HeaderWithTip>
          <NumberInput value={retentionP}
            defaultValue={30}
            min={30}
            className='logging-calc-ant-input'
            suffix='day(s)'
            onChange={this.handleChange.bind(this, 'retentionPrd')}
            placeholder='30 days'/>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={8}>
          <HeaderWithTip tooltipProps={{ title: this.getTipData('utilization') }} tipIconProps={ { style: tipStyle }}>
            <LSCLabel>Utilization</LSCLabel>
          </HeaderWithTip>
          <Select value={selection} onChange={this.handleSelectionChange} style={{ width: 120 }} >
            <Option value={STANDARD}>Standard</Option>
            <Option value={CUSTOM}>Custom</Option>
          </Select>
        </Col>
        { selection === CUSTOM ?
          <Row style={{ marginTop: 10 }}>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <HeaderWithTip>
                <LSCLabel style={{ whiteSpace: 'normal' }}>Business hours(per day)</LSCLabel>
              </HeaderWithTip>
              <Input defaultValue={0} suffix='hrs.' value={busyHrs} className='logging-calc-ant-input' onChange={this.handleChange.bind(this, 'busyHrs')}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <LSCLabel style={{ whiteSpace: 'normal' }} >Utilization (Business Hours)</LSCLabel>
              <Input defaultValue={0} suffix='%' className='logging-calc-ant-input' value={busyHrsUtilization} onChange={this.handleChange.bind(this, 'busyHrsUtilization')}/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={8}>
              <LSCLabel style={{ whiteSpace: 'normal' }} >Utilization (Non-Business Hours)</LSCLabel>
              <Input defaultValue={0} suffix='%' className='logging-calc-ant-input' value={offHrsUtilization} onChange={this.handleChange.bind(this, 'offHrsUtilization')}/>
            </Col>
          </Row> : null }
      </Row>
    )
  }
}

/**
 *
 * @type {{totalData: *, daysFormatter: *, retentionPrd: *, isMagnifierChecked: *}}
 */
GPCSRemoteOffice.propTypes = {
  totalData: PropTypes.func,
  daysFormatter: PropTypes.func,
  retentionPrd: PropTypes.number,
  others: PropTypes.object,
  isMagnifierChecked: PropTypes.bool
}

/**
 *
 * @type {{totalData: (function(): nope), daysFormatter: (function(): nope), retentionPrd: number}}
 */
GPCSRemoteOffice.defaultProps = {
  totalData: () => nope,
  daysFormatter: () => nope,
  others: {
    thrputPurchase: 0,
    retentionPrd: 0,
    busyHrs: 10,
    busyHrsUtilization: 65,
    offHrsUtilization: 10,
    effectiveLogRate: 0,
    totalStorage: 0,
  },
  retentionPrd: 0
}
export default GPCSRemoteOffice
