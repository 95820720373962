import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { Button } from '@pan/cloud-base'
import AppIcon from './AppIcon'
import { allowAppAdmin } from '../../utils/rbacUtils'
import { APPS, ACTIVATE } from '../../constants/RouteConstants'
import { LGS, STRATA_INSIGHTS_FREE } from '../../constants/AppConstants'

import './Apps.scss'

// eslint-disable-next-line jsx-a11y/anchor-has-content
const ALink = React.memo(({ children, to, ...props }) => (to.startsWith('https:') ?
  <a {...props} href={to}>{children}</a> :
  <Link {...props} to={to}>{children}</Link>
))
ALink.displayName = 'ALink'
ALink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
}

export const OneApp = ({
  appClassName,
  url,
  displayName,
  name,
  description,
  role,
  roles,
  isAuthenticated,
  isNova,
  usePubSub,
  isFake,
  has_auth_code,
  isThirdParty,
  activatable,
  isCob,
  history,
  logo,
  flags,
  extra,
}) => {
  const activateBtn = useMemo(() => {
    const shouldShowBtn = isAuthenticated && isCob && (
      (isThirdParty && activatable !== false) ||
      (activatable && (!has_auth_code || name === LGS) && !isNova && !isFake && !usePubSub && !flags?.use_license_hash_only && allowAppAdmin({ name, role }, roles))
    )
    if (shouldShowBtn) {
      if (name === STRATA_INSIGHTS_FREE) {
        return <Button type='primary' size='small' onClick={() => {
          window.open(`${extra?.redirect_url}/activation/aiops-free`)
        }}>Activate</Button>
      }
      return <Button type='primary' size='small' onClick={() => {
        history.push(ACTIVATE, { referer: APPS, appName: name })
      }}>Activate</Button>
    }
    return null
  }, [isAuthenticated, isCob, isThirdParty, activatable, has_auth_code, name, isNova, isFake, usePubSub, flags?.use_license_hash_only, role, roles, extra?.redirect_url, history])

  return (
    <div className={appClassName}>
      <div className='app_wrapper'>
        <ALink
          className='icon_shadow'
          to={url}
        >
          <AppIcon logo={logo} flags={flags} />
        </ALink>
      </div>
      <div className='app_desc_container'>
        <p className='app_display_name'>{displayName}</p>
        <div className='app_desc'>{description}</div>
        {activateBtn}
        <ALink
          className='learn_more'
          to={url}
        >Learn More</ALink>
      </div>
    </div>
  )
}

OneApp.propTypes = {
  name: PropTypes.string,
  displayName: PropTypes.string,
  logo: PropTypes.string,
  role: PropTypes.string,
  roles: PropTypes.object,
  description: PropTypes.string,
  appClassName: PropTypes.string,
  entitled: PropTypes.bool,
  url: PropTypes.string,
  isCob: PropTypes.bool,
  isNova: PropTypes.bool,
  usePubSub: PropTypes.bool,
  isFake: PropTypes.bool,
  isThirdParty: PropTypes.bool,
  has_auth_code: PropTypes.bool,
  activatable: PropTypes.bool,
  selectedAccount: PropTypes.number,
  isAuthenticated: PropTypes.bool,
  flags: PropTypes.object,
  showAppActivateModal: PropTypes.func,
  history: PropTypes.object,
  extra: PropTypes.object,
}

OneApp.defaultProps = {
  selectedAccount: 0,
  appClassName: '',
  description: ' ',
  url: '/',
}

// we need to pass roles to the component to force it re-render
const mapStateToProps = ({ roles }) => ({ roles })

export default withRouter(connect(mapStateToProps)(React.memo(OneApp)))
