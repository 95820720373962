/**
 *
 * Created by vsuthar on 9/25/18 File AppAssignRole
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Table, Layout, Select, Popover, Icon, Button, Tooltip } from '@pan/cloud-base'
import { hasRole } from '@pan/cloud-appframework'
import { get, cloneDeep, isEmpty } from 'lodash'
import { validateEmail, determineUserPrivilege } from '../../../utils/rbacUtils'
import { isNonemptyArray } from '../../../utils/common'
import { HeaderWithTip } from './HeaderWithTip'
import { renderRole } from '../custom_role/RolePageComponent'
import './../../../common.scss'
import './AssignRole.scss'
import {
  RBAC_ADMIN,
  RBAC_APP_ADMIN_HELP_TEXT,
  RBAC_NOT_APP_ADMIN,
  RBAC_NOT_APP_ADMIN_HELP_TEXT,
  RBAC_INSTANCE_ADMIN,
  RBAC_INSTANCE_ADMIN_HELP_TEXT,
  RBAC_NO_ROLE,
  R_NO_ROLE_ENUM,
  RBAC_NO_ROLE_HELP_TEXT,
  RBAC_ROLE_INSTANCE_ADMIN,
  RBAC_CUSTOM_PERMISSION_ASSIGN,
  PRISMA_SAAS,
  RBAC_PRISMA_SAAS_LINK,
  PRISMA_CLOUD,
  RBAC_PRISMA_CLOUD_LINK,
  ACCOUNT_SUPER_USER,
  EMPTY_SVG, APP_ADMIN,
  RBAC_ROLE_TOOL_TIP,
  RBAC_APP_ADMIN_V2,
  RBAC_ACCOUNT_SUPER_USER_V2,
  RBAC_ROLE_MIX,
  R_CUSTOM,
  R_USER,
  RBAC_ALL_INSTANCES,
  RBAC_CUSTOM_PERMISSIONS,
  RBAC_PERMISSIONS_ASSIGNED
} from '../../../constants/AppConstants'

const { ContainerDimensions } = Layout
const { Option, OptGroup } = Select

const rolesStyle = {
  fontSize: 16,
  color: '#0E9AC8',
  marginRight: 5
}

const skuAdminAppRoles = [
  RBAC_APP_ADMIN_V2,
  RBAC_ACCOUNT_SUPER_USER_V2,
  RBAC_ROLE_MIX,
  R_NO_ROLE_ENUM
]

class AppAssignRole extends PureComponent {
  constructor(args) {
    super(args)
    this.state = {
      selectedRole: 'not_account_super_user',
    }
    this.handleSelectionRoleChange = this.handleSelectionRoleChange.bind(this)
    this.getRoleSelectionRender = this.getRoleSelectionRender.bind(this)
  }


  hideToolTip = () => {
    this.setState({
      showTipHelp: false,
    })
  }

  getHelpTextContent = () => {
    const labelStyle = { width: 160 }
    const descriptionStyle = { width: 200 }
    return (
      <div className={'vbox'} style={{ width: 380 }}>
        <button className={'RBAC__app_assign_tip_button flex-end'} onClick={this.hideToolTip}>
          <Icon type="close"/>
        </button>
        <div className={'hbox'}>
          <span style={labelStyle}><b>{RBAC_ADMIN}</b></span>
          <p style={descriptionStyle}>{RBAC_APP_ADMIN_HELP_TEXT}</p>
        </div>
        <div className={'hbox'}>
          <span style={labelStyle}><b>{RBAC_NOT_APP_ADMIN}</b></span>
          <p style={descriptionStyle}>{RBAC_NOT_APP_ADMIN_HELP_TEXT}</p>
        </div>
        <div className={'hbox'}>
          <span style={labelStyle}><b>{RBAC_INSTANCE_ADMIN}</b></span>
          <p style={descriptionStyle}>{RBAC_INSTANCE_ADMIN_HELP_TEXT}</p>
        </div>
        <div className={'hbox'}>
          <span style={labelStyle}><b>{RBAC_NO_ROLE}</b></span>
          <p style={descriptionStyle}>{RBAC_NO_ROLE_HELP_TEXT}</p>
        </div>
      </div>
    )
  }


  getHelperText = () => {
    const { showTipHelp } = this.state
    return (
      <Popover trigger={'click'} visible={showTipHelp} content={this.getHelpTextContent()}>
        <button className={'RBAC__app_assign_tip_button'} onClick={() => this.setState({ showTipHelp: true })}>
          <Icon type='question-circle' theme={'outlined'}/>
        </button>
      </Popover>
    )
  }

  getRoleHeaderWithTips = () => {
    return !this.props.readOnly ? <HeaderWithTip/> : <span>Role {this.getHelperText()} </span>
  }

  /**
   *
   * @param height
   * @returns {*}
   * rendering table for the All Assign Roles. Height will allow us to render scrollbar if content is offset height
   */
  getTable(height) {
    const { app, useHeight, appLoading } = this.props
    let data = []
    /**
     * @TODO bad code fix it better way this is quick fix
     */
    if (app && app.access && app.access.length > 0) {
      if (app.uniqueId === 'superuser_account_id') {
        data = app.access.slice(0)
      }
      else if (app.access.length === 1) {
        data = app.access.slice(0, app.access.length)
      }
      else {
        data = app.access.filter(instance => {
          return instance && instance.name !== APP_ADMIN
        })
      }

    }
    const columns = [
      {
        Header: '',
        accessor: 'isDirty',
        width: 30,
        style: { alignSelf: 'auto' },
        Cell: ({ value }) => {
          return value ? <div
            style={{ marginTop: 10, height: 10, backgroundColor: 'orange', width: 10, borderRadius: '100%' }}/> : null
        },
      },
      {
        Header: 'Instances',
        accessor: 'name',
        Cell: (props) => {
          return props.value || props.original.tenant_id
        },
      },
      {
        Header: this.getRoleHeaderWithTips,
        accessor: 'roles',
        Cell: this.getRoleSelectionRender,
      },
    ]
    const style = useHeight ? { borderColor: '#e8e8e8', height } : { borderColor: '#e8e8e8' }
    return (
      <Table style={style}
        loading={appLoading}
        styles={{
          trGroup: {
            flex: '0 0 auto',
            minHeight: 'none',
          },
        }}
        columns={columns}
        data={data}/>
    )
  }

  editPermission(value, original) {
    this.props.editPermission(value, original)
  }

  /**
   *
   * @param value
   * @param option
   * @param original
   */
  handleSelectionRoleChange(value, option, original) {
    /**
     * getting rbac_app_type from app. so,
     * it will help us to iterate over the which app and find correct app from entitlements list
     */
    if (value === 'add_custom_role') {
      /**
       * Open App Assign Permission dialog box here
       */
      const { tenant_id, tenantSkus } = original
      this.props.openAssignCustomPermission(true, tenant_id, tenantSkus)
    }
    else {
      const { app } = this.props
      const { rbac_app_type, role } = app
      if (this.props.app.uniqueId === 'superuser_account_id') {
        this.setState({
          selectedRole: value,
        })
      }
      this.props.selectedInstanceRole(value, { ...original, role }, rbac_app_type, option)
    }

  }
  /**
   * filter app specific rbac schema scopes with tenant specific skus
   *
   */
  filterSchemaScope = (customRoleScopes, schemaPerApp, tenantSkus) => {
    const isEmptyTenantSkus = isEmpty(tenantSkus)
    const validScopeCount = customRoleScopes.reduce((count, customRoleScope) => {
      const skuList = schemaPerApp?.scopes?.find((item) => item.scope === customRoleScope)?.sku || []
      if (isEmptyTenantSkus || tenantSkus.some(sku => skuList.includes(sku))) {
        count += 1
      }
      return count
    }, 0)
    return validScopeCount ? `${validScopeCount} ${RBAC_PERMISSIONS_ASSIGNED}` : RBAC_CUSTOM_PERMISSIONS
  }

  findUserRoleScope = (selectedUserRole, customRole, tenant_id, schemaPerApp, tenantSkus) => {
    const find = (customRole || []).find((eachRole) => eachRole?._rid === selectedUserRole && eachRole?.tenant_id === tenant_id)

    if (find?.scope) {
      return this.filterSchemaScope(find.scope, schemaPerApp, tenantSkus)
    }
    return RBAC_CUSTOM_PERMISSIONS

  }

  /**
   *  compare tenant skus with rbac sku list(rbacSkuMap)
   *  if SKU is mismatched it will not show in role selection dropdown options (i.e. return null)
   *  expect current selected role option (since user needs to decide and change to new valid role),
   */
  isTenantSkuValid = (rbacSkuMap, app_id, _rid, rbac_role_selected, tenantSkus) => {
    const roleSkuList = rbacSkuMap?.[app_id]?.[_rid]?.sku || []
    const isSkuMismatch = !(roleSkuList.some(schemaSku => tenantSkus.some(tenantSku => tenantSku === schemaSku) || schemaSku === '*')) // * implies no sku list found in rbac schema
    return _rid !== rbac_role_selected && !skuAdminAppRoles.includes(_rid) && isSkuMismatch
  }

  customRolesOptGroupV2 = (customRole, tenantSkus, app_id, rbac_role_selected, rbacSkuMap) => {
    const selectedRoleRbacSkuList = rbacSkuMap?.[app_id]?.[rbac_role_selected]?.sku || []
    const isTenantSkus = isNonemptyArray(tenantSkus) // if empty sku mismatch check will bypass and user will get and all roles and scopes

    return <OptGroup label={'Roles'}>
      {customRole.map((role = {}, idx) => {
        const { _rid, type } = role || {}
        let { name } = role || {}

        if ((type === R_USER) || (type !== R_CUSTOM && isTenantSkus && this.isTenantSkuValid(rbacSkuMap, app_id, _rid, rbac_role_selected, tenantSkus))) return null

        if (_rid && name) {
          /**
           * For RBAC V2 and Custom role for instances app_superuser will be instance admin
           */
          name = _rid === RBAC_APP_ADMIN_V2 ? RBAC_INSTANCE_ADMIN : name

          const isSelectedRoleMismatch = (
            isTenantSkus && _rid !== RBAC_ROLE_MIX &&
            type !== R_CUSTOM &&
            rbac_role_selected !== R_NO_ROLE_ENUM &&
            _rid === rbac_role_selected &&
            !(selectedRoleRbacSkuList.some(schemaSku => tenantSkus.some(tenantSku => tenantSku === schemaSku) || schemaSku === '*'))
          )
          const renderLabel = isSelectedRoleMismatch ? <Tooltip placement='left' title={RBAC_ROLE_TOOL_TIP}>
            <Icon type="info-circle" style={rolesStyle} />{renderRole({ original: { type }, value: name })}
          </Tooltip> : renderRole({ original: { type }, value: name })

          return <Option type={type} disabled={role.disabled} key={`${role._rid}:${idx}`}
            value={role._rid}>{renderLabel}</Option>
        }
        return null
      })}
    </OptGroup>
  }

  rbacRolesOptGroup = (rbac_roles) => {
    return <OptGroup label={'Roles'}>
      {rbac_roles.map((role, idx) => {
        const { _rid, name } = role
        if (_rid && name) {
          return <Option disabled={role.disabled} key={`${role._rid}:${idx}`}
            value={role._rid}>{name}</Option>
        }
        return null
      })}
    </OptGroup>
  }
  /**
   *
   * @param row
   * @returns {*}
   */
  getRoleSelectionRender(row) {
    const { original } = row
    const { rbac_roles = [], name, rbac_role_selected, tenant_id, parentAppRole: app_id, tenantSkus = [] } = original
    const { app, readOnly, roleDefMap, rbacSkuMap, schemaPerApp } = this.props
    /* below logic define when we should disabled the select drop down
    we are setting set selectedRole only for superuser which is global for all the roles
    ex: if Account page user select account_super_user that time we need to disable all the other
    app select dropdown menu*/
    let isDisabled = false
    const { disabled, isChildDisabled } = app
    if (disabled && isChildDisabled) {
      isDisabled = true
    }
    else if (isChildDisabled && name !== RBAC_ALL_INSTANCES) {
      isDisabled = true
    }

    /**
     * Regex for check if role contain email address. This will determine if the role is user role or not.
     * @type {boolean}
     */
    const isUserRole = validateEmail(rbac_role_selected)

    if (readOnly) {

      /*const selectedRole = rbac_roles.find((role) => {
        const { _rid } = role
        /!**
         * We have to convert newer role with older one when we display
         *!/
        return _rid === rbac_role_selected
      })*/
      let displayRole = determineUserPrivilege(rbac_role_selected, app?.app_id)
      displayRole = displayRole === RBAC_ROLE_INSTANCE_ADMIN ? RBAC_INSTANCE_ADMIN : displayRole
      return isUserRole ? RBAC_CUSTOM_PERMISSION_ASSIGN : displayRole
    }

    const hasCorrectRole = tenant_id === '*' ? true : hasRole(`${app_id}:Instance Admin:${tenant_id}`)
    if (rbac_roles && rbac_roles.length) {
      const support_custom_role = get(app, 'rbac_role.support_custom_role', false)
      //THIS IS HACK
      let rbacRoles = rbac_roles
      if (!support_custom_role && (roleDefMap[app.app_id] || []).length && name !== 'All Instances') {
        rbacRoles = roleDefMap[app.app_id].filter(each => Boolean(each)).map(each => {
          if (each.name === 'Administrator') {
            each.name = RBAC_INSTANCE_ADMIN
          }
          return each
        })
      }
      const customRole = cloneDeep(roleDefMap[app.app_id]) || []
      return (
        <div className={'hbox'}>
          <Select style={{ flex: 1 }}
            value={isUserRole ? this.findUserRoleScope(rbac_role_selected, customRole, tenant_id, schemaPerApp, tenantSkus) : rbac_role_selected}
            className={isUserRole ? 'RBAC_app_assign_select' : ''}
            disabled={ !hasCorrectRole || (isDisabled || this.props.appLoading) }
            onChange={(value, options) => this.handleSelectionRoleChange(value, options, original)}>
            {support_custom_role && name !== 'All Instances' ?
              <Option key={'add_custom_role'} value={'add_custom_role'}>Assign Permission</Option> : null}
            {/*{this.customRolesOptGroupV2(customRole, tenantSkus, app_id, rbac_role_selected, rbacSkuMap)}*/}
            { support_custom_role && name !== 'All Instances' ?
              this.customRolesOptGroupV2(customRole, tenantSkus, app_id, rbac_role_selected, rbacSkuMap) :
              this.rbacRolesOptGroup(rbacRoles)
            }
          </Select>
          {isUserRole ?
            <Button v2={false} className={'RBAC__edit_permission_button'}
              onClick={() => this.editPermission('edit_permission', original)}>Edit</Button> : null}
        </div>
      )
    }
    return null

  }

  /**
   * Get app icon based on the app type
   */
  getImage() {
    const { rbac_app_type, logo } = this.props.app
    switch (rbac_app_type) {
      case ACCOUNT_SUPER_USER:
        return EMPTY_SVG
      default:
        return logo || EMPTY_SVG
    }
  }


  /**
   *
   * @returns {*}
   * Render component based on app selection
   */
  getAssignRoles() {
    const { app, height } = this.props
    const { display_name } = app
    return (
      <div className='vbox flex-box-fill RBAC__app_assign_role_container' style={{ minHeight: height }}>
        <div className='hbox RBAC__app_assign_role_header'>
          <img style={{ marginRight: '.5rem' }} src={this.getImage()} alt={'App Name'} width={32} height={32}/>
          <span>{display_name}</span>
        </div>
        <div className='flex-box-fill'>
          <ContainerDimensions>
            {({ height }) => {
              return <div style={{ height, overflow: 'auto', minHeight: 100 }}>
                {this.getTable(height)}
              </div>
            }}
          </ContainerDimensions>
        </div>
      </div>
    )
  }

  showMessageNonRBACSupport({ display_name, app_id }) {
    let link = ''
    switch (app_id) {
      case PRISMA_SAAS:
        link = RBAC_PRISMA_SAAS_LINK
        break
      case PRISMA_CLOUD:
        link = RBAC_PRISMA_CLOUD_LINK
        break
      default:
        link = ''
    }
    // put the link if u have link otherwise just keep is blank
    const helpLink = link ? <a href={link} target='_blank' rel='noopener noreferrer'>{display_name}</a> : display_name
    return (
      <div className='vbox flex-box-fill align-items-center justify-center'>
        <h4 style={{ opacity: 0.5, padding: 50, textAlign: 'center' }}><span>You can not manage {display_name} roles using the hub at this time. Please see
          the </span>{helpLink}<span> product documentation for information on how to manage roles for that app.</span>
        </h4>
      </div>
    )
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { app } = this.props
    if (app && Object.keys(app).length) {
      const { rbac_role } = app
      if (rbac_role && rbac_role.hasOwnProperty('not_allowed_manage') && rbac_role.not_allowed_manage === true) {
        return this.showMessageNonRBACSupport(app)
      }
      else {
        return this.getAssignRoles()
      }

    }
    else {
      return (
        <div className='vbox flex-box-fill align-items-center justify-center'>
          <h1 style={{ opacity: 0.5 }}>Please select an app to continue.</h1>
        </div>
      )
    }
  }
}

/**
 *
 * @type {{app: *, selectedUserRoles: *, roles: *, defaultAppRoles: *, selectedUsers: *, appLoading: *, readOnly: *, useHeight: *, roleDefMap: *, selectedInstanceRole: *, openAssignCustomPermission: *, editPermission: *, height: *}}
 */
AppAssignRole.propTypes = {
  app: PropTypes.object,
  height: PropTypes.number,
  roles: PropTypes.array,
  defaultAppRoles: PropTypes.object,
  appLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  selectedUserRoles: PropTypes.array,
  selectedInstanceRole: PropTypes.func,
  useHeight: PropTypes.bool,
  openAssignCustomPermission: PropTypes.func,
  roleDefMap: PropTypes.object,
  editPermission: PropTypes.func,
  selectedUsers: PropTypes.array,
  rbacSkuMap: PropTypes.object,
  schemaPerApp: PropTypes.object
}
/**
 *
 * @type {{selectedUserRoles: [], roleDefMap: [], selectedInstanceRole: AppAssignRole.defaultProps.selectedInstanceRole, openAssignCustomPermission: AppAssignRole.defaultProps.openAssignCustomPermission, roles: [], defaultAppRoles: {}, selectedUsers: [], readOnly: boolean, appLoading: boolean, editPermission: AppAssignRole.defaultProps.editPermission, useHeight: boolean}}
 */
AppAssignRole.defaultProps = {
  readOnly: false,
  roles: [],
  selectedUserRoles: [],
  defaultAppRoles: {},
  appLoading: false,
  selectedInstanceRole: () => {
  },
  useHeight: true,
  openAssignCustomPermission: () => {
  },
  roleDefMap: {},
  editPermission: () => {
  },
  selectedUsers: [],
  rbacSkuMap: {},
  schemaPerApp: {}
}
export default AppAssignRole
