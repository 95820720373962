import Apps from '../components/Apps'
import MarketPlace from '../components/MarketPlace'
import Activate from '../components/Activate'
import Setup from '../containers/Setup'
import Settings from '../components/Settings/Settings'
import LoggingServiceCalculator from '../containers/LoggingCalculator/LoggingCalculatorPage'
import RolesPage from '../containers/Roles/RolePage'
import AssignRolePage from '../containers/Roles/assignRoles/AssignRolePage'
import AccountPage from '../containers/Account/AccountInfoPage'
import CustomRoleDefPage from '../containers/Roles/custom_role/CustomRoleDefPage'
import * as RConstants from './../constants/RouteConstants'
import RegistrationPage from '../containers/Registration/RegistrationPage'
import Reload from '../components/Reload'
// import ComingSoon from '../components/ComingSoon'
import { FT } from '../utils/activateUtils'

const FT_NO_CLASSIC_HUB = FT('NO_CLASSIC_HUB') || FT('FEDRAMP_HIGH')

export default [
  {
    path: RConstants.APPS,
    component: Apps,
    text: 'Apps',
    defaultPath: true,
    public: true
  },
  (FT('HUB_TSG_IAM') && {
    path: RConstants.SPARKY_SHELL,
    component: Reload,
  }),
  {
    path: RConstants.ACTIVATE,
    component: FT_NO_CLASSIC_HUB ? Reload : Activate
  },
  {
    path: RConstants.MARKET_PLACE_NAME,
    component: MarketPlace
  },
  {
    path: RConstants.MARKET_PLACE,
    redirectTo: RConstants.APPS,
  },
  {
    path: RConstants.SETTINGS,
    component: FT_NO_CLASSIC_HUB ? Reload : Settings
  },
  {
    path: RConstants.LOGGING_SERVICE_CALC,
    redirectTo: FT('STRATA_LOGGING_SERVICE_REBRAND') ? RConstants.STRATA_SIZING_ESTIMATOR : RConstants.CORTEX_SIZING_ESTIMATOR
  },
  {
    path: RConstants.CORTEX_SIZING_CALCULATOR,
    redirectTo: FT('STRATA_LOGGING_SERVICE_REBRAND') ? RConstants.STRATA_SIZING_ESTIMATOR : RConstants.CORTEX_SIZING_ESTIMATOR
  },
  {
    path: RConstants.CORTEX_SIZING_ESTIMATOR,
    component: LoggingServiceCalculator
  },
  {
    path: RConstants.STRATA_SIZING_ESTIMATOR,
    component: LoggingServiceCalculator
  },
  {
    path: RConstants.ROLES,
    component: FT_NO_CLASSIC_HUB ? Reload : RolesPage,
  },
  {
    path: RConstants.ASSIGN_ROLES,
    component: FT_NO_CLASSIC_HUB ? Reload : AssignRolePage
  },
  {
    path: RConstants.ACCOUNT_INFO,
    component: FT_NO_CLASSIC_HUB ? Reload : AccountPage
  },
  {
    path: RConstants.ROLE_DEF,
    component: CustomRoleDefPage
  },
  {
    path: RConstants.SETUP_LINK,
    component: Setup
  },
  {
    path: RConstants.CONTEXT_SESSION,
    component: Setup
  },
  {
    path: RConstants.SETUP_REGISTRATION,
    component: RegistrationPage
  },
  FT('ELA_IOT_SAAS') && {
    path: RConstants.ELA_SETUP_LINK,
    component: Setup,
  },
].filter(Boolean)
