import React, { Suspense, lazy, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { appframeworkActions, Images } from '@pan/cloud-appframework'

const SetupPage = lazy(() => import('./SetupPage'))

const { setAppBarProps } = appframeworkActions
const { HUB_V2_Logo } = Images

const SetupSuspense = (props) => {
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    const set = (props) => dispatch(setAppBarProps(props))
    set({ hideAppBar: true })
    return () => set({
      hideAppBar: false,
      isVertical: false,
      allowHubLinkEvent: true,
      logoImg: HUB_V2_Logo
    })
  }, [dispatch])

  return (
    <Suspense fallback={null}>
      <SetupPage {...props} />
    </Suspense>
  )
}

export default SetupSuspense
