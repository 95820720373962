import axios from 'axios'
import EventWatcher from '../components/EventWatcher'
import AppCommonAddons from '../components/AppCommonAddons'
import ElaActivation from '../components/ElaActivation'
import HubToggle from '../components/HubToggle'
import SettingsDropDown from '../components/SettingsDropDown'
import { APP_PORTAL_DISPLAY_NAME } from '../constants/AppConstants'
import { Images } from '@pan/cloud-appframework'

const { HUB_V2_Logo } = Images

export default {
  logo: HUB_V2_Logo,
  name: APP_PORTAL_DISPLAY_NAME,
  path: 'app',
  title: 'Hub',
  hasSettings: false,
  hasUserAccountInfo: true,
  navigateHome: true,
  commonAddons: [EventWatcher, AppCommonAddons],
  navItems: [HubToggle, ElaActivation],
  navUtils: [SettingsDropDown],
  helpLink: 'https://docs.paloaltonetworks.com/hub',
  appInitAction: (dispatch) => axios.get('/api/v1/apps').then((response) => {
    dispatch({
      type: 'FETCH_APPS_SUCCESS',
      apps: response.data
    })
  })
}
