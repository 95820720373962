import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Icon, Row, Col, Button, Table, Menu, Dropdown, Tooltip } from '@pan/cloud-base'
import CustomRoleDialog from './CustomRoleDailog'
import {
  R_TYPE,
  R_USER,
  RBAC_DELETE_ROLE,
  RBAC_ROLE_CLONE,
  RBAC_ROLE_EDIT_PERMISSIONS,
  RBAC_ROLE_USER_CREATED_ROLE,
  RBAC_ROLE_VIEW_ROLE,
} from '../../../constants/AppConstants'
import { ROLES } from '../../../constants/RouteConstants'
import ConfirmationDialog from '../../../components/common/ConfirmationDiaglog'
import SearchInput from '../../../components/RBAC/SearchInput'
import { useDebouncedCallback } from '../../../utils/hooks'
import './RolePageComponent.scss'

/**
 *
 * @param obj
 * @param e
 * @param handleCustomRoleAction
 * @param confirmationDialogAction
 */
const menuClick = (obj, e, handleCustomRoleAction, confirmationDialogAction) => {
  if (e.key === 'delete') {
    const { role_label, role } = obj
    confirmationDialogAction({
      visible: true,
      title: 'Delete Role',
      confirmationBody: `Are you sure you want to delete ${role_label || role}?`,
      onOk: () => handleCustomRoleAction(obj, e),
      okButtonProps: { loading: true },
      onCancel: () => {
        confirmationDialogAction({
          visible: false
        })
      }

    }, obj)
  }
  else {
    handleCustomRoleAction(obj, e)
  }
}


const actionMenu = (value, handleCustomRoleAction, obj, confirmationDialogAction) => {
  return <Menu onClick={(e) => menuClick(obj, e, handleCustomRoleAction, confirmationDialogAction)}>
    {value === 'custom' ? <Menu.Item key={'edit_permission'}>{RBAC_ROLE_EDIT_PERMISSIONS}</Menu.Item> : <Menu.Item key={'view_role'}>{RBAC_ROLE_VIEW_ROLE}</Menu.Item>}
    {obj.role === 'app_superuser' || obj.role === 'account_superuser' ? null : <Menu.Item key={'clone'}>{RBAC_ROLE_CLONE}</Menu.Item>}
    {value === 'custom' ?
      <Menu.Item key={'delete'}>{RBAC_DELETE_ROLE}</Menu.Item> : null}
  </Menu>
}

const actionRender = (handleCustomRoleAction, confirmationDialogAction, { value, original }) => {
  return (
    <Dropdown trigger={['click']} overlay={actionMenu(value, handleCustomRoleAction, original, confirmationDialogAction)}>
      <Button size={'small'}>Actions<Icon style={{ marginLeft: 5 }} type="down" /></Button>
    </Dropdown>
  )
}

actionRender.propTypes = {
  value: PropTypes.string
}


/**
 * Render role column with badge
 * @param original
 * @param value
 * @returns {*}
 */
const renderRole = ({ original, value }) => {
  const { type } = original
  return type === 'custom' ? <div className={'hbox align-middle'}>{value}
    <Tooltip placement={'right'} title={RBAC_ROLE_USER_CREATED_ROLE}><div className={'center uc-badge '}>UC</div></Tooltip>
  </div> : value
}

/**
 *
 * @type {{original: *, value: *}}
 */
renderRole.propTypes = {
  original: PropTypes.object,
  value: PropTypes.any
}

/**
 *
 * @returns {*[]}
 */
const getCol = (handleCustomRoleAction, confirmationDialogAction) => [
  {
    Header: 'Role',
    accessor: 'role_label',
    maxWidth: 240,
    Cell: renderRole,
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Actions',
    accessor: 'type',
    width: 150,
    Cell: actionRender.bind(null, handleCustomRoleAction, confirmationDialogAction)
  }
]


/**
 *
 * @param getRolesDefPerApp
 * @param roleDef
 * @param searchRoles
 * @param isRoleSearching
 * @param filteredRoleDef
 * @param schemaPerApp
 * @param schemaTree
 * @param history
 * @param isAuthenticated
 * @param createCustomRole
 * @param custom_role
 * @param handleCustomRoleAction
 * @param openCloseCustomRoleDiag
 * @param isCustomRoleDialOpen
 * @param roleToBeView
 * @param dialogActionType
 * @param customRoleDialogTitle
 * @param isCustomRoleTableLoading
 * @param confirmationDialogProps
 * @param confirmationDialogAction
 * @param selectedApp
 * @param onTreeChecked
 * @returns {*}
 * @constructor
 */

const RolePageComponent = ({ getRolesDefPerApp,
  roleDef,
  searchRoles,
  isRoleSearching,
  filteredRoleDef,
  schemaPerApp,
  schemaTree,
  history,
  isAuthenticated,
  createCustomRole,
  custom_role,
  handleCustomRoleAction,
  openCloseCustomRoleDiag,
  isCustomRoleDialOpen,
  roleToBeView,
  dialogActionType,
  customRoleDialogTitle,
  isCustomRoleTableLoading,
  confirmationDialogProps,
  confirmationDialogAction,
  selectedApp,
  onTreeChecked,
  todayDate
}) => {
  roleDef = (roleDef || []).filter(role => role[R_TYPE] !== R_USER)
  const { confirmationBody, ...otherConfirmProps } = confirmationDialogProps
  useEffect(() => {
    if (!isAuthenticated) {
      history.push(ROLES)
    }
    else {
      if (custom_role?.hasOwnProperty('success') && custom_role?.success && custom_role.success.length) {
        openCloseCustomRoleDiag(false)
      }
      if (selectedApp?.app_id) {
        getRolesDefPerApp(selectedApp.app_id)
      }

    }
    // if custom_roles is come then set success message


  }, [custom_role, getRolesDefPerApp, history, isAuthenticated, isCustomRoleDialOpen, openCloseCustomRoleDiag, selectedApp])

  const onRoleSearch = useDebouncedCallback((e) => {
    searchRoles(e.target.value)
  }, 700, [searchRoles])

  return (
    <div className={'vbox custom-role-page'}>
      <Row gutter={12}>
        <Col xs={24}>
          <SearchInput onChange={onRoleSearch} />
        </Col>
      </Row>
      <div className='flex-box-fill'>
        <Table
          styles={{
            trGroup: {
              flex: '0 0 auto',
              minHeight: 'none'
            },
            noData: {
              top: '20%',
              pointerEvents: 'inherit'
            }
          }}
          autoScrollbar
          loading={isCustomRoleTableLoading}
          style={{ border: '1px solid #c8cbce', marginTop: 17, background: '#fff' }}
          columns={getCol(handleCustomRoleAction, confirmationDialogAction)}
          data={isRoleSearching ? filteredRoleDef || [] : roleDef || []} />
      </div>
      <ConfirmationDialog {...otherConfirmProps}>
        {confirmationBody}
      </ConfirmationDialog>
      <CustomRoleDialog visible={isCustomRoleDialOpen}
        createCustomRole={createCustomRole}
        maskClosable={false}
        closable={false}
        roleDef={roleDef}
        bodyStyle={{ padding: '24px 0', margin: 0 }}
        roleToBeView={roleToBeView}
        custom_role={custom_role}
        width={850}
        schemaPerApp={schemaPerApp}
        schemaTree={schemaTree}
        onTreeChecked={onTreeChecked}
        style={{ top: 20 }}
        dialogActionType={dialogActionType}
        disabledTree={dialogActionType === 'read_only'}
        title={customRoleDialogTitle}
        todayDate={todayDate}
        onOk={() => openCloseCustomRoleDiag(false)}
        onCancel={() => openCloseCustomRoleDiag(false)} />
    </div>

  )
}

const nope = () => {}
/**
 *
 * @type {{isCustomRoleDialOpen: boolean, customRoleDialogTitle: string, isCustomRoleTableLoading: boolean, createCustomRole: *, isAuthenticated: boolean, handleCustomRoleAction: *, openCloseCustomRoleDiag: *, filteredRoleDef: [], appLoadingState: boolean, roleToBeView: {}, isConfirmationOpen: boolean, isRoleSearching: boolean, roleDef: [], dialogActionType: string}}
 */
RolePageComponent.defaultProps = {
  roleDef: [],
  isRoleSearching: false,
  filteredRoleDef: [],
  isAuthenticated: false,
  createCustomRole: nope,
  handleCustomRoleAction: nope,
  openCloseCustomRoleDiag: nope,
  isCustomRoleDialOpen: false,
  roleToBeView: {},
  appLoadingState: false,
  dialogActionType: 'edit',
  customRoleDialogTitle: 'Create Role',
  isCustomRoleTableLoading: false,
  confirmationDialogProps: {
    visible: false
  },
  confirmationDialogAction: nope,
  todayDate: new Date().toDateString()
}
/**
 *
 * @type {{getSchemaDef: *, isCustomRoleDialOpen: *, customRoleDialogTitle: *, isCustomRoleTableLoading: *, createCustomRole: *, custom_role: *, isAuthenticated: *, history: *, handleCustomRoleAction: *, openCloseCustomRoleDiag: *, filteredRoleDef: *, appLoadingState: *, confirmationBody: *, searchRoles: *, roleToBeView: *, isConfirmationOpen: *, isRoleSearching: *, schemaTree: *, schemaPerApp: *, roleDef: *, getRolesDefPerApp: *, dialogActionType: *}}
 */
RolePageComponent.propTypes = {
  getSchemaDef: PropTypes.func,
  getRolesDefPerApp: PropTypes.func,
  roleDef: PropTypes.array,
  searchRoles: PropTypes.func,
  isRoleSearching: PropTypes.bool,
  filteredRoleDef: PropTypes.array,
  schemaPerApp: PropTypes.object,
  schemaTree: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
  createCustomRole: PropTypes.func,
  custom_role: PropTypes.object,
  handleCustomRoleAction: PropTypes.func,
  openCloseCustomRoleDiag: PropTypes.func,
  isCustomRoleDialOpen: PropTypes.bool,
  roleToBeView: PropTypes.object,
  appLoadingState: PropTypes.bool,
  dialogActionType: PropTypes.string,
  customRoleDialogTitle: PropTypes.string,
  isCustomRoleTableLoading: PropTypes.bool,
  confirmationDialogProps: PropTypes.object,
  confirmationDialogAction: PropTypes.func,
  selectedApp: PropTypes.object,
  onTreeChecked: PropTypes.func,
  todayDate: PropTypes.string
}
export { renderRole }
export default RolePageComponent

