import React from 'react'
import { CheckboxTable as AppTable } from '@pan/cloud-base'
import '../../../containers/Roles/AssignRoleContent.scss'


class RoleTable extends React.PureComponent {
  render() {
    return (
      <AppTable sortable={true}
        keyField={'email'}
        showPagination={true}
        className='role-table-bg' {...this.props}/>
    )
  }
}

export default RoleTable
