const globalLoadingMessage = (state = '', action) => {
  switch (action.type) {
    case 'GLOBAL_LOADING_MASK_OFF':
    case 'SET_ENTITLED_APPS':
    case 'FETCH_ENTITLEMENTS_SUCCESS':
    case 'FETCH_ENTITLEMENTS_FAILURE':
      return ''
    case 'SET_CURRENT_SUPPORT_ACCOUNT_INDEX':
      return 'Switching account...'
    case 'FETCH_CREDENTIALS_SUCCESS':
    case 'FETCH_ENTITLEMENTS_START':
      return 'Loading your apps...' // override APPORTAL-2274
    case 'SET_GLOBAL_LOADING_MASK_MSG':
      return action.message || ''
    default:
      return state
  }
}

export default globalLoadingMessage
