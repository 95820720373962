/**
 * This form is used for roleDefinition page
 * This will show definition of the each role and custom role with tree
 *
 */
import React from 'react'
import { Form } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import { RBAC_ROLE_DESCRIPTION, RBAC_ROLE_NAME, RBAC_ROLE_SELECT_PERMISSION } from '../../../constants/AppConstants'
import RBACScopeTree from '../../../components/RBAC/RBACScopeTree'
import './RoleForm.scss'

const FormItem = Form.Item

const labelStyle = {
  fontSize: 14,
  letterSpacing: 0.1,
  color: '#1d2124',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  whiteSpace: 'break-spaces'
}
const RoleForm = ({ roleToBeView, schemaTree, schemaPerApp }) => {
  const { role_label, role, description } = roleToBeView
  const { support_custom_role = false } = schemaPerApp
  return <Form layout='vertical' style={{ paddingLeft: 23 }}>
    <FormItem label={RBAC_ROLE_NAME}>
      <p style={labelStyle}>{role_label || role}</p>
    </FormItem>
    <FormItem label={RBAC_ROLE_DESCRIPTION}>
      <p style={labelStyle}>{description}</p>
    </FormItem>
    {
      support_custom_role && schemaTree.length ? <FormItem label={RBAC_ROLE_SELECT_PERMISSION}>
        <RBACScopeTree defaultExpandParent={true} disabledTree={true} checkable={true}
          checkedKeys={roleToBeView.checkedNode || []} schemaTree={schemaTree}/>
      </FormItem> : null
    }
  </Form>
}
/**
 *
 * @type {{roleToBeView: {}}}
 */
RoleForm.defaultProps = {
  roleToBeView: {},
  schemaTree: [],
  schemaPerApp: {}


}
/**
 *
 * @type {{roleToBeView: *}}
 */
RoleForm.propTypes = {
  roleToBeView: PropTypes.object,
  schemaTree: PropTypes.array,
  schemaPerApp: PropTypes.object
}

export default RoleForm
