/**
 * Created by vsuthar on 5/11/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useLayoutEffect, useState, useMemo, useEffect, useCallback } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import RegistrationForm from './RegistrationForm'
import { Button, Col, Row } from '@pan/cloud-base'
import { Images, appframeworkActions } from '@pan/cloud-appframework'
import {
  REGISTRATION_CREATE_NEW_ACCOUNT,
  REGISTRATION_QUIT,
  CONTACT_SUPPORT_URL,
} from '../../constants/AppConstants'
import '../Setup/setupbase.scss'
import './registration-form-header.scss'
import { USER_GET_ACCOUNTS, USER_REGISTRATION } from '../../config/actions/URLs'
import SetUpErrorCard from '../../components/Setup/SetUpErrorCard'
import { APPS } from '../../constants/RouteConstants'
import { ShieldSVG } from '../../images/svg-icons'

const { HUB_V2_Logo } = Images
const { setAppBarProps } = appframeworkActions

const RegistrationPage = ({ history }) => {
  const dispatch = useDispatch()
  useLayoutEffect(() => {
    const set = (props) => dispatch(setAppBarProps(props))
    set({ hideAppBar: true })
    return () => set({
      hideAppBar: false,
      isVertical: false,
      allowHubLinkEvent: true,
      logoImg: HUB_V2_Logo
    })
  }, [dispatch])


  const [isLoading, setLoading] = useState(false)
  const [{ isError, errorMsg }, setError] = useState({ isError: false, errorMsg: '' })
  const [supportAccountIds, setSupportAccountIds] = useState([])
  const [supportActErrMsg, setSupportAccountErrorMsg] = useState('')

  const relayState = sessionStorage.getItem('relayState') || ''
  const hash = relayState.startsWith('/setup/') ? relayState.substr(relayState.lastIndexOf('/') + 1, relayState.length) : null

  useEffect(() => {
    if (hash) {
      axios({ url: USER_GET_ACCOUNTS, params: { hash } })
        .then((res) => {
          const { support_accounts } = res?.data
          setSupportAccountIds(support_accounts)
        })
        .catch((e) => {
          const { data } = e?.response
          const message = data?.message?.startsWith('No available account') ? 'No Support Account Found.' : data?.message
          setSupportAccountErrorMsg(message || 'We have encountered a critical error and cannot continue')
        })
    }

  }, [hash])


  const onRegisterUser = useCallback((form, values) => {
    values = { ...values, eventType: 'create', relayState }
    const data = _.pick(values, ['password', 'firstName', 'lastName', 'supportAccountId', 'companyName', 'email', 'relayState'])
    setLoading(true)
    axios({ url: `${USER_REGISTRATION}`, method: 'POST', data: data })
      .then((res) => {
        form.resetFields()
        setLoading(false)
        setError({ isError: false, errorMsg: 'success_register' })
        sessionStorage.removeItem('relayState')
        return res.data
      })
      .catch((e) => {
        form.resetFields()
        const { data } = e?.response
        const message = data?.message || 'We have encountered a critical error and cannot continue'
        setLoading(false)
        setError({ isError: true, errorMsg: message })
      })
  }, [relayState])

  const handleQuite = useCallback(() => {
    history.push(APPS)
  }, [history])

  const getErrorPage = useMemo(() => {

    if (errorMsg === 'success_register' && !isError) {

      return <Row className={'setup-card'}>
        <Row type={'flex'} style={{ padding: '30px 0' }} className={'setup-card-header'} justify={'center'}>
          <img height={50} src={Images.PANW_ORANGE_BLACK} alt={''} />
        </Row>
        <SetUpErrorCard errorMsg={'Please check your email for verification.'} bodyIcon={ShieldSVG}/>
      </Row>
    }
    else if (isError) {
      return <Row className={'setup-card'}>
        <Row type={'flex'} style={{ padding: '30px 0' }} className={'setup-card-header'} justify={'center'}>
          <h1>Error: Unhandled Exception</h1>
        </Row>
        <SetUpErrorCard errorMsg={errorMsg}/>
        <Row className='setup-base-footer' type={'flex'} justify={'space-between'} align={'middle'}>
          <Col>
            <Button v2={false} onClick={handleQuite}>{REGISTRATION_QUIT}</Button>
          </Col>
          <Col>
            <Button v2={false} type={'danger'} style={{ marginLeft: 5 }}>
              <a href={CONTACT_SUPPORT_URL} target="_blank" rel="noopener noreferrer">Contact Support</a>
            </Button>
          </Col>
        </Row>
      </Row>
    }
    else {
      return <Row className={'setup-card'}>
        <Row type={'flex'} style={{ padding: '30px 0' }} className={'setup-card-header'} justify={'center'}>
          <img height={50} src={Images.PANW_ORANGE_BLACK} alt={''} />
        </Row>
        <Row type={'flex'} justify={'middle'}>
          <Col xs={24}>
            <h4 className={'registration-form-header'}>{REGISTRATION_CREATE_NEW_ACCOUNT}</h4>
          </Col>
          <Col xs={24}>
            <RegistrationForm supportAccountMsg={supportActErrMsg}
              supportAccountIds={supportAccountIds}
              handleQuite={handleQuite}
              isLoading={isLoading}
              onRegisterUser={onRegisterUser} />
          </Col>
        </Row>
      </Row>
    }
  }, [errorMsg, isError, handleQuite, supportActErrMsg, supportAccountIds, isLoading, onRegisterUser])

  return <Row type={'flex'} justify={'center'} align={'center'} className={'setup-base registration'}>
    <Col lg={12} xxl={6} md={12}>
      { getErrorPage }
    </Col>
  </Row>
}

RegistrationPage.propTypes = {
  history: PropTypes.object,
}
export default RegistrationPage

