import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const AppIcon = ({ logo, flags }) => {
  const imgCls = classNames('icon_image', {
    'icon_image_full': flags?.use_full_size_logo,
  })
  return (
    <div className='icon_shape'>
      {logo && <img src={logo} className={imgCls} alt='' />}
    </div>
  )
}

AppIcon.propTypes = {
  logo: PropTypes.string,
  flags: PropTypes.object,
}

export default AppIcon
