import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as RolesAction from '../../config/actions/rbacActions'
import * as CustomRoleActions from '../../config/actions/custom_roles/customRoleActions'
import RoleAccess from './RoleAccess'
const RolePage = ({ isAuthenticated, ...props }) => (
  isAuthenticated ? <RoleAccess {...props} /> : null
)

RolePage.propTypes = {
  isAuthenticated: PropTypes.bool,
}

/**
 * Redux connect component that give connect to redux store.
 */

/**
 *
 * @param state
 * @returns {{rbac: *}}
 */
const mapStateToProps = state => {

  const { rbac, appLoadingState, selectedAccount, isAuthenticated, entitledAppsList, supportAccountIds } = state
  const selectedCSPAccount = supportAccountIds[selectedAccount]
  return { rbac, ...appLoadingState, selectedAccount, isAuthenticated, entitledAppsList, selectedCSPAccount }
}

/**
 *
 * @param dispatch
 * @returns {{getAllRoles?}}
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ ...RolesAction, ...CustomRoleActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(RolePage)
