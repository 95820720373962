/**
 *
 * Created by vsuthar on 10/4/18 File Service
 * Project: App Portal ©PaloAlto Networks
 */

import { store as AppStore } from '@pan/cloud-appframework'
import { appPortalSetLoadingState, appPortalErrorHasOccurred } from './../config/actions'
import Axios from 'axios'

class Service {

  /**
   *
   * @param state
   * @returns {*}
   */
  static getAccountId(state) {
    const { supportAccountIds, selectedAccount, user_email } = state
    if (supportAccountIds.length && user_email) {
      const { accountid } = supportAccountIds[selectedAccount]
      return { support_account_id: accountid, email: user_email }
    }
    console.error('Yor are not logged in')// eslint-disable-line
    return null
  }

  /**
   *
   * @param axiosConfig
   * @returns {AxiosInstance}
   */
  static createAxiosRequest(axiosConfig) {
    const requireParams = Service.getAccountId(AppStore.getState())
    const { method } = axiosConfig
    if (method === 'GET') {
      const { params } = axiosConfig
      axiosConfig = { ...axiosConfig, params: { ...params, ...requireParams } }
    }
    else {
      //@TODO put the logic for post and other HTTP method type
      const data = { ...axiosConfig.data }
      axiosConfig = { ...axiosConfig, data, params: { ...requireParams } }
    }
    return () => Axios.request(axiosConfig)
  }

  /**
   * we can expand this util to better handle errors and data in central place.
   *
   * @param axiosPromis
   * @param returnData if true it will only send data part of http request. returnData = false will send whole axios resolve promise
   * @returns {*}
   */
  static getData(axiosPromise, returnData = true) {
    let promise = null
    if (returnData && axiosPromise) {
      const { status } = axiosPromise
      if (status >= 200 && status <= 299) {
        if (axiosPromise.data && (axiosPromise.data.code >= 200 && axiosPromise.data.code <= 299)) {
          const { json = [] } = axiosPromise.data
          promise = json
        }
        else if (axiosPromise.data.error) {
          const { error } = axiosPromise.data
          //dispatch error here
          const data = error?.response?.data
          AppStore.dispatch(appPortalErrorHasOccurred(data))
          AppStore.dispatch(appPortalSetLoadingState(false))
        }
        else {
          // some other error handle code
        }
      }
    }
    else {
      promise = axiosPromise
    }
    AppStore.dispatch(appPortalSetLoadingState(false))
    return promise
  }

  /**
   *
   * @param axiosInstance - Expect axios instance
   * @returns {Promise<*>}
   */
  static call(axiosInstance) {
    AppStore.dispatch(appPortalSetLoadingState(true))
    const makeCall = async () => {
      try {
        return await axiosInstance()
      }
      catch (error) {
        AppStore.dispatch(appPortalSetLoadingState(false))
        const data = error?.response?.data
        AppStore.dispatch(appPortalErrorHasOccurred(data))
        if (error) {
          const { message = 'UNKNOWN_ERROR' } = error
          return {
            message,
            error: error.toString(),
          }
        }

      }

    }
    return makeCall()
  }
}

export default Service
