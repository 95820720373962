import React, { useEffect, useImperativeHandle, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form } from '@pan/cloud-base'
import { isClcsOrTelemetryInstance, isRunningOrProvisioningInstance, LGS_ID } from '../../../utils/activateUtils'

const FakeField = React.forwardRef((ignored, ref) => {
  useImperativeHandle(ref, () => ({}), [])
  return null
})
FakeField.displayName = 'FakeField'

const app_id = LGS_ID
const fieldName = `associations.${app_id}`
const CdlUpgradeField = ({
  initialValues,
  appInfoMap,
  config,
  selectedAccount,
  getFieldDecorator,
  getFieldValue,
  getFieldError,
  setFields,
  validateFields,
  instanceRegionFilter,
  isFieldValidating,
  isFieldTouched,
  regions,
  ...restProps
}) => {

  const cdlInstance = useMemo(() => {
    if (!selectedAccount) {
      return undefined // also ensure change when account switch
    }
    const cdlApp = appInfoMap.getAnyApp(app_id)
    // const { display_name: displayName = app_id } = cdlApp || {}
    const instances = cdlApp?.tenants?.filter(instanceRegionFilter).filter(isClcsOrTelemetryInstance)
    return instances.find(isRunningOrProvisioningInstance) || instances[0]
  }, [appInfoMap, instanceRegionFilter, selectedAccount])

  const instanceDecorator = useMemo(() => {
    return getFieldDecorator(fieldName, {
      // rules: []
    })
  }, [getFieldDecorator])

  const err = getFieldError(fieldName)
  const validateStatus = isFieldValidating(fieldName) ? 'validating' : err ? 'error' : ''
  const [label, help] = useMemo(() => {
    if (validateStatus === 'error' || !cdlInstance) {
      return ['Note', '']
    }
    const { serial_number, is_telementry, is_clcs } = cdlInstance
    let instanceDisplay = serial_number
    if (is_clcs) {
      return [' ', `Upgrade CLCS ${instanceDisplay}`]
    }
    const instanceType = is_telementry ? 'telemetry storage' : 'instance'
    instanceDisplay = instanceDisplay ? `${instanceType} (${instanceDisplay})` : instanceType
    return ['Note', `Upon activation, we will convert your ${instanceDisplay} to the purchased storage.`]
  }, [validateStatus, cdlInstance])

  useEffect(() => {
    let value
    if (cdlInstance?.tenant_id) {
      const { tenant_id, region, serial_number = '' } = cdlInstance
      value = `${tenant_id}|${serial_number}|${region}`
    }
    setFields({ [fieldName]: { value } }, () => {
      validateFields([fieldName], { force: true })
    })
  }, [cdlInstance, setFields, validateFields])

  if (!cdlInstance) { // it should not happen, just in case
    return null
  }

  return <Form.Item
    {...restProps}
    className={cdlInstance.is_clcs ? 'cdl-upgrade-field-invisible' : 'cdl-upgrade-field'}
    label={label}
    validateStatus={validateStatus}
    help={validateStatus === 'error' ? err : help}
  >
    {instanceDecorator(<FakeField />)}
  </Form.Item>
}

CdlUpgradeField.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  config: PropTypes.object,
  regions: PropTypes.arrayOf(PropTypes.object),
  selectedAccount: PropTypes.number,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  setFields: PropTypes.func,
  validateFields: PropTypes.func,
  instanceRegionFilter: PropTypes.func,
  isFieldValidating: PropTypes.func,
  isFieldTouched: PropTypes.func,
}

export default CdlUpgradeField
