import React from 'react'
import { Tree, Card, Row, Col, Tooltip, Icon } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import ErrorBoundary from '../ErrorBoundary'
import { isEmpty, cloneDeep } from 'lodash'

const { TreeNode } = Tree || {}

const renderTreeNodes = (data, disabledTree, tenantSkus) => {
  const nodes = cloneDeep(data) // clone original data before filtering roles with mismatch SKU's
  let filteredNodes = []
  if (isEmpty(tenantSkus)) {
    filteredNodes = nodes
  }
  else {
    nodes.forEach(node => {
      if (node?.sku?.some(item => tenantSkus.includes(item))) {
        filteredNodes.push(node)
      }
    })
  }

  return filteredNodes.map(item => {
    const { renderAsMain, doRender, description, title } = item
    const _titleTip = <Tooltip title={description} placement='bottom' >{title}</Tooltip>
    if (item.children && renderAsMain === false && doRender) {
      return (
        <TreeNode disableCheckbox={disabledTree} selectable={false} title={_titleTip} key={item.key} dataRef={item}>
          {renderTreeNodes(item.children, disabledTree, tenantSkus)}
        </TreeNode>
      )
    }
    return <TreeNode disableCheckbox={disabledTree} key={item.key} {...item} title={_titleTip} />
  })
}

/**
 *
 * @type {{backgroundColor: string, height: number}}
 */
const cardHeaderStyle = {
  height: 45,
  backgroundColor: '#f6f7f7'
}

class RBACScopeTree extends React.PureComponent {

  getEachCard = (treeNode, treeProps, disabledTree, checkedNodeObj = {}, tenantSkus) => {
    const { title, description } = treeNode
    // const _getKey = key.split('-')[0]
    // const checkedKeys = treeProps.checkedKeys.concat(checkedNodeObj?.[_getKey] || [])
    const _titleTip = <span style={{ color: '#7f868c', fontSize: 12 }}>{title.toUpperCase()}
      <Tooltip placement='bottom' title={description}>
        <Icon type="question-circle" style={{ fontSize: 10, color: 'rgba(0,0,0,0.8)', marginLeft: 5 }} theme="filled" />
      </Tooltip>
    </span>
    return <Card headStyle={cardHeaderStyle} title={_titleTip} bodyStyle={{ padding: 20 }} style={{ margin: '5px 0px 0px 10px' }} >
      {<Tree {...treeProps } defaultExpandAll >{treeNode?.children?.length ? renderTreeNodes(treeNode.children, disabledTree, tenantSkus) : 'Loading...'} </Tree>}
    </Card>
  }

  getTree({ schemaTree, schema, disabledTree, checkedNodeObj, tenantSkus, ...treeProps }) {
    if (Array.isArray(schemaTree)) {
      return schemaTree.map((treeNode, idx) => {
        return <Col key={idx} span={24 / schemaTree.length || 1} >
          {this.getEachCard(treeNode, treeProps, disabledTree, checkedNodeObj, tenantSkus)}
        </Col>
      })
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <Row type={'flex'} style={{ height: 350, overflowY: 'auto' }}>
          {this.getTree(this.props)}
        </Row>
      </ErrorBoundary>
    )
  }
}

RBACScopeTree.defaultProps = {
  checkedKeys: []
}
RBACScopeTree.propTypes = {
  schema: PropTypes.object,
  isOpen: PropTypes.bool,
  scopes: PropTypes.array,
  schemaTree: PropTypes.array,
  disabledTree: PropTypes.bool,
  tenantSkus: PropTypes.array
}

export default RBACScopeTree

