import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import { appframeworkActions, utils, FT } from '@pan/cloud-appframework'
import { allowInstanceAdmin, allowAppInstance, allowAppAdmin } from './rbacUtils'
import { isNonemptyArray, indexArray, toAppId, wait, omitEmptyValues, cleanObj } from './common'
import {
  LGS_ID,
  SETUP_PENDING_STATUS,
  NFR,
  MONTHS,
  DAYS,
  RUNNING,
  SETUP_CTX_PRIMARY_APPS,
  ZINGBOX_NGFW_DRDL,
  ZINGBOX_3P_ADDON,
  ZINGBOX_NGFW,
  PRISMA_SAAS,
  PRISMA_SAAS_NGFW,
  PANORAMA,
  SAAS_API,
  AIOPS_FREE_INSTANCE_TYPE,
  AIOPS_PREMIUM_INSTANCE_TYPE,
  STRATA_INSIGHTS
} from '../constants/AppConstants'
export {
  DSS, DSS_ID,
  LGS, LGS_ID,
  TRAPS,
  PRISMA_ACCESS,
  STORAGE_INCLUDED,
  SETUP_CREATE_NEW,
  LOGGING_SERVICE_DISPLAY_NAME as LGS_DISPLAY
} from '../constants/AppConstants'
export { isNonemptyArray, indexArray, toAppId, wait, omitEmptyValues, cleanObj, allowInstanceAdmin, allowAppInstance, allowAppAdmin, FT }
export * from './hooks'
export { ACTIVATE, APPS, SETTINGS, ROLES, SETUP } from '../constants/RouteConstants'
export const CORTEX_XDR_NOVA = 'cortex_xdr'

export const EMPTY_LOC_STATE = Object.freeze({})
export const EMPTY_INIT_VALUES = Object.freeze(Object.defineProperties({}, { isEmpty: { value: true } }))

export const { fetchEntitlements, fetchCredential, switchAccountIndex, setAppBarProps } = appframeworkActions
export const REFRESH_ENTITLEMENTS_AFTER = 60000 // 1min

export const formItemLayout = {
  colon: false,
  labelCol: {
    sm: { span: 24 },
    md: { span: 6 },
  },
  wrapperCol: {
    sm: { span: 24 },
    md: { span: 18 },
  },
}

export const LGS_ASSO_FIELD = `associations.${LGS_ID}`

export const returnFalseFn = () => false
export const returnTrueFn = () => true

export const getAppInfoMap = (appNames, entitledAppsList, defaults) => {
  if (!isNonemptyArray(appNames)) {
    const empty = []
    const emptyFn = () => empty
    const falseFn = () => false
    return Object.assign(new Map(), {
      ...defaults,
      key: '',
      isBundle: false,
      isEmpty: true,
      appNames: empty,
      associations: [],
      single: {},
      filter: emptyFn,
      map: emptyFn,
      some: falseFn,
      every: falseFn,
      hasDupName: falseFn,
      getInstances: emptyFn,
      getDisplayName: _.noop,
      getRegions: () => [],
      getAppRegions: () => [],
      getAnyApp: _.noop,
      getBundleSuffix: _.noop,
      hasInstanceNameField: falseFn,
      subdomainMap: new Map(),
      regionsMap: new Map(),
    })
  }
  const apps = entitledAppsList.filter(
    app => appNames.includes(app.name) || appNames.includes(app.app_id)
  )
  const appInfoMap = apps.reduce((map, app) => {
    map.set(app.app_id, app)
    if (app.app_id !== app.name) {
      map.set(app.name, app)
    }
    return map
  }, new Map()) // new Map(apps.map(app => [app.name, app]))
  const { _map: allAppMap } = entitledAppsList
  const isBundle = appNames.length > 1
  const getRegions = (app) => {
    if (Array.isArray(app.regions)) {
      return app.regions
    }
    return undefined
  }
  const bundleSuffixMap = new Map(apps.map(app => [app.name, app.bundle_suffix || app.display_name || app.name]))
  const regionsMap = new Map(apps.map(app => [app.name, getRegions(app)]))
  const subdomainMap = new Map(apps.filter(app => app.customizable_subdomain).map(app =>
    [app.name, app.domain_postfix]
  ))
  const associations = (!isBundle && apps[0]?.associations) || []
  if (isBundle) {
    const associationsMap = new Map()
    apps.forEach((app) => {
      _.each(app.associations, (config) => {
        const app_id = config.app_id
        if (associationsMap.has(app_id)) {
          const existing = associationsMap.get(app_id)
          _.assignWith(existing, config, (objValue, srcValue, key) => {
            if (key === 'app' || key === 'app_id') {
              return srcValue
            }
            if (srcValue == null) {
              return objValue
            }
            if (objValue == null) {
              return srcValue
            }
            // bool flag, use OR logic
            if (_.isBoolean(objValue) && _.isBoolean(srcValue)) {
              return srcValue || objValue
            }
            // array or object equal
            if (objValue === srcValue || _.isEqual(objValue, srcValue)) {
              return srcValue
            }
            // eslint-disable-next-line no-console
            console.warn('conflict dependency config', key, objValue, srcValue)
            return srcValue
          })
        }
        else {
          associationsMap.set(app_id, config)
        }
      })
    })
    associations.push(...associationsMap.values())
  }
  const hasDupNameAmongInstances = (instances, instanceNameLowerred) => _.some(instances, t =>
    t.tenant_instance_name && t.tenant_instance_name.toLowerCase() === instanceNameLowerred
  )
  const hasDupNameFn = _.memoize(isBundle ? (instanceNameLowerred) => {
    const hasDup = appInfoMap.appNames.some((appName) => {
      const fullInstanceNameLowered = `${instanceNameLowerred} - ${bundleSuffixMap.get(appName)}`.toLowerCase()
      const dup = hasDupNameAmongInstances(appInfoMap.getInstances(appName), fullInstanceNameLowered)
      return dup // easier debug
    })
    return hasDup
  } : (instanceNameLowerred) => {
    const hasDup = hasDupNameAmongInstances(appInfoMap.single.tenants, instanceNameLowerred)
    if (hasDupNameFn.cache.size > hasDupNameFn.cache.maxSize) {
      hasDupNameFn.cache.clear()
    }
    return hasDup
  })
  hasDupNameFn.cache = new Map()
  hasDupNameFn.cache.maxSize = 300
  const hasDupName = (instanceName, initialValue = '') => {
    const lowered = instanceName.trim().toLowerCase()
    if (lowered === initialValue.toLowerCase()) {
      return false
    }
    return hasDupNameFn(instanceName.trim().toLowerCase())
  }

  let domain_postfix = defaults && defaults.domain_postfix
  if (!domain_postfix && subdomainMap.size === 1) {
    domain_postfix = subdomainMap.values().next().value
  }
  if (domain_postfix) {
    domain_postfix = domain_postfix.toLowerCase()
    if (!domain_postfix.startsWith('.')) {
      domain_postfix = `.${domain_postfix}`
    }
  }

  Object.assign(appInfoMap, {
    ...defaults,
    key: appNames.join(','),
    isEmpty: false,
    isBundle,
    appNames,
    single: !isBundle && apps[0],
    getInstances: name => allAppMap.get(name)?.tenants || [],
    getDisplayName: name => allAppMap.get(name)?.display_name || name,
    getRegions: name => appInfoMap.metaRegions || regionsMap.get(name),
    getAppRegions: name => regionsMap.get(name) || getRegions(allAppMap.get(name)),
    getAnyApp: allAppMap.get.bind(allAppMap),
    getBundleSuffix: bundleSuffixMap.get.bind(bundleSuffixMap),
    some: apps.some.bind(apps),
    every: apps.every.bind(apps),
    filter: apps.filter.bind(apps),
    find: apps.find.bind(apps),
    map: apps.map.bind(apps),
    hasInstanceNameField: apps.some(({ customizable_instance_name }) => customizable_instance_name !== false),
    hasSubdomainField: subdomainMap.size > 0,
    hasDupName,
    subdomainMap,
    domain_postfix,
    regionsMap,
    associations,
  })

  appInfoMap.id = _.uniqueId()
  return appInfoMap
}

export const remoteValidator = (rule, value) => {
  if (!rule.remote || !value) {
    return Promise.resolve()
  }
  return axios.post(rule.remote, {
    [rule.valueField || rule.field]: value,
    selectedAccount: rule.selectedAccount,
    ...rule.extraValues,
  }).catch((error) => {
    if (!error.response || error.response.status !== 404) {
      const message = error?.response?.data?.message || error.message || error
      throw new Error(message)
    }
  })
}

const instanceStatusAllowed = new Set([RUNNING, 'provisioning', 'pending'])
export const isRunningOrProvisioningInstance = (tenant) => instanceStatusAllowed.has(tenant.instance_status)
export const isRunningInstance = (tenant) => tenant.instance_status === RUNNING

export const convertObjToString = obj => obj && `${obj.tenant_id}|${obj.serial_number || ''}|${obj.region || ''}`

export const encodeAuthCode = (authcode, salt = 0) => {
  let leading
  let num
  let len
  if (!authcode) {
    return ''
  }
  if (isNaN(+authcode.charAt(0))) {
    leading = authcode.charCodeAt(0).toString(36)
    len = authcode.length - 1
    num = +authcode.slice(1)
  }
  else {
    leading = '0'
    len = authcode.length
    num = +authcode
  }
  const encoded = `${leading}${(num ^ +salt).toString(36)}${len.toString(36)}`
  return [...encoded].reverse().join('')
}
export const decodeAuthCode = (encoded, salt = 0) => {
  if (!encoded) {
    return ''
  }
  const len = encoded.charAt(0)
  const d = n => Number.parseInt(n, 36)
  const reversed = [...encoded.slice(1)].reverse().join('')
  const [leading, xorNum] = reversed.startsWith('0') ? ['', d(reversed)] :
    [String.fromCharCode(d(reversed.slice(0, 2))), d(reversed.slice(2))]
  const numStr = (xorNum ^ salt).toString().padStart(len, '0')
  return `${leading}${numStr}`
}
//(encoded.startsWith('0') ?
//   Number.parseInt(encoded, 36) :
//   `${String.fromCharCode(Number.parseInt(encoded.slice(0, 2), 36))}${Number.parseInt(encoded.slice(2), 36)}`
// )

export const isTelemetryInstance = tenant => tenant.is_telemetry
export const isClcsInstance = tenant => tenant.is_clcs

export const isClcsOrTelemetryInstance = (tenant) => {
  return tenant.is_clcs || tenant.is_telemetry
}
export const isFreeCdl = tenant => tenant.data_size >= 0 && tenant.data_size < 1000
// export const isFreeTier100GbLgs = tenant => +tenant.data_size === 100

export const genCancelSource = () => {
  const src = axios.CancelToken.source()
  src.unmountCallback = () => src.cancel('unmount')
  return src
}

export const hasFormErrors = (getFieldsError) => {
  const { associations: associationsErr, developer_defined_fields: ddfErr, ...otherErr } = getFieldsError()
  const hasErrors = _.some({ ...otherErr, ...associationsErr, ...ddfErr }, Boolean)
  return hasErrors
}

export const canNovaSetup = (tenant, app) => { // without role check
  return Boolean(app.isNova && tenant.serial_number) && tenant.instance_status === SETUP_PENDING_STATUS
}
const pickAddon = ({ tenant_id, serial_number, app_id, expiration_date }) => ({ tenant_id, serial_number, app_id, expiration_date })
export const getNovaSetupPathState = (tenant, appInfo, selectedAccount) => {
  if (!appInfo.isNova || !tenant.serial_number || tenant.instance_status !== SETUP_PENDING_STATUS) {
    return false
  }
  const { tenant_id, serial_number, bundled_tenant, support_account_ids } = tenant
  return {
    appName: appInfo.name,
    isNova: 1,
    tenant_id,
    serial_number,
    bundled_tenant: bundled_tenant ? pickAddon(bundled_tenant) : undefined,
    support_account_ids: Array.isArray(support_account_ids) ? support_account_ids : undefined,
    ...(!appInfo.usePubSub && selectedAccount >= 0 && { selectedAccount }), // ensure go back when switch account
  }
}

export const getSelectedAccountInfo = (supportAccountIds, selectedAccount) => {
  const { accountid: support_account_id, name: support_account_name } = supportAccountIds[selectedAccount] || {}
  if (!support_account_id || !support_account_name) {
    return {}
  }
  return {
    selectedAccount,
    support_account_id,
    support_account_name,
  }
}

export const activate = async (values, { url = '/hub/v2/activate' } = {}) => {
  // console.info('activate', values)
  try {
    const data = { ...values }
    if (!data.auth_code && !data.instances) {
      data.auth_code = '' // in order to pass validation
    }
    const resp = await axios.post(url, data)
    // console.info('activate resp', resp.data)
    return resp.data
  }
  catch (err) {
    // console.error('activate err', err, { ...err })
    if (!err.response) {
      throw err
    }
    const {
      error = err.message,
      statusCode: status = err?.response?.status || 0,
      message,
    } = err?.response?.data || err
    throw Object.assign(err, { message, error, status })
  }
}

export const getAppInstanceWithIndexing = (app, tenant_id) => {
  if (!tenant_id || !isNonemptyArray(app?.tenants)) {
    return undefined
  }
  return indexArray(app.tenants, 'tenant_id', { cached: true }).get(tenant_id)
}

export const sortSupportAccounts = (supportAccountIds) =>
  _.sortBy(supportAccountIds, [({ support_account_name }) => support_account_name.toLowerCase()])

export const firewallDeviceSearch = (value, deviceList) => {
  return deviceList.filter(device => {
    const serial_number = device?.serial_number?.toLowerCase()
    const device_name = device?.device_name?.toLowerCase()
    const sub_string = value?.toLowerCase()
    return (serial_number && serial_number.includes(sub_string)) || (device_name && device_name.includes(sub_string))
  })
}

export const getSubdomainFromEmail = _.memoize(user_email => user_email && /\S@(\w[\w-]*)\./.exec(user_email)?.[1])

// give isFederalLicense 'free' since CIE supports fedramp without magiclink
export const getAvailableAccountsProps = ({ apps, supportAccounts, notAvailFn = returnFalseFn, allowAppFn = allowAppAdmin, isFederalLicense = 'free' }) => {
  const supportAccountsIndexing = _.keyBy(supportAccounts, 'support_account_id')
  const isDisabledFn = allowAppFn === returnTrueFn && notAvailFn === returnFalseFn ? returnFalseFn : (cspId) => {
    if (notAvailFn(cspId)) {
      return true
    }
    if (allowAppFn === returnTrueFn) {
      return false
    }
    const account = supportAccountsIndexing[cspId]
    const allow = account && apps.every(app => allowAppFn(app, account.rbacRoles))
    return !allow
  }
  const appSupportFed = apps.some(app => app.has_federal_region)
  const propsArray = supportAccounts.map(({ support_account_id: csp_id, support_account_name, support_account_index, default_account, is_federal }) => {
    // const notSupportFed = Boolean(is_federal && !appSupportFed)
    const notSupported = (isFederalLicense === true && (appSupportFed ? !is_federal : true)) ||
      (isFederalLicense === false && Boolean(is_federal)) ||
      Boolean(is_federal && !appSupportFed)
    const disabled = isDisabledFn(csp_id) || notSupported
    const children = disabled ? `${support_account_name} ${notSupported ? '(not supported)' : '(limited access)'}` : support_account_name
    return {
      index: support_account_index,
      value: csp_id,
      disabled,
      children,
      default_account,
      is_federal,
    }
  })
  const sortedProps = _.sortBy(propsArray, [({ disabled, children }) => `${disabled ? '1' : '0'} ${children}`.toLowerCase()])
  return sortedProps
}

export const hasAddon = (toBeActivateArr, primaryAppId, addonAppId) => {
  return toBeActivateArr.find(each => each?.app_id === primaryAppId && each.checked)?.addons?.find(({ app_id }) =>
    (Array.isArray(addonAppId) ? addonAppId.includes(app_id) : app_id === addonAppId))
}

export const filterRegions = (regions, accountIsFederal) => regions?.filter((region) => {
  if (region.tsg_only) {
    return false
  }
  if (accountIsFederal) {
    return region.supported_types?.federal === true
  }
  // by default
  return !region.supported_types || region.supported_types.commercial === true
}) || []

export const renderInstances = ({
  config,
  appInfoMap,
  regions,
  editableOnly,
  hasInitialValues,
  instanceRegionFilter,
  initialTenantId,
  renderOptions,
  renderOptionGroups,
}) => {
  const {
    app_id,
    // flags
    region_matching,
    region_assignment,
    single_association,
    stop_auto_select,
    // for extention
    instanceFilterFn,
    isDisabledFn,
    extraTenantInfoRenderFn,
    instancesPropsFn,
    instancesRenderedFn,
    allowedInstanceStatuses,
  } = config
  const appDef = appInfoMap.getAnyApp(app_id)
  if (!appDef || !appDef.enabled) {
    return []
  }
  const { name: appName = app_id, display_name: displayName = appName } = appDef
  const availableLgsRegions = new Set(_.map(regions, 'logging_service_region'))
  const instances = appInfoMap.getInstances(app_id) || []
  let filtered = allowedInstanceStatuses ? instances.filter((tenant) => allowedInstanceStatuses.has(tenant.instance_status)) : instances.filter(isRunningOrProvisioningInstance)
  if (filtered.length) { // cannot early return due to instancesPropsFn hook at last
    if (region_matching && instanceRegionFilter) {
      filtered = filtered.filter(instanceRegionFilter)
    }
    else if (region_assignment && regions?.length) {
      const regionsSet = new Set(_.map(regions, 'name').filter(Boolean))
      filtered = filtered.filter(t => regionsSet.has(t.region) || availableLgsRegions.has(t.lcaas_region || t.region))
    }
    if (instanceFilterFn) {
      filtered = filtered.filter(instanceFilterFn)
    }
  }
  const used = new Set()
  if (filtered.length && single_association) {
    const appNames = Array.isArray(single_association) ? single_association : appInfoMap.appNames
    const isCdlField = app_id === LGS_ID
    appNames.forEach(app => {
      let appInstances = appInfoMap.getInstances(app)
      // AIOps uses same app_id for free and paid instances which can share same CDL
      if (FT('AIOPS_PREMIUM') && app === STRATA_INSIGHTS) {
        appInstances = config?.isEasyActivation ? appInstances.filter(inst => inst.type !== AIOPS_FREE_INSTANCE_TYPE) : // filter free aiops for EA flow
          appInstances.filter(inst => inst.type !== AIOPS_PREMIUM_INSTANCE_TYPE) // filter paid aiops for old activation flow
      }

      appInstances.forEach(({ associations = {}, platform_id }) => {
        const tenant_id = associations[app_id]?.tenant_id || (isCdlField && platform_id)
        if (tenant_id) {
          used.add(tenant_id)
        }
      })
    })
    const appIdSet = new Set(appNames.map(toAppId))
    for (const inst of filtered) {
      if (isNonemptyArray(inst.used_by) && inst.used_by.some(appId => appIdSet.has(appId))) {
        used.add(inst.tenant_id)
      }
    }
    if (initialTenantId) {
      used.delete(initialTenantId)
    }
  }
  const isDisabled = (tenant) => {
    if (used.has(tenant.tenant_id) && initialTenantId !== tenant.tenant_id) {
      return true
    }
    if (!isRunningInstance(tenant)) {
      return true
    }
    if (isDisabledFn && isDisabledFn(tenant, config)) {
      return true
    }
    return undefined
  }
  const renderExtraTenantInfo = (tenant) => {
    if (used.has(tenant.tenant_id)) {
      return '(used)'
    }
    if (editableOnly && initialTenantId && initialTenantId === tenant.tenant_id) {
      return '(current)'
    }
    if (!isRunningInstance(tenant) && tenant.instance_status) {
      return `(${tenant.instance_status})`
    }
    if (extraTenantInfoRenderFn) {
      return extraTenantInfoRenderFn(tenant) || ''
    }
    return ''
  }
  const toProps = tenant => ({
    key: tenant.tenant_id,
    disabled: isDisabled(tenant),
    value: tenant.value || convertObjToString(tenant),
    group: (tenant.region_display || tenant.region || '').toUpperCase(),
    display: tenant.tenant_display_name || tenant.tenant_instance_name || tenant.serial_number || tenant.tenant_id,
    extra: renderExtraTenantInfo(tenant),
  })
  const optionsProps = filtered.map(toProps)
  let optionsGroups = _.groupBy(optionsProps, 'group')
  if (instancesPropsFn) {
    instancesPropsFn({ config, instances, filtered, regions, optionsGroups, toProps, optionsProps, availableLgsRegions, displayName })
  }
  if (regions?.length && !region_matching) { // sort by regions
    const cloned = { ...optionsGroups }
    const regionGroups = regions.map(r => (r.display || r.name).toUpperCase())
    optionsGroups = regionGroups.reduce((groups, group) => {
      const matched = cloned[group]
      if (matched) {
        delete cloned[group]
        groups.push([group, matched])
      }
      return groups
    }, [])
    optionsGroups.push(...(Object.entries || _.entries)(cloned))
  }
  else {
    optionsGroups = (Object.entries || _.entries)(optionsGroups)
  }
  if (optionsGroups.length === 0) {
    const rendered = []
    if (instancesRenderedFn) {
      instancesRenderedFn({ config, availables: [], rendered, displayName })
    }
    return rendered
  }
  const availables = optionsProps.filter(p => !p.disabled)
  const rendered = optionsGroups.length === 1 && region_matching ?
    renderOptions(optionsGroups[0][1]) : renderOptionGroups(optionsGroups)
  if (!editableOnly && !hasInitialValues && availables.length === 1 && !stop_auto_select) {
    rendered.onlyAvailableOptionValue = availables[0].value
  }
  if (instancesRenderedFn) {
    instancesRenderedFn({ config, availables, rendered, displayName })
  }
  return rendered
}

export const renderAssetType = (value) => {
  switch (value) {
    case NFR:
      value = value.toUpperCase()
      break
    default:
      value = _.capitalize(value)
  }
  return value
}

export const getContextApp = (context, entitledAppsList) => entitledAppsList.get(context) ||
  entitledAppsList.get(SETUP_CTX_PRIMARY_APPS[context]?.[0]) ||
  entitledAppsList.find(app => app.setup?.context === context)

/**
 *
 * @param {number} term
 * @param {string} unit
 */
export const getRemainingTime = (term, unit) => {
  if (unit !== MONTHS && unit !== DAYS) {
    return term //invalid unit, return as it is
  }

  let result = ''
  const start = moment()
  const end = moment().add(term, unit)
  const years = end.diff(start, 'year')
  start.add(years, 'years')
  const months = end.diff(start, 'months')
  start.add(months, 'months')
  const days = end.diff(start, 'days')

  if (years > 0) {
    result = years > 1 ? `${years} years` : `${years} year`
  }

  if (months > 0) {
    result = `${result} ${months}`
    result = months > 1 ? `${result} months` : `${result} month`
  }

  if (unit === MONTHS && days > 0) {
    result = '1 month' //APPORTAL-4005: if unit is month ceil remaining days always to 1 month (eg: jan-29 to feb-28)
  }

  if (unit === DAYS && days > 0) { // only if request unit in days
    result = `${result} ${days}`
    result = days > 1 ? `${result} days` : `${result} day`
  }
  return result
}

/**
 *
 * @param {array} apps
 * @param {string} app_id
 */
export const hasAppActivation = (apps = [], app_id) => {
  return apps.some(app => app.app_id === app_id && app.checked)
}

/**
 *
 * @param {array} toBeActivatedApp
 */
export const hasOnlyIoTThirdPartyActivation = (toBeActivatedApp) => {
  return hasAppActivation(toBeActivatedApp, ZINGBOX_3P_ADDON) &&
    !hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW_DRDL) &&
    !hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW)
}

/**
 *
 * @param {array} toBeActivatedApp
 */
export const hasOnlyIoTPackageTwoActivation = (toBeActivatedApp) => {
  return hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW_DRDL) &&
    !hasAppActivation(toBeActivatedApp, ZINGBOX_NGFW)
}


/**
 *
 * @param {array} toBeActivatedApp
 */
export const isActivatingOnlySaasRest = (toBeActivatedApp) => {
  return hasAppActivation(toBeActivatedApp, PRISMA_SAAS) &&
    !hasAppActivation(toBeActivatedApp, PRISMA_SAAS_NGFW)
}

/**
 *
 * @param {array} toBeActivatedApp
 */
export const isActivatingSaasInlineAndRest = (toBeActivatedApp) => {
  return hasAppActivation(toBeActivatedApp, PRISMA_SAAS) &&
    hasAppActivation(toBeActivatedApp, PRISMA_SAAS_NGFW)
}

/**
 *
 * @param {array} toBeActivatedApp
 */
export const isActivatingSaasCasbInline = (toBeActivatedApp) => {
  return toBeActivatedApp?.some(app => app.app_id === PRISMA_SAAS_NGFW && app?.licenses?.some(each => each?.is_casb_inline))
}

/**
 *
 * @param {array} toBeActivatedApp
 */
export const isActivatingSaasCasbApi = (toBeActivatedApp) => {
  return toBeActivatedApp?.some(app => app.app_id === PRISMA_SAAS && app?.is_casb_api)
}

/**
 *
 * @param {string} deviceModel
 * @param {string} sku
 */
export const firewallModelMatch = (deviceModel, sku) => {
  let model = deviceModel

  const modelUpCase = deviceModel?.toUpperCase()
  //VM series special case: SKU: PAN-VM-300-**, device_model: PA-VM-300
  //panorama series special case: SKU: PAN-M-600-AIOPS-NGFW, device_model: PA-M-600
  //VM panorama series special case: SKU: PAN-PRA-1000-AIOPS-NGFW, device_model: PRA-1000
  const value = modelUpCase?.match(/-VM-(\d+)/) || modelUpCase?.match(/-PRA-(\d+)/) || modelUpCase?.match(/-M-(\d+)/)

  if (value) {
    model = value?.[0]
  }
  return sku?.match(model)
}

export const hasPrismaSaasRestTenant = (prismaSaasTenants) => {
  return prismaSaasTenants?.some(tenant => tenant?.license_type?.includes(SAAS_API))
}

export const appDomainPostfix = (app, appRegion) => {
  return (appRegion && app?.regions?.find(region => region?.name === appRegion)?.domain_postfix) || app?.domain_postfix
}

export const selectedDevicesAndLicenses = (entitledAppsList, licenses, appId, updatedAppId) => {
  const ngfwEntitlements = indexArray(entitledAppsList.get(appId)?.tenants, 'serial_number')
  const ngfwLicenses = licenses?.find(license => license.app_id === appId)?.licenses
  const devices = ngfwLicenses.map(each => {
    const { serial_number, auth_code } = each
    const tenantId = ngfwEntitlements.get(serial_number)?.tenant_id
    return { serial_number, auth_code, app_id: (updatedAppId || appId), ...(tenantId && { tenant_id: tenantId }) } //tenant_id: overwrite existing raptor device license
  })
  return { licenses: ngfwLicenses, devices }
}

export const validatePanoramaSerial = (serial, { setupSelectedAccount: selectedAccount, toBeActivatedApp }, extraData) => {
  if (!serial) {
    return Promise.reject(new Error('Panorama Serial Number is required'))
  }
  const has_support_license = extraData?.has_support_license ?? toBeActivatedApp?.some(app =>
    app.checked && app.app_id.endsWith(extraData?.panorama_app_id || PANORAMA) && app.auth_code
  )
  return axios.post('/hub/v2/validate/panorama_support', {
    serial_number: serial,
    selectedAccount,
    register_new: Boolean(extraData?.register_new),
    has_support_license,
  }, { skipInterceptors: true }).catch((error) => {
    if (!error.response || error.response.status !== 404) {
      const message = error?.response?.data?.message || error.message || String(error)
      if (error.response.status === 403 && message.includes('G0002')) {
        utils.showLoginDialog({
          title: 'Session Expired or Invalidated',
          content: message,
        }).catch(_.noop)
      }
      throw new Error(message)
    }
  })
}

export const isMultiRegionAllowed = (supportAccounts, setupSelectedAccount) => FT.bySupportAccount('SAAS_MULTI_REGIONS', supportAccounts?.find(e => e.support_account_index === setupSelectedAccount)?.accountid)

export const preventDefault = (e) => {
  e.preventDefault()
}

export const hasAccountAccess = (supportAccounts, allowedAccounts) => {
  const supportAccountsMap = new Map(supportAccounts.map(e => [e.accountid, e]))
  return allowedAccounts?.some(each => supportAccountsMap.has(each))
}

export const isIoTMultiCdlRegionAllowed = (supportAccounts, setupSelectedAccount) => FT.bySupportAccount('IOT_CA_ACCOUNTS', supportAccounts?.find(e => e.support_account_index === setupSelectedAccount)?.accountid)
