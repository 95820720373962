import React from 'react'
import { MAGNIFIER_LSC_DISPLAY_NAME } from '../../constants/AppConstants'

const Magnifier = () => {
  return (
    <span>
      {MAGNIFIER_LSC_DISPLAY_NAME} increases storage demand across all other products. It requires higher fidelity logs in order to perform its advanced analytics.
    </span>
  )
}

export default Magnifier
