/**
 *
 * Created by vsuthar on 9/26/18 File ChipsTags
 * Project: App Portal ©PaloAlto Networks
 */
import React, { Component } from 'react'
import { Tag, Button } from '@pan/cloud-base'
import { debounce } from 'lodash'
import PropTypes from 'prop-types'
import { VIEW_ALL_SELECTED_USERS } from '../constants/AppConstants'
import ConfirmationDialog from './common/ConfirmationDiaglog'
import SearchInput from './RBAC/SearchInput'
class ChipsTags extends Component {

  constructor(props) {
    super(props)
    this.debounceSearch = debounce(this.debounceSearch, 1000)
    this.state = {
      chips: [],
      openDialog: false,
      searchChips: [],
      isSearching: false
    }
  }


  /**
   *
   * @param e
   * @param item
   */
  handleClose(e, item) {
    this.props.onClose(item, e)
  }


  /**
   *
   * @returns {string}
   */
  buttonLabel = () => {
    const { chips } = this.props
    return `${VIEW_ALL_SELECTED_USERS} ${chips.length}`
  }

  /**
   *
   */
  onViewAll= () => {
    this.setState({
      openDialog: true
    })
  }

  /**
   * Render all tag based on renderAll flag also looks for ease user Searching
   * @param renderAll
   * @returns {*}
   */
  renderChips(renderAll) {
    const { searchChips, isSearching } = this.state
    const { chips, onClose, isLoading, ...others } = this.props
    /**
     * If user local search this then pass searchChip only
     */
    let _chips = isSearching ? searchChips : chips
    if (!renderAll) {
      _chips = _chips.slice(0, 10)
    }

    return <React.Fragment>
      {
        _chips.map((item, idx) =>
          (
            <Tag v2={true}
              key={idx} visible={true}
              {...others}
              closable={chips.length > 1}
              onClose={this.handleClose.bind(this, item)} >{item}</Tag>
          )
        )
      }
      <br/>
      {!renderAll && chips.length > 10 ? <Button disabled={isLoading} onClick={this.onViewAll} style={{ marginTop: 3 }}>{this.buttonLabel()}</Button> : null}
    </React.Fragment>

  }

  /**
   *
   * @param value
   */

  debounceSearch(value) {
    const { chips } = this.props
    const searchChips = chips.filter(eachEmail => eachEmail.trim().indexOf(value.trim()) > -1)
    this.setState({ searchChips, isSearching: Boolean(value.trim().length) })
  }


  /**
   * Search users
   * @param e
   */
  handleSearch(e) {
    const { value } = e.target
    this.debounceSearch(value)
  }

  getTitle = () => {

    return (
      <div className={'hbox space-between'}>
        {this.buttonLabel()}
        <SearchInput style={{ width: '50%' }}
          onChange={ this.handleSearch.bind(this) }/>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.renderChips()}
        <ConfirmationDialog title={this.getTitle()}
          closable={false}
          bodyStyle={{ overflow: 'auto', height: 450 }}
          footer={[
            <Button key={'Close'}
              onClick={ () => this.setState({ openDialog: false, isSearching: false })}>Close</Button>,
          ]}
          visible={this.state.openDialog} width='65%'>
          {this.renderChips(true)}
        </ConfirmationDialog>
      </React.Fragment>

    )
  }
}

ChipsTags.propTypes = {
  chips: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  isLoading: PropTypes.bool
}

ChipsTags.defaultProps = {
  chips: [],
  onClose: () => {},
  isLoading: false
}
export default ChipsTags
