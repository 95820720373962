import * as Actions from './../actions/ActionConstants'
import { getAccountRoles } from '../actions/rbacActions'
import * as AppConstants from './../../constants/AppConstants'
import {
  assignRoleToEntitlements,
  disabledEnabledAllApp,
  getRolesFromUser,
  flattenRolesV2, isAccountSuperUser, isAppAdmin,
} from './rbacAssignRoleEntitilements'
import { uniqueId, cloneDeep, merge, forOwn, keyBy, groupBy, has, unset, set, unionWith, omit, differenceWith, isEqual, isEmpty } from 'lodash'
import { store as AppStore, hasRole } from '@pan/cloud-appframework'
import { parseRoles, allowAppAdmin } from '../../utils/rbacUtils'
import { isNonemptyArray } from '../../utils/common'
import {
  RBAC_ROLE_INSTANCE_ADMIN,
  RBAC_ADMIN,
  RBAC_NO_ROLE,
  RBAC_INSTANCE_LIMITED_ADMIN,
  FILTER_USERS_WITHOUT_ROLES,
  RBAC_ACCOUNT_SUPER_USER_V2,
  R_TYPE,
  R_CUSTOM,
  R_USER,
  R_NO_ROLE_ENUM,
  RBAC_INSTANCE_PENDING
} from '../../constants/AppConstants'

const SUPER_ACCOUNT_ID = 'superuser_account_id'

let superuserAppList = []
let tenantIdsMap = {}
const users = { data: [], lastUpdate: Date.now() }
const _superuserAccount = {
  name: 'Account',
  display_name: 'Account',
  hasInstanceChild: false,
  uniqueId: SUPER_ACCOUNT_ID,
  isServiceApp: true,
  rbac_app_type: AppConstants.ACCOUNT_SUPER_USER,
  rbac_role_selected: 'not_account_super_user',
  _rbac_role: AppConstants.ACCOUNT_SUPER_USER,
  doRender: false,
  role: AppConstants.ACCOUNT_SUPER_USER,
  access: [
    {
      name: 'All Instances',
      isSuperUserAcnt: true,
      rbac_role_selected: 'not_account_super_user',
      _rbac_role: AppConstants.ACCOUNT_SUPER_USER,
      uniqueId: uniqueId('rbac'),
      rbac_roles: [
        {
          name: AppConstants.RBAC_DISPLAY_ACCOUNT_SUPER_USER,
          _rid: 'account_superuser'
        },
        {
          name: RBAC_NO_ROLE,
          _rid: 'not_account_super_user',
        },
        {
          name: AppConstants.RBAC_MIX_ROLE,
          _rid: 'mix',
          disabled: true,
        },
      ],
    },
  ],
}
const _defaultRoles = [
  {
    name: 'All Users',
    hasInstanceChild: false,
    doRender: true,
    filter: '',
    isServiceApp: false,
    uniqueId: uniqueId('rbac'),
  },
  {
    name: FILTER_USERS_WITHOUT_ROLES,
    filter: '(rolename is null)',
    doRender: true,
    hasInstanceChild: false,
    isServiceApp: false,
    uniqueId: uniqueId('rbac'),
  },
  {
    name: 'Account Admin (All Apps)',
    filter: '(rolename eq "Account Super User")',
    doRender: true,
    hasInstanceChild: false,
    isServiceApp: false,
    uniqueId: uniqueId('rbac'),
  },
]
/**
 *
 * @type {{isTableSearching: boolean, autoComplete: [], roles: [], autoCompleteSelection: [], selectedUsers: [], tenantCache: {}, isAutoCompleteLoading: boolean, users: {data: [], lastUpdate: number}, searchUsers: {searchTerm: string, data: [], lastUpdate: number}, selectedFilter: string, _orig_rbac_entitlements: [], rbacSkuMap: {}, roleMapping: {}, assign_roles: {}, orig_app_list: [], createdRoles: [], rbac_entitlements: [], isUserTableLoad: boolean, rbacRoles: {}, accountAdminEntitlements: [], rolesNeedsToBeDeleted: [], superuserAccount: {hasInstanceChild: boolean, role: string, access: [{name: string, isSuperUserAcnt: boolean, rbac_roles: [{_rid: string, name: string}, {_rid: string, name: string}, {_rid: string, name: string, disabled: boolean}], rbac_role_selected: string, _rbac_role: string, uniqueId: *}], name: string, doRender: boolean, isServiceApp: boolean, display_name: string, rbac_app_type: string, rbac_role_selected: string, uniqueId: string, _rbac_role: string}}}
 */
const defaultState = {
  users,
  accountAdminEntitlements: [],
  searchUsers: { data: [], lastUpdate: Date.now(), searchTerm: '' },
  isTableSearching: false,
  isAutoCompleteLoading: false,
  roles: [],
  rbacRoles: {},
  roleMapping: {},
  autoComplete: [],
  selectedFilter: '',
  superuserAccount: _superuserAccount,
  autoCompleteSelection: [],
  selectedUsers: [],
  assign_roles: {},
  orig_app_list: [],
  createdRoles: [],
  rolesNeedsToBeDeleted: [],
  _orig_rbac_entitlements: [],
  rbac_entitlements: [],
  isUserTableLoad: false,
  tenantCache: {},
  rbacSkuMap: {},
  selectedAccountInfo: undefined
}


const createTenantRoleDropDown = ({ _rbac_role, rbac_role }) => {
  let filter = `(rolename eq "Account Super User") or (rolename eq "${_rbac_role}")`
  let rbacRolesMapping = []
  if (rbac_role && rbac_role.support_rbac && rbac_role.granular_roles && Array.isArray(rbac_role.granular_roles)) {
    rbacRolesMapping = rbac_role.granular_roles.map(role => {
      const { role_name, role_label, role: custom_role, type = 'app_predefined', scope, tenant_id } = role || {}
      if ((role_name && role_label) || (role_label && custom_role)) {
        /**
         * @name: use for show readable name for dropdown menu
         * @_rid: use for actual rbac role
         */
        filter += ` or (rolename eq "${_rbac_role || custom_role}:${role_name}:tenant_id")`
        let nameLabel = role_label || role_name
        if (type === 'user') {
          nameLabel = `${scope.length} Permissions`
        }
        const _rid = (role_name || custom_role) === 'Instance Admin' ? 'app_superuser' : role_name || custom_role
        return { name: nameLabel, _rid, [R_TYPE]: type, filter, scope, disabled: type === 'user', tenant_id }
      }
      return null
    })
  }

  return {
    instancesRole: [
      {
        name: AppConstants.RBAC_MIX_ROLE,
        _rid: 'mix',
        disabled: true,
      },
      ...rbacRolesMapping,
      {
        name: RBAC_NO_ROLE,
        _rid: R_NO_ROLE_ENUM,
      }
    ],
    filter
  }
}

const getInstance = ({ tenants, name, _rbac_role, rbac_role, display_name, app_id }) => {
  const newArr = []
  if (isNonemptyArray(tenants)) {
    /**
     * @rbac_role: coming from app.js in server which is hard coded. only XDR coming from mongo
     */

    const { instancesRole, filter } = createTenantRoleDropDown({ _rbac_role, rbac_role })
    // reset everytime

    tenants.forEach(tenant => {
      const { tenant_instance_name, tenant_id, tenant_display_name, serial_number, skus = null, instance_status = null, ignore_rbac } = tenant

      // APPORTAL-2905, APPORTAL-2964
      if (instance_status === RBAC_INSTANCE_PENDING || ignore_rbac) return

      /**
       * Sometimes entitlements sent two different key for some instance.
       */
      tenantIdsMap[`${tenant_id}`] = {
        tenant_instance_name: tenant_instance_name || tenant_display_name,
        tenant_id,
        serial_number,
        app: name,
        app_id,
        display_name,
        ...(skus && { tenantSkus: skus })
      }

      const instanceObj = {
        name: tenant_instance_name || tenant_display_name || serial_number || tenant_id,
        parentAppRole: _rbac_role,
        tenant_id,
        filter: filter.replace(/tenant_id/g, tenant_id),
        doRender: true,
        _rbac_role: `${_rbac_role}:Instance Admin:${tenant_id}`,
        uniqueId: uniqueId('rbac'),
        rbac_role_selected: R_NO_ROLE_ENUM, // selected role when user select role from assign role page
        rbac_roles: instancesRole,
        tenantSkus: skus
      }
      newArr.push(instanceObj)
    })
  }
  return newArr
}
const getChildInstance = (app, rbacRoles) => {
  const { filter } = app
  const appAdmin = hasRole(app.role, rbacRoles) ? {
    name: 'App Admin',
    doRender: true,
    uniqueId: app.uniqueId,
    filter,
  } : {}
  const allInstanace = hasRole(app.role, rbacRoles) ? {
    name: 'All Instances',
    rbac_role_selected: 'not_app_administrator',
    uniqueId: uniqueId('rbac'),
    doRender: false,
    isAllowed: allowAppAdmin(app, rbacRoles),
    rbac_roles: [
      {
        name: AppConstants.RBAC_MIX_ROLE,
        _rid: 'mix',
        disabled: true,
      },
      {
        name: RBAC_ADMIN,
        _rid: 'app_superuser',
        disabled: !allowAppAdmin(app, rbacRoles),
      },
      {
        name: RBAC_NO_ROLE,
        _rid: 'not_app_administrator',
        disabled: !allowAppAdmin(app, rbacRoles),
      }],
  } : {}
  allInstanace['tenant_id'] = '*'
  const newChildArr = [appAdmin, allInstanace]
  return newChildArr.concat(getInstance(app))
}

/**
 *
 * @param rbac_entitlements
 * @param superuserAccount
 * @param rbacRoles
 * @param entitlements
 * @returns {{rbac_entitlements: *[], _orig_rbac_entitlements: *, orig_app_list: *}}
 */
const getRBAC_Entitlements = ({ rbac_entitlements, rbacRoles = {}, superuserAccount, selectedAccountInfo }, entitlements) => {
  const _rbac_array = []
  superuserAppList = []
  tenantIdsMap = {}
  const is_federal = Boolean(selectedAccountInfo?.is_federal)
  if (entitlements.length) {
    entitlements.forEach(app => {
      // Do not add App Portal Entitlements for RBAC
      if (hasRole(app.role, rbacRoles)) {
        if (app.enabled && !app.isThirdParty &&
          app.rbac_role && app.rbac_role.support_rbac &&
          app.name !== AppConstants.APP_PORTAL) {
          if (is_federal && !app?.has_federal_region) return
          const { rbac_role } = app
          superuserAppList.push({ display_name: app.display_name, app_id: app?.app_id }) //
          const uId = uniqueId('rbac')
          /**
           * We look for first rbac_role under each app. if that is not present then we look for role property then rollback to app.name if none of present
           *
           */
          const role = rbac_role && rbac_role.app_role ? rbac_role.app_role : app.role ? app.role : app.name
          app.rbac_app_type = (app.display_name || app.name).replace(/ /g, '_').toUpperCase() // we going to this as enum to recognize app type
          app.isServiceApp = true
          app.filter = `(rolename eq "${role}") or (rolename eq "Account Super User")`
          app.filterQuery = app.name
          /**
             * in RBAC_V2 role will be not app role for App Admin it will be app_superuser
             * so for keep backward compatiable we going to keep app._rbac_role and assign that role to app_id
             * cause in RBAC_V2 we going to assign role to app.
             * Example {
             *   app: 'directory_sync',
             *   role: 'app_superuser',
             *   tenant_id: '*',
             *   email: '<email>,
             *
             *
             * }
             *
             */
          app._rbac_role = role
          app.uniqueId = uId
          app.doRender = true
          app.access = getChildInstance(app, rbacRoles)
          _rbac_array.push(app)
        }
      }

    })
  }
  /**
   * Keep copy of original entitlements when after first time constructed.
   * Do not mutate _orig_rbac_entitlements any cost. We need diff letter on
   * for what change and shows user to diff
   */
  const _rbac_entitlements = superuserAccount ? [..._defaultRoles, ..._rbac_array, superuserAccount] : [..._defaultRoles, ..._rbac_array]

  const _orig_rbac_entitlements = cloneDeep(_rbac_entitlements)

  // const roleMapping = createRoleMapping(entitlements)
  /**
   * I know this is anti pattern but we need to do this if user is on the Account Info page and hard reload page
   * we dont have way to get roles, So we have to wait till entitlements
   */
  setTimeout(() => {
    AppStore.dispatch(getAccountRoles())
  }, 100)
  return {
    rbac_entitlements: _rbac_entitlements,
    _orig_rbac_entitlements: _orig_rbac_entitlements,
    orig_app_list: entitlements
  }
}


const updateRole = (appName, roleType, roleMapping, tenant_id, type) => {
  if (roleType === 'not_app_administrator') {
    roleMapping[appName] = {
      'App Admin': false,
    }
  }
  else if (roleType === 'app_administrator') {
    roleMapping[appName] = {
      'App Admin': true,
      'Instance Admin': null,
    }
  }
  else if (roleType === 'app_superuser' && tenant_id === '*') {
    roleMapping[appName] = {
      'App Admin': true,
      'Instance Admin': null,
    }
  }
  else if (roleType === 'account_super_user') {
    roleMapping[AppConstants.ACCOUNT_SUPER_USER] = true
  }
  else if (roleType === 'not_account_super_user') {
    roleMapping[AppConstants.ACCOUNT_SUPER_USER] = false
  }
  else if (roleMapping[appName]) {
    const mergeRole = {
      [appName]: {
        [tenant_id]: {
          [roleType]: true,
          type
        },
      },
    }
    roleMapping = merge(roleMapping, mergeRole)
  }
  else {
    const newRole = {
      [appName]: {
        [tenant_id]: {
          [roleType]: true,
          type
        },
      },
    }
    roleMapping = { ...roleMapping, ...newRole }
  }

  // console.log(roleMapping)

  return roleMapping
}

const getRbacRolesBasedOnSelectedAccount = ({ supportAccountIds, id }) => {
  if (supportAccountIds && supportAccountIds.length) {
    const { rbacRoles } = supportAccountIds[id]
    return rbacRoles
  }
  return null
}
const getRolesBasedOnAccountSelection = ({ rbac_entitlements, superuserAccount }, { supportAccountIds, id }) => {
  // make sure supportAccountIds are present before construct
  let suAccount = superuserAccount
  if (supportAccountIds && supportAccountIds.length) {
    const auth = {
      isAuthenticated: true,
      credentials: {
        supportAccountIds,
      },
    }
    const { superuserAccount: newSuperUserAccount } = constructSuperUserAcnt({ superuserAccount: _superuserAccount }, auth)
    suAccount = newSuperUserAccount

    rbac_entitlements = rbac_entitlements.map(app => {
      if (app.uniqueId === SUPER_ACCOUNT_ID) {
        return { ...app, ...suAccount }
      }
      return app
    })
  }
  return { rbac_entitlements, superuserAccount: suAccount }

}

/**
 *
 * @param appObj
 * revert back all the roles per instances when user select App Admin in All Instance
 */
const revertBackAllRolesForSelectedService = appObj => {
  if (appObj) {
    const { access } = appObj
    if (access && access.length) {
      access.forEach(tenants => {
        const { name, isDirty } = tenants
        /*
          Ignore App Admin, and change only for isDirty
         */
        if (name !== 'App Admin' && isDirty === true) {
          if (name === AppConstants.RBAC_ALL_INSTANCES) {
            tenants.rbac_role_selected = 'not_app_administrator' // set role back to no role
          }
          else {
            tenants.rbac_role_selected = R_NO_ROLE_ENUM // set role back to no role
          }
          delete tenants.isDirty // delete isDirty property to not render dirty field
        }
      })
    }
  }
}
/**
 *
 * @param state
 * @param findInstanceObj
 * @param appObj
 * @param type
 * @returns {{roleMapping: *, createdRoles: *}}
 */
const createUserWithRoles = (state, findInstanceObj, appObj, type) => {
  const {
    selectedUsers,
    autoCompleteSelection,
    rbac_entitlements,
    existingUsersWithRoles = [],
    roleMapping,
    autoComplete,
  } = state
  let { rbac_role_selected } = findInstanceObj
  const { name, tenant_id } = findInstanceObj
  if (name === AppConstants.RBAC_ALL_INSTANCES && (rbac_role_selected === 'app_administrator' || rbac_role_selected === 'app_superuser')) {
    revertBackAllRolesForSelectedService(appObj)

  }
  else if (rbac_role_selected === 'account_super_user' || rbac_role_selected === 'account_superuser') {
    // revert all and set it account superuser
    rbac_entitlements.forEach(app => {
      if (app.isServiceApp) {
        revertBackAllRolesForSelectedService(app)
      }
    })
  }
  else {
    if (rbac_role_selected === 'instance_administrator') {
      rbac_role_selected = RBAC_ROLE_INSTANCE_ADMIN
    }
    else if (rbac_role_selected === 'instance_limited_user') {
      rbac_role_selected = RBAC_INSTANCE_LIMITED_ADMIN
    }
    else if (rbac_role_selected === `${appObj.name}_service_specific_role`) {
      rbac_role_selected = `${appObj.name} Service Specific_role`
    }

  }

  /**
   * Remove exiting tenant role if present. There will be always one role per tenant
   */
  if (has(roleMapping, `${appObj._rbac_role}.${tenant_id}`)) {
    unset(roleMapping, `${appObj._rbac_role}.${tenant_id}`)
  }
  const updateRoleMapping = updateRole(appObj._rbac_role, rbac_role_selected, roleMapping, tenant_id, type)
  const _createdRoles = []
  const array = [...selectedUsers, ...autoCompleteSelection]

  // const newUsers = assigneRoleToUsers(existingUsersWithRoles, mergeRoles)
  array.forEach(email => {
    const { needsToBeCreated } = createRoles([...existingUsersWithRoles, ...autoComplete],
      email, updateRoleMapping,
      {
        selectedRole: appObj._rbac_role,
        tenant_id,
        type
      })
    _createdRoles.push(...needsToBeCreated)
  })
  /**
   * For RBAC_V2 we needs to keep track of what roles are removed
   */
  const rolesNeedsToBEDeleted = checkIfRolesNeedsToRemove(state, _createdRoles) || []
  return {
    createdRoles: _createdRoles,
    rolesNeedsToBeDeleted: rolesNeedsToBEDeleted,
    roleMapping: updateRoleMapping,
  }
}


const checkIfRolesNeedsToRemove = ({ existingUsersWithRoles = [] }, createdRoles) => {
  const _existingRole = []
  /**
   * Get all roles from each user
   */
  existingUsersWithRoles.forEach(eachUser => {
    _existingRole.push(...eachUser.roles)
  })
  /**
   * Get uniqe rile by app, role and tenant_id
   */
  const uniqueRole = unionWith(_existingRole, (arg1, arg2) => {
    return arg1.app === arg2.app && (arg1.role === arg2.role && arg1.tenant_id === arg2.tenant_id)
  })
  /**
   * remove property for comparision so we can find difference easily
   */
  const _createdRoles = createdRoles.map(role => omit(role, ['email', 'version']))
  /**
   * Put back roles needs to be deleted for each user
   * @type {{roles: *, version: (*|string), email: *}[]}
   * @private
   */
  const _deleteRole = []
  differenceWith(uniqueRole, _createdRoles, isEqual).forEach(eachRole => {
    const userRoleMap = existingUsersWithRoles.map(eachUser => {
      if (eachRole.role === RBAC_ACCOUNT_SUPER_USER_V2) {
        /**
         * Dont send version for account_superuser role
         */
        return {
          ...eachRole,
          email: eachUser.email,
        }
      }
      return {
        ...eachRole,
        version: eachRole?.version || 'v1',
        email: eachUser.email,
      }
    })
    _deleteRole.push(...userRoleMap)
  })
  return _deleteRole
}


const convertNewRolesToLegacy = (roles) => {
  let newRolesArray = []
  for (let x = 0; x < roles.length; x++) {
    const { role, app, tenant_id, type } = roles[x]
    if (role === AppConstants.RBAC_ACCOUNT_SUPER_USER_V2) {
      // empty array if any roles is present
      // most time this is not going to happen but we can prevent this
      // conflict role with account super user if that happen
      newRolesArray = newRolesArray.length > 0 ? [] : newRolesArray
      newRolesArray.push(AppConstants.ACCOUNT_SUPER_USER)
      break
    }
    else if (tenant_id === '*') {
      // app admin roles
      newRolesArray.push(app)
    }
    else {
      // instance admin role
      newRolesArray.push(`${app}:${role}:${tenant_id}=${type}`)
    }
  }
  return newRolesArray
}
/**
 *
 * @param existingUsers
 * @param email
 * @param mergeRoles
 * @param selectedRole
 * @param tenant_id
 * @returns {{needsToBeCreated: [], needsToBeDeleted: []}}
 */
const createRoles = (existingUsers, email, mergeRoles, { selectedRole, tenant_id, type }) => {
  const checkUserPresent = existingUsers.find(user => user.email === email)
  let { roles = [] } = checkUserPresent || {}
  roles = convertNewRolesToLegacy(roles)
  const _parsedRoles = parseRoles(roles)
  // we need to do this to remove conflicting role like
  forOwn(_parsedRoles, (value, key) => {
    if (mergeRoles.hasOwnProperty(key) && mergeRoles[key]) {
      if (has(mergeRoles, `${key}.${AppConstants.APP_ADMIN}`)) {
        unset(_parsedRoles, `${key}`)
      }
      //loop over all the tenant role and set new role
      else {
        for (const tenant_id in value) {
          if (has(mergeRoles, `${key}.${tenant_id}`)) {
            unset(_parsedRoles, `${key}.${tenant_id}`)
          }
        }
      }

    }
  })
  const _mergedRole = merge(_parsedRoles, mergeRoles)
  return flattenRolesV2(_mergedRole, email)
}

const changeRoleInInstance = (state, instanceSelection) => {
  const { rbac_entitlements, _orig_rbac_entitlements, assign_roles } = state
  const { value, appType, instanceObj, type } = instanceSelection
  if (instanceObj && instanceObj.uniqueId) {
    const { uniqueId, role } = instanceObj
    const appIdx = rbac_entitlements.findIndex(app => app.rbac_app_type === appType)
    const appObj = rbac_entitlements[appIdx]
    // get original object from immutable entitlements array
    const _origAppObj = _orig_rbac_entitlements[appIdx]
    // list of instances from selected obj
    const { access } = appObj
    if (access && access.length && _origAppObj.access && _origAppObj.access.length) {
      const findInstanceIndex = access.findIndex(instance => instance.uniqueId === uniqueId)
      const _origFindInstObj = _origAppObj.access[findInstanceIndex]
      appObj['rbac_role_selected'] = value
      const findInstanceObj = access[findInstanceIndex]
      findInstanceObj['rbac_role_selected'] = value
      if (findInstanceObj.isSuperUserAcnt && value === 'not_account_super_user') {
        disabledEnabledAllApp('all', groupBy(rbac_entitlements, 'rbac_app_type'), false)
      }
      /**
       * account_super_user for legacy RBAC_V1 and account_superuser for RBACV2
       */
      else if ((findInstanceObj.isSuperUserAcnt && value === 'account_super_user') || (findInstanceObj.isSuperUserAcnt && value === 'account_superuser')) {
        disabledEnabledAllApp('all', groupBy(rbac_entitlements, 'role'), true)
      }
      else if (value === 'app_administrator' || (value === 'app_superuser' && findInstanceObj.tenant_id === '*')) {
        disabledEnabledAllApp(role, groupBy(rbac_entitlements, 'role'), true)
      }
      else if (value === 'not_app_administrator') {
        disabledEnabledAllApp(role, groupBy(rbac_entitlements, 'role'), false)
      }
      // we need to remove this before compare object with immutable object otherwise its always dirty
      // this is helping us to show orange icon on the each instance
      delete findInstanceObj['isDirty']
      delete appObj['isDirty']
      const userWithRoles = createUserWithRoles(state, findInstanceObj, appObj, type)
      appObj.isDirty = !(JSON.stringify(findInstanceObj) === JSON.stringify(_origFindInstObj)) // check if field is dirty
      findInstanceObj.isDirty = !(JSON.stringify(findInstanceObj) === JSON.stringify(_origFindInstObj)) // check if field is dirty
      return { rbac_entitlements: rbac_entitlements.slice(0), ...userWithRoles, selectedApp: appObj }
    }
  }
  return { rbac_entitlements, assign_roles }
}

const constructSuperUserAcnt = ({ superuserAccount }, auth, selectedAccountIdx) => {
  superuserAccount = superuserAccount ? superuserAccount : _superuserAccount
  if (auth?.isAuthenticated) {
    const { credentials } = auth
    selectedAccountIdx = selectedAccountIdx >= 0 ? selectedAccountIdx : (+localStorage?.selectedAccount || 0)
    const selectedAccount = credentials.supportAccountIds[selectedAccountIdx] || credentials.supportAccountIds[0]
    let name = 'Account'
    if (selectedAccount?.name) {
      name = selectedAccount.name
    }
    const access = [{ ...superuserAccount.access[0], name }]
    const superObje = {
      access,
    }
    const { rbacRoles } = selectedAccount || {}
    if (rbacRoles) {
      const isAllowed = hasRole(['Account Super User'], rbacRoles)
      superuserAccount = isAllowed ? { ...superuserAccount, ...superObje } : null
      return { superuserAccount, rbacRoles }
    }
    // no rbac roles so we don't add superuser account
    return { superuserAccount: null, rbacRoles: {} }

  }
  // not authenticated
  return { superuserAccount: null, rbacRoles: {} }
}
/**
 *
 * @param _orig_rbac_entitlements
 * @param selectedUsers
 * @param autoCompleteSelection
 * @param users
 * @param tagTobeRemoved
 * @returns {{[p: string]: *}}
 */
const removeTagFromSelection = ({ _orig_rbac_entitlements, selectedUsers, autoCompleteSelection, users }, tagTobeRemoved) => {
  if (selectedUsers && selectedUsers.length && selectedUsers.indexOf(tagTobeRemoved) > -1) {
    selectedUsers = selectedUsers.filter(tag => tag !== tagTobeRemoved)
  }
  else if (autoCompleteSelection && autoCompleteSelection.length && autoCompleteSelection.indexOf(tagTobeRemoved) > -1) {
    autoCompleteSelection = autoCompleteSelection.filter(tag => tag !== tagTobeRemoved)
  }

  const roleChangeEntitlements = getRolesFromUser({ _entitlementsList: _orig_rbac_entitlements, selectedUsers, users })
  return { selectedUsers, autoCompleteSelection, ...roleChangeEntitlements }
}

/**
 *
 * @param users
 * @param isTableSearching
 * @param search
 * @returns {*}
 */
const searchUsers = ({ users, isTableSearching }, search) => {
  search = search?.replace(/ /g, '').toLowerCase() || ''
  const { data } = users
  const _data = data.slice(0)
  if (isTableSearching && _data && _data.length && search) {
    const _users = _data.filter((user) => {
      const { email = '' } = user
      let { first_name = '', last_name = '' } = user
      first_name = first_name?.replace(/ /g, '') || ''
      last_name = last_name?.replace(/ /g, '') || ''
      const fullName = `${first_name}${last_name}`
      return fullName.toLowerCase().indexOf(search) > -1 || email?.toLowerCase().indexOf(search) > -1 ? user : null
    })
    return _users || []
  }
  return data
}

/**
 *
 * @param rbac_entitlements
 * @param selection
 * @returns {*}
 */
export const getSelectedAppBasedonRadio = ({ rbac_entitlements }, selection) => {
  return rbac_entitlements.find((item) => {
    const { role } = item
    if (selection.indexOf(role) > -1) {
      return item
    }
    return null
  })
}

/**
 *
 * @param _orig_rbac_entitlements
 * @param selectedUsers
 * @param users
 * @returns {*}
 */

const createRoleForEntitlements = ({ _orig_rbac_entitlements, selectedUsers, users }) => {
  return getRolesFromUser({ _entitlementsList: _orig_rbac_entitlements, selectedUsers, users })
}

/**
 *
 * @param _orig_rbac_entitlements
 * @param roles
 * @param selectedUsers
 * @returns {{rbac_entitlements: *, existingUsersWithRoles: *}}
 */

const createAdminRoleEntitlements = ({ _orig_rbac_entitlements }, { roles, selectedUsers }) => {
  return assignRoleToEntitlements({
    _entitlementsList: _orig_rbac_entitlements || {},
    selectedUsers: selectedUsers,
    users: [],
  }, roles, null, true)

}


const getAccountAdminAppRoleList = (list, { rbacRoles = {} }) => {
  const appList = list.filter(item => item.isServiceApp)
  const listKeyBy = keyBy(appList, 'role')
  if (rbacRoles.hasOwnProperty(AppConstants.ACCOUNT_SUPER_USER) && rbacRoles[AppConstants.ACCOUNT_SUPER_USER]) {
    return list.filter(app => app.role === AppConstants.ACCOUNT_SUPER_USER)
  }
  else {
    forOwn(rbacRoles, (tenants, role) => {
      const newAccArr = []
      for (const tenant_id in tenants) {
        if (listKeyBy[role] && listKeyBy[role].isServiceApp) {
          const foundApp = listKeyBy[role].access.find(item => {
            if (tenant_id === AppConstants.APP_ADMIN && item.name === AppConstants.RBAC_ALL_INSTANCES) {
              return item
            }
            return item.tenant_id && item.tenant_id.toString() === tenant_id.toString()
          }
          )
          if (foundApp) {
            newAccArr.push(foundApp)
          }

        }
      }
      if (listKeyBy[role] && (listKeyBy[role].access)) {
        listKeyBy[role].access = newAccArr
      }
    })
  }
  return appList
}


/**
 *
 * @param rbac_entitlements
 * @param rbacRoles
 * @param roleDef
 * @param app_id
 * @param tenant_id
 * @returns {{}}
 */
const mergeCustomRole = ({ rbac_entitlements, rbacRoles }, roleDef, app_id, { tenant_id, role }) => {
  const selectedApp = rbac_entitlements.find(app => app.app_id === app_id)

  /**
   * Try to set custom permission to tenantId directly
   */
  // if (tenant_id) {
  //   const access = get(selectedApp, 'access', [])
  //   const findTenant = access.find(eachTenant => eachTenant.tenant_id === tenant_id)
  //   // console.log(findTenant)
  //   // if (findTenant) {
  //   //   findTenant.rbac_role_selected = role
  //   // }
  // }

  {
    const rbac_roles = [
      {
        name: AppConstants.RBAC_MIX_ROLE,
        _rid: 'mix',
        disabled: true,
      },
      {
        name: RBAC_ADMIN,
        _rid: 'app_administrator',
        disabled: !allowAppAdmin(selectedApp, rbacRoles),
      },
      {
        name: RBAC_NO_ROLE,
        _rid: 'not_app_administrator',
        disabled: !allowAppAdmin(selectedApp, rbacRoles),
      }]
    /**
     * Just put app_superuser on top if this it is not on top
     * @type {number | *}
     */
    const idx = roleDef.findIndex(role => role.role === 'app_superuser')
    const app_superuser = roleDef[idx]
    roleDef.splice(idx, 1)
    roleDef.unshift(app_superuser)
    set(selectedApp, 'rbac_role.granular_roles', rbac_roles.concat(roleDef))
  }
  const { instancesRole = [] } = createTenantRoleDropDown(selectedApp)
  return { [app_id]: instancesRole } // NOTE: must return rbac_entitlements otherwise it will break rbac
}

const getApplicationName = (roles, rbac_entitlements, appDisplayNameMap) => {
  const _roles = []
  roles.forEach(eachRole => {
    const { role, app } = eachRole
    if (role === RBAC_ACCOUNT_SUPER_USER_V2) {
      _roles.push(...superuserAppList)
    }
    _roles.push({ display_name: appDisplayNameMap?.[app]?.display_name || app, app_id: app })
  })
  return _roles
}
const getAppInstances = (roles) => {
  return roles.map(eachRole => {
    const { tenant_id } = eachRole

    if (isAccountSuperUser(roles)) {
      return { access: 'All Instances' }
    }
    else if (isAppAdmin(eachRole)) {
      return { access: 'All Instances' }
    }
    else {
      const tenantIdName = tenantIdsMap[tenant_id]?.tenant_instance_name || tenantIdsMap[tenant_id]?.serial_number || tenant_id
      return { access: tenantIdName }
    }
  })
}

const getRolesPerUser = (roles, rbacSkuMap) => {
  const roleList = []
  roles.forEach(eachRole => {
    const { app, tenant_id, type, role = null } = eachRole
    const app_id = tenantIdsMap[tenant_id]?.app_id || app // fallback to app (some tenants in roles doesn't exists in entitlements list)
    const roleObj = {
      role,
      app,
      app_id,
      tenant_id,
      isRoleMismatch: false
    }

    if (rbacSkuMap[app] && ![R_USER, R_CUSTOM].includes(type)) {
      const rbacSkuList = rbacSkuMap[app][role]?.sku || []
      const tenantSkus = tenantIdsMap[tenant_id]?.tenantSkus
      // check mismatch only tenant sku exists AND rbac schema app_predefined_roles role exists
      if (tenantSkus && isNonemptyArray(rbacSkuList)) {
        roleObj.isRoleMismatch = !(rbacSkuList.some(schemaSku => schemaSku === '*' || tenantSkus.some(tenantSku => tenantSku === schemaSku)))
      }
    }

    roleList.push(roleObj)
  })

  return roleList.sort((x, y) => y.isRoleMismatch - x.isRoleMismatch)
}

const rbacAppRoleSkuMap = ({ rbacSkuMap }, rbacSchema) => {
  if (isEmpty(rbacSchema)) return rbacSkuMap
  rbacSchema.forEach((schema) => {
    const { app, app_predefined_roles } = schema
    if (isEmpty(app_predefined_roles)) return rbacSkuMap
    rbacSkuMap[app] = {}
    app_predefined_roles.forEach((app_predefined_role) => {
      const { role, role_label, sku = null } = app_predefined_role
      if (role && role_label) {
        rbacSkuMap[app][role] = {
          sku: sku ? sku : ['*'], // if no rbac SKU default to all(i.e. *)
          role,
          role_label
        }
      }
    })

  })
  return { rbacSkuMap }
}


const mergeUsersRoleWithEntitlements = ({ rbac_entitlements, rbacSkuMap }, userData = []) => {
  const appDisplayNameMap = keyBy(rbac_entitlements, 'app_id')
  const userList = userData.map((eachUser) => {
    const { first_name, last_name, roles } = eachUser
    const userRoles = getRolesPerUser(roles, rbacSkuMap)
    const isSkuMisMatch = userRoles.some(x => x.isRoleMismatch === true)
    const isAccountSuperUser = Array.isArray(roles) && roles[0]?.role === RBAC_ACCOUNT_SUPER_USER_V2
    return {
      ...Object.freeze(eachUser),
      fullName: `${first_name} ${last_name}`,
      isSkuMisMatch,
      isAccountSuperUser,
      application: isNonemptyArray(userRoles) ? getApplicationName(userRoles, rbac_entitlements, appDisplayNameMap) : null,
      appInstance: isNonemptyArray(userRoles) ? getAppInstances(userRoles) : null,
      displayRoles: userRoles
    }
  })
  return userList.sort((x, y) => y.isSkuMisMatch - x.isSkuMisMatch)
}

/**
 *
 * @param state
 * @param action
 * @returns {*}
 * @constructor
 */
const RbacReducer = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case 'IS_FEDERAL': {
      const { supportAccountIds, selectedAccount } = action.data
      const selectedAccountInfo = supportAccountIds?.[selectedAccount]
      return { ...state, selectedAccountInfo }
    }
    case 'MERGE_APP': {
      const { rbac_entitlements, _orig_rbac_entitlements, orig_app_list } = getRBAC_Entitlements(state, action.mergeAppsList)
      const data = mergeUsersRoleWithEntitlements({ rbac_entitlements, rbacSkuMap: state.rbacSkuMap }, state.users.data)
      return { ...state, rbac_entitlements, _orig_rbac_entitlements, orig_app_list, users: { data, lastUpdate: Date.now() }, tenantCache: tenantIdsMap }
    }
    case Actions.IS_MIXED_HAPPEN: {
      return { ...state, isMixedHappen: action.isMixedHappen }
    }
    case Actions.GET_ALL_ROLES: {

      return { ...state, users: { data: action.users || [], lastUpdate: Date.now() } }
    }
    case 'MERGE_USER_WITH_LIST': {
      const data = mergeUsersRoleWithEntitlements(state, action.users)
      return { ...state, users: { data, lastUpdate: Date.now() } }
    }
    case 'SET_CURRENT_SUPPORT_ACCOUNT_INDEX': {
      const { supportAccountIds, selectedAccount, id } = action
      const rbacRoles = getRbacRolesBasedOnSelectedAccount({ supportAccountIds, selectedAccount, id })
      const { rbac_entitlements, superuserAccount } = getRolesBasedOnAccountSelection(state, {
        supportAccountIds,
        selectedAccount,
        id,
      })
      return { ...state, ...{ rbac_entitlements, rbacRoles, superuserAccount }, tenantCache: tenantIdsMap }
    }
    case 'FETCH_CREDENTIALS_SUCCESS': {
      return { ...state, ...constructSuperUserAcnt(state, action.data, action.selectedAccount) }
    }
    case Actions.AUTO_COMPLETE_GET_USERS: {
      return { ...state, autoComplete: action.autoComplete }
    }
    case Actions.SELECTED_USERS: {
      return { ...state, selectedUsers: action.selection }
    }
    case Actions.AUTO_COMPLETE_USER_SELECTION: {
      return { ...state, autoCompleteSelection: action.autoCompleteSelection }
    }
    case Actions.GET_CLOUD_ROLES: {
      return { ...state, defaultCloudRoles: state.defaultCloudRoles }
    }
    case Actions.ASSIGN_INSTANCE_ROLES: {
      return { ...state, ...changeRoleInInstance(state, action.instanceSelection) }
    }
    case Actions.FILTER_CHANGE: {
      const appInstance = getSelectedAppBasedonRadio(state, action.radioFilterValue)
      return { ...state, ...{ selectedFilter: action.radioFilterValue, selectedApp: appInstance } }
    }
    case Actions.REMOVE_SELECTED_USER_TAG: {
      return { ...state, ...removeTagFromSelection(state, action.tagTobeRemoved) }
    }
    case Actions.TABLE_SEARCH: {
      return { ...state, isTableSearching: action.isTableSearching }
    }
    case Actions.AUTO_COMPLETE_LOADING: {
      return { ...state, isAutoCompleteLoading: action.isAutoCompleteLoading }
    }
    case Actions.INPUT_TABLE_SEARCH: {
      return {
        ...state,
        searchUsers: { data: searchUsers(state, action.search), lastUpdate: Date.now(), searchTerm: action.searchTerm },
      }
    }
    case Actions.GET_SELECTED_USERS_ROLES: {
      return {
        ...state,
        users: { data: action.users || [], lastUpdate: Date.now() },
        existingUsersWithRoles: action.existingUsersWithRoles
      }
    }
    case Actions.CLEAR_ASSIGN_ROLE_CACHE: {
      return { ...state, ...{ roleMapping: {}, assign_roles: {}, appDisplayNameMap: {}, ...createRoleForEntitlements(state) } }
    }
    case Actions.GET_USER_ACCOUNT_ROLE: {
      const { rbac_entitlements } = createAdminRoleEntitlements(state, action.adminRole)
      return { ...state, accountAdminEntitlements: getAccountAdminAppRoleList(rbac_entitlements, state) }
    }
    case Actions.RBAC_MERGE_ROLE_DEF: {
      return { ...state, roleDefMap: mergeCustomRole(state, action.roleDef, action.app_id, { ...action.roleObj }) }
    }
    case 'RBAC_TABLE_LOADING': {
      return { ...state, isUserTableLoad: action.isUserTableLoad }
    }
    case Actions.RBAC_SKU_LOOKUP: {
      return { ...state, ...rbacAppRoleSkuMap(state, action.rbacSchema) }
    }
    default: {
      return state
    }
  }
}

export default RbacReducer
