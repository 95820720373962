/**
 * Created by vsuthar on 3/3/20
 * Project: App Portal ©Palo Alto Networks
 */
import React from 'react'
import { groupBy } from 'lodash'
import { Icon, Menu, Tooltip } from '@pan/cloud-base'
import {
  RBAC_ALL_APPS,
  RBAC_ROLE_TOOL_TIP,
  RBAC_ALL_INSTANCES,
  RBAC_INSTANCE_ADMIN,
  RBAC_CUSTOM_PERMISSION_ASSIGN,
} from '../../constants/AppConstants'
import { determineUserPrivilege, validateEmail, isInstanceAdmin } from '../../utils/rbacUtils'
import ListItem from '../../components/ListItem'

const { SubMenu, Item } = Menu

const rolesStyle = {
  fontSize: 16,
  color: '#0E9AC8',
  marginRight: 5
}

const cellPadding = {
  paddingTop: 10,
  paddingBottom: 10,
  height: 39,
  lineHeight: 1.3
}

const allAppsStyle = {
  color: '#999',
  fontStyle: 'italic',
  ...cellPadding
}

const renderInstances = (value) => {
  return <div className={'RBAC__rtable_instanceRole'}>{
    value?.map((val, idx) => <div key={idx} style={val?.access === RBAC_ALL_INSTANCES ? allAppsStyle : cellPadding}>{<ListItem toolTipProps={{ placement: 'bottom' }}>{val?.access}</ListItem>}</div>)
  }</div>
}

const renderRoles = (value) => {
  return <div className={'RBAC__rtable_instanceRole'}>{
    value?.map((eachRole, idx) => {
      const { role, isRoleMismatch, app_id } = eachRole
      let roleDisplayName
      if (isInstanceAdmin(eachRole)) {
        roleDisplayName = RBAC_INSTANCE_ADMIN
      }
      else if (validateEmail(role)) {
        roleDisplayName = RBAC_CUSTOM_PERMISSION_ASSIGN
      }
      else {
        roleDisplayName = determineUserPrivilege(role, app_id)
      }
      return isRoleMismatch ? renderMismatchRole(roleDisplayName) : <div key={idx} style={cellPadding}>{<ListItem toolTipProps={{ placement: 'bottom' }}>{roleDisplayName}</ListItem>}</div>
    })
  }</div>
}

const renderMismatchRole = (role) => {
  return <Tooltip placement='left' title={RBAC_ROLE_TOOL_TIP}>
    <div style={cellPadding}><Icon type="info-circle" style={rolesStyle}/><ListItem toolTipProps={{ placement: 'bottom' }}>{role}</ListItem></div>
  </Tooltip>
}

const renderAllApps = (value) => {
  return <Menu mode={'inline'} style={{ border: 0 }} inlineIndent={0} selectable={false}>
    <SubMenu title={RBAC_ALL_APPS} className={'RBAC__all_apps'} size={'small'}>
      {value.map((app, idx) => {
        return <Item key={idx}>{app.display_name}</Item>
      })
      }
    </SubMenu>
  </Menu>
}

const renderApps = (value) => {
  const groupedApps = groupBy(value, 'app_id')
  return Object.keys(groupedApps).map((app_id) => {
    return <div key={app_id} className={'appName'}>
      {
        groupedApps[app_id].map((obj, idx) => {
          if (idx === 0) {
            return <div key={idx} style={ cellPadding }>{obj?.display_name}</div>
          }
          else if (idx === groupedApps[app_id].length - 1) {
            return <div key={idx} style={ cellPadding } />
          }
          return <div key={idx} style={ cellPadding }/>
        })
      }
    </div>

  })
}

const renderNone = (roleObj = {}) => {
  const { app, role, tenant_id } = roleObj
  if (!app && !role && !tenant_id) return <span style={allAppsStyle}>None</span>

}

export const roleColumns = [
  {
    Header: 'EMAIL',
    accessor: 'email'
  },
  {
    Header: 'Name',
    accessor: 'fullName',
  },
  {
    Header: 'APPLICATIONS',
    accessor: 'application',
    className: 'applications',
    Cell: ({ value, original }) => {
      const { isAccountSuperUser } = original
      if (value && value.length) {
        return isAccountSuperUser ? renderAllApps(value) : renderApps(value)
      }
      return renderNone()
    }
  },
  {
    Header: 'APP INSTANCES',
    accessor: 'appInstance',
    Cell: ({ value }) => {
      return value && value.length ? renderInstances(value) : renderNone()
    }
  },
  {
    Header: 'ROLES',
    accessor: 'displayRoles',
    Cell: ({ value }) => {
      return value && value.length ? renderRoles(value) : renderNone()
    }
  }
]
