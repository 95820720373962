import React, { PureComponent } from 'react'
import { Row, Col } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import HeaderWithTip from '../../components/LoggingCalculator/HeaderWithTip'
import { LSCLabel } from './LSCLabel'
import HelpText from './HelpText'
import NumberInput from '../../components/LoggingCalculator/NumberInput'
import { FT } from '@pan/cloud-appframework'

class GPCSMobileUser extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      numberOfUsers: 0, // number of user set to 0
      retentionPrd: 30, // set retention period to the default value of 30 days
      effectiveLogRate: 0, // set effective Log rate to 0
      totalStorage: 0,
      storageDetailLog: 0
    }
  }

  handleChange(type, e) {
    let value = Boolean(e?.target?.value) ? e.target.value : e
    value = Math.abs(value) // we need un-sign int
    this.setState({
      [type]: isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10)
    }, () => {
      const { numberOfUsers = 0, retentionPrd = 0 } = this.state
      /* retentionPrd is 0 by default we have to account for global retention period. if global retention period is present
          and component level retention period is 0 then take that in account global retention period for calculation
      */
      /**
       * TODO: We should put this logic to center place. Currently same logic is used in redux when magnifier clicked
       */
      const { isMagnifierChecked, isIoTChecked } = this.props
      const primeRetentionPrd = retentionPrd === 0 && this.props.retentionPrd > 0 ? this.props.retentionPrd : retentionPrd
      const effectiveLogRate = (0.05 * numberOfUsers)
      const storageDetailLog = isMagnifierChecked || isIoTChecked ? (numberOfUsers * primeRetentionPrd * 15) / 1024 :
        (numberOfUsers * primeRetentionPrd * 6666666.667) / Math.pow(1024, 3)
      const totalStorage = FT('PA_CALC_UPDATE_FORMULA') ? storageDetailLog : (storageDetailLog / 0.6)
      this.setState({
        totalStorage, effectiveLogRate, storageDetailLog, retentionPrd: primeRetentionPrd
      }, this.reportToParent.bind(this))
    })
  }

  reportToParent() {
    this.props.totalData({ appId: 'gpcsMobileUsers', ...this.state })
  }

  render() {
    const { others } = this.props
    const { numberOfUsers = 0, retentionPrd = 0 } = others
    const tipStyle = { color: '#999999', fontSize: 14 }
    /**
     * if global retention period is present then we should use this.props.retentionPrd unless component overrides it
     */
    return (
      <Row type='flex' align='middle'>
        <Col xs={12}>
          <HeaderWithTip>
            <LSCLabel>Number of Users</LSCLabel>
          </HeaderWithTip>
          <NumberInput value={numberOfUsers}
            className='logging-calc-ant-input'
            defaultValue={0}
            onChange={this.handleChange.bind(this, 'numberOfUsers')} />
        </Col>
        <Col xs={12}>
          <HeaderWithTip tooltipProps={{ title: HelpText('retentionPrd') }} tipIconProps={ { style: tipStyle }}>
            <LSCLabel>Retention Period (in days)</LSCLabel>
          </HeaderWithTip>
          <NumberInput suffix='day(s)'
            className='logging-calc-ant-input'
            value={retentionPrd}
            defaultValue={30}
            min={30}
            onChange={this.handleChange.bind(this, 'retentionPrd')}/>
        </Col>
      </Row>
    )
  }
}

/**
 *
 * @type {{totalData: *, isMagnifierChecked: *, retentionPrd: *}}
 */
GPCSMobileUser.propTypes = {
  totalData: PropTypes.func,
  isMagnifierChecked: PropTypes.bool,
  others: PropTypes.object,
  retentionPrd: PropTypes.number,
  isIoTChecked: PropTypes.bool,
}

/**
 *
 * @type {{totalData: GPCSMobileUser.defaultProps.totalData, retentionPrd: number}}
 */

GPCSMobileUser.defaultProps = {
  totalData: () => { },
  retentionPrd: 0,
  others: {
    numberOfUsers: 0,
    retentionPrd: 0,
    effectiveLogRate: 0,
    totalStorage: 0,
    storageDetailLog: 0
  }
}

export default GPCSMobileUser
