import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { APPS, ACTIVATE } from '../../../constants/RouteConstants'
import { AddTileDotted } from '../../../images/svg-icons'
import { ACTIVATE_APP } from '../../../constants/AppConstants'

export const ActivateLink = ({ showAuthCodeModal }) => (
  <div id='activate' className='app_wrapper'>
    <Link to={{ pathname: ACTIVATE, state: { referer: APPS } }} onClick={(e) => {
      e.preventDefault()
      showAuthCodeModal()
    }} className='activate-link'>
      <AddTileDotted className='add-tile dotted' />
    </Link>
    <p><span className='icon_caption'>{ACTIVATE_APP}</span></p>
  </div>
)

ActivateLink.propTypes = {
  showAuthCodeModal: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  showAuthCodeModal: () => dispatch({ type: 'SHOW_AUTHCODE_ACTIVATE_MODAL', authcode: '' }),
})

export default connect(null, mapDispatchToProps)(ActivateLink)
