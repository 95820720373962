import { isString } from 'lodash'
import { hasRole, getRoles, utils } from '@pan/cloud-appframework'
import {
  LGS,
  TRAPS,
  MAGNIFIER,
  XDR,
  ACCOUNT_SUPER_USER,
  RBAC_ACCOUNT_SUPER_USER_V2,
  RBAC_DISPLAY_ACCOUNT_SUPER_USER,
  RBAC_APP_ADMIN_V2,
  RBAC_ADMIN,
  RBAC_ROLE_INSTANCE_ADMIN,
} from '../constants/AppConstants'
import { isNonemptyArray } from './common'

export const APP_ADMIN_ROLE = 'App Admin'
export const INSTANCE_ADMIN_ROLE = 'Instance Admin'
const APP_ADMIN_ROLE_OBJ = {
  [APP_ADMIN_ROLE]: true,
}
const ALL = '*'

const INST_ROLE_REGEX = /^[\w -]+:[\w -]+:\w+$/
const USER_ROLE_REGEX = /[\w -]:[^\s@]+@[^\s@]+\.[^\s@]+:\d/

const DSS = 'Directory Sync Service'
const PRISMA_ACCESS = 'Prisma Access'
const APERTURE = 'Aperture' //APPORTAL-1846
const REDLOCK = 'RedLock' //APPORTAL-1846
const KNOWN_ROLES = {
  BOOL: new Set([ACCOUNT_SUPER_USER]),
  APPS: new Set([DSS, LGS, TRAPS, MAGNIFIER, XDR, PRISMA_ACCESS, APERTURE, REDLOCK]),
}

const KNOWN_ROLES_MAP = (() => {
  const rolesMap = new Map()
  rolesMap.set(RBAC_ACCOUNT_SUPER_USER_V2, RBAC_DISPLAY_ACCOUNT_SUPER_USER)
  rolesMap.set(RBAC_APP_ADMIN_V2, RBAC_ADMIN)
  rolesMap.set('Instance Admin', RBAC_ROLE_INSTANCE_ADMIN)
  return rolesMap
})()

export const AUTHCODE_APP_ADMIN = [LGS, TRAPS, MAGNIFIER, XDR, PRISMA_ACCESS].map(r => `${r}.${APP_ADMIN_ROLE}`)
export const APP_ADMIN = [`${DSS}.${APP_ADMIN_ROLE}`, ...AUTHCODE_APP_ADMIN]
// export const APP_INSTANCE_ADMIN_ROLES = []

export const updateKnownRoles = (appList) => {
  // add new known roles to the const after apps been fetched
  const apps = Array.isArray(appList) ? appList : Object.values(appList)
  APP_ADMIN.length = 0 // clear array
  AUTHCODE_APP_ADMIN.length = 0 // clear array
  // APP_INSTANCE_ADMIN_ROLES.length = 0 // clear array
  apps.forEach((app) => {
    const { app_id } = app
    if (app.role && !app.isThirdParty) {
      KNOWN_ROLES.APPS.add(app.role)
      // Get all role mapping and their label for granular role.
      // This is used when we show confirmation dialog
      if (app.hasOwnProperty('rbac_role') && app.rbac_role.granular_roles) {
        const { granular_roles } = app.rbac_role
        granular_roles.forEach(eachRole => {
          const { role_name, role_label } = eachRole
          // we needs to do that because there are two xdr present. Also if role_name is same for some apps
          // we needs to make sure key not collide with value
          KNOWN_ROLES_MAP.set(`${app_id}-${role_name}`, role_label)
        })
      }
      const appAdminRole = `${app.role}.${APP_ADMIN_ROLE}`
      APP_ADMIN.push(appAdminRole)
      if (app.has_auth_code) {
        AUTHCODE_APP_ADMIN.push(appAdminRole)
      }
      // APP_INSTANCE_ADMIN_ROLES.push(`${app.role}.${INSTANCE_ADMIN_ROLE}`)
    }
  })
}

export const shouldAppUsePlatformId = () => false

/*! please keep in sync with @pan/cloud-service-saml/lib/utils/rbacUtils.js */
/**
 * parse the rbac roles string array to a tree structure object
 * examples:
 * 'Account Super User' => { 'Account Super User': true }
 * 'Traps' => { 'Traps': { 'App Admin': true } } matches 'Traps.App Admin' or 'Traps:App Admin'
 * 'Traps:Instance Admin:123' => { 'Traps': { '123': { 'Instance Admin': true } } } matches 'Traps.123.App Admin' or 'Traps:Instance Admin:123'
 * 'Traps:granular_role:123' => { 'Traps': { '123': { 'granular_role': true } } } matches 'Traps.123.granular_role' or 'Traps:granular_role:123'
 * @param {Array<string>} roles from saml jwt token
 * @return {Object} rbac roles object
 */

export const parseRoles = (roles) => {
  const roleMap = {}
  if (!isNonemptyArray(roles)) {
    return roleMap
  }
  for (const role of roles) {
    let _role = role
    let type = null
    /**
     * Because now we have type for each role we are setting type separated by = in each role
     * Like xdr:in_admin:<tenant_id>=<type>
     *
     */
    { // RBAC_V2
      const [__role, roleType] = role.split('=')
      _role = __role
      type = roleType
    }
    if (KNOWN_ROLES.BOOL.has(_role)) {
      roleMap[role] = true
    }
    else if (KNOWN_ROLES.APPS.has(_role)) {
      roleMap[role] = { ...APP_ADMIN_ROLE_OBJ }
    }
    else if (INST_ROLE_REGEX.test(_role) || USER_ROLE_REGEX.test(_role)) {
      const [app, name, tid] = _role.split(':')
      if (KNOWN_ROLES.APPS.has(app)) {
        const appRoleObj = roleMap[app] || (roleMap[app] = {})
        const instRoles = appRoleObj[tid] || (appRoleObj[tid] = {})
        instRoles[name] = true
        if (type) {
          instRoles.type = type
        }
      } // else: ignore unknown apps
    } // else: ignore unknown roles
  }
  return roleMap
}

/**
 * check rbac role with expected role
 * @param {{name: string, role: string, isThirdParty: bool?}} app object from app list
 * @param {string|function|array} expectedRole
 */
export const allowRole = (app, expectedRole = app.role, loggedInRole) => {
  if (!app) {
    return false
  }
  if (app.role === 'ANY') {
    return true
  }
  return hasRole(expectedRole, loggedInRole)
}

/**
 * check rbac role for app
 * either app admin or any instance admin
 * @param {{name: string, role: string, isThirdParty: bool?}} app object from app list
 */
export const allowApp = (app, loggedInRole) => allowRole(app, app.role, loggedInRole)

/**
 * check rbac role for app admin only
 * @param {{name: string, role: string}} app object from app list
 */
export const allowAppAdmin = (app, loggedInRole) => allowRole(app, `${app.role}.${APP_ADMIN_ROLE}`, loggedInRole)

/**
 * check rbac role for app instance
 * either app admin or specific instance admin
 * @param {{name: string, role: string, isThirdParty: bool?}} app object from app list
 * @param {string|{tenant_id: string, platform_id: string?}} tenant tenant object or id
 */
export const allowAppInstance = (app, tenant, instanceRole = null, loggedInRole) => {
  const tid = isString(tenant) ? tenant : tenant.tenant_id // does not support mapping yet
  if (!tid) {
    return false
  }
  const tRole = instanceRole ? `${tid}.${instanceRole}` : tid
  return allowRole(app, tid === ALL ? app.role : [
    `${app.role}.${APP_ADMIN_ROLE}`,
    `${app.role}.${tRole}`,
  ], loggedInRole)
}

export const allowInstanceAdmin = (app, tenant, loggedInRole) => allowAppInstance(app, tenant, INSTANCE_ADMIN_ROLE, loggedInRole)

/**
 * filter app list with tenants by rbac roles
 * @param {object[]} entitledAppsList array from redux state
 * @param {object} [roles] rbac roles object
 */
export const filterAppListByRole = (entitledAppsList, roles = getRoles()) => {
  return utils.filterAppEntitlementsByRole(entitledAppsList, roles)
}

export const filterAppEntitlementsByNoAccessFlag = utils.filterAppEntitlementsByNoAccessFlag

export const checkIfDSS = (app) => {
  return app === 'directory_sync_service' ? 'directory_sync' : app
}


const validateEmail = (data) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data)

}

const isCustomPermission = (role) => {
  if (validateEmail(role)) {
    return 'Custom Permissions'
  }
  return role
}

/**
 * Get role label from known roles constructed while app loaded
 * Please look rbacUtils updateKnownRoles function KNOWN_ROLES_MAP set
 * @param role
 * @param app
 * @returns {string|V|*}
 */
export const determineUserPrivilege = (role, app_id) => {
  if (KNOWN_ROLES_MAP.has(`${app_id}-${role}`)) {
    const roleLabel = KNOWN_ROLES_MAP.get(`${app_id}-${role}`)
    if (!roleLabel) {
      return isCustomPermission(role)
    }
    return roleLabel
  }
  // sometimes app_superuser and account_superuser not need per app role because its global role
  else if (KNOWN_ROLES_MAP.has(role)) {
    return KNOWN_ROLES_MAP.get(role)
  }
  return role
}

export const isInstanceAdmin = (roleObject) => {
  const { tenant_id, role } = roleObject
  return tenant_id !== ALL && role === RBAC_APP_ADMIN_V2
}
export { hasRole, getRoles, KNOWN_ROLES_MAP, validateEmail }
