/**
 *
 * Created by vsuthar on 9/20/18 File PANContent
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import { Layout, PageHeader } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import './PANContent.css'
import { Link } from 'react-router-dom'
const { Content } = Layout
class PANContent extends PureComponent {
  /**
   *
   * @returns {*}
   * ** showPageHeader = true**
   */
  getPageHeader() {
    const { showPageHeader, showGoBackLink = false, goBackLabel = '', subheadClassName, goBackTo } = this.props
    let link
    if (showGoBackLink) {
      link = <Link className={'PAN__go_back'} to={goBackTo}>{`Go Back to ${goBackLabel}`}</Link>
    }
    let pageHeader = null
    /**
     * if showPageHeader is true then default to below param header text
     */
    if (showPageHeader) {
      const { header = 'Main Header', subHeader = 'Your SubHeader' } = this.props
      pageHeader = <PageHeader head={header} subhead={subHeader} subheadClassName={subheadClassName}/>
    }
    return <div style={{ paddingTop: 24 }}>
      {link}
      {pageHeader}
    </div>
  }
  render() {
    const { showPageHeader, header, subHeader, goBackLabel, showGoBackLink, goBackTo, subheadClassName, children, ...others } = this.props
    return (
      <Layout>
        <Content className='PAN__vbox' {...others}>
          {this.getPageHeader()}
          {children}
        </Content>
      </Layout>
    )
  }
}

PANContent.propTypes = {
  showPageHeader: PropTypes.bool,
  subheadClassName: PropTypes.string,
  header: PropTypes.node,
  subHeader: PropTypes.node,
  children: PropTypes.any,
  showGoBackLink: PropTypes.bool,
  goBackTo: PropTypes.string,
  goBackLabel: PropTypes.string,
  history: PropTypes.object
}

export default PANContent
