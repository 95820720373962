import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MyOneApp from './MyOneApp'
import NoAppsPlaceholder from './NoAppsPlaceholder'
import { getRecordInfo } from '../../../actions'
class MyAppsViewer extends PureComponent {
  render() {
    const { apps, allApps } = this.props
    const hasApps = apps && apps.length > 0
    return (
      <div className='my-apps'>
        {hasApps && apps.map(app => <MyOneApp
          key={app.name}
          {...app}
          display_name={app.icon_display_name || app.display_name || app.name}
          appClassName='app_wrapper'
          selectedAccount={this.props.selectedAccount}
          showInstanceActionModalHandler={this.props.showInstanceActionModalHandler}
          showRecordFormModalHandler={this.props.showRecordFormModalHandler}
        />)
        }
        {!hasApps &&
          <NoAppsPlaceholder
            loading={this.props.entitlementFetching}
            isAuthenticated={this.props.isAuthenticated}
            hasAnyApp={allApps && allApps.filter(app => app.entitled && !app.extension_to && !app.no_app_tile_ui).length > 0}
          />
        }
      </div>
    )
  }
}

MyAppsViewer.propTypes = {
  isAuthenticated: PropTypes.bool,
  entitlementFetching: PropTypes.bool,
  selectedAccount: PropTypes.number,
  title: PropTypes.string,
  apps: PropTypes.array,
  allApps: PropTypes.array,
  tenants: PropTypes.array,
  children: PropTypes.any,
  showInstanceActionModalHandler: PropTypes.func,
  showRecordFormModalHandler: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => ({
  showRecordFormModalHandler: (selectedAccount, tenant, application_name, isCob, upsert, onlyFields) => (isCob ?
    dispatch({ type: 'SHOW_INSTANCE_RECORD_MODAL', instance: { ...tenant, application_name, onlyFields } }) :
    dispatch(getRecordInfo(selectedAccount, tenant, application_name, upsert))
  ),
  showInstanceActionModalHandler: (action, instance, { name, display_name, isThirdParty, isCob }) => dispatch({
    type: 'SHOW_APP_INSTANCE_ACTION_MODAL', action, instance: {
      ...instance,
      app_name: name,
      app_display_name: display_name,
      isThirdParty,
      isCob,
    }
  }),
})

export default connect(null, mapDispatchToProps)(MyAppsViewer)
