import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col } from '@pan/cloud-base'
import { snakeCase } from 'lodash'
import { Link } from 'react-router-dom'
import { FT } from '@pan/cloud-appframework'
import {
  setWrapperClassName,
  getLoggigCalcAppList,
  checkedLoggingCalcApp,
  loggingCalCardCollapsed,
  setTotalDataLoggingSrv,
  getFirewallSelection,
} from '../../config/actions'
import { LOGGING_SERVICE_DISPLAY_NAME, LOGGING_SERVICE_DISPLAY_NAME2 } from '../../constants/AppConstants'
import PageWrapper from '../../components/PageWrapper'
import CheckableTag from '../../components/LoggingCalculator/ToggleIconButton'
import SimpleCard from './../../components/LoggingCalculator/SimpleCard'
import NGFWCmp from './NGFWCmp'
import Magnifier from './Magnifier'
import Summary from './Summary'
import GPCSRemoteOffice from './GPCSRemoteOffice'
import { formatBytes } from './../../utils/ByteBase'
import Traps from './Traps'
import GPCSMobileUser from './GPCSMobileUser'
import { dummyObj } from './NGFWMoreDialog'
import LoggingServiceIcon from './../../images/app-icons/calculator.svg'
import './LoggingCalculatorPage.css'
import IoTSecurity from './IoTSecuirty'
import { getDefaultNgFwDevice } from './NgfwLogRateDetails'


/**
 * Container page to create Logging Service Calculator
 *
 */

class LoggingCalculatorPage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      desiredRetPeriod: null, //global desired retention period
      headRoomGrowth: 0, // default headroom growth
      isFloat: true,
      position: 'fixed'
    }
    this.handleToggled = this.handleToggled.bind(this)
    this.handleDesiredRetPeriod = this.handleDesiredRetPeriod.bind(this)
    this.setTotalDataFromCards = this.setTotalDataFromCards.bind(this)
    this.handleDaysFormatter = value => `${value} days`
    this.handleLogFormatter = value => `${value} per second`
  }

  componentDidMount() {
    const defaultDevice = FT('CALC_NEW_FW') ? getDefaultNgFwDevice() : dummyObj
    this.props.setWrapperClassName('')
    this.props.getLoggigCalcAppList()
    this.props.getFirewallSelection(defaultDevice)
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    this.props.setWrapperClassName('')
  }

  /**
   *
   * @returns {<div>}
   * Header for the <PageWrapper />
   */
  getHeader() {
    return (
      <Row type='flex' className='lsc-page-header' align='middle'>
        <Col>
          <img className='lsc-img-calc' src={LoggingServiceIcon} alt={`${FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : LOGGING_SERVICE_DISPLAY_NAME} Estimator`} />
        </Col>
        <Col xs={16}>
          <h1 className='page-title'> {FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : LOGGING_SERVICE_DISPLAY_NAME} Estimator</h1>
          <div className='lsc-description'>
            Use this tool to estimate the amount of {FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : <Link
              to={`/marketplace/${snakeCase(LOGGING_SERVICE_DISPLAY_NAME)}`}>{LOGGING_SERVICE_DISPLAY_NAME}</Link>} storage
            you may need to purchase.
          </div>
        </Col>
      </Row>
    )
  }

  /**
   *
   * @param checked True | false
   * @param itemID unique Id string
   * Callback from ToggleIconButton which provide callback with checked and ItemID after setting internal state to the
   * component
   */

  handleToggled({ checked, itemID }) {
    this.props.checkedLoggingCalcApp({ checked, itemID })

  }

  handleDesiredRetPeriod(e) {
    const { value } = e.target
    this.setState({
      desiredRetPeriod: isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10),
    })
  }

  /**
   *
   * @returns {any[]}
   */

  getClickableIcon() {
    const { loggingServiceCalAppList } = this.props
    return loggingServiceCalAppList.map(app => {
      const { appId, icon, name, iconWidth, checked } = app
      return (
        <CheckableTag itemID={appId} key={appId} checked={checked} className='toggle-icon-btn' title={name}
          handleToggled={this.handleToggled}>
          <Row type='flex' align='middle' justify='center' className='logging-calc-avatar'>
            <img src={icon} alt={name} width={iconWidth || 50} />
          </Row>
        </CheckableTag>
      )
    })
  }

  /**
   *
   * @param name Title for collapsible panel
   * @param icon Icon for Collapsible panel
   * @returns {*}
   */

  getCardHeader(name, icon, totalStorage, iconWidth) {
    return (
      <Row type='flex' align='middle' justify='space-between'>
        <Row type='flex' align='middle'>
          <Col>
            <img alt={name} src={icon} width={iconWidth} />
          </Col>
          <Col xs={12} offset={1}>
            <h3> {name}</h3>
          </Col>
        </Row>
        <Col xs={8} className='logging-calc-layout-col'>
          <span className='total-storage'>
            {formatBytes(totalStorage, 2, 'GB')}
          </span>
          <div className='total-text'>
            <span>Total storage required <br /> (default quota)</span>
          </div>
        </Col>
      </Row>
    )
  }

  /**
   *
   * @param appId
   * collapse card based on appId
   */
  onCollapsibleBtnClicked(appId) {
    this.props.loggingCalCardCollapsed(appId)
  }

  setTotalDataFromCards(totalData) {
    this.props.setTotalDataLoggingSrv(totalData)
  }


  getAppBodyType(appType) {

    const { appId } = appType
    const { desiredRetPeriod } = this.state
    const { firewallSelectionType, dispatch, isMagnifierChecked, hasBladeCount, isCustomSelected, isIoTChecked } = this.props
    // APPORTAL-8933 remove Cortex XDR and Cortex XDR Agent from the Estimator.
    // The isMagnifierChecked was set if the user selected Cortex XDR. Since we removed XDR we are making this value always false.
    switch (appId) {
      case 'ngf':
        return <NGFWCmp
          daysFormatter={this.handleDaysFormatter}
          isMagnifierChecked={isMagnifierChecked}
          isIoTChecked={isIoTChecked}
          dispatch={dispatch}
          others={appType}
          hasBladeCount={hasBladeCount}
          isCustomSelected={isCustomSelected}
          firewallSelectionType={firewallSelectionType}
          logRateFormatter={this.handleLogFormatter}
          totalData={this.setTotalDataFromCards}
          retentionPrd={desiredRetPeriod}
        />

      case 'gpcsRemoteNetworks':
        return <GPCSRemoteOffice
          daysFormatter={this.handleDaysFormatter}
          others={appType}
          isMagnifierChecked={isMagnifierChecked}
          isIoTChecked={isIoTChecked}
          logRateFormatter={this.handleLogFormatter}
          retentionPrd={desiredRetPeriod}
          totalData={this.setTotalDataFromCards}
        />

      case 'traps':
        return <Traps
          totalData={this.setTotalDataFromCards}
          others={appType}
          isMagnifierChecked={isMagnifierChecked}
          isIoTChecked={isIoTChecked}
          daysFormatter={this.handleDaysFormatter}
          retentionPrd={desiredRetPeriod}
          logRateFormatter={this.handleLogFormatter}
        />

      case 'gpcsMobileUsers':
        return <GPCSMobileUser
          totalData={this.setTotalDataFromCards}
          others={appType}
          isMagnifierChecked={isMagnifierChecked}
          isIoTChecked={isIoTChecked}
          daysFormatter={this.handleDaysFormatter}
          retentionPrd={desiredRetPeriod}
          logRateFormatter={this.handleLogFormatter}
        />
      case 'magnifier':
        return <Magnifier />
      case 'iot':
        return <IoTSecurity />
      default:
        return null
    }
  }

  /**
   *
   * @returns {*}
   * Iterate over all selected app list and construct card if app is selected
   *
   */

  getAppCard() {
    return this.props.loggingServiceCalAppList.map(appType => {
      const { checked, appId, name } = appType
      const checkedStyle = checked ? { transform: 'scaleY(1)' } : { transform: 'scaleY(0)' }
      if (checked) {
        return (
          <SimpleCard style={{ marginTop: 40, ...checkedStyle }} key={appId} title={<h4>{name}</h4>}>
            {this.getAppBodyType(appType)}
          </SimpleCard>
        )
      }
      return null
    })
  }

  /**
   *
   * @returns {*}
   */
  getPageBody() {
    const marginLeft = { marginLeft: 10 }
    return (
      <div className='lcs-main-page-body page-body'>
        <div className='app_content'>
          <div className='row ant-row-flex-space-between '>
            <Row type='flex'>
              <Col xs={24} offset={1}>
                <h4 style={marginLeft}>Which products will you be using?</h4>
              </Col>
            </Row>
            <div className='row col-12' style={marginLeft}>
              {this.getClickableIcon()}
            </div>
            <Row className='col-lg-11 col-md-12 col-sm-12 logging-calc-margin-top'>
              {this.getAppCard()}
            </Row>

          </div>
        </div>
      </div>
    )
  }

  /**
   *
   * @returns {*}
   * Get Children for the <PageWrapper />
   */
  getChild() {
    // APPORTAL-8933 remove Cortex XDR and Cortex XDR Agent from the Estimator.
    // The isMagnifierChecked was set if the user selected Cortex XDR. Since we removed XDR we are making this value always false.
    const { isMagnifierChecked } = this.props
    return (
      <div className='container hbox'>
        <div className={'vbox'}>
          {this.getHeader()}
          {this.getPageBody()}
        </div>
        <Summary
          loggingServiceName={FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : LOGGING_SERVICE_DISPLAY_NAME}
          isMagnifierChecked={isMagnifierChecked}
          selectedApps={this.props.loggingServiceCalAppList}
        />
      </div>
    )
  }

  render() {
    return (
      <PageWrapper id='app' className={'lcs-main has-footer'} excludeFooter={false}>
        {this.getChild()}
      </PageWrapper>
    )
  }
}

/**
 *
 * @type {{setWrapperClassName: *, getLoggigCalcAppList: *, checkedLoggingCalcApp: *, loggingCalCardCollapsed: *, setTotalDataLoggingSrv: *, getFirewallSelection: *, loggingServiceCalAppList: *}}
 * default propTypes
 */
LoggingCalculatorPage.propTypes = {
  setWrapperClassName: PropTypes.func,
  getLoggigCalcAppList: PropTypes.func,
  checkedLoggingCalcApp: PropTypes.func,
  loggingCalCardCollapsed: PropTypes.func,
  setTotalDataLoggingSrv: PropTypes.func,
  getFirewallSelection: PropTypes.func,
  dispatch: PropTypes.func,
  hasBladeCount: PropTypes.bool,
  isCustomSelected: PropTypes.bool,
  checkedMagnifier: PropTypes.func,
  loggingServiceCalAppList: PropTypes.array,
  firewallSelectionType: PropTypes.array,
  isMagnifierChecked: PropTypes.bool, // if magnifier checked then we have to use different formula to calculate total storage
  isIoTChecked: PropTypes.bool,
}

/**
 *
 * @type {{setWrapperClassName: LoggingCalculatorPage.defaultProps.setWrapperClassName, getLoggigCalcAppList: LoggingCalculatorPage.defaultProps.getLoggigCalcAppList, checkedLoggingCalcApp: LoggingCalculatorPage.defaultProps.checkedLoggingCalcApp, loggingCalCardCollapsed: LoggingCalculatorPage.defaultProps.loggingCalCardCollapsed, setTotalDataLoggingSrv: LoggingCalculatorPage.defaultProps.setTotalDataLoggingSrv, getFirewallSelection: LoggingCalculatorPage.defaultProps.getFirewallSelection, loggingServiceCalAppList: Array, firewallSelectionType: Array}}
 */
LoggingCalculatorPage.defaultProps = {
  setWrapperClassName: () => {
  },
  getLoggigCalcAppList: () => {
  },
  checkedLoggingCalcApp: () => {
  },
  loggingCalCardCollapsed: () => {
  },
  setTotalDataLoggingSrv: () => {
  },
  getFirewallSelection: () => {
  },
  loggingServiceCalAppList: [],
  firewallSelectionType: [],
}
/**
 *
 * @param state
 * @returns {{loggingServiceCalAppList, firewallSelectionType}}
 */
const mapStateToProps = (state) => {
  const { loggingServiceCal } = state
  const { user_email, isAuthenticated } = state
  return { ...loggingServiceCal, userInfo: { user_email, isAuthenticated } }
}

/**
 *
 * @param dispatch
 * @returns {{setWrapperClassName: (function(*=): *), getLoggigCalcAppList: (function(): *), checkedLoggingCalcApp: (function(*=): *), loggingCalCardCollapsed: (function(*=): *), setTotalDataLoggingSrv: (function(*=): *), getFirewallSelection: (function(*=): *)}}
 * dispatch event to set class that render the gradient
 */
const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setWrapperClassName: value => dispatch(setWrapperClassName(value)),
  getLoggigCalcAppList: () => dispatch(getLoggigCalcAppList()),
  checkedLoggingCalcApp: appType => dispatch(checkedLoggingCalcApp(appType)),
  loggingCalCardCollapsed: appId => dispatch(loggingCalCardCollapsed(appId)),
  setTotalDataLoggingSrv: appData => dispatch(setTotalDataLoggingSrv(appData)),
  getFirewallSelection: selection => dispatch(getFirewallSelection(selection)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggingCalculatorPage)
