const currentInstanceRecordModal = (state = { visible: false }, record) => {
  switch (record.type) {
    case 'SHOW_INSTANCE_RECORD_MODAL':
      return {
        ...record.instance,
        visible: true,
      }
    case 'HIDE_INSTANCE_RECORD_MODAL':
      return { ...state, visible: false }
    case 'RESET_INSTANCE_RECORD_MODAL':
      return { visible: false }
    default:
      return state
  }
}

// const currentInstanceRecordModal = () => null

export default currentInstanceRecordModal
