/**
 * Created by vsuthar on 6/15/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'

const IoTSecurity = () => {

  return (
    <span>
      IoT Security Application increases storage demand across Next-Generation Firewall. It requires higher fidelity logs in order to discover IoT/ OT devices, identify risks, and security threats, anomalies and analytics.
    </span>
  )
}

IoTSecurity.propTypes = {}
export default IoTSecurity
