import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@pan/cloud-base'
import MyAppsViewer from './MyAppsViewer'

const LEARN_MORE_LNK = 'https://www.paloaltonetworks.com'

const LearnMoreLink = (props) => <a
  className='learn-more-link'
  href={LEARN_MORE_LNK}
  rel='noopener noreferrer'
  target='_blank'
  {...props}
>{props.children}</a>

LearnMoreLink.propTypes = {
  children: PropTypes.node,
}

export default class MyApplications extends Component {
  render() {
    return (
      <div id='apps' className={['apps', 'v2-blue-bkg'].join(' ')}>
        {
          this.props.isAuthenticated ? (
            <MyAppsViewer title='My Apps'
              isAuthenticated={this.props.isAuthenticated}
              allApps={this.props.entitledAppsList}
              apps={this.props.selectedAppsAfterRoleFilter}
              selectedAccount={this.props.selectedAccount}
              entitlementFetching={this.props.entitlementFetching}
            />
          ) : <>
            <h1 className='intro-header'>Discover, Access, and Manage Apps</h1>
            <div className='text-center intro-links'>
              <Button className={['intro-login-btn', 'pan-btn-alt'].join(' ')} size='large' type='primary' href='/saml/login'>Sign In</Button>
              <br />
              <LearnMoreLink>Learn More</LearnMoreLink>
            </div>
          </>
        }
      </div>
    )
  }
}
MyApplications.propTypes = {
  selectedAccount: PropTypes.number,
  entitledAppsList: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  entitlementFetching: PropTypes.bool,
  selectedAppsAfterRoleFilter: PropTypes.array
}
MyApplications.defaultProps = {
  selectedAccount: 0,
  entitledAppsList: [],
  isAuthenticated: false,
  entitlementFetching: false
}
