/**
 *
 * Created by vsuthar on 11/9/18 File rbacAssignRoleEntitilements
 * Project: App Portal ©PaloAlto Networks
 */

import React from 'react'
import { cloneDeep, uniq, uniqWith, forOwn, isObject, has, groupBy } from 'lodash'
import { parseRoles, checkIfDSS } from '../../utils/rbacUtils'
import {
  ACCOUNT_SUPER_USER,
  APP_ADMIN, R_NO_ROLE_ENUM, R_TYPE, RBAC_ACCOUNT_SUPER_USER_V2, RBAC_ALL_INSTANCES, RBAC_APP_ADMIN_V2,
  RBAC_NOT_ACCOUNT_SUPER_USER,
  RBAC_NOT_APP_ADMIN,
} from '../../constants/AppConstants'
import { Tooltip } from '@pan/cloud-base'

/**
 *
 * @param appType
 * @param _key_by_entitlements
 * @param isDisabled
 */
const disabledEnabledAllApp = (appType = 'all', _key_by_entitlements, isDisabled = false) => {
  appType = checkIfDSS(appType)
  if (appType === 'all') {
    for (const entitlement in _key_by_entitlements) {
      //when account super user found make ever app disabled
      const apps = _key_by_entitlements[entitlement] || []
      if (apps && Array.isArray(apps)) {
        apps.forEach(app => {
          if (app.uniqueId !== 'superuser_account_id') {
            app.disabled = isDisabled
            app.isChildDisabled = isDisabled
          }
        })
      }
      else {
        if (apps.uniqueId !== 'superuser_account_id') {
          apps.disabled = isDisabled
          apps.isChildDisabled = isDisabled
        }
      }

    }
  }
  else {
    const apps = _key_by_entitlements[appType]
    if (apps && Array.isArray(apps)) {
      apps.forEach(app => {
        app.isChildDisabled = isDisabled
      })
    }
    else if (apps) {
      apps.isChildDisabled = isDisabled
    }
  }
}

/**
 * This function used for set Admin or Instance Admin role or any other role to instances
 * @param rolesList
 * @param _key_by_entitlements
 * @param selectedUsers
 */
const setInstanceAndAdminRoleOnList = (rolesList, _key_by_entitlements, selectedUsers) => {
  const groupRoleList = groupBy(rolesList, 'app')
  rolesList.forEach((roleObj) => {
    // Set App Admin on each app and disable rest of the instances
    const { app, tenant_id, role } = roleObj
    const appBasedOnRole = _key_by_entitlements[app]
    if (appBasedOnRole && Array.isArray(appBasedOnRole)) {
      appBasedOnRole.forEach(_app => {
        if (_app && _app.hasOwnProperty('access') && _app.access.length) {
          const allInstance = _app.access.find(instance => instance.name === RBAC_ALL_INSTANCES)
          if (isAppAdmin(roleObj)) {
            if (allInstance) {
              allInstance.rbac_role_selected = groupRoleList[app] && groupRoleList[app].length > 1 ? 'mix' : 'app_superuser'
              const notAppAdmin = allInstance.rbac_roles.find(item => {
                const { _rid } = item
                return _rid === 'not_app_administrator'
              })
              if (notAppAdmin && isObject(notAppAdmin)) {
                notAppAdmin['name'] = RBAC_NOT_APP_ADMIN
              }
            }
            disabledEnabledAllApp(app, _key_by_entitlements, true)
          }
          else {
            // set instance admin role here
            const { access } = _app
            const findInstance = access.find(tenant => tenant.tenant_id === tenant_id)
            if (findInstance) {
              findInstance.rbac_role_selected = selectedUsers.length > 1 ? 'mix' : role
            }
          }
        }
      })
    }
  })
}

/**
 *
 * @param rolesList
 * @param _key_by_entitlements
 * @param selectedUsers
 */
const setInstanceAndAdminRole = (rolesList, _key_by_entitlements, selectedUsers) => {
  const _parsedRole = parseRoles(rolesList)
  const _parsedRoleArr = Object.keys(_parsedRole)
  if (_parsedRoleArr && _parsedRoleArr.length) {
    _parsedRoleArr.forEach(key => {
      const tenents = _parsedRole[key]
      const apps = _key_by_entitlements[key]
      if (apps && Array.isArray(apps)) {
        apps.forEach(app => {
          if (app && app.hasOwnProperty('access') && app.access.length) {
            const allInstance = app.access.find(instance => instance.name === 'All Instances')
            if (tenents && tenents['App Admin'] && allInstance) {
              allInstance.rbac_role_selected = selectedUsers.length > 1 ? 'mix' : RBAC_APP_ADMIN_V2
              if (allInstance.rbac_roles && Array.isArray(allInstance.rbac_roles)) {
                //APPORTAL-1610
                const notAppAdmin = allInstance.rbac_roles.find(item => {
                  const { _rid } = item
                  return _rid === 'not_app_administrator'
                })
                if (notAppAdmin && isObject(notAppAdmin)) {
                  notAppAdmin['name'] = RBAC_NOT_APP_ADMIN
                }
              }
              disabledEnabledAllApp(app.role, _key_by_entitlements, true)
            }
            else {
              forOwn(tenents, (value, key) => {
                if (tenents && tenents[key]) {
                  for (const _role in tenents[key]) {
                    const findInstance = app.access.find(instance => instance.tenant_id && instance.tenant_id === key)
                    app.rbac_role_selected = 'mix' // setting out side also hence we not need to see inside access array
                    if (allInstance) {
                      allInstance.rbac_role_selected = selectedUsers.length > 1 ? 'mix' : 'not_app_administrator'
                    }
                    if (findInstance) {
                      findInstance.rbac_role_selected = selectedUsers.length > 1 ? 'mix' : _role
                    }
                    // disabledEnabledAllApp(app.role, _key_by_entitlements, true)
                  }
                }
              })
            }

          }
        })
      }

    })
  }
}

/**
 *
 * @param _entitlementsList
 * @param selectedUsers
 * @param users
 * @returns {*}
 */
const getRolesFromUser = ({ _entitlementsList, selectedUsers, users }) => {
  if (_entitlementsList && _entitlementsList.length) {
    const { data } = users
    let rolesList = []

    const existingUsersWithRoles = []
    if (data && data.length && selectedUsers && selectedUsers.length) {
      data.forEach((user) => {
        if (user && user.email) {
          const { email, roles = [] } = user
          if (selectedUsers.includes(email)) {
            const rolePerEmail = { email, roles: [...roles] }
            rolesList.push(...roles)
            existingUsersWithRoles.push(rolePerEmail)
          }
        }
      })
      rolesList = uniq(rolesList)
      return assignRoleToEntitlements({ _entitlementsList, selectedUsers, users }, rolesList, existingUsersWithRoles)
    }
  }
  return _entitlementsList || []

}


const isAppAdmin = (roleObj) => {
  const { tenant_id, role } = roleObj
  return tenant_id === '*' && role === RBAC_APP_ADMIN_V2
}

const isAccountSuperUser = (roles) => {
  return roles.filter(roleObj => roleObj.role === 'account_superuser').length > 0
}

const setAccountSuperUserAndDisabledAllApps = (_entitlements, _key_by_entitlements, isMixed, shouldDisabled, { rolesList }) => {
  const findSuperUserAct = _entitlements.find(app => app.uniqueId === 'superuser_account_id')
  if (findSuperUserAct && findSuperUserAct.access && findSuperUserAct.access.length) {
    findSuperUserAct.rbac_role_selected = isMixed ? 'mix' : RBAC_ACCOUNT_SUPER_USER_V2
    const { access } = findSuperUserAct
    access[0].rbac_role_selected = isMixed ? 'mix' : RBAC_ACCOUNT_SUPER_USER_V2
    //APPORTAL-1607
    if (access[0].rbac_roles && access[0].rbac_roles.length) {
      const notAccontSuperUser = access[0].rbac_roles.find(item => {
        return item._rid === 'not_account_super_user'
      })
      if (isObject(notAccontSuperUser)) {
        notAccontSuperUser.disabled = rolesList.find(each => each.onlyLeft)
        notAccontSuperUser.name = notAccontSuperUser.disabled ?
          <Tooltip title={'This account requires at least one Account Administrator'} placement={'left'}>{RBAC_NOT_ACCOUNT_SUPER_USER}</Tooltip> :
          RBAC_NOT_ACCOUNT_SUPER_USER
      }
    }
    disabledEnabledAllApp('all', _key_by_entitlements, shouldDisabled)
  }
}

const createUniqueRole = (rolesList) => {
  return uniqWith(rolesList, (l_role, r_role) => {
    return l_role.role === r_role.role && l_role.app === r_role.app && l_role.tenant_id === r_role.tenant_id
    // debugger
  })
}

/**
 *
 * @param _entitlementsList
 * @param selectedUsers
 * @param users
 * @param rolesList
 * @param existingUsersWithRoles
 * @returns {{rbac_entitlements: *, existingUsersWithRoles: *}}
 */
const assignRoleToEntitlements = ({ _entitlementsList, selectedUsers, users }, rolesList = [], existingUsersWithRoles, isOlderWay) => {
  const _entitlements = cloneDeep(_entitlementsList)
  if (!isOlderWay) {
    // Dont do anything if roleList is empty
    if (_entitlementsList && _entitlementsList.length && !rolesList.includes('')) {
      const _rolesList = createUniqueRole(rolesList)
      const _key_by_entitlements = groupBy(_entitlements, 'role')
      if (isAccountSuperUser(rolesList)) {
        if (_rolesList.length > 1) {
          setAccountSuperUserAndDisabledAllApps(_entitlements, _key_by_entitlements, true, true, { rolesList: _rolesList })
          rolesList = rolesList.filter(roleObj => roleObj.role !== 'account_superuser')
          setInstanceAndAdminRoleOnList(rolesList, _key_by_entitlements, selectedUsers)
        }
        else {
          setAccountSuperUserAndDisabledAllApps(_entitlements, _key_by_entitlements, false, true, { rolesList: _rolesList })
        }
      }
      else {
        // set account super user and disable rest
        setInstanceAndAdminRoleOnList(_rolesList, _key_by_entitlements, selectedUsers, false)
        // if (isAccountSuperUser(rolesList)) {
        //   // setAccountSuperUserAndDisabledAllApps(_entitlements, _key_by_entitlements, false)
        //   setInstanceAndAdminRoleOnList(rolesList, _key_by_entitlements, selectedUsers)
        // }
      }
    }
    return { rbac_entitlements: _entitlements, existingUsersWithRoles }
  }
  else {
    if (_entitlementsList && _entitlementsList.length) {
      const _key_by_entitlements = groupBy(_entitlements, '_rbac_role')
      rolesList = uniq(rolesList)
      if (rolesList.includes('Account Super User')) {
        if (rolesList.length > 1) {
          const findSuperUserAct = _entitlements.find(app => app.uniqueId === 'superuser_account_id')
          if (findSuperUserAct && findSuperUserAct.access && findSuperUserAct.access.length) {
            findSuperUserAct.rbac_role_selected = 'mix'
            findSuperUserAct.access[0].rbac_role_selected = 'mix'
            disabledEnabledAllApp('all', _key_by_entitlements) // disabled all app beside super account
            rolesList = rolesList.filter(role => role !== 'Account Super User')
          }
          setInstanceAndAdminRole(rolesList, _key_by_entitlements, selectedUsers)
        }
        else {
          setAccountSuperUserAndDisabledAllApps(_entitlements, _key_by_entitlements, false, true, { rolesList })
        }
      }
      else {
        setInstanceAndAdminRole(rolesList, _key_by_entitlements, selectedUsers)
      }
    }
    return { rbac_entitlements: _entitlements, existingUsersWithRoles }
  }

}

const flattenRolesV2 = (givenRole, email) => {
  const _flattenRoles = []
  if (givenRole.hasOwnProperty(ACCOUNT_SUPER_USER) && givenRole[ACCOUNT_SUPER_USER]) {
    _flattenRoles.push({
      email,
      version: 'v1',
      type: 'special_predefined',
      role: RBAC_ACCOUNT_SUPER_USER_V2,
      tenant_id: '*',
      app: '*'

    })
  }
  else {
    forOwn(givenRole, (value, appName) => {
      if (isObject(value) && value[APP_ADMIN]) {
        _flattenRoles.push({
          email,
          type: 'app_predefined',
          app: appName,
          version: 'v1',
          tenant_id: '*',
          role: RBAC_APP_ADMIN_V2
        })
      }
      else if (isObject(value) && !value[APP_ADMIN]) {
        forOwn(value, (roles, tenant_id) => {
          for (const x in roles) {
            if (!has(roles, R_NO_ROLE_ENUM) && roles[x] && x !== R_TYPE) {
              _flattenRoles.push({
                email,
                type: roles['type'],
                app: appName,
                version: 'v1',
                tenant_id: tenant_id,
                role: x === 'Instance Admin' ? 'app_superuser' : x
              })
            }
          }

        })
      }
    })
  }
  return { needsToBeCreated: _flattenRoles, needsToBeDeleted: [] }
}

export { assignRoleToEntitlements, disabledEnabledAllApp, getRolesFromUser, flattenRolesV2, isAccountSuperUser, isAppAdmin }
