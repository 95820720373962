import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import axios from 'axios'
import {
  LGS,
  LGS_ID,
  STORAGE_INCLUDED,
  ACTIVATE,
  isClcsOrTelemetryInstance,
  isTelemetryInstance,
  isFreeCdl,
  indexArray,
} from '../../../utils/activateUtils'
import AssociationInstanceField, { getInitials } from './AssociationInstanceField'

const storage_included = _.snakeCase(STORAGE_INCLUDED)
const bundled_tenant = 'bundled_tenant'

const checkQuota = (url, data) => {
  let promise = checkQuota._check_query_promise
  if (promise && promise.url === url) {
    return promise
  }
  const call = async () => {
    try {
      await axios.post(url, data)
    }
    catch (e) {
      throw e
    }
    finally {
      if (promise === checkQuota._check_query_promise) {
        checkQuota._check_query_promise = undefined
      }
    }
  }
  promise = call()
  promise.url = url
  checkQuota._check_query_promise = promise
  return promise
}

const appName = LGS
const app_id = LGS_ID
const fieldName = `associations.${app_id}`
const CdlInstanceField = ({
  initialValues,
  appInfoMap,
  config,
  selectedAccount,
  getFieldDecorator,
  getFieldValue,
  getFieldError,
  setFields,
  validateFields,
  instanceRegionFilter,
  isFieldValidating,
  isFieldTouched,
  editableOnly,
  regions,
  newActivate,
  ...restProps
}) => {
  const { has_auth_code, isCob, isNova, display_name: displayName = appName } = appInfoMap.getAnyApp(appName) || {}
  const { initialValue, initialTenantId, hasInitialValues } = useMemo(() => (
    getInitials(fieldName, initialValues)
  ), [initialValues])

  const initialValueInstance = useMemo(() => {
    if (!initialTenantId || editableOnly) {
      return null
    }
    const tenants = indexArray(appInfoMap.getInstances(appName), 'tenant_id', { cached: true })
    const instance = tenants.get(initialTenantId)
    if (!instance) {
      return null
    }
    return {
      ...instance,
      tenant_display_name: instance.tenant_display_name || instance.tenant_instance_name || instance.serial_number,
      isFreeTier: isClcsOrTelemetryInstance(instance),
      initialValue,
    }
  }, [initialValue, initialTenantId, editableOnly, appInfoMap])

  const showActivateFn = useCallback(({ setField, setAuthCodeOption }) => {
    if (config.authcode_activation) { // only CDL for now
      const onDiscovery = (data, { hide }) => {
        const authcodeValue = initialValueInstance ?
          `${initialValueInstance.initialValue}|${data.auth_code}` :
          `|||${data.auth_code}`
        const display = initialValueInstance ?
          `${initialValueInstance.tenant_display_name} with Auth Code ${data.auth_code}` :
          `Activate new ${displayName} with Auth Code ${data.auth_code}`
        setAuthCodeOption({
          key: data.auth_code,
          value: authcodeValue,
          display,
        })
        if (initialValueInstance) {
          initialValueInstance.authcodeValue = authcodeValue
        }
        setField(authcodeValue)
        hide()
        return false
      }
      newActivate({ appName, displayName, has_auth_code, isCob, isNova, onDiscovery })
    }
    else {
      newActivate({ appName, displayName, has_auth_code, isCob, isNova })
    }
    return false
  }, [config.authcode_activation, displayName, has_auth_code, initialValueInstance, isCob, isNova, newActivate])

  const [instanceDecoratorPreFn, instanceDecoratorConfig] = useMemo(() => {
    const preFn = ({ config, requiredRule, clearField, showActivateHandler }) => {
      const {
        quota_enforced = false,
        initialValue: initValue, // passed down fixed initial value, not config.initials.initialValue
      } = config
      if (initValue) { // for hidden use case
        return getFieldDecorator(fieldName, {
          initialValue: initValue,
          getValueProps: () => ({
            value: initValue,
            disabled: true,
          }),
          rules: [],
        })
      }
      const quotaValidateRule = quota_enforced && {
        validator: async (rule, value) => {
          if (!value || value === storage_included) {
            return
          }
          const [tenant_id, , region] = value.split('|', 4)
          const { url } = _.find(appInfoMap.getInstances(appName), { tenant_id }) || {}
          if (!url) {
            throw new Error('The instance chosen is currently not available.')
          }
          try {
            await checkQuota(`${rule.remote}/${app_id}/${tenant_id}/${region}`, {
              apps: appInfoMap.appNames
            })
          }
          catch (error) {
            if (error.response.status === 404) {
              return
            }
            const configUrl = url.replace('/?', '/configuration?')
            const missingQuotaNames = error.response?.data?.missing || appInfoMap.appNames
              .map(name => appInfoMap.getDisplayName(name) || name)
            const errMsg = <span key='no-lcaas-config'>The instance chosen has no log quota set for the required log types of the {
              missingQuotaNames.length > 1 ? `apps: ${missingQuotaNames.join(', ')}` : `app: ${missingQuotaNames[0]}`
            }. Please <a
              href={configUrl}
              rel='external noopener noreferrer'
              target='_blank'
              onClick={clearField}
            >set quota</a> before proceeding with activation.</span>
            throw errMsg
          }
        },
        remote: '/hub/v2/validate/quota',
      }

      if (hasInitialValues && !editableOnly) {
        const validator = (rule, value, callback) => {
          const [tenant_id] = initialValue ? initialValue.split('|', 2) : []
          if (!tenant_id) { // should not happen
            return callback('Invalid instance selected')
          }
          if (initialValueInstance === null) { // should not happen unless entitlements stalled
            return callback('Selected instance is not avaiable')
          }
          if (config.allow_free_telemetry && initialValueInstance.isFreeTier && !initialValueInstance.authcodeValue) {
            return callback( // allow_free_telemetry implies not eval
              <span key='convert'>
                Please convert the selected {STORAGE_INCLUDED} by <Link to={ACTIVATE} onClick={showActivateHandler}>activating a purchased auth code</Link>.
              </span>
            )
          }
          callback()
        }

        return getFieldDecorator(fieldName, {
          initialValue,
          getValueProps: () => ({
            value: initialValueInstance?.authcodeValue || initialValue,
            disabled: true,
          }),
          validateFirst: true,
          rules: [
            { validator },
            quotaValidateRule,
            requiredRule,
          ].filter(Boolean),
        })
      }
    }

    return [preFn]
  }, [appInfoMap, editableOnly, getFieldDecorator, hasInitialValues, initialValue, initialValueInstance])

  const instanceFilterFn = useMemo(() => {
    const {
      allow_free_telemetry,
      free_cdl_upgrade_only,
    } = config

    if (free_cdl_upgrade_only) { // upgrade
      return isFreeCdl
    }

    if (allow_free_telemetry) { // display
      return undefined
    }

    if (config.bundled_tenant?.tenant_id) {
      return undefined // leave telemetry
    } // if no bundled cdl, filter out telemetry
    return (tenant) => !isClcsOrTelemetryInstance(tenant) // default case
  }, [config])

  const extraTenantInfoRenderFn = undefined

  const instancesPropsFn = useCallback(({ config, filtered, regions, optionsGroups, optionsProps, availableLgsRegions }) => {
    if (editableOnly) {
      return // skip
    }
    if (config.bundled_tenant?.tenant_id) {
      const { tenant_id, serial_number } = config.bundled_tenant
      if (regions?.length) {
        const regionNameSet = new Set(_.map(regions, 'name'))
        const upgradableRegionMapping = _.keyBy(filtered.filter(isClcsOrTelemetryInstance)
          .filter(t => regionNameSet.has(t.region)), 'region')
        if (Object.keys(upgradableRegionMapping).length) {
          for (const region of regions) {
            const inst = upgradableRegionMapping[region.name]
            const key = (region.display || region.name).toUpperCase()
            const group = optionsGroups[key] || (optionsGroups[key] = [])
            const found = inst && group.find(op => op.key === inst.tenant_id)
            if (found) {
              _.pull(group, found)
              const { tenant_id, serial_number, tenant_display_name = serial_number, region } = inst
              const typeDisplay = isTelemetryInstance(inst) ? 'Telemetry' : 'CLCS'
              const newProps = {
                key: tenant_id,
                value: `${tenant_id}|${serial_number}|${region}`, // |${bundled_tenant}
                ...found,
                display: `Upgrade ${typeDisplay} Instance ${found?.display || tenant_display_name}`,
              }
              Object.assign(found, newProps)
              group.push(newProps)
            }
            else {
              const newProps = {
                key: tenant_id,
                value: `${tenant_id}|${serial_number}|${region.name}|${bundled_tenant}`,
                display: `Activate new ${displayName}`,
              }
              optionsProps.push(newProps)
              group.push(newProps)
            }
          }
          return
        }
      }
      const newProps = {
        key: tenant_id,
        value: `${tenant_id}|${serial_number}||${bundled_tenant}`,
        display: `Activate new ${displayName}`,
      }
      // add new option
      optionsProps.push(newProps)
      optionsGroups[''] = [newProps]
    }
  }, [displayName, editableOnly])

  const helpTextFn = useCallback(() => {
    if (config.free_cdl_upgrade_only) {
      return 'Upon activation, we will convert your storage included or telemetry storage to the purchased storage.'
    }
    return null // return null skip existing logic
    // returning undefined for continue existing logic
  }, [config.free_cdl_upgrade_only])

  const onSelect = useCallback((value) => { // revalidate
    if (value === getFieldValue(fieldName)) {
      validateFields([fieldName], { force: Boolean(getFieldError(fieldName)) })
    }
  }, [getFieldError, getFieldValue, validateFields])

  const fieldProps = {
    initialValues,
    appInfoMap,
    selectedAccount,
    getFieldDecorator,
    getFieldValue,
    getFieldError,
    setFields,
    validateFields,
    instanceRegionFilter,
    isFieldValidating,
    isFieldTouched,
    editableOnly,
    regions,
    newActivate,
    config: {
      ...config,
      app: appName,
      initials: {
        initialValue,
        initialValues,
        initialTenantId,
        hasInitialValues,
      },
      instanceFilterFn,
      extraTenantInfoRenderFn,
      instancesPropsFn,
      instanceDecoratorPreFn,
      instanceDecoratorConfig,
      showActivateFn,
      helpTextFn,
      onSelect: config.quota_enforced ? onSelect : undefined,
    },
    ...restProps,
  }

  return <AssociationInstanceField {...fieldProps} />
}

CdlInstanceField.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  config: PropTypes.object,
  editableOnly: PropTypes.bool,
  regions: PropTypes.arrayOf(PropTypes.object),
  selectedAccount: PropTypes.number,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  setFields: PropTypes.func,
  validateFields: PropTypes.func,
  instanceRegionFilter: PropTypes.func,
  isFieldValidating: PropTypes.func,
  isFieldTouched: PropTypes.func,
  newActivate: PropTypes.func,
}

CdlInstanceField.defaultProps = {
  config: {},
  newActivate: _.noop,
}

export default CdlInstanceField
