/**
 * Created by vsuthar on 6/1/20
 * Project: App Portal ©Palo Alto Networks
 */

/**
 * TODO enhance more. Done some ground works
 */
import React, { useState } from 'react'
import { Tooltip } from '@pan/cloud-base'
import PropTypes from 'prop-types'

const ListItem = ({ children, toolTipProps = {} }) => {
  const { title } = toolTipProps
  const [toolTip, setToolTip] = useState('')
  const handleMouseOver = (e) => {
    const el = e.target
    if (el.scrollWidth > el.clientWidth) {
      setToolTip(title || children)
    }
  }

  const resetTooltip = () => {
    setToolTip('')
  }

  return <Tooltip {...toolTipProps} title={toolTip} >
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
      onMouseEnter={handleMouseOver}
      onMouseLeave={resetTooltip}>
      {children}
    </div>
  </Tooltip>
}

ListItem.propTypes = {
  enabledTip: PropTypes.bool,
  children: PropTypes.node,
  toolTipProps: PropTypes.object
}
export default ListItem

