import React, { useEffect, useLayoutEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import PageWrapper from '../PageWrapper'
import MyApplications from './MyApps/MyApplications'
import MoreApps from './MorePanApps'
import ThirdPartyApps from './ThirdPartyApps'
import InstanceRecordModal from '../Settings/InstanceRecordModal'
import InstanceActionModal from '../Settings/InstanceActionModal'
import OTPModal from '../Setup/OTPModal'
import { getMyApps, getMoreApps, getOtherApps } from '../../config/apps'
import { filterAppEntitlementsByNoAccessFlag } from '../../utils/rbacUtils'
import { URLSearchParams, FT } from '@pan/cloud-appframework'

export const Apps = ({
  setWrapperClassName,
  selectedAccount,
  isAuthenticated,
  supportAccountId,
  entitledAppsList,
  entitlementFetching,
  accountIsFederal,
}) => {
  useLayoutEffect(() => {
    setWrapperClassName('home portal v2')
    return () => setWrapperClassName('')
  }, [setWrapperClassName])

  useEffect(() => {
    if (isAuthenticated && (FT('NO_CLASSIC_HUB') || FT('FEDRAMP_HIGH'))) {
      const tsg_id = new URLSearchParams(window.location.search).get('tsg_id')
      const url = /^\d+$/.test(tsg_id) ? `/hub?tsg_id=${tsg_id}` : '/hub'
      window.location.replace(url)
    }
    if (!isAuthenticated && window.opener && !window.opener.closed) {
      window.opener.postMessage({
        popup: 'hub',
        ts: Date.now(),
      }, '*')
    }
  }, [isAuthenticated])

  const { apps, selectedAppsAfterRoleFilter } = useMemo(() => {
    const selectedAppsAfterRoleFilter = supportAccountId ? filterAppEntitlementsByNoAccessFlag(getMyApps(entitledAppsList)) : []
    const myApps = selectedAppsAfterRoleFilter // getMyApps(entitledAppsList).filter(allowApp)
    const filterAppByAccount = app => _.isEmpty(app.restricted_accounts) || _.includes(app.restricted_accounts, supportAccountId)
    const filterAppByFederal = app => !accountIsFederal || app.has_federal_region
    const appendPublicApps = isAuthenticated ? [] : entitledAppsList.filter(app => app.enabled && app.is_panw && app.extra?.merge_tiles_for_tsg_and_public?.length)
    const moreApps = getMoreApps(entitledAppsList).concat(appendPublicApps).filter(filterAppByAccount).filter(filterAppByFederal)
    const otherApps = accountIsFederal ? [] : getOtherApps(entitledAppsList).filter(filterAppByAccount)
    return { apps: { myApps, moreApps, otherApps }, selectedAppsAfterRoleFilter }
  }, [accountIsFederal, entitledAppsList, supportAccountId, isAuthenticated])

  return useMemo(() => (
    <PageWrapper id='portal'>
      <div className='page-body'>
        <MyApplications
          entitledAppsList={entitledAppsList}
          selectedAppsAfterRoleFilter={selectedAppsAfterRoleFilter}
          selectedAccount={selectedAccount}
          isAuthenticated={isAuthenticated}
          entitlementFetching={entitlementFetching}
        />
        {
          apps.moreApps.length > 0 ?
            <MoreApps
              isAuthenticated={isAuthenticated}
              apps={apps.moreApps}
            /> :
            <div id='more' className={entitlementFetching ? 'more-loading' : ''}></div>
        }
        {
          apps.otherApps.length > 0 &&
          <ThirdPartyApps
            isAuthenticated={isAuthenticated}
            apps={apps.otherApps}
          />
        }
        {isAuthenticated &&
          <>
            <InstanceRecordModal />
            <InstanceActionModal />
            <OTPModal selectedAccount={selectedAccount} />
          </>
        }
      </div>
    </PageWrapper>
  ), [apps, entitledAppsList, entitlementFetching, isAuthenticated, selectedAccount, selectedAppsAfterRoleFilter])
}

Apps.propTypes = {
  selectedAccount: PropTypes.number,
  supportAccountId: PropTypes.number,
  entitledAppsList: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  entitlementFetching: PropTypes.bool,
  roles: PropTypes.object,
  setWrapperClassName: PropTypes.func,
  hideAppActivateModal: PropTypes.func,
}

Apps.defaultProps = {
  selectedAccount: 0,
  entitledAppsList: [],
  isAuthenticated: false,
  entitlementFetching: false,
  roles: {},
  setWrapperClassName: () => { },
}

const mapStateToProps = ({
  selectedAccount,
  supportAccountIds,
  entitledAppsList,
  isAuthenticated,
  roles,
  entitlementFetching,
}) => ({
  selectedAccount,
  entitledAppsList,
  isAuthenticated,
  roles,
  entitlementFetching,
  supportAccountId: +supportAccountIds[selectedAccount]?.accountid || NaN,
  accountIsFederal: Boolean(supportAccountIds[selectedAccount]?.is_federal),
})

const mapDispatchToProps = (dispatch) => ({
  setWrapperClassName: (value) => dispatch({ type: 'SET_WRAPPER_CLASS_NAME', value })
})

export default connect(mapStateToProps, mapDispatchToProps)(Apps)
