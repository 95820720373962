
import { isEmpty, groupBy, find, uniq } from 'lodash'
import { KNOWN_ROLES_MAP } from '../../../utils/rbacUtils'
import {
  GET_RBAC_SCHEMA,
  GET_ROLE_DEF,
  OPEN_ASSIGN_CUSTOM_PERMISSIONS, RBAC_CONFIRMATION_DIALOG,
  RBAC_CREATE_CUSTOM,
  RBAC_CUSTOM_ROLE_DIALOG,
  RBAC_DELETE_CUSTOM_ROLE_ACTION,
  RBAC_EDIT_ROLE, RBAC_ROLE_TABLE_LOADING,
  RBAC_SEARCH_ROLE,
  RBAC_SEARCH_ROLE_SEARCHING,
  RBAC_SELECT_APP_SUCCESS, RBAC_SELECTED_ROLE,
  RBAC_VIEW_ROLE,
} from '../../actions/ActionConstants'
import {
  ACCOUNT_SUPER_USER,
  ACCOUNT_SUPER_USER_HELP_TEXT_2,
  RBAC_ADMIN,
  RBAC_APP_ADMIN_HELP_TEXT_2,
  RBAC_INSTANCE_ADMIN,
  RBAC_INSTANCE_ADMIN_HELP_TEXT_2,
} from '../../../constants/AppConstants'

const defaultState = {
  /**
   * Dialog open|close on the AppAssignPage this is used for custom role permission assignment directly without creating role
   */
  isAssignCustomPerDiagOpen: false,
  /**
   * Role definition for all apps
   */
  roleDef: [],
  /**
   * Role Schema for all apps. This will contain rbac schema for all apps, Also apps who have supportCustomRole flag true get
   * overridden by this role
   */
  rbacSchema: [],
  /**
   *
   */
  filteredRoleDef: [],
  /**
   * Flag for searching custom role
   */
  isRoleSearching: false,
  selectedAppRoleSchema: {
    app_predefined_roles: []
  },
  /**
   * schema def per application
   */
  schemaPerApp: {},
  /**
   * schema tree for selected app
   */
  schemaTree: [],
  /**
   * Custome role
   */
  custom_role: {},
  isCustomRoleDialOpen: false,
  roleToBeView: {},
  /**
   * Dialog action type weather is Edit, Readonly or create mode
   */
  dialogActionType: 'edit',
  /**
   * Role Table is loading
   */
  isCustomRoleTableLoading: false
}

/**
 *
 * @param str
 * @param val
 * @returns {boolean}
 */
const searchStr = (str, val) => str.toLowerCase().trim().indexOf(val) > -1
/**
 * Search based on role
 * @param roleDef
 * @param value
 * @returns {*}
 */
const searchRole = ({ roleDef }, value) => {
  if (Array.isArray(roleDef)) {
    return roleDef.filter(roleObj => {
      const { role = '', description = '', role_label = '' } = roleObj
      return [role, description, role_label].some(str => searchStr(str, value))
    })
  }
  return roleDef
}

/**
 *
 * @param tree
 * @param prev
 * @returns {*|boolean|boolean}
 */
const findNode = (tree, prev) => {
  if (tree && Array.isArray(tree)) {
    for (let x = 0; x < tree.length; x++) {
      if (tree[x].prev === prev) return tree[x]
      const obj = findNode(tree[x].children, prev)
      if (!isEmpty(obj)) return obj
    }
  }
  return false
}
/**
 *
 * @param node
 * @param val
 * @param prev
 * @param doRender
 * @param title
 */
const addChild = (node, val, prev, addedTree, parentKey, others) => {
  if (!addedTree[`${prev}.${val}`]) {
    if (node.hasOwnProperty('children') && Array.isArray(node.children)) {
      addedTree[`${prev}.${val}`] = true
      node.children.push({
        key: `${parentKey}-${val}`,
        children: [],
        ...others,
        prev: `${prev}.${val}`
      })

      // update parent node [SCOPE, DESCRIPTION] to NULL if node has more than 1 children node
      if (!others?.renderAsMain && node.children.length > 1) {
        node.scope = null
        node.description = null
      }

      //APPORTAL-2779 - add all child nodes skus to parent node skus
      if (!others?.renderAsMain && Array.isArray(node?.sku) && others?.sku) {
        node.sku = uniq([...node.sku, ...others.sku])
      }
    }
  }
  return
}

/**
 *
 * @param rbacSchema
 * @param selectedApp
 * @returns {{schemaPerApp: *}}
 */
const generateTreeFromSchema = ({ rbacSchema }, selectedApp) => {
  if (!selectedApp) return
  let schemaPerApp = rbacSchema.find(schema => schema.app === selectedApp.app_id)
  const schemaTree = []
  if (!isEmpty(schemaPerApp)) {
    const { support_custom_role = false, support_rbac = false } = selectedApp?.rbac_role || {}
    schemaPerApp = { ...schemaPerApp, support_custom_role, support_rbac }
    const { scope_display, scopes } = schemaPerApp
    const _scope_display_key = groupBy(scope_display, 'name')
    const addedTree = {}
    scopes.forEach(eachScope => {
      const { scope, disallowed_in_custom_role, description, sku = null } = eachScope
      const permissions = scope.split('.')
      if (!disallowed_in_custom_role && permissions && Array.isArray(permissions)) {
        permissions.forEach((eachPermission, index) => {
          if (index === permissions.length - 1) return
          const parentKey = permissions[0]
          const renderAsMain = index === 0
          const { label: title = eachPermission } = find(_scope_display_key[eachPermission], { name: eachPermission }) || eachPermission
          const prev = permissions[index - 1] ? permissions.slice(0, index).join('.') : null
          // prevNode += `${eachPermission}.`
          const node = findNode(schemaTree, prev)
          if (node) {
            addChild(node, eachPermission, prev, addedTree, parentKey, { doRender: true, title, renderAsMain, scope, prev, description, sku })
          }
          else if (!addedTree[eachPermission]) {
            // prevNode += `${eachPermission}.`
            addedTree[eachPermission] = true
            schemaTree.push({
              scope,
              description,
              key: `${parentKey}`,
              title: title,
              renderAsMain,
              children: [],
              prev: eachPermission
            })
          }
        })
      }
    })
  }
  if (selectedApp.uniqueId === 'superuser_account_id') {

    schemaPerApp = {
      ...selectedApp,
      app_predefined_roles: [{
        role: 'account_superuser',
        description: ACCOUNT_SUPER_USER_HELP_TEXT_2,
        role_label: ACCOUNT_SUPER_USER,
        scopes: ['*']
      }]
    }
  }
  return {
    schemaPerApp,
    schemaTree
  }
}

const getViewRole = ({ schemaTree }, roleToBeView) => {
  const { scope = [] } = roleToBeView || {}
  const checkedNode = []
  const checkedNodeObj = {}
  scope.forEach(eachScope => {
    const eachScopeArr = eachScope.split('.')
    const parent = eachScopeArr[0]

    const secondLast = eachScopeArr[[eachScopeArr.length - 1] - 1]
    // this is we need to use as checked keys
    checkedNode.push(`${parent}-${secondLast}`)
    if (checkedNodeObj[parent]) {
      checkedNodeObj[parent] = checkedNode
    }
    else {
      checkedNodeObj[parent] = checkedNode
    }
  })

  return { ...roleToBeView, checkedNode: checkedNode || [], checkedNodeObj }
}

const getRoleDef = (roleDefPrime) => {
  const roleDef = roleDefPrime.concat([])
  if (roleDef && roleDef.length) {
    const idx = roleDef.findIndex(eachRole => eachRole.role === 'app_superuser')
    const appSuperUser = roleDef[idx]
    roleDef.splice(idx, 1)
    /**
     * We need to add this static role because PM wants to show this role in role def. RBAC V2 these two roles are app_superuser only
     * Diff will be the way user assign meaning if tenant_id: * then it will App Admin and tenant: <string> will be instance admin
     */
    roleDef.unshift({
      role: 'app_superuser',
      description: RBAC_APP_ADMIN_HELP_TEXT_2,
      role_label: RBAC_ADMIN,
      scope: appSuperUser?.scope || []
    }, {
      role: 'app_superuser',
      description: RBAC_INSTANCE_ADMIN_HELP_TEXT_2,
      role_label: RBAC_INSTANCE_ADMIN,
      scope: appSuperUser?.scope || []
    })
  }
  return roleDef
}

const updatedKnownRoles = (schema = []) => {
  schema.forEach(each => {
    const { app, app_predefined_roles = [] } = each
    app_predefined_roles.forEach(each => {
      const { role, role_label } = each
      if (role !== 'app_superuser') {
        KNOWN_ROLES_MAP.set(`${app}-${role}`, role_label)
      }
    })
  })
}
/**
 *
 * @param state
 * @param action
 * @returns {{schema, rbacSchema, selectedAppRoleSchema, isAssignCustomPerDiagOpen, roleDef}|{rbacSchema, selectedAppRoleSchema, isAssignCustomPerDiagOpen: *, roleDef}|{rbacSchema, selectedAppRoleSchema, isAssignCustomPerDiagOpen, roleDef, filteredRoleDef: *}|{rbacSchema, selectedAppRoleSchema, isAssignCustomPerDiagOpen, roleDef: {role: *, description: *, role_label: *}[]}|{rbacSchema: [], selectedAppRoleSchema: {app_predefined_roles: []}, isAssignCustomPerDiagOpen: boolean, roleDef: []}}
 */
const customRoleReducer = (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_ASSIGN_CUSTOM_PERMISSIONS: {
      const { isOpen, tenantSkus, userRoleTenant_id, roleToBeView } = action
      return { ...state, isAssignCustomPerDiagOpen: isOpen,
        userRoleTenant_id,
        tenantSkus,
        roleToBeView: getViewRole(state, roleToBeView)
      }
    }
    case GET_ROLE_DEF: {
      return { ...state, roleDef: getRoleDef(action.roleDef) }
    }
    case GET_RBAC_SCHEMA: {
      updatedKnownRoles(action.rbacSchema)
      return { ...state, rbacSchema: action.rbacSchema }
    }
    case RBAC_SEARCH_ROLE_SEARCHING: {
      return { ...state, isRoleSearching: action.isRoleSearching }
    }
    case RBAC_SEARCH_ROLE: {
      return { ...state, filteredRoleDef: searchRole(state, action.value) }
    }
    case RBAC_SELECT_APP_SUCCESS: {
      return { ...state, ...generateTreeFromSchema(state, action.selectedApp) }
    }
    case RBAC_CREATE_CUSTOM: {
      if (action.customRoleSuccess && action.customRoleSuccess.success && Array.isArray(action.customRoleSuccess.success)) {
        return { ...state, ...{ roleDef: [...state.roleDef, ...action.customRoleSuccess.success || []], custom_role: action.customRoleSuccess } }
      }
      return { ...state, custom_role: action.customRoleSuccess }
    }
    case RBAC_DELETE_CUSTOM_ROLE_ACTION: {
      return { ...state, roleDef: state.roleDef.filter(role => role?.role !== action.deletedRoleObj?.role) }
    }
    case RBAC_CUSTOM_ROLE_DIALOG: {
      let roleToBeView = state.roleToBeView
      if (action.isOpen) {
        roleToBeView = {}
      }
      return { ...state, isCustomRoleDialOpen: action.isOpen,
        roleToBeView: { ...roleToBeView },
        custom_role: {},
        customRoleDialogTitle: 'Create Role',
        dialogActionType: action.dialogActionType }
    }
    case RBAC_VIEW_ROLE: {
      return { ...state,
        roleToBeView: getViewRole(state, action.roleTobeView),
        dialogActionType: 'read_only',
        customRoleDialogTitle: 'View Role'
      }
    }
    case RBAC_EDIT_ROLE: {
      return { ...state,
        roleToBeView: getViewRole(state, action.roleTobeView),
        dialogActionType: 'edit',
        customRoleDialogTitle: 'Edit Role'
      }
    }
    case RBAC_ROLE_TABLE_LOADING: {
      return { ...state, isCustomRoleTableLoading: action.isCustomRoleTableLoading }
    }

    case RBAC_CONFIRMATION_DIALOG: {
      return { ...state, confirmationDialogProps: action.confirmationDialogProps }
    }

    case 'TREE_NODE_CHECKED': {
      return { ...state, roleToBeView: action.roleToBeView }
    }
    case 'CREATE_AS_NEW_ROLE': {
      return { ...state, roleToBeView: action.roleToBeView }
    }
    case RBAC_SELECTED_ROLE: {
      return { ...state, selectedCustomRole: action.customRole }
    }

    default: {
      return state
    }
  }

}
export { getViewRole }
export default customRoleReducer
