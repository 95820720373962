import NGFIcon from '../../images/app-icons/black_ngf.svg'
import PrismaAccess from '../../images/app-icons/prisma_access.svg'
import StrataAccess from '../../images/app-icons/strata_access.svg'
import IoTLogo from '../../images/app-icons/zingbox_logo.svg'
import CORTEX_XDR_LOGO from '../../images/calc/cortex_xdr.svg'
import CORTEX_AGENT from '../../images/calc/cortex_agent.svg'
import { FT } from '@pan/cloud-appframework'
import {
  CORTEX_XDR,
  CORTEX_XDR_AGENTS,
  IOT_SECURITY,
  PRISMA_ACCESS_DISPLAY_NAME,
  STRATA_ACCESS_DISPLAY_NAME,
} from '../../constants/AppConstants'
import { ngFwModels } from '../../containers/LoggingCalculator/NgfwLogRateDetails'

const ngfFormula = ({ retentionPrd = 0, avgLogRate = 0 }, isMagnifierChecked) => {
  const storageDetailLog = isMagnifierChecked ? (avgLogRate * 86400 * retentionPrd * 2100) / Math.pow(1024, 3) :
    (avgLogRate * 86400 * retentionPrd * 1500) / Math.pow(1024, 3)
  return FT('CALC_UPDATE_FORMULA') ? storageDetailLog : (storageDetailLog / 0.6)
}
const FT_REBRAND = FT('STRATA_ACCESS_REBRAND')
const LOGGING_SVC_CALC_APP_LIST = FT('REMOVE_XDR') ? [
  {
    appId: 'ngf',
    name: 'Next-Generation Firewall',
    icon: NGFIcon,
    iconWidth: 80,
    avgLogRate: null, // need to do this cause we need to show placeholder
    retentionPrd: 30, // default retentionPrd make it 30
    totalStorage: 0,
    storageDetailLog: 0,
    checked: true,
    doRender: true,
    isCollapsed: false,
    formula: ngfFormula
  },
  {
    appId: 'gpcsRemoteNetworks',
    name: FT_REBRAND ? `${STRATA_ACCESS_DISPLAY_NAME} (Remote Networks)` : `${PRISMA_ACCESS_DISPLAY_NAME} (Remote Networks)`,
    icon: FT_REBRAND ? StrataAccess : PrismaAccess,
    thrputPurchase: 0,
    retentionPrd: 30,
    busyHrs: 10,
    busyHrsUtilization: 65,
    offHrsUtilization: 10,
    effectiveLogRate: 0,
    totalStorage: 0,
    iconWidth: 84,
    checked: false,
    doRender: true,
    isCollapsed: false
  },
  {
    appId: 'gpcsMobileUsers',
    numberOfUsers: 0,
    retentionPrd: 30,
    name: FT_REBRAND ? `${STRATA_ACCESS_DISPLAY_NAME} (Mobile Users)` : `${PRISMA_ACCESS_DISPLAY_NAME} (Mobile Users)`,
    iconWidth: 84,
    icon: FT_REBRAND ? StrataAccess : PrismaAccess,
    totalStorage: 0,
    checked: false,
    doRender: true,
    isCollapsed: true
  },
  {
    appId: 'iot',
    name: IOT_SECURITY,
    icon: IoTLogo,
    doRender: false,
    iconWidth: 80,
    totalStorage: 0,
    checked: false,
    isCollapsed: true
  }
] : [
  {
    appId: 'ngf',
    name: 'Next-Generation Firewall',
    icon: NGFIcon,
    iconWidth: 80,
    avgLogRate: null, // need to do this cause we need to show placeholder
    retentionPrd: 30, // default retentionPrd make it 30
    totalStorage: 0,
    storageDetailLog: 0,
    checked: true,
    doRender: true,
    isCollapsed: false,
    formula: ngfFormula
  },
  {
    appId: 'traps',
    name: CORTEX_XDR_AGENTS,
    iconWidth: 50,
    numberOfUsers: 0,
    retentionPrd: 30,
    numberOfXDRClients: 0,
    xdrRetentionPrd: 30,
    icon: CORTEX_AGENT,
    totalStorage: 0,
    totalDataLakeStorage: 0,
    hasChild: {
      name: CORTEX_XDR_AGENTS,
      totalProperty: 'totalDataLakeStorage'
    },
    doRender: true,
    checked: false,
    isCollapsed: true
  },
  {
    appId: 'gpcsRemoteNetworks',
    name: FT_REBRAND ? `${STRATA_ACCESS_DISPLAY_NAME} (Remote Networks)` : `${PRISMA_ACCESS_DISPLAY_NAME} (Remote Networks)`,
    icon: FT_REBRAND ? StrataAccess : PrismaAccess,
    thrputPurchase: 0,
    retentionPrd: 30,
    busyHrs: 10,
    busyHrsUtilization: 65,
    offHrsUtilization: 10,
    effectiveLogRate: 0,
    totalStorage: 0,
    iconWidth: 84,
    checked: false,
    doRender: true,
    isCollapsed: false
  },
  {
    appId: 'gpcsMobileUsers',
    numberOfUsers: 0,
    retentionPrd: 30,
    name: FT_REBRAND ? `${STRATA_ACCESS_DISPLAY_NAME} (Mobile Users)` : `${PRISMA_ACCESS_DISPLAY_NAME} (Mobile Users)`,
    iconWidth: 84,
    icon: FT_REBRAND ? StrataAccess : PrismaAccess,
    totalStorage: 0,
    checked: false,
    doRender: true,
    isCollapsed: true
  },
  {
    appId: 'magnifier',
    name: CORTEX_XDR,
    icon: CORTEX_XDR_LOGO,
    doRender: false,
    iconWidth: 60,
    totalStorage: 0,
    checked: false,
    isCollapsed: true
  },
  {
    appId: 'iot',
    name: IOT_SECURITY,
    icon: IoTLogo,
    doRender: false,
    iconWidth: 80,
    totalStorage: 0,
    checked: false,
    isCollapsed: true
  }
]


const firewallSelectionType = []
/**
 *
 * @param appList
 * @param appType
 * @returns {*}
 *
 * find app from LOGGING_SVC_CALC_APP_LIST using appType.appId object and determine if app is checked (selected)
 * or un-checked.
 *
 * Make checked flag true or false based on user click on the tile of the app in logging service calculator
 *
 */

const appChecked = ({ loggingServiceCalAppList }, appType) => {
  const { itemID, checked } = appType
  const foundAppIndex = loggingServiceCalAppList.findIndex(el => el.appId === itemID)
  const appObj = loggingServiceCalAppList[foundAppIndex]
  const newArray = loggingServiceCalAppList.slice() // create shallow copy
  newArray[foundAppIndex] = { ...appObj, checked: checked } // modify object of selected app index
  const isMagnifierChecked = checkMagnifier({ loggingServiceCalAppList: newArray })
  const isIoTChecked = checkIoT({ loggingServiceCalAppList: newArray })  // check magnifier is checked in redux store and apply formula accordingly
  doTotalCalculation(newArray, isMagnifierChecked, isIoTChecked)
  return { loggingServiceCalAppList: newArray, isMagnifierChecked: isMagnifierChecked, isIoTChecked }
}

// re calculate total storage if magnifier checked
const doTotalCalculation = (loggingServiceCalAppList, isMagnifierChecked, isIoTChecked) => {
  loggingServiceCalAppList.forEach(item => {
    const { appId } = item
    if (appId === 'ngf') {
      item.totalStorage = item.formula(item, isMagnifierChecked || isIoTChecked)
    }
    else if (appId === 'gpcsRemoteNetworks') {
      const { thrputPurchase = 0, busyHrsUtilization = 0, busyHrs = 0, offHrsUtilization = 0, retentionPrd = 0 } = item
      const effectiveLogRate = isMagnifierChecked || isIoTChecked ? ((3 * (thrputPurchase * (busyHrsUtilization / 100)) * (busyHrs * 60 * 60)) + (3 * (thrputPurchase * (offHrsUtilization / 100)) * ((24 - busyHrs) * 60 * 60))) / 86400 :
        ((1.5 * (thrputPurchase * (busyHrsUtilization / 100)) * (busyHrs * Math.pow(60, 2))) + (1.5 * (thrputPurchase * (offHrsUtilization / 100)) * ((24 - busyHrs) * Math.pow(60, 2)))) / 86400

      const storageDetailLog = isMagnifierChecked || isIoTChecked ? (((effectiveLogRate / 2) * 86400 * retentionPrd * 1500) + ((effectiveLogRate / 2) * 86400 * retentionPrd * 600)) / (Math.pow(1024, 3)) :
        (effectiveLogRate * 86400 * retentionPrd * 1500 / Math.pow(1024, 3))

      item.totalStorage = FT('PA_CALC_UPDATE_FORMULA') ? storageDetailLog : (storageDetailLog / 0.6)
    }
    else if (appId === 'gpcsMobileUsers') {
      const { numberOfUsers = 0, retentionPrd = 0 } = item
      const storageDetailLog = isMagnifierChecked || isIoTChecked ? (numberOfUsers * retentionPrd * 15) / 1024 :
        (numberOfUsers * retentionPrd * 6666666.667) / Math.pow(1024, 3)
      item.totalStorage = FT('PA_CALC_UPDATE_FORMULA') ? storageDetailLog : (storageDetailLog / 0.6)
    }
  })
}

/**
 *
 * @param appList
 * @param appId
 * @returns {*}
 * collapse card if appId clicked
 */
const checkCollapsedCard = (appList, appId) => {
  const foundAppIndex = appList.findIndex(el => el.appId === appId)
  const appObj = appList[foundAppIndex]
  const newArray = appList.slice() // create shallow copy
  newArray[foundAppIndex] = { ...appObj, isCollapsed: !appObj.isCollapsed } // modify object of selected app index
  return newArray
}

const setTotalData = (appList, appData) => {
  const { appId } = appData
  const foundIndx = appList.findIndex(el => el.appId === appId)
  const appObj = appList[foundIndx]
  const cloneArr = appList.slice()
  cloneArr[foundIndx] = { ...appObj, ...appData }
  return cloneArr
}

/**
 *
 * @param firewallList
 * @param firewallSelectionType
 * @returns {*}
 * selection according to dialog box from NGFWMoreDialog component
 */
const getFireWallsSelectionType = (firewallList, firewallSelectionType) => {
  const hasBladeCount = firewallList.some(item => ngFwModels.find(each => each.type === item.type)?.bladeCounts)
  const isCustomSelected = firewallList.some(item => item.utilization === 'custom')
  if (firewallSelectionType.hasOwnProperty('index') && firewallSelectionType.index !== '') {
    const { index } = firewallSelectionType
    const newArr = firewallList.slice()
    newArr[index] = { ...newArr[index], ...firewallSelectionType }
    return { isCustomSelected, hasBladeCount, firewallSelectionType: newArr }
  }
  else {
    return { isCustomSelected, hasBladeCount, firewallSelectionType: [].concat(firewallSelectionType) }
  }
}

/**
 *
 * @param firewallList
 * @param firewallSelectionType
 * @returns {*}
 * Delete firewall
 */
const deletedFirewallSelectionType = (firewallList, firewallSelectionType) => {

  if (firewallSelectionType.hasOwnProperty('index') && firewallSelectionType.index !== '') {
    const { index } = firewallSelectionType
    const newArray = firewallList.filter(item => item.index !== index)
    const hasBladeCount = newArray.some(item => ngFwModels.find(each => each.type === item.type)?.bladeCounts)
    const isCustomSelected = newArray.some(item => item.utilization === 'custom')
    return { isCustomSelected, hasBladeCount, firewallSelectionType: newArray }
  }
}

// return magnifier checked true | false
const checkMagnifier = ({ loggingServiceCalAppList }) => {
  // we are hacking here, Since iot and magnifier shared same formula
  return FT('REMOVE_XDR') ? false : loggingServiceCalAppList.find(el => el.appId === 'magnifier').checked
}

const checkIoT = ({ loggingServiceCalAppList }) => {
  // we are hacking here, Since iot and magnifier shared same formula
  return loggingServiceCalAppList.find(el => el.appId === 'iot').checked
}



/**
 *
 * @type {{firewallSelectionType: Array, isMagnifierChecked: boolean, loggingServiceCalAppList: *[]}}
 * @isMagnifierChecked true|false use different formula to calculate totalStorage in each service
 */
const loggingCalState = {
  firewallSelectionType,
  isMagnifierChecked: false,
  isIoTChecked: false,
  loggingServiceCalAppList: LOGGING_SVC_CALC_APP_LIST
}

const loggingServiceCalcReducer = (state = { ...loggingCalState }, action) => {
  switch (action.type) {
    case 'GET_LOGGING_CALC_APP_LIST': {
      return { ...state, loggingServiceCalAppList: LOGGING_SVC_CALC_APP_LIST }
    }
    case 'CHECKED_LOGGING_APP_TYPE': {
      return {
        ...state,
        ...appChecked({ ...state }, action.appType)
      }
    }
    case 'LOGGING_CALC_CARD_COLLAPSED': {
      return { ...state, loggingServiceCalAppList: checkCollapsedCard(state.loggingServiceCalAppList, action.appId) }
    }
    case 'SET_LOGGING_SERVICE_TOTAL_DATA': {
      return { ...state, loggingServiceCalAppList: setTotalData(state.loggingServiceCalAppList, action.appData) }
    }
    case 'CHANGE_DESIRED_RET_PERIOD': {
      return { ...state, desiredRetPeriod: action.desiredRetPeriod }
    }
    case 'GET_FIREWALL_SELECTION': {
      return { ...state, ...getFireWallsSelectionType(state.firewallSelectionType, action.selection) }
    }
    case 'DELETE_FIREWALL_SELECTION': {
      return { ...state, ...deletedFirewallSelectionType(state.firewallSelectionType, action.selection) }
    }
    default: {
      return state
    }
  }
}

export default loggingServiceCalcReducer
