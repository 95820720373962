import React, { useMemo, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from '@pan/cloud-base'
import { remoteValidator, toAppId, getSubdomainFromEmail } from '../../../utils/activateUtils'
import { SUBDOMAIN_FIELD } from '../../../constants/AppConstants'

const SubdomainField = ({
  initialValues,
  appInfoMap,
  fieldName = SUBDOMAIN_FIELD,
  selectedAccount,
  domainPostfix,
  getFieldDecorator,
  getFieldError,
  getFieldValue,
  isFieldValidating,
  isFieldTouched,
  setFieldsInitialValue,
  validateFields,
  resetFields,
  autoFocus,
  ...restProps
}) => {
  const initialValue = initialValues[fieldName]
  const hasInitialValues = !initialValues.isEmpty
  const [label, defaultSubdomain] = useMemo(() => {
    if (!appInfoMap.hasSubdomainField) {
      return [] // no customizable subdomain
    }
    let label = restProps.label || 'Subdomain'
    const { subdomainMap } = appInfoMap
    if (appInfoMap.isBundle && subdomainMap.size === 1) {
      const subdomainApp = appInfoMap.find(app => app.customizable_subdomain)
      const displayName = appInfoMap.getDisplayName(subdomainApp.name)
      label = `${displayName} ${label}`
    }
    const { user_email } = appInfoMap
    const subdomain = getSubdomainFromEmail(user_email)
    return [label, subdomain]
  }, [appInfoMap, restProps.label])
  const subdomainDecorator = useMemo(() => {
    if (!appInfoMap.hasSubdomainField) {
      return null // no customizable subdomain
    }
    // console.info(FIELD_NAME, appInfoMap, initialValue)
    const { subdomainMap } = appInfoMap
    const useRemoteValidator = domainPostfix && !initialValue
    const pattern = restProps?.domainPattern || /^((?!-)[a-zA-Z0-9-]{0,63}[a-zA-Z0-9])$/
    const simpleRules = [
      { whitespace: true, required: true, message: 'Subdomain is required' },
      { pattern: pattern, message: 'Subdomain is not valid' },
      { validator: (rule, value, callback) => callback(domainPostfix ? undefined : 'Service for Cortex XDR is not available, please try again later') }
    ]
    return getFieldDecorator(fieldName, {
      initialValue,
      getValueProps: hasInitialValues ? () => ({
        value: initialValue,
        disabled: true,
      }) : undefined,
      normalize: value => (value ? value.trim().toLowerCase() : ''),
      validateFirst: true,
      validate: [
        {
          trigger: ['onChange'],
          validateFirst: true,
          rules: simpleRules,
        },
        useRemoteValidator && {
          trigger: ['onBlur'],
          validateFirst: true,
          rules: [...simpleRules, {
            // valueField to override the validator that's using fieldName as key to validate
            valueField: SUBDOMAIN_FIELD,
            validator: remoteValidator,
            // transform: (value) => `${value}${domainPostfix}`, // for xdr, we only send the value since post fix can be changed per region/env
            remote: `/hub/v2/validate/${Array.from(subdomainMap.keys()).map(toAppId).join(',')}/subdomain`,
            selectedAccount,
            extraValues: {
              domainPostfix
            }
          }],
        }
      ].filter(Boolean)
    })
  }, [appInfoMap, domainPostfix, getFieldDecorator, initialValue, hasInitialValues, selectedAccount, restProps.domainPattern, fieldName])

  useLayoutEffect(() => {
    if (!appInfoMap.hasSubdomainField) {
      return // no customizable subdomain
    }
    if (!initialValue && defaultSubdomain && !isFieldTouched(fieldName)) {
      setFieldsInitialValue({ [fieldName]: defaultSubdomain })
    }
  }, [appInfoMap.hasSubdomainField, defaultSubdomain, fieldName, initialValue, isFieldTouched, setFieldsInitialValue])

  if (!appInfoMap.hasSubdomainField) {
    return null // no customizable subdomain
  }

  const err = getFieldError(fieldName)
  const validateStatus = isFieldValidating(fieldName) ? 'validating' : err ? 'error' : ''
  return <Form.Item
    {...restProps}
    label={label}
    validateStatus={validateStatus}
    help={validateStatus === 'error' ? err : ''}
  >
    {subdomainDecorator(<Input
      addonAfter={domainPostfix}
      autoFocus={autoFocus}
    />)}
  </Form.Item>
}

SubdomainField.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  fieldName: PropTypes.string,
  initialValues: PropTypes.object,
  selectedAccount: PropTypes.number,
  domainPostfix: PropTypes.string,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  isFieldValidating: PropTypes.func,
  isFieldTouched: PropTypes.func,
  setFieldsInitialValue: PropTypes.func,
  validateFields: PropTypes.func,
  resetFields: PropTypes.func,
  autoFocus: PropTypes.bool,
}

export default SubdomainField
