
import * as AConstants from './ActionConstants'
import { RBAC_AUTOCOMPLETE, RBAC_GET_USERS, RBAC_UPDATE_USERS } from './URLs'
import axios from 'axios'
import { get, isEqual } from 'lodash'
import Service from './../../utils/Service'
import { ACCOUNT_SUPER_USER, R_TYPE, R_USER } from '../../constants/AppConstants'
import { appPortalSetLoadingState } from './index'
import { CLEAR_ASSIGN_ROLE_CACHE, GET_SELECTED_USERS_ROLES } from './ActionConstants'
import { appframeworkActions } from '@pan/cloud-appframework'
import { getSchemaDef } from './custom_roles/customRoleActions'

const RoleAccessQuery = {
  filter: '',
  sort_order: 'asc',
  start_index: 0,
  sort_by: 'email',
  count: 50000
}

const RoleAccessQueryV2 = {
  filter: '',
  sort_order: 'asc',
  sort_by: 'email',
  start_index: 0,
  count: 50000
}

/**
 *
 * @param qParam -  start_index, filter, sort_by, sort_order
 * @returns {Function}
 */
export const getAllRoles = (params) => async (dispatch, getState) => {
  /**
   * taking support_account_id required param
   */
  const { rbac } = getState() || {}
  const { isTableSearching, selectedFilter } = rbac
  const filter = selectedFilter.split('-').length > 0 ? selectedFilter.split('-')[1] : ''
  params = { ...RoleAccessQueryV2, filter, ...params }
  //enabled table loading
  dispatch({
    type: 'RBAC_TABLE_LOADING',
    isUserTableLoad: true
  })
  const req = Service.createAxiosRequest({ url: RBAC_GET_USERS, method: 'POST', data: params })
  const resp = await Service.getData(await Service.call(req))
  const { users = [] } = resp || {}
  dispatch({
    type: AConstants.GET_ALL_ROLES,
    users
  })
  //disabled table loading
  dispatch({
    type: 'RBAC_TABLE_LOADING',
    isUserTableLoad: false
  })

  /**
   * Do not remove runtime check for @_orig_rbac_entitlements from getState method. This will break sorting per sku
   */
  if (getState()?.rbac?._orig_rbac_entitlements?.length) {
    dispatch({
      type: 'MERGE_USER_WITH_LIST',
      users
    })
  }

  // we need to update search result if user have already put search terms
  if (isTableSearching) {
    const { searchUsers } = rbac
    if (searchUsers.searchTerm) {
      dispatch(handleInputSearch(searchUsers.searchTerm))
    }
  }
}


/**
 *
 * @param params
 * @returns {Function}
 */

const { CancelToken } = axios
let cancel

export const autoCompleteUsers = (params) => async (dispatch) => {
  /**
   * we need to cancel pervios request in order to complete auto search
   * Current can be faster and then previous so when previous request fulfill then result may override
   * by current result.
   */
  if (cancel !== undefined) {
    cancel()
  }
  dispatch({
    type: AConstants.AUTO_COMPLETE_LOADING,
    isAutoCompleteLoading: true
  })
  const req = Service.createAxiosRequest({
    url: RBAC_AUTOCOMPLETE,
    method: 'GET',
    params,
    cancelToken: new CancelToken(((c) => {
      cancel = c
    }))
  })
  const resp = await Service.getData(await Service.call(req)) || []
  const autoComplete = resp.filter(user => !user.roles.includes(ACCOUNT_SUPER_USER))
  dispatch({
    type: AConstants.AUTO_COMPLETE_GET_USERS,
    autoComplete
  })

  dispatch({
    type: AConstants.AUTO_COMPLETE_LOADING,
    isAutoCompleteLoading: false
  })
  dispatch(appPortalSetLoadingState(false))
}

export const doSelectedUsers = selection => dispatch => {
  dispatch({
    type: AConstants.SELECTED_USERS,
    selection
  })
}

export const handleAutoCompleteSelection = autoCompleteSelection => dispatch => {
  dispatch({
    type: AConstants.AUTO_COMPLETE_USER_SELECTION,
    autoCompleteSelection
  })
}

/**
 *
 * @param value : change value from drop down selection
 * @param instanceObj : original value for the app row
 * @param appType : this is will help us to find app that needs to change.
 * It will reduce iteration to find app we needs to change
 * @returns {Function}
 */
export const selectedInstanceRole = (value, instanceObj, appType, options) => dispatch => {
  const { props } = options
  const type = get(props, 'type', 'app_predefined')
  dispatch({
    type: AConstants.ASSIGN_INSTANCE_ROLES,
    instanceSelection: { value, instanceObj, appType, type }
  })
}

/**
 * select schema based on app selected
 * @param selectedApp
 * @returns {Function}
 */
export const selectSchemaPerApp = (selectedApp) => (dispatch, getState) => {
  const { rbacSchema } = getState()
  if (!(rbacSchema && rbacSchema.length === 0)) {
    dispatch(getSchemaDef(selectedApp))
  }
}

/**
 *side panel selection
 * @param radioFilterValue
 * @returns {Function}
 */
export const handleFilterChange = radioFilterValue => async (dispatch, getState) => {
  dispatch({
    type: AConstants.FILTER_CHANGE,
    radioFilterValue
  })
  const { selectedApp = {} } = getState().rbac
  dispatch(selectSchemaPerApp(selectedApp))

}
/**
 *
 * @param usersList
 * @param shouldRefreshUsersList
 * @param rolesNeedsToBeDeleted
 * @returns {function(*, *): Promise<unknown>}
 */
export const doUpdateUsers = (usersList, shouldRefreshUsersList, rolesNeedsToBeDeleted) => async (dispatch, getState) => {
  // get roles as per new schema.
  // Note: We are not passing support_account_id which is required. We need to get that from server session
  // We are doing this for security reason
  const { support_account_id: csp_account_id } = Service.getAccountId(getState())
  usersList = usersList.map(user => {
    /**
     * We are making sure that role for type user role will be always user email address. So again we are overriding if its not
     */
    if (user[R_TYPE] === R_USER) {
      return {
        ...user,
        csp_account_id: csp_account_id.toString(),
        role: user.email
      }
    }
    return {
      ...user,
      csp_account_id: csp_account_id.toString()
    }
  })
  rolesNeedsToBeDeleted = rolesNeedsToBeDeleted.map(user => {
    return {
      ...user,
      csp_account_id: csp_account_id.toString()
    }
  })

  const data = { users: usersList, removeUsers: rolesNeedsToBeDeleted }
  const req = Service.createAxiosRequest({ url: RBAC_UPDATE_USERS, method: 'PATCH', data })
  const resp = await Service.getData(await Service.call(req))
  dispatch({
    type: AConstants.PATCH_UPDATE_USERS,
    resp
  })

  if (shouldRefreshUsersList) {
    dispatch(getAllRoles())
  }

  // we need to return promise cause we have to redirect page after update success
  return Promise.resolve(resp)
}

/**
 *
 * @param tagTobeRemoved
 * @returns {{type: string, tagTobeRemoved: *}}
 */
export const removeTagFromSelection = tagTobeRemoved => {
  return {
    type: AConstants.REMOVE_SELECTED_USER_TAG,
    tagTobeRemoved
  }
}


/**
 *
 * @param value
 * @returns {Function}
 */
export const handleInputSearch = value => dispatch => {

  dispatch({
    type: AConstants.TABLE_SEARCH,
    isTableSearching: !!value.length
  })
  dispatch({
    type: AConstants.INPUT_TABLE_SEARCH,
    search: value.toLowerCase() || '',
    searchTerm: value
  })
}

export const clearAssignRoleCache = () => async (dispatch, getState) => {
  dispatch({
    type: AConstants.CLEAR_ASSIGN_ROLE_CACHE,
    clearAssignRoleCache: true
  })
  //APPORTAL-1844-start
  const { selectedUsers, users: localUsers } = getState().rbac || {}
  let userParam = ''
  selectedUsers.forEach((user, i) => {
    userParam += `(email eq "${user}") ${i === selectedUsers.length - 1 ? '' : 'or '}`
  })
  const req = Service.createAxiosRequest({ url: RBAC_GET_USERS, method: 'POST', data: { ...RoleAccessQuery, filter: userParam } })

  const resp = await Service.getData(await Service.call(req))
  const { users = [] } = resp || {}
  if (users.length === 0) {
    localUsers.data.forEach(eachUser => {
      const { email } = eachUser
      if (selectedUsers.indexOf(email) > -1) {
        users.push(eachUser)
      }
    })
  }
  const existingUsersWithRoles = users.map(user => {
    return {
      email: user.email || '',
      roles: user.roles || []
    }
  })
  dispatch({
    type: GET_SELECTED_USERS_ROLES,
    existingUsersWithRoles,
    users
  })
  dispatch({
    type: CLEAR_ASSIGN_ROLE_CACHE,
    clearAssignRoleCache: true
  })
  //APPORTAL-1844-end
}

export const getAccountRoles = () => (dispatch, getState) => {
  let adminRole = {}
  const { supportAccountIds, user_email, selectedAccount = +localStorage?.selectedAccount || 0 } = getState()
  if (selectedAccount >= 0 && supportAccountIds && user_email) {
    const { roles } = supportAccountIds[selectedAccount]
    adminRole = {
      roles,
      selectedUsers: [user_email]
    }
  }
  dispatch({
    type: AConstants.GET_USER_ACCOUNT_ROLE,
    adminRole: adminRole
  })
}

export const checkAccountRbacRoles = ({ rbac_roles, support_account_id }) => (dispatch, getState) => {
  const { roles, selectedAccount, supportAccountIds } = getState()
  const sameAccount = selectedAccount >= 0 && +supportAccountIds?.[selectedAccount]?.accountid === +support_account_id
  if (sameAccount && !isEqual(rbac_roles, roles)) {
    dispatch(appframeworkActions.fetchCredential({ loading: false }))
  }
}
