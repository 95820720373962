import isLoading from './isLoading'
import currentInstanceActionModal from './currentInstanceActionModal'
import currentInstanceRecordModal from './currentInstanceRecordModal'
import selectedInstance from './selectedInstance'
import recordFormModalVisible from './recordFormModalVisible'
import appActivate from './appActivate'
import authCodeModal from './authCodeModal'
import entitledAppsList from './entitledAppsListOverride'
import globalLoadingMessage from './globalLoadingOverride'
// import jobs from './jobs'
import disabledTenants from './disabledTenants'
import loggingServiceCal from './loggingServiceCalculator'
import rbacReducer from './rbacReducer'
import appLoadingState from './appLoadingState'
import autoRedirect from './autoRedirect'
import customRoleReducer from './custom_roles/customRolesReducer'
import setupProgress from './setupProgress'
import msp from './msp'

export default {
  currentInstanceActionModal,
  currentInstanceRecordModal,
  selectedInstance,
  entitledAppsList, // override the appframework one
  appLogos: (state = {}) => state, // load logo json from cdn and store here
  isLoading,
  recordFormModalVisible,
  authCodeModal,
  appActivate,
  setupProgress,
  // jobs,
  disabledTenants,
  loggingServiceCal,
  autoRedirect,
  msp,
  rbac: rbacReducer,
  rbacCustomRole: customRoleReducer,
  appLoadingState,
  globalLoadingMessage,
}
