import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form, Button, Spin } from '@pan/cloud-base'
import {
  DSS as DSS_NAME, DSS_ID,
  LGS_ID,
  LGS_DISPLAY, ACTIVATE, EMPTY_INIT_VALUES,
  getAppInfoMap,
  genCancelSource,
  wait,
  formItemLayout,
  hasFormErrors,
  useMemoForm,
  useMemoizeValue,
  isNonemptyArray,
  activate,
} from '../../utils/activateUtils'
import AccountNameField from './fields/AccountNameField'
import InstanceNameField from './fields/InstanceNameField'
import DescriptionField from './fields/DescriptionField'
import RegionAssociationsFields from './fields/RegionAssociationsFields'
import CustomFields from './fields/CustomFields'
import EulaField from './fields/EulaField'
import './ActivateForm.scss'

const DSS = DSS_ID
const LGS = LGS_ID

const throwErr = (err) => {
  throw err
}

const multiActivate = async (values, { hasBundledTenant, displayName, showActivateProgress, hideActivateProgress }) => {
  if (!values.app_names || !values.app_names.length) {
    throw new Error('') // bad request, should not happen
  }
  if (values.app_names.length > 1 || !values.associations) { // skip if is bundle or no associations
    return activate(values)
  } // otherwise single app
  const activateNewDSS = values.associations[DSS] === '||' // || means no tenant id and no region
  const activateNewLGS = /^\|\|\||\|bundled_tenant$/.test(values.associations[LGS] || '') // should be |||authcode
  if (!activateNewDSS && !activateNewLGS && !hasBundledTenant) {
    return activate(values) // as normal
  }
  const namePrefix = values.tenant_instance_name.replace(new RegExp(`[ -]*${displayName}`, 'i'), '').trim() || values.tenant_instance_name
  const {
    region_data,
    description,
    support_account_id,
    is_eula_accepted,
  } = values
  const [, lcaas_region] = region_data.split('|', 2)
  // console.error(namePrefix, activateNewDSS, activateNewLGS, values)
  // use await for some delays after dispatch
  const steps = [
    activateNewDSS ? `Activating ${DSS_NAME}` : '',
    activateNewLGS ? `Activating ${LGS_DISPLAY}` : '',
    `${hasBundledTenant ? 'Setting up' : 'Activate'} ${displayName}`,
  ]
  await showActivateProgress(0, '', steps)
  try {
    const dssPromise = activateNewDSS && activate({
      app_names: DSS,
      tenant_instance_name: `${namePrefix} - ${DSS_NAME}`,
      region_data: `${lcaas_region}|${lcaas_region}`,
      auth_code: '',
      description,
      support_account_id,
      is_eula_accepted,
    }).then((dssTenant) => {
      if (!dssTenant?.tenant_id) {
        // console.error('failed to create dss, tenant_id not returned', dssTenant)
        throw new Error(`Failed to activate a new ${DSS_NAME}`)
      }
      return dssTenant
    }).catch((err) => {
      showActivateProgress(1, 'error')
      throw err
    })

    const lgsAuthCode = activateNewLGS && values.associations[LGS].split('|')[3]
    const lgsPromise = lgsAuthCode && lgsAuthCode !== 'bundled_tenant' && activate({
      app_names: LGS,
      auth_code: lgsAuthCode,
      tenant_instance_name: `${namePrefix} - ${LGS_DISPLAY}`,
      region_data: `${lcaas_region}|${lcaas_region}`,
      description,
      support_account_id,
      is_eula_accepted,
    }, { url: '/hub/v2/activate' }).catch(throwErr)

    if (dssPromise) {
      const dssTenant = await dssPromise
      values.associations[DSS] = `${dssTenant.tenant_id}||${lcaas_region}`
    }
    showActivateProgress(1)
    if (lgsPromise) {
      try {
        const lgsTenant = await lgsPromise
        if (!lgsTenant.tenant_id) {
          throw new Error('tenant_id is not returned')
        }
        if (lgsTenant.available === false) {
          // TODO: need to wait for sse
          // console.error('timeout for waiting lgs ready')
          await wait(5000) // 5s
        }
        values.associations[LGS] = `${lgsTenant.tenant_id}|${lgsTenant.serial_number}|${lcaas_region}`
      }
      catch (err) {
        showActivateProgress(2, 'error')
        throw err
      }
    }
    else if (!activateNewLGS) {
      showActivateProgress(2)
    } // if activateNewLGS but no lgsPromise, means LGS activate in the back
    // if (hasBundledTenant) {
    //   values.steps = steps
    // }
    const result = await activate(values)
    if (!result.job_id) { // do not show progress if it is a job
      showActivateProgress(3)
      setTimeout(hideActivateProgress, 500)
    }
    return result
  }
  catch (err) {
    setTimeout(hideActivateProgress, 500)
    throw err
  }
}

const ActivateForm = ({
  form,
  appNames,
  auth_code,
  displayName,
  initialValues,
  selectedAccount,
  supportAccounts,
  is_eval,
  available_account_ids,
  support_account_id,
  support_account_name,
  entitledAppsList,
  user_email,
  tenant_id,
  serial_number,
  bundled_tenant,
  domain_postfix,
  regions,
  accountIsFederal,
  activateResult,
  history,
  onCancel,
  onActivated,
  onActivateFailed,
  onActivateClicked,
  switchAccount,
  setInitialValues,
  resetActivate,
  resetActivateResult,
  showActivateProgress,
  hideActivateProgress,
  showAuthCodeModal,
  showAppActivateModal,
}) => {
  initialValues = useMemoizeValue(initialValues)
  const formMethods = useMemoForm(form)
  const {
    getFieldDecorator,
    getFieldsError,
    validateFields,
  } = formMethods
  const [bkgId, setBkgId] = useState()
  const [isLoading, setLoading] = useState(false)
  const cancelSource = useMemo(genCancelSource, [])
  const clearLoading = useCallback(() => {
    if (!cancelSource.token.reason) {
      setLoading(false)
    }
  }, [cancelSource])
  const appInfoMap = useMemo(() => (
    getAppInfoMap(appNames, entitledAppsList, { displayName, domain_postfix, user_email, is_eval })
  ), [appNames, entitledAppsList, displayName, domain_postfix, is_eval, user_email])
  domain_postfix = domain_postfix || appInfoMap.domain_postfix
  regions = useMemo(() => (
    isNonemptyArray(regions) ? regions :
      (appInfoMap.single && appInfoMap.getRegions(appInfoMap.single.name))
  ), [appInfoMap, regions])
  const hasBundledTenant = Boolean(bundled_tenant)
  const activateHandler = useCallback((e) => {
    e?.preventDefault?.()
    setLoading(true)
    onActivateClicked()
    validateFields({ force: true }, async (err, values) => {
      if (err) {
        return clearLoading()
      }
      const tenant_instance_name = (values.tenant_instance_name && values.tenant_instance_name.trim()) || undefined
      const [region] = values.region_data ? values.region_data.split('|', 2) : []
      const domainPostfix = regions.find(r => (r.name || r.value) === region)?.domain_postfix || domain_postfix
      try {
        const data = await multiActivate({
          ...values,
          tenant_instance_name,
          app_names: appNames,
          auth_code,
          ...(domain_postfix && { domain_postfix: domainPostfix }),
          ...(serial_number && {
            tenant_id,
            serial_number,
          }),
        }, { displayName, hasBundledTenant, showActivateProgress, hideActivateProgress }) // not cancelable
        if (!data) {
          await onActivateFailed(new Error(' '))
          clearLoading()
        }
        else if (data.uuid) { // is bkg/worker job
          setBkgId(data.uuid)
        }
        else {
          await onActivated(data)
          clearLoading()
        }
      }
      catch (e) {
        await onActivateFailed(e)
        clearLoading()
      }
    })
  }, [onActivateClicked, validateFields, regions, domain_postfix, clearLoading, appNames, auth_code, serial_number, tenant_id, displayName, hasBundledTenant, showActivateProgress, hideActivateProgress, onActivateFailed, onActivated])

  useEffect(() => cancelSource.unmountCallback, [cancelSource])
  useEffect(() => {
    if (regions && Array.isArray(regions) && regions.length) {
      appInfoMap.metaRegions = [...regions]
    }
    else {
      appInfoMap.metaRegions = null
    }
  }, [appInfoMap, regions])

  const defaultInstanceName = useMemo(() => {
    if (appInfoMap.single) {
      const instanceName = `${support_account_name} - ${appInfoMap.single.display_name}`
      if (instanceName.length < 64) {
        return instanceName
      }
    }
    return support_account_name
  }, [appInfoMap.single, support_account_name])

  const hasErrors = hasFormErrors(getFieldsError)

  const newActivate = useCallback(({ appName, displayName, has_auth_code, isCob, isNova, onDiscovery }) => {
    if (has_auth_code) {
      showAuthCodeModal({ appName, displayName, onDiscovery })
    }
    else if (!isCob) {
      showAppActivateModal(appName)
    }
    else if (!isNova) { // is cob without authcode
      resetActivate() // consider keep a record?
      history.push(ACTIVATE, {
        referer: {
          pathname: ACTIVATE,
          state: history.location.state,
        },
        appName,
      })
    }
  }, [history, resetActivate, showAppActivateModal, showAuthCodeModal])

  useEffect(() => { // stop loading and callback once activate success or failed
    if (bkgId && activateResult && activateResult.bkgId === bkgId) {
      if (activateResult.error) {
        setBkgId()
        const message = activateResult.message || activateResult.error.message
        onActivateFailed({ message }).then(clearLoading, clearLoading)
      }
      else if (activateResult.data) {
        setBkgId()
        clearLoading()
        onActivated(activateResult.data, { message: activateResult.message })
      }
      else {
        setBkgId()
        clearLoading()
      }
      resetActivateResult()
    }
  }, [bkgId, activateResult, clearLoading, onActivated, onActivateFailed, resetActivateResult])

  useEffect(() => { // always validate first to have an initial required error
    if (!appInfoMap.isEmpty && selectedAccount >= 0) {
      validateFields({ force: true })
    }
  }, [appInfoMap, validateFields, selectedAccount])

  if (appInfoMap.isEmpty) {
    return null
  }

  const canSwitchAccount = !( // cannot switch account if:
    history.state?.selectedAccount >= 0 || // state has selectedAccount
    (appInfoMap.single?.isNova && !appInfoMap.single.usePubSub) || // nova non-pubsub app (can only change account via CSP)
    !initialValues.isEmpty // or has initial values (traps/magnifier upgrade, or retry after error)
  )

  return <>
    <Spin spinning={isLoading}>
      <Form key={appInfoMap.key} onSubmit={activateHandler} className='activate-form'>
        <div className='activate-form-body'>
          <AccountNameField
            {...formItemLayout}
            {...formMethods}
            support_account_id={support_account_id}
            support_account_name={support_account_name}
            tenant_id={tenant_id}
            display_name={displayName}
            appInfoMap={appInfoMap}
            canSwitchAccount={canSwitchAccount}
            supportAccounts={supportAccounts}
            available_account_ids={available_account_ids}
            switchAccount={switchAccount}
          />
          <InstanceNameField
            {...formItemLayout}
            {...formMethods}
            appInfoMap={appInfoMap}
            selectedAccount={selectedAccount}
            initialValues={initialValues}
            defaultValue={defaultInstanceName}
          />
          <DescriptionField
            {...formItemLayout}
            getFieldDecorator={getFieldDecorator}
            initialValues={initialValues}
          />
          <RegionAssociationsFields
            {...formItemLayout}
            {...formMethods}
            appInfoMap={appInfoMap}
            selectedAccount={selectedAccount}
            initialValues={initialValues}
            regions={regions}
            bundled_tenant={bundled_tenant}
            newActivate={newActivate}
            accountIsFederal={accountIsFederal}
          />
          <CustomFields
            {...formItemLayout}
            {...formMethods}
            fields={appInfoMap.single?.developer_defined_fields}
            fieldNamePrefix='developer_defined_fields'
            requiredByDefault={true}
            initialValues={initialValues}
          />
          <EulaField
            {...formItemLayout}
            getFieldDecorator={getFieldDecorator}
          />
        </div>
        <div className='form-footer activate-form-footer'>
          <div className='ant-form-item-required col-required-legend'></div>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            htmlType='submit'
            type='primary'
            disabled={hasErrors}
            loading={isLoading}
          >
            Agree & Activate
          </Button>
        </div>
      </Form>
    </Spin>
  </>
}

ActivateForm.propTypes = {
  appNames: PropTypes.arrayOf(PropTypes.string),
  history: PropTypes.object,
  form: PropTypes.object,
  initialValues: PropTypes.object,
  entitledAppsList: PropTypes.array,
  serial_number: PropTypes.string,
  tenant_id: PropTypes.string,
  user_email: PropTypes.string,
  is_eval: PropTypes.bool,
  selectedAccount: PropTypes.number,
  supportAccounts: PropTypes.array,
  displayName: PropTypes.string,
  available_account_ids: PropTypes.array,
  support_account_id: PropTypes.number,
  support_account_name: PropTypes.string,
  auth_code: PropTypes.string,
  bundled_tenant: PropTypes.object,
  domain_postfix: PropTypes.string,
  regions: PropTypes.array,
  accountIsFederal: PropTypes.bool,
  fetchEntitlements: PropTypes.func,
  activateResult: PropTypes.object,
  activateProgressModal: PropTypes.object,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  switchAccount: PropTypes.func,
  showAuthCodeModal: PropTypes.func,
  setInitialValues: PropTypes.func,
  resetActivate: PropTypes.func,
  showAppActivateModal: PropTypes.func,
  showActivateProgress: PropTypes.func,
  hideActivateProgress: PropTypes.func,
  resetActivateResult: PropTypes.func,
  onCancel: PropTypes.func,
  onActivated: PropTypes.func,
  onActivateClicked: PropTypes.func,
  onActivateFailed: PropTypes.func,
}

ActivateForm.defaultProps = {
  appNames: [],
  initialValues: EMPTY_INIT_VALUES,
  auth_code: '',
  serial_number: '',
  activateProgressModal: {},
}

export default Form.create()(ActivateForm)
