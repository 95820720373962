import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '@pan/cloud-base'

const EulaField = ({
  getFieldDecorator,
  ...restProps
}) => {
  return (
    <Form.Item
      {...restProps}
      label='EULA'
    >
      {
        getFieldDecorator('is_eula_accepted', {
          initialValue: true
        })(
          <span className='ant-form-text ant-form-extra'>By clicking “Agree & Activate”, you accept the terms of the <a href='https://www.paloaltonetworks.com/legal/eula' rel='noopener noreferrer' target="_blank">End User License Agreement</a>.</span>
        )
      }
    </Form.Item>
  )
}

EulaField.propTypes = {
  getFieldDecorator: PropTypes.func,
}

export default React.memo(EulaField)
