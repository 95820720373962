export default {
  name: 'prisma_cloud',
  display_name: 'Prisma™ Cloud',
  developer: 'Palo Alto Networks, Inc.',
  developer_link: 'https://www.paloaltonetworks.com/',
  summary: 'Prisma™ Cloud redefines effective security and compliance management through a combination of automation and machine learning to deliver unprecedented visibility and control across even the most complex, distributed multi-cloud environments.',
  product_link: 'https://www.paloaltonetworks.com/prisma/cloud',
  first_release: 'October 2018',
  latest_release: 'July 2019',
  system_requirements: 'NA',
  custom_activate_btn: '', // no button
  heading: 'Public cloud threat protection, governance & compliance',
  body: [{
    paragraphs: [
      'Prisma Cloud dynamically discovers cloud resources and sensitive data across AWS, Azure, and Google Cloud Platform to detect risky configurations and identify network threats, suspicious user behavior, malware, data leakage, and host vulnerabilities. It eliminates blind spots across your cloud environments and provides continuous protection with a combination of rule-based security policies and advanced machine learning. It also vastly simplifies the task of managing compliance across the multi-cloud landscape and supports audit-ready reports across the industries’ most complete library of supported frameworks (CIS, NIST, PCI, HIPAA, GDPR, ISO, SOC 2, and more).',
    ]
  }, {
    content: [
      { p: 'Key Benefits:' },
      {
        ul: [
          'Accelerate threat detection with a deep, contextual understanding of risks such as seeing publicly exposed instances with vulnerable exploits, currently receiving traffic from suspicious IP addresses.',
          'Build policy guardrails for dev teams and detect anomalous IAM activity to quickly identify compromised developer credentials, malicious insider activity, and other suspicious behaviors',
          'Meet data and regulatory mandates with the industry’s most complete library of policies and compliance frameworks for audit-ready reports available with just a single click.',
        ]
      },
    ]
  }]
}
