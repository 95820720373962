import React from 'react'
import PropTypes from 'prop-types'
import Lightbox from './Lightbox'
import { isNonemptyArray } from '../../utils/common'

const SimpleGallery = ({ images = [] }) => {
  if (!isNonemptyArray(images)) {
    return false
  }
  if (images.length > 4) { // hard limit for now
    images.length = 4
  }
  const colCls = `col-${12 / images.length}`
  return (
    <div className='row gallery_image_urls'>
      {images.map((img) =>
        <div key={img} className={colCls}>
          <Lightbox
            className='screenshot'
            img={img}
            alt='screenshot'
          />
        </div>
      )}
    </div>
  )
}

SimpleGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string)
}

export default SimpleGallery
