// import './polyfills'
import './styles/fake-root.scss'
import './styles/lato.css'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
//import './index.css'
//import registerServiceWorker from './registerServiceWorker'
import App, { utils } from '@pan/cloud-appframework'
import appConfig from './config'

//portal specific
import './styles/portal.scss'

document.title = `${appConfig.settings.name} - Palo Alto Networks`
utils.setupAxiosInterceptors(axios, { ignore: /^\/actionservice\// })
ReactDOM.render(<App appConfig={appConfig} />, document.getElementById('root'))
//registerServiceWorker()
