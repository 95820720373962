import React from 'react'
import PANPage from '../../../components/PANPage'
import { Button, Col, Row } from '@pan/cloud-base'
import { RBAC_CUSTOMIZE_ROLES, RBAC_CORTEX_XDR_ROLES_DESC, RBAC_ADD_CUSTOM_ROLE } from '../../../constants/AppConstants'
import RolePageComponent from './RolePageComponent'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as CustomRoleAction from '../../../config/actions/custom_roles/customRoleActions'
import './../RBACcommon.scss'
import { ROLES as ROLE_ROUTE } from '../../../constants/RouteConstants'

const CustomRoleDefPage = (props) => {
  const { selectedApp } = props
  return (
    <Row type={'flex'} style={{ background: 'white', width: '100%', height: '100%' }}>
      <Col xs={24} md={20} sm={24} lg={20} >
        <PANPage hideSidePanel={true}
          style={{ height: '100%' }}
          mainContentProps={{
            showGoBackLink: true,
            goBackTo: ROLE_ROUTE,
            showPageHeader: true,
            header: `${RBAC_CUSTOMIZE_ROLES} ${selectedApp?.display_name || ''}`,
            subHeader: <div className={'hbox space-between'}>{RBAC_CORTEX_XDR_ROLES_DESC}
              <Button icon={'plus'} type={'primary'} onClick={() => props.openCloseCustomRoleDiag(true)}>{RBAC_ADD_CUSTOM_ROLE}</Button></div>,
            subheadClassName: 'roleAccess_SubHeader',
            goBackLabel: 'Access Management',
            style: { background: 'white' }
          }}
          mainContent={<RolePageComponent {...props} />}
        >
        </PANPage>
      </Col>
    </Row>
  )
}

CustomRoleDefPage.propTypes = {
  selectedApp: PropTypes.object,
  openCloseCustomRoleDiag: PropTypes.func
}
/**
 *
 * @type {{openCloseCustomRoleDiag: CustomRoleDefPage.defaultProps.openCloseCustomRoleDiag}}
 */
CustomRoleDefPage.defaultProps = {
  openCloseCustomRoleDiag: () => {}
}

const mapStateToProps = state => {
  const { rbacCustomRole = {}, isAuthenticated, appLoadingState, isLoading, rbac } = state
  const { rbacSchema = [],
    roleDef,
    isRoleSearching,
    filteredRoleDef,
    schemaPerApp,
    schemaTree,
    custom_role,
    isCustomRoleDialOpen,
    roleToBeView,
    dialogActionType,
    customRoleDialogTitle,
    isCustomRoleTableLoading,
    confirmationDialogProps
  } = rbacCustomRole
  const { selectedApp } = rbac
  return {
    rbacSchema,
    roleDef,
    isRoleSearching,
    filteredRoleDef,
    schemaPerApp,
    schemaTree,
    isAuthenticated,
    custom_role,
    isCustomRoleDialOpen,
    roleToBeView,
    dialogActionType,
    customRoleDialogTitle,
    isCustomRoleTableLoading,
    confirmationDialogProps,
    selectedApp,
    isLoading,
    ...appLoadingState
  }
}


const mapDispatchToProps = { ...CustomRoleAction }
export default connect(mapStateToProps, mapDispatchToProps)(CustomRoleDefPage)
