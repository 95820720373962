import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppAccessSideBar from './AppAccessSideBar'
import AssignRoleContent from './AssignRoleContent'
import PANPage from '../../components/PANPage'
import { ACCESS_MANAGEMENT, ASSIGN_ROLES_SUB_HEADER } from './../../constants/AppConstants'

import './../../common.scss'
import './RBACcommon.scss'

/**
 * RoleAccess that is main page to access RBAC ui
 */
class RoleAccess extends Component {
  constructor(args) {
    super(args)
    this.state = {
      selectedAccount: null
    }
  }

  componentDidUpdate(preProps) {
    const { selectedAccount: prevSelectedAccount } = preProps
    const { selectedAccount } = this.props

    if (prevSelectedAccount !== selectedAccount) {
      this.componentDidMount()
    }
  }
  componentDidMount() {
    const { selectedFilter } = this.props.rbac
    let query = null
    if (selectedFilter) {
      const filterQ = selectedFilter.split('-').length > 0 ? selectedFilter.split('-')[1] : ''
      query = { filter: filterQ }
    }
    this.props.getAllRoles(query)
    this.props.handleFilterChange(selectedFilter)
    this.props.getSchemaDef()
    this.props.getRoleDef()
  }

  render() {
    const { getAllRoles, handleFilterChange } = this.props
    const { rbac_entitlements, selectedFilter } = this.props.rbac
    return (
      <PANPage sidePanelProps={{ width: 240 }}
        mainContentProps={{
          showPageHeader: true,
          header: ACCESS_MANAGEMENT,
          subHeader: ASSIGN_ROLES_SUB_HEADER,
          subheadClassName: 'roleAccess_SubHeader'
        }}
        sidePanel={<AppAccessSideBar roles={rbac_entitlements}
          selectedFilter={selectedFilter}
          getAllRoles={getAllRoles}
          handleFilterChange={handleFilterChange}/>}
        mainContent={<AssignRoleContent {...this.props.rbac} {...this.props} />} />
    )
  }
}

RoleAccess.propTypes = {
  rbac: PropTypes.object,
  /**
   * @sidePanelProps [Sider]https://ant.design/components/layout/#components-layout-demo-side
   * this will be all sider props in antd
   */
  sidePanelProps: PropTypes.object,
  getAllRoles: PropTypes.func,
  selectedAccount: PropTypes.number,
  handleFilterChange: PropTypes.func,
  getSchemaDef: PropTypes.func,
  selectedCSPAccount: PropTypes.object,
  getRoleDef: PropTypes.func,
}

export default RoleAccess
