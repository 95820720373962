import React, { useState, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { Modal, Form, Spin, Input, Button, Message } from '@pan/cloud-base'
import {
  ACTIVATE,
  REFRESH_ENTITLEMENTS_AFTER,
  fetchEntitlements,
  isNonemptyArray,
  encodeAuthCode,
  genCancelSource,
  allowInstanceAdmin,
  getNovaSetupPathState,
} from '../../utils/activateUtils'
import './AuthCodeModal.scss'

const FIELD_NAME = 'auth_code'
export const discovery = async (authcode, selectedAccount, cancelToken) => {
  const newError = (msg, status = 400) => Object.assign(new Error(msg), { status })
  if (!authcode) {
    throw newError('Auth code is not given')
  }
  const auth_code = authcode.trim()
  try {
    const postData = {
      selectedAccount,
      auth_code,
    }
    const resp = await axios.post('/hub/v2/discovery', postData, { cancelToken })
    const {
      issuccess,
      can_activate = true,
      isactivated,
      isorchestrated,
      application_name,
      error_message,
      redirects,
    } = resp.data || {}
    if (redirects?.startsWith('/')) {
      return { redirects }
    }
    if (!can_activate) {
      throw newError('Invalid auth code input.')
    }
    else if (!issuccess) {
      throw newError(error_message || 'Invalid auth code input.')
    }
    else if (isactivated && isorchestrated) {
      throw newError('This auth code has been activated already.')
    }
    else if (!application_name) {
      throw newError('Bad auth code without application name.')
    }
    const appNames = [application_name]
    return { ...resp.data, auth_code, appNames }
  }
  catch (error) {
    if (error.status === 400 || cancelToken.reason) {
      throw error
    }
    const message = error?.response?.data?.message || error.message || 'service temporarily unavailable'
    const status = error?.response?.status || error.status || 500
    throw newError(message, status)
  }
}

export const AuthCodeModal = ({
  visible,
  isAuthenticated,
  selectedAccount,
  authcode,
  appName,
  displayName,
  error,
  history,
  form: {
    getFieldDecorator,
    getFieldValue,
    getFieldError,
    setFields,
    isFieldTouched,
  },
  entitlementsLoaded,
  entitledAppsList,
  fetchEntitlements,
  clearAuthCodeError,
  hideAuthCodeModal,
  resetAuthCodeModal,
  appActivateViaAuthcode,
  onDiscovery,
}) => {
  const [isLoading, setLoading] = useState(false)
  const cancelSource = useMemo(genCancelSource, [])
  const lookupPath = useCallback((input, selectedAccount) => {
    if (!input) {
      return null
    }
    if (entitledAppsList._activateLookupMap) {
      return entitledAppsList._activateLookupMap.get(input.toLowerCase())
    }
    const activateLookupMap = entitledAppsList.reduce((map, app) => {
      const { name, display_name, app_id, enabled, has_auth_code, activatable, isNova, isFake, isCob } = app
      if (enabled && activatable !== false && !has_auth_code && !isFake) {
        if (isCob && !isNova) {
          const pathInfo = { appName: name }
          map.set(name.toLowerCase(), pathInfo)
          if (name !== display_name) {
            map.set(display_name.toLowerCase(), pathInfo)
          }
          if (name !== app_id) {
            map.set(app_id, pathInfo)
          }
          return map
        }
        if (isNova && isNonemptyArray(app.tenants)) {
          for (const tenant of app.tenants) {
            const pathInfo = allowInstanceAdmin(app, tenant) && getNovaSetupPathState(tenant, app, selectedAccount)
            if (pathInfo) {
              map.set(tenant.serial_number.toLowerCase(), pathInfo)
            }
          }
          return map
        }
      }
      return map
    }, new Map())
    Object.defineProperties(entitledAppsList, {
      _activateLookupMap: { value: activateLookupMap }
    })
    return activateLookupMap.get(input.toLowerCase())
  }, [entitledAppsList])
  const discoveryHander = useCallback(async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault()
    }
    setLoading(true)
    const auth_code = getFieldValue(FIELD_NAME).trim()
    const foundPathInfo = lookupPath(auth_code, selectedAccount)
    if (foundPathInfo) {
      history.push(ACTIVATE, {
        referer: {
          pathname: history.location.pathname,
          state: history.location.state,
        },
        ...foundPathInfo,
      })
      setLoading(false)
      hideAuthCodeModal()
      return
    }
    const setErrMsg = msg => setFields({ auth_code: { value: auth_code, errors: msg ? [msg] : [] } })
    try {
      const data = await discovery(auth_code, selectedAccount, cancelSource.token)
      if (data.redirects) {
        hideAuthCodeModal()
        history.push(data.redirects)
        return
      }
      if (appName && data.application_name !== appName) {
        throw Object.assign(new Error(`The auth code is not for ${displayName}`), { status: 400 })
      }
      if (onDiscovery && onDiscovery(data, { hide: hideAuthCodeModal }) === false) {
        return
      }
      if (Date.now() - entitlementsLoaded > REFRESH_ENTITLEMENTS_AFTER) {
        await fetchEntitlements() // ensure have latest instances and meta
      }
      appActivateViaAuthcode(data)
      hideAuthCodeModal()
    }
    catch (error) {
      if (error.status === 400) {
        setErrMsg(error.message || 'Invalid auth code input.')
      }
      else if (!cancelSource.token.reason) {
        setErrMsg()
        Message.error(error.message || 'service temporarily unavailable', 3)
      } // ignore cancel
    }
    finally {
      if (!cancelSource.token.reason) {
        setLoading(false)
        clearAuthCodeError() // remove error in redux
      }
    }
  }, [getFieldValue, lookupPath, history, hideAuthCodeModal, setFields, selectedAccount, cancelSource.token, appName, onDiscovery, entitlementsLoaded, displayName, fetchEntitlements, appActivateViaAuthcode, clearAuthCodeError])

  useEffect(() => cancelSource.unmountCallback, [cancelSource])

  if (!isAuthenticated) {
    return null
  }

  const fieldError = error && !isFieldTouched(FIELD_NAME) ? error.message : getFieldError(FIELD_NAME)
  return <Modal
    width={500}
    className='auth-code-modal'
    title={`Activate ${displayName || ' App'}`}
    visible={visible}
    onCancel={hideAuthCodeModal}
    afterClose={resetAuthCodeModal}
    maskClosable={false}
    destroyOnClose={true}
    closable={!isLoading}
    keyboard={!isLoading}
    footer={null}
  >
    <Spin spinning={isLoading}>
      <Form onSubmit={discoveryHander}>
        <label htmlFor={FIELD_NAME} className='authcode-label'>Enter the {displayName || 'product'} auth code you received by email</label>
        <Form.Item
          validateStatus={fieldError ? 'error' : ''}
          help={fieldError || ''}
        >
          {
            getFieldDecorator(FIELD_NAME, {
              initialValue: authcode || '',
              rules: [
                { required: true, message: 'Auth Code is required', whitespace: true },
                { pattern: /^[a-zA-Z0-9]*$/, message: 'Auth Code is not valid' }
              ]
            })(<Input placeholder='########' autoFocus={true} readOnly={isLoading} />)
          }
        </Form.Item>
        <div>If you received a link from the email instead of the auth code, <br />please visit the link directly to activate your products.</div>
        <Button
          type='primary'
          loading={isLoading}
          disabled={!authcode && !getFieldValue(FIELD_NAME)}
          onClick={discoveryHander}
        >OK</Button>
      </Form>
    </Spin>
  </Modal>
}

AuthCodeModal.propTypes = {
  form: PropTypes.object.isRequired,
  visible: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedAccount: PropTypes.number,
  authcode: PropTypes.string,
  appName: PropTypes.string,
  displayName: PropTypes.string,
  error: PropTypes.object,
  history: PropTypes.object,
  entitledAppsList: PropTypes.array,
  entitlementsLoaded: PropTypes.number,
  clearAuthCodeError: PropTypes.func,
  hideAuthCodeModal: PropTypes.func,
  resetAuthCodeModal: PropTypes.func,
  fetchEntitlements: PropTypes.func,
  appActivateViaAuthcode: PropTypes.func,
  onDiscovery: PropTypes.func,
}

AuthCodeModal.defaultProps = {
  authcode: '',
  entitledAppsList: [],
}

const mapStateToProps = ({
  isLoading,
  isAuthenticated,
  selectedAccount,
  entitledAppsList,
  authCodeModal: { visible, authcode, ...rest },
  appActivate: { entitlementsLoaded },
}) => ({
  isLoading,
  isAuthenticated,
  selectedAccount,
  visible: Boolean(visible),
  entitledAppsList,
  entitlementsLoaded,
  authcode,
  ...rest,
})

const mapDispatchToProps = (dispatch) => ({
  clearAuthCodeError: (authcode) => dispatch({ type: 'SHOW_AUTHCODE_ACTIVATE_MODAL', authcode }),
  hideAuthCodeModal: () => dispatch({ type: 'HIDE_AUTHCODE_ACTIVATE_MODAL' }),
  resetAuthCodeModal: () => dispatch({ type: 'RESET_AUTHCODE_ACTIVATE_MODAL' }),
  fetchEntitlements: (options) => dispatch(fetchEntitlements(options)),
  appActivateViaAuthcode: (data) => dispatch({ type: 'APP_ACTIVATE_VIA_AUTHCODE', data }),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    clearAuthCodeError: () => stateProps.error && dispatchProps.clearAuthCodeError(stateProps.authcode),
    appActivateViaAuthcode: (data) => {
      // ensure go back when switch account if has initialValues
      const selectedAccount = !_.isEmpty(data.initialValues) && stateProps.selectedAccount
      dispatchProps.appActivateViaAuthcode(data)
      const { pathname, state } = ownProps.history.location
      const referer = pathname === ACTIVATE && state &&
        (state.appName || state.referer) ? { pathname, state } : pathname
      ownProps.history.push(ACTIVATE, {
        reference: encodeAuthCode(data.auth_code),
        display: data.display_name,
        referer,
        ...(selectedAccount && { selectedAccount }),
      })
    },
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(Form.create()(AuthCodeModal)))
