import React, { useState, useEffect } from 'react'
import { Input, Form, Button, Icon } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import ConfirmationDialog from '../../../components/common/ConfirmationDiaglog'
import RBACScopeTree from '../../../components/RBAC/RBACScopeTree'
import { uniq, toAppId as lowerSnakeCase } from '../../../utils/common'
import {
  R_APP,
  R_CUSTOM,
  R_DESCRIPTION,
  R_ROLE,
  R_SCOPE,
  R_TENANT_ID,
  R_ROLE_LABEL,
  R_TYPE,
  R_USER,
  R_VERSION,
  RBAC_ROLE_DESCRIPTION,
  RBAC_ROLE_SELECT_PERMISSION,
  RBAC_ROLE_NAME,
  RBAC_CUSTOM_ROLE_ALREADY_USED,
} from '../../../constants/AppConstants'
import './RoleForm.scss'

const FormItem = Form.Item
const { TextArea } = Input

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 },
  },
}


const handleFormValidation = ({ role, role_label, description, scopes, app, version, userRoleTenant_id, roleToBeView }, createCustomRole, hideForm) => {
  /**
   * * hideForm flag decide whether we need to use role type user vs custom
   * @type {{[p: string]: string|*, '[R_APP]': *, '[R_ROLE]': *, '[R_SCOPE]': *, '[R_DESCRIPTION]': *, '[R_TYPE]': (string), '[R_VERSION]': *}}
   */
  const customRole = {
    [R_TYPE]: hideForm ? R_USER : R_CUSTOM,
    [R_VERSION]: version,
    [R_APP]: app,
    [R_ROLE_LABEL]: role_label,
    [R_ROLE]: role,
    [R_DESCRIPTION]: description,
    [R_SCOPE]: uniq(scopes),
    [R_TENANT_ID]: userRoleTenant_id
  }
  // if (hideForm) {
  //
  // }
  createCustomRole(customRole)
}


const CustomRoleDialog = (props) => {
  const { hideForm,
    onSaveAsNewRole,
    userRoleTenant_id,
    tenantSkus,
    schemaPerApp,
    schemaTree,
    createCustomRole,
    roleToBeView,
    disabledTree,
    onTreeChecked,
    roleDef,
    dialogActionType,
    todayDate,
    ...diagProps } = props
  /**
   * if @hideForm is true then only show table and checkbox.
   */
  const { app, version, restricted_role_names = [] } = schemaPerApp || {}
  const [role_label, setRoleLabel] = useState('')
  const [role, setRoleName] = useState('')
  const [description, setDescription] = useState('')
  const [scopes, setScopes] = useState([])
  const [checkedKeys, setCheckedKeys] = useState(roleToBeView.checkedNode || [])

  /**
   * Prevent end-user to create custom role just like predefined role.
  */
  const _roleDef = restricted_role_names.map((role) => lowerSnakeCase(role))

  roleDef.forEach(eachRole => {
    if (eachRole.type !== 'custom') {
      _roleDef.push(lowerSnakeCase(eachRole.role_label))
      _roleDef.push(eachRole.role)
    }
  })
  /**
   * Make sure role are duplicated hence prevent user to create duplicate role
   */

  const isRoleNotPresent = (dialogActionType === 'create' || dialogActionType === 'edit') && (_roleDef.includes(lowerSnakeCase(role_label)))
  let buttonDisabled
  if (dialogActionType === 'view_role' || dialogActionType === 'read_only') {
    buttonDisabled = true
  }
  else {
    buttonDisabled = hideForm ? Boolean(!scopes.length) : !(role_label.trim().length > 0 &&
      scopes.length > 0 && !isRoleNotPresent)
  }
  const okButtonProps = {
    disabled: buttonDisabled
  }

  const saveNewRoleOnClick = () => {
    //update local state [role_label and role_name] first, before reducer state update to avoid error custom role already exist label flicker
    setRoleLabel('')
    setRoleName('')
    onSaveAsNewRole()
  }

  useEffect(() => {
    const { role, role_label, description, checkedNode, scope } = roleToBeView
    setRoleLabel(role_label || '')
    setRoleName(role || '')
    setDescription(description || '')
    setCheckedKeys(checkedNode || [])
    setScopes(scope || [])
  }, [roleToBeView])

  const { onCancel } = diagProps
  const footerProps = [
    <Button key={'cancel'} onClick={onCancel} >Cancel</Button>,
    hideForm ? <Button key={'saveNewRole'} onClick={saveNewRoleOnClick}>Save As New Role</Button> : null,
    <Button key={'onOk'} {...okButtonProps}
      onClick={handleFormValidation.bind(null, {
        role,
        role_label,
        description,
        scopes: scopes,
        app,
        version,
        userRoleTenant_id,
        roleToBeView },
      createCustomRole,
      hideForm)}
    >Save</Button>
  ]
  return (
    <ConfirmationDialog style={{ top: 20 }} {...diagProps}
      footer={footerProps}
    >
      {
        hideForm ? <div>
          <div style={{ padding: '23px 0px 21px 12px' }}>
            Please select the permissions you want the current user(s) to have.
          </div>
          <RBACScopeTree defaultExpandParent={true}
            checkable={true}
            disabledTree={disabledTree}
            checkedKeys={checkedKeys}
            schema={schemaPerApp}
            schemaTree={schemaTree}
            tenantSkus={tenantSkus}
            onCheck={onTreeChecked.bind(null, { ...roleToBeView, description, role_label: role_label, role: role })}/>
        </div> : <Form style={{ overflowY: 'auto' }}>
          <FormItem required={true}
            validateStatus={ !disabledTree ? isRoleNotPresent ? 'error' : 'success' : null }
            help={isRoleNotPresent ? <span><Icon type="exclamation-circle" color={'red'} theme="filled" /> {RBAC_CUSTOM_ROLE_ALREADY_USED } </span> : '' }
            hasFeedback {...formItemLayout} label={RBAC_ROLE_NAME}>
            {disabledTree ? <label className={'role_label'}>{role_label}</label> : <Input autoFocus placeholder={'Role Label'} value={role_label} onChange={e => {
              // do not edit role when user edit existing role
              const { value } = e.target
              const role = value.replace(/[^\w\s]/gi, '')
              setRoleLabel(role)
              if (dialogActionType !== 'edit') {
                // create role name from role label what user types
                setRoleName(role.toLowerCase().replace(/ /g, '_'))
              }
            }}/> }
          </FormItem>
          <FormItem {...formItemLayout} hasFeedback label={RBAC_ROLE_DESCRIPTION}>
            {disabledTree ? <label className={'role_label'}>{description}</label> : <TextArea placeholder={`Role Description \nCreated on ${todayDate}`} value={description} row={4} onChange={e => setDescription(e.target.value)} /> }
          </FormItem>
          <FormItem required={true} {...formItemLayout} label={RBAC_ROLE_SELECT_PERMISSION}>
            <RBACScopeTree defaultExpandParent={true}
              checkable={true}
              schema={schemaPerApp}
              disabledTree={disabledTree}
              schemaTree={schemaTree}
              checkedKeys={checkedKeys}
              tenantSkus={tenantSkus}
              onCheck={onTreeChecked.bind(null, { ...roleToBeView, description, role_label: role_label, role: role })}
            />
          </FormItem>
        </Form>
      }
    </ConfirmationDialog>
  )
}

/**
 *
 * @type {{onSaveAsNewRole: CustomRoleDialog.defaultProps.onSaveAsNewRole, roleToBeView: {}, onTreeChecked: CustomRoleDialog.defaultProps.onTreeChecked, todayDate: string, disabledTree: boolean, roleDef: []}}
 */
CustomRoleDialog.defaultProps = {
  disabledTree: false,
  roleToBeView: {},
  onSaveAsNewRole: () => {},
  onTreeChecked: () => {},
  todayDate: new Date().toDateString(),
  roleDef: []
}

/**
 *
 * @type {{onSaveAsNewRole: *, createCustomRole: *, roleToBeView: *, hideForm: *, userRoleTenant_id: *, onTreeChecked: *, schemaTree: *, todayDate: *, schemaPerApp: *, disabledTree: *, roleDef: *, dialogActionType: *}}
 */
CustomRoleDialog.propTypes = {
  hideForm: PropTypes.bool,
  schemaPerApp: PropTypes.object,
  schemaTree: PropTypes.array,
  createCustomRole: PropTypes.func,
  roleToBeView: PropTypes.object,
  disabledTree: PropTypes.bool,
  userRoleTenant_id: PropTypes.string,
  tenantSkus: PropTypes.array,
  onSaveAsNewRole: PropTypes.func,
  onTreeChecked: PropTypes.func,
  roleDef: PropTypes.array,
  dialogActionType: PropTypes.string,
  todayDate: PropTypes.string

}

export default CustomRoleDialog
