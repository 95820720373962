import { Col, Row } from '@pan/cloud-base'
import React from 'react'
import { FT } from '@pan/cloud-appframework'
import { LOGGING_SERVICE_DISPLAY_NAME, LOGGING_SERVICE_DISPLAY_NAME2 } from '../../constants/AppConstants'

/**
 *
 * Created by vsuthar on 11/15/18 File HelpText
 * Project: App Portal ©PaloAlto Networks
 */

const HelpText = (type) => {
  switch (type) {
    case 'retentionPrd': {
      return (
        <Row>
          <Col>
            {`Enter the maximum number of days for which you want to store logs on the ${FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : LOGGING_SERVICE_DISPLAY_NAME}.`}
          </Col>
          <br />
          <Col>
            Should you run out of storage space, the oldest logs are overwritten by new logs.
          </Col>
        </Row>
      )
    }
    case 'quantity':
      return (
        <Row>
          <Col>
            <p><b>Quantity</b>{`:Enter a value for the number of firewalls that will send logs to the ${FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : LOGGING_SERVICE_DISPLAY_NAME}`}.<br/>
              For every pair of firewalls configured in a high availability setup, enter the quantity count as 1.</p>
          </Col>
        </Row>
      )
    case 'bladeCount':
      return (
        <Row>
          <Col>
            <p><b>Blade Count</b>: Number of Network Processing Card (NPC) on the PA-7000 and PA-5400 series firewalls</p>
          </Col>
        </Row>
      )
    default:
      return null
  }
}

export default HelpText
