import React from 'react'
import PropTypes from 'prop-types'
import AppsViewer from './AppsViewer'
import { APPLICATION_FRAMEWORK_DISPLAY_NAME, LOGGING_SERVICE_DISPLAY_NAME2 } from '../../constants/AppConstants'

const ThirdPartyApps = ({ apps, isAuthenticated, platformName }) => (
  <div id='other' className='other'>
    <AppsViewer
      isAuthenticated={isAuthenticated}
      title={`Explore Apps from Partners for ${LOGGING_SERVICE_DISPLAY_NAME2}`}
      apps={apps}
    />
  </div>
)

ThirdPartyApps.propTypes = {
  isAuthenticated: PropTypes.bool,
  apps: PropTypes.array,
  platformName: PropTypes.string,
}

ThirdPartyApps.defaultProps = {
  isAuthenticated: false,
  apps: [],
  platformName: APPLICATION_FRAMEWORK_DISPLAY_NAME,
}

export default ThirdPartyApps
