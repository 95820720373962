import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Col, Row, CheckableTag } from '@pan/cloud-base'
import './ToggleButton.css'

class ToggleIconButton extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      checked: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  /**
   *
   * @param checked True or False when Tag button being clicked
   */
  handleChange = checked => {
    this.setState({
      checked
    }, () => {
      const { itemID } = this.props
      this.props.handleToggled({ checked, itemID })
    })
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { checked } = this.state
    const { title, handleToggled, ...others } = this.props
    return (
      <Row type='flex' justify='center'>
        <div className='avatar-box'>
          <CheckableTag checked={ checked } {...others} className={this.props.className} onChange={this.handleChange}/>
          <Col className='toggle-btn-title'>
            {title}
          </Col>
        </div>
      </Row>
    )
  }
}

/**
 *
 * @type {{children: *, title: *, className: *}} PropTypes validation
 */

ToggleIconButton.propTypes = {
  children: PropTypes.any,
  title: PropTypes.any,
  className: PropTypes.string,
  itemID: PropTypes.string,
  handleToggled: PropTypes.func
}

/**
 *
 * @type {{handleToggled: ToggleIconButton.defaultProps.handleToggled}}
 */
ToggleIconButton.defaultProps = {
  handleToggled: () => {}
}

export default ToggleIconButton
