/**
 *
 * Created by vsuthar on 10/5/18 File appLoadingState
 * Project: App Portal ©PaloAlto Networks
 */

/**
 *
 * @type {{appLoading: boolean}}
 */


import { APP_LOADING_STATE, ERROR_OCCURRED } from './../actions/ActionConstants'
const defaultState = {
  appLoading: false,
  error: {
    message: undefined
  }
}
/**
 *
 * @param state
 * @param action
 * @returns {{appLoading: boolean}}
 */
const appLoadingState = (state = { ...defaultState }, action) => {
  switch (action.type) {
    case APP_LOADING_STATE:
      return { ...state, appLoading: action.isLoading }
    case ERROR_OCCURRED:
      return { ...state, error: action.error }
    default:
      return state
  }
}
export default appLoadingState
