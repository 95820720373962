import React from 'react'
import AppsViewer from './AppsViewer'
import PropTypes from 'prop-types'

const MoreApps = ({ apps, isAuthenticated }) =>
  <div id='more' className='more'>
    <AppsViewer
      isAuthenticated={isAuthenticated}
      title='Explore Apps from Palo Alto Networks'
      apps={apps}
    />
  </div>

MoreApps.propTypes = {
  isAuthenticated: PropTypes.bool,
  apps: PropTypes.array
}

MoreApps.defaultProps = {
  isAuthenticated: false,
  apps: []
}

export default MoreApps
