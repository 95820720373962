/**
 *
 * Created by vsuthar on 11/5/18 File SearchAssignRole
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import { Modal, Row, Col, Form, Select } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import UserAutoComplete from '../UserAutoComplete'
import { RBAC_APP_INSTANCES, RBAC_ROLE, RBAC_SEARCH_USER } from '../../../constants/AppConstants'

const ASSIGN_ROLE = 'Search & Assign Roles to Users'
const { Item: FormItem } = Form
const { Option } = Select

class SearchAssignRole extends PureComponent {
  constructor(args) {
    super(args)
    this.handleSelectionRoleChange = this.handleSelectionRoleChange.bind(this)
  }

  handleSelectionRoleChange(value, option) {
    const { rbac_app_type } = this.props.selectedApp
    const { selectedInstanceRole } = this.props.usersAutoCompleteProps
    selectedInstanceRole(value, option, rbac_app_type)
  }

  getAppType() {
    const { selectedApp, selectedFilter } = this.props
    const { name } = selectedApp || {}
    if (selectedApp && selectedFilter) {
      const arr = selectedFilter.split(':')
      if (arr.length) {
        let tenentId = null
        arr.forEach(item => {
          if (!isNaN(parseInt(item, 10))) {
            tenentId = parseInt(item, 10)
          }
        })

        if (tenentId) {
          return selectedApp.access.map((instance) => {
            if (parseInt(instance.tenant_id, 10) === tenentId) {
              return this.getDropDownMenu(instance)
            }
            return null // we can return all the instances here. But we are not doing it cause UX not required that.
          })
        }
        else if (selectedFilter.indexOf(name) > -1) {
          const appAdminRolePerApp = selectedApp.access.find(item => item.name === 'All Instances')
          return this.getDropDownMenu(appAdminRolePerApp)
        }
      }
      else {
        return null
      }
    }
    return null

  }

  getDropDownMenu = (instance) => {
    if (instance) {
      const { name, tenant_id, rbac_roles, rbac_role_selected } = instance
      return <Row>
        <Col>
          <FormItem key={'appInstances'} label={RBAC_APP_INSTANCES} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <label>{name || tenant_id}</label>
          </FormItem>
        </Col>
        <Col>
          <FormItem key={tenant_id} label={RBAC_ROLE} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Select style={{ width: '100%' }}
              value={rbac_role_selected}
              onChange={(value) => this.handleSelectionRoleChange(value, instance)}>
              {rbac_roles.map((role, idx) => {
                return <Option disabled={role.disabled} key={`${role._rid}:${idx}`} value={role._rid}>{role.name}</Option>
              })}
            </Select>
          </FormItem>
        </Col>
      </Row>
    }
    else {
      return null
    }

  }

  render() {
    const { usersAutoCompleteProps, ...rest } = this.props
    return (
      <Modal {...rest} title={ASSIGN_ROLE}>
        <Row type={'flex'}>
          <Col xs={24}>
            <UserAutoComplete placeholder={RBAC_SEARCH_USER} {...usersAutoCompleteProps} />
          </Col>
          <Col xs={24}>
            <FormItem>Assign a role for the selected instance(s)</FormItem>
            {this.getAppType()}
          </Col>
        </Row>
      </Modal>
    )
  }
}

/**
 *
 * @type {{usersAutoCompleteProps: *, selectedApp: *, selectedFilter: *, closable: *, handleAutoCompleteSelection: *, selectedInstanceRole: *}}
 */
SearchAssignRole.propTypes = {
  usersAutoCompleteProps: PropTypes.object,
  selectedApp: PropTypes.object,
  selectedFilter: PropTypes.string,
  closable: PropTypes.bool,
  handleAutoCompleteSelection: PropTypes.func, // auto complete selection
  selectedInstanceRole: PropTypes.func,
  visible: PropTypes.bool, // dialog open close
}
/**
 *
 * @type {{usersAutoComplete: {}, handleAutoCompleteSelection: SearchAssignRole.defaultProps.handleAutoCompleteSelection, closable: boolean}}
 */
SearchAssignRole.defaultProps = {
  usersAutoCompleteProps: {},
  handleAutoCompleteSelection: () => {
  },
  closable: false,
  visible: false,

}

export default SearchAssignRole
