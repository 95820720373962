const currentInstanceActionModal = (state = { visible: false }, action) => {
  switch (action.type) {
    case 'SHOW_APP_INSTANCE_ACTION_MODAL':
      return {
        ...action.instance,
        action: action.action,
        visible: true,
      }
    case 'HIDE_INSTANCE_ACTION_MODAL':
      return { ...state, visible: false }
    case 'RESET_INSTANCE_ACTION_MODAL':
      return { visible: false }
    default:
      return state
  }
}

export default currentInstanceActionModal
