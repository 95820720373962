import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, Input } from '@pan/cloud-base'

const FIELD_NAME = 'description'
const DescriptionField = ({
  initialValues,
  getFieldDecorator,
  ...restProps
}) => {
  const initialValue = initialValues[FIELD_NAME]
  const hasInitialValues = false // !initialValues.isEmpty // TODO: change it when needed
  const decorator = useMemo(() => {
    return getFieldDecorator(FIELD_NAME, {
      initialValue,
      getValueProps: hasInitialValues ? () => ({
        value: initialValue,
        disabled: true,
      }) : undefined,
      rules: [
        { max: 1023, message: 'Description can have at most 1023 characters' }
      ],
    })
  }, [getFieldDecorator, hasInitialValues, initialValue])
  return (
    <Form.Item
      {...restProps}
      label='Description'
      validateStatus=''
    >
      {decorator(<Input.TextArea rows={2} maxLength={1024} />)}
    </Form.Item>
  )
}

DescriptionField.propTypes = {
  initialValues: PropTypes.object,
  getFieldDecorator: PropTypes.func,
}

export default DescriptionField
