import _ from 'lodash'
import { store as AppStore } from '@pan/cloud-appframework'
import { isNonemptyArray, indexArray } from '../../utils/common'
import { updateKnownRoles } from '../../utils/rbacUtils'
import { APP_PORTAL } from '../../constants/AppConstants'
import { checkAccountRbacRoles } from '../actions/rbacActions'

const getGeneratedStamp = apps => _.get(apps, [APP_PORTAL, 'generated'], 0)
const calcHash = (apps) => {
  const { hash, apps: aCount, tenants: tCount } = _.get(apps, APP_PORTAL, {})
  return hash ? `0${aCount.toString(36)}A${tCount.toString(36)}X${hash}` : ''
}

export const mergeApps = ({ _apps, _entitlements, _disabled, _loading } = {}) => {
  const _generated = getGeneratedStamp(_entitlements)
  const _hash = calcHash(_entitlements)
  const filteredEntitlements = _entitlements && _.pick(_entitlements, _.keys(_apps))
  const array = _.values(_.mergeWith({}, _apps, filteredEntitlements, (tar, src) => {
    if (Array.isArray(tar) && Array.isArray(src)) { // do not merge array
      return src
    }
  }))
    .filter(app => app.name && app.enabled !== false)
    .map((app) => ({
      ...app,
      flags: app.flags || {},
      display_name: app.display_name || app.name,
      role: app.role || (app.isThirdParty ? 'ANY' : app.name),
      regions: app.regions && indexArray(app.regions, 'name'),
      tenants: app.tenants && _disabled.size ?
        app.tenants.map(t => (_disabled.has(t.tenant_id) ? { ...t, instance_status: '' } : t)) :
        app.tenants,
    }))
  array._apps = _apps
  array._disabled = _disabled
  array._entitlements = _entitlements
  array._generated = _generated
  array._hash = _hash
  if (_loading) {
    array._loading = true
  }
  const map = array.reduce((map, app) => {
    map.set(app.name, app)
    if (app.app_id !== app.name) {
      map.set(app.app_id, app)
    }
    return map
  }, new Map())
  // for apps like DSS, but also avoid override like pa onprem exts
  for (const app of array) {
    if (app.license_name && app.license_name !== app.name && !map.has(app.license_name)) {
      map.set(app.license_name, app)
    }
  }
  Object.defineProperties(array, {
    _map: {
      value: map,
    },
    isAppsLoaded: {
      value: Boolean(array._apps && Object.keys(array._apps).length),
    },
    isEntitlementsLoaded: {
      value: Boolean(array._entitlements && Object.keys(array._entitlements).length),
    },
    isLoading: {
      value: Boolean(_loading),
    },
    get: {
      value: name => array._map.get(name)
    },
    has: {
      value: name => array._map.has(name)
    },
  })
  return array
}

export const linkExtensions = (apps) => {
  _.each(apps, (app) => {
    const { name, app_id, extension_to, associations, tenants } = app
    if (!isNonemptyArray(tenants)) {
      return // skip if no instance
    }
    if (extension_to && apps.has(extension_to) &&
      Array.isArray(associations) && associations.some(a => a.app_id === extension_to)) {
      const extToApp = apps.get(extension_to)
      const { tenants: extToTenants = [] } = extToApp
      if (!extToTenants.length) {
        return
      }
      extToApp.extentions = extToApp.extentions || []
      extToApp.extentions.push(app)
      for (const t of tenants) {
        t.application_name = name
        t.app_id = app_id
      }
      const extGroup = _.groupBy(tenants, t => {
        const tid = t.associations?.[extension_to]?.tenant_id
        return tid || ''
      })
      for (const t of extToTenants) {
        const extensions = extGroup[t.tenant_id]
        if (extensions) {
          if (t.extensions) {
            t.extensions.push(...extensions)
          }
          else {
            t.extensions = extensions
          }
        }
      }
    }
  })
  return apps
}

const initState = mergeApps({ _apps: {}, _disabled: new Set(), _loading: true })
const entitledAppsList = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_CREDENTIALS_NO_AUTH': // no login
    case 'SET_CURRENT_SUPPORT_ACCOUNT_INDEX': // switching account
      return mergeApps({
        ...state,
        _entitlements: {},
      })
    case 'FETCH_APPS_SUCCESS': {
      const _apps = Array.isArray(action.apps) ? _.keyBy(action.apps, 'name') : action.apps
      updateKnownRoles(_apps)
      return mergeApps({
        ...state,
        _apps: _.merge({}, _apps),
        _loading: false,
      })
    }
    case 'SET_ENTITLED_APPS': {
      if ((state._hash && calcHash(action.apps) === state._hash) || // ignore if hash is the same
        (state._generated && getGeneratedStamp(action.apps) <= state._generated)) {
        return state // ignore due to generated timestamp is the older or same
      }
      const mergeAppsList = linkExtensions(mergeApps({
        ...state,
        _entitlements: action.apps,
      }))
      setTimeout(() => {
        const { support_account_id, rbac_roles, errors } = _.get(action.apps, APP_PORTAL, {})
        AppStore.dispatch({ type: 'MERGE_APP', mergeAppsList })
        if (support_account_id > 0) {
          AppStore.dispatch(checkAccountRbacRoles({ support_account_id, rbac_roles }))
        }
        if (isNonemptyArray(errors)) {
          // eslint-disable-next-line no-console
          console.error('fetch entitlements with errors', errors)
        }
      })
      return mergeAppsList
    }
    case 'APPLY_DISBALED_TENANTS': {
      return mergeApps({
        ...state,
        _disabled: action.tenants,
      })
    }
    default: {
      return state
    }
  }
}

export default entitledAppsList
