/**
 * Created by vsuthar on 5/14/20
 * Project: App Portal ©Palo Alto Networks
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as ErrIcon } from '../../images/setup-error-card-icon.svg'

const errorContainerStyle = {
  minWidth: 400,
  padding: '110px 0', transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms'
}

const SetUpErrorCard = ({ errorMsg = 'We have encountered a critical error and cannot continue', bodyIcon: BodyIcon, reactNode }) => {
  return (
    <div className={'vbox center'} style={errorContainerStyle}>
      <div style={{ width: 100 }}>
        {BodyIcon ? <BodyIcon /> : <ErrIcon />}
      </div>
      <label style={{ marginTop: 10 }}>{errorMsg}</label>
      {reactNode}
    </div>
  )
}

SetUpErrorCard.propTypes = {
  /**
   * Error message that shows
   */
  errorMsg: PropTypes.string,
  bodyIcon: PropTypes.object,
  reactNode: PropTypes.object,
}

export default SetUpErrorCard

