const selectedInstance = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SELECTED_INSTANCE': {
      return action.selectedInstance || state
    }
    default: {
      return state
    }
  }
}

export default selectedInstance
