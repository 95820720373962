/**
 *
 * Created by vsuthar on 9/21/18 File AssignRoles
 * Project: App Portal ©PaloAlto Networks
 */
import React, { Fragment, PureComponent } from 'react'
import { Row, Col, PageHeader, Card, Menu, Button } from '@pan/cloud-base'
import { isEmpty, forOwn, keyBy, groupBy } from 'lodash'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import PANPage from '../../../components/PANPage'
import Chips from '../../../components/ChipsTags'
import AppAssignRole from './AppAssignRole'
import * as AppConstants from './../../../constants/AppConstants'
import { ROLES as ROLE_ROUTE } from '../../../constants/RouteConstants'
import ConfirmationDialog from '../../../components/common/ConfirmationDiaglog'
import { parseRoles, checkIfDSS, determineUserPrivilege } from '../../../utils/rbacUtils'
import {
  ACCOUNT_SUPER_USER,
  RBAC_ASSIGN_CUSTOM_PERMISSION,
  ASSIGN_ROLES,
  RBAC_CONFIRM_DIALOG_TITLE,
  RBAC_ADMIN, APP_ADMIN,
  RBAC_DISPLAY_ACCOUNT_SUPER_USER
} from '../../../constants/AppConstants'
import CustomRoleDialog from '../custom_role/CustomRoleDailog'

import './../../../common.scss'
import './AssignRole.scss'

const { Item } = Menu

class AssignRoles extends PureComponent {
  constructor() {
    super()
    this.handleItemSelection = this.handleItemSelection.bind(this)
    this.handleCancelBtn = this.handleCancelBtn.bind(this)
    this.handleChipClose = this.handleChipClose.bind(this)
    this.state = {
      selectedApp: AppConstants.RBAC_LOGGING_SERVICE,
      isConfirmDiagOpen: false,
      hideForm: true
    }
  }

  componentDidMount() {
    const { rbac_entitlements = [] } = this.props
    if (rbac_entitlements.length) {
      const firstApp = rbac_entitlements.find(item => {
        if (item.hasOwnProperty('isServiceApp') && item['isServiceApp']) {
          return item
        }
        return null
      })
      if (firstApp) {
        this.setState({
          appType: firstApp,
          selectedApp: firstApp.rbac_app_type,
        }, () => {
          this.getRoleSchema(firstApp)
        })
      }
    }
  }


  static getDerivedStateFromProps(props, state) {
    const { rbac_entitlements } = props
    if (rbac_entitlements && rbac_entitlements.length) {
      const { selectedApp } = state
      const appType = rbac_entitlements.find(item => item.rbac_app_type === selectedApp)
      return {
        selectedApp,
        appType,
      }
    }
    return null
  }

  getRoleSchema(findApp) {
    this.props.getRolesDefPerApp(findApp.app_id, true)
    this.props.selectSchemaPerApp(findApp)
  }

  /**
   *
   * @param item
   * @param key
   * @param selectedKeys
   *
   * callback from when menu item selected.
   */
  handleItemSelection({ item, key, selectedKeys }) {
    // go over the rbac_entitlements to find app object from key
    const findApp = this.props.rbac_entitlements.find(item => item.uniqueId === key)
    const selectedState = {}
    /**
     * construct special selectedState object if user click on Account. other then that we have app object available from
     * rbac_entitlements
     */
    if (findApp) {
      const { rbac_app_type } = findApp
      if (rbac_app_type) {
        selectedState['selectedApp'] = rbac_app_type
        selectedState['appType'] = findApp
      }
    }
    else {
      selectedState['selectedApp'] = key
      selectedState['appType'] = { display_name: 'Account' }
    }
    /**
     * get schema
     */
    this.getRoleSchema(findApp)

    this.setState({ ...selectedState })
  }

  /**
   * Now remove tag from redux store. Tag must present in selectedUsers or autoCompleteSelection store
   * Remove tag from selection will help to not assign role from unwanted users.
   * @param e
   * @param val
   */
  handleChipClose(e, val) {
    this.props.removeTagFromSelection(val)
  }

  /**
   * Click on Save button will open confirm dialog box
   */

  openConfirmDialog = () => {
    this.setState({
      isConfirmDiagOpen: true,
    })
  }

  closeConfirmDialog = () => {
    this.setState({
      isConfirmDiagOpen: false,
    })
  }


  handleRBAC_V2Save = () => {
    const { createdRoles, rolesNeedsToBeDeleted } = this.props
    if ((createdRoles && createdRoles.length) || rolesNeedsToBeDeleted.length) {
      // once successful redirect to the role page
      this.props.doUpdateUsers(createdRoles, false, rolesNeedsToBeDeleted)
        .then((res) => {
          if (!isEmpty(res)) {
            const { success, errors, failed } = res
            if (success?.length > 0 && errors?.length === 0 && failed?.length === 0) {
              // success
              this.closeConfirmDialog()
              this.props.history.push(ROLE_ROUTE)
              this.props.sendNotification({ message: 'Access has been successfully updated.' })
            }
            else if (success?.length === 0 && (errors?.length > 0 || failed?.length > 0)) {
              // error notification
              this.closeConfirmDialog()
              this.props.appPortalErrorHasOccurred({ message: 'Role assignment failed. Please try again.' })
            }
            else {
              this.closeConfirmDialog()
              this.props.history.push(ROLE_ROUTE)
              this.props.sendNotification({ message: 'Access has been successfully updated.' })
            }

          }
          else {
            const { status } = res || {}
            if (status === 'success') {
              this.closeConfirmDialog()
              this.props.history.push(ROLE_ROUTE)
            }
          }
        })
    }
  }
  /**
   * User click on yes button on dialog box will save new roles
   */
  handleSaveBtn = () => {
    const { createdRoles, rolesNeedsToBeDeleted } = this.props
    if (createdRoles && createdRoles.length) {
      // once successful redirect to the role page
      this.props.doUpdateUsers(createdRoles, false, rolesNeedsToBeDeleted)
        .then((res) => {
          if (!isEmpty(res)) {
            this.closeConfirmDialog()
            this.props.history.push(ROLE_ROUTE)
          }
          else {
            const { status } = res || {}
            if (status === 'success') {
              this.closeConfirmDialog()
              this.props.history.push(ROLE_ROUTE)
            }
          }
        })
    }
  }


  /**
   * Cancel go back to prev page
   */
  handleCancelBtn() {
    this.props.history.push(ROLE_ROUTE)
  }

  /**
   *
   * @returns {*}
   */
  assignSidePanel() {
    const { rbac_entitlements } = this.props
    const defaultKeys = []
    if (rbac_entitlements.length) {
      rbac_entitlements.some(item => {
        if (item.hasOwnProperty('isServiceApp') && item['isServiceApp']) {
          defaultKeys.push(item.uniqueId)
          return true
        }
        return false
      })
    }
    return (
      <Menu mode={'inline'}
        defaultOpenKeys={defaultKeys}
        defaultSelectedKeys={defaultKeys}
        className='RBAC__assign_role_menu'
        onSelect={this.handleItemSelection}>
        {rbac_entitlements.map(item => {
          if (item) {
            const { uniqueId, name, isServiceApp, isDirty, display_name } = item
            const style = uniqueId === 'superuser_account_id' ? {
              borderTop: '2px solid #e8e8e8',
              display: 'flex',
            } : null
            const dirtyIcon = isDirty ?
              <div style={{
                marginRight: 5,
                height: 10,
                backgroundColor: 'orange',
                width: 10,
                borderRadius: '100%',
              }} /> : null
            return isServiceApp ? <Item style={style} key={uniqueId.toString()}>
              <div title={display_name || name} className={'hbox align-middle'}>{dirtyIcon}
                <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{display_name || name}</span>
              </div>
            </Item> : null
          }
          return null
        })}
      </Menu>
    )
  }

  getDialogBody_RBAC_V2() {
    // RBAC V2
    const { isConfirmDiagOpen } = this.state
    if (isConfirmDiagOpen) {
      const { createdRoles, orig_app_list, rolesNeedsToBeDeleted, tenantCache } = this.props
      const emails = groupBy(createdRoles, 'email')
      const totalUsers = Object.keys(emails).length
      let emailsStr = ''
      const appsWithRole = keyBy(orig_app_list, (app) => {
        return app.hasOwnProperty('rbac_role') && app.rbac_role.support_rbac && app.app_id
      }) || {} // so it wont break when we try to access it
      const isAppPresent = {}
      let body

      if (createdRoles.length) {
        body = createdRoles.map((roleObj, idx) => {
          const { email, role, tenant_id } = roleObj
          let { app } = roleObj
          const key = `${app}-${tenant_id}`
          let _role = role
          if (tenant_id === '*' && role !== 'account_superuser') {
            _role = role
          }
          else if (tenant_id !== '*' && role === 'app_superuser') {
            /**
             * just name role as instance admin so it can show instance admin role for display purpose
             this is because in legacy RBAC V1 is we have instance admin
             RBAC V2 we have app_superuser for instance and also for all instances when tenant="*"
             */
            _role = 'Instance Admin'

          }
          const comma = idx === createdRoles.length - 1 ? '' : ', '
          if (emailsStr.indexOf(email) === -1) {
            emailsStr += `${email}${comma}`
          }

          if (isAppPresent[key]) return null
          const app_id = tenantCache[tenant_id]?.app_id || app // fallback to app (some tenants in roles doesn't exists in entitlements list)
          isAppPresent[key] = true
          let appName
          app = checkIfDSS(app)
          if (app && appsWithRole[app] && appsWithRole[app].display_name) {
            appName = tenantCache[tenant_id]?.display_name || appsWithRole[app].display_name || app
          }
          else {
            appName = 'All Apps'
          }
          return <li key={idx}>
            {`${appName} ${tenant_id && tenant_id !== '*' ? `(${tenant_id})` : ''}: ${determineUserPrivilege(_role, app_id)}`}
          </li>
        })
      }
      else {
        /**
         * Construct no role sentence
         */
        rolesNeedsToBeDeleted.forEach((eachRole, idx) => {
          const comma = idx === rolesNeedsToBeDeleted.length - 1 ? '' : ', '
          const { email } = eachRole
          if (emailsStr.indexOf(email) === -1) {
            emailsStr += ` ${email}${comma}`
          }
        })
      }

      let msgStr = createdRoles.length ? `The following user(s): ${emailsStr}` : `The following user(s) will have no role access: ${emailsStr}`
      msgStr += createdRoles.length ? ' will have the following roles(s).' : ''
      return <div style={{ height: '100%' }}>
        <ul style={{ listStyleType: 'disk', overflowY: 'auto' }}>
          {
            totalUsers > 10 ? `${totalUsers} Users will have the following role:` : msgStr
          }
          {body}
        </ul>
      </div>
    }

  }

  getDialogBody = () => {
    const { selectedUsers, createdRoles, orig_app_list } = this.props
    const _roles = []
    const grantRoles = []
    const { isConfirmDiagOpen } = this.state
    createdRoles.forEach((user) => {
      if (user.hasOwnProperty('roles') && user.roles.length) {
        _roles.push(...user.roles)
      }
    })
    const parsedRoles = parseRoles(_roles)
    let str = ''
    if (!isEmpty(parsedRoles) && isConfirmDiagOpen) {
      const apps = groupBy(orig_app_list, 'role')
      //APPORTAL-2198
      const getApp = (role) => (apps[role] || []).find(appObj => {
        return appObj.role === role
      })

      const getGranularRole = (role) => (apps[role] || []).reduce((acc, eachApp) => {
        return acc.concat(eachApp.rbac_role?.granular_roles || [])
      }, [])
      forOwn(parsedRoles, (roleValue, role) => {
        if (role === ACCOUNT_SUPER_USER && parsedRoles[role]) {
          str = `The following user(s) will be granted the ${RBAC_DISPLAY_ACCOUNT_SUPER_USER} role: ${selectedUsers.join(', ')}.`
        }
        else if (parsedRoles[role][APP_ADMIN]) {
          const li = <li key={role}>{getApp(role) ? getApp(role).display_name : role}: {RBAC_ADMIN} </li>
          grantRoles.push(li)
        }
        else {
          let granular_role_str = ''
          forOwn(roleValue, (value, key) => {
            const _role = Object.keys(value)[0]
            const app = getApp(role)
            if (app && app.hasOwnProperty('rbac_role') && app.rbac_role) {
              const granular_roles = getGranularRole(app.role)
              const granularRoleKey = keyBy(granular_roles, 'role_name')
              const { role_label } = granularRoleKey[_role] || _role
              if (!granular_role_str.includes(role_label)) {
                // adding - to make it , later on
                granular_role_str += ` ${role_label}-`
              }

            }
          })
          //remove last - to make period
          granular_role_str = granular_role_str.substring(0, granular_role_str.length - 1)
          if (granular_role_str) {
            const li = <li key={role}>{getApp(role) ? getApp(role).display_name : role}: {granular_role_str.split('-').join(',')} </li>
            grantRoles.push(li)
          }
          // str += `role for ${apps[role] ? apps[role].display_name : role} `

        }
      })
    }
    else {
      str = `The following user(s) will have no role access: ${selectedUsers.join(', ')}.`
    }

    if (grantRoles && grantRoles.length) {
      str = (
        <div>
          The user(s): {selectedUsers.join(', ')} <br />
          will have the following role(s):
          <ul style={{ listStyleType: 'disk' }}>
            {grantRoles}
          </ul>
        </div>
      )
    }
    return (
      <div className={'vbox'}>
        {str}
      </div>
    )
  }

  /**
   *
   * @returns {*}
   * Main Panel that render right side of content in Assign Roles page
   */
  assignMainPanel() {
    const { appType } = this.state
    const { defaultCloudRoles, defaultAppRoles, roleDefMap, schemaPerApp, ...others } = this.props
    return <AppAssignRole
      app={appType}
      {...others}
      roleDefMap={roleDefMap}
      roles={defaultCloudRoles.roles}
      schemaPerApp={schemaPerApp}
      defaultAppRoles={defaultAppRoles}
    />
  }


  onSaveAsNewRole = () => {
    this.setState({
      hideForm: false
    }, () => {
      this.props.createAsNewRole(this.props.roleToBeView)
    })
  }
  render() {
    const {
      selectedUsers,
      createdRoles,
      isAssignCustomPerDiagOpen,
      roleToBeView,
      schemaPerApp,
      schemaTree,
      createCustomRole,
      userRoleTenant_id,
      tenantSkus,
      rolesNeedsToBeDeleted,
      roleDef
    } = this.props

    const { isConfirmDiagOpen, hideForm } = this.state
    return (
      <Fragment>
        <Row type={'flex'} style={{ flex: 1 }} className='RBAC__assign_role_page'>
          <Col xs={24} md={20} sm={24} lg={20} className={'vbox'}>
            <span className={'RBAC_app_assign_go_back'}>
              <Link to={ROLE_ROUTE}>{'Go Back to Access Management'}</Link>
            </span>
            <PageHeader head={ASSIGN_ROLES} />
            <div style={{ padding: '0 34px', overflow: 'hidden' }}>
              <Chips chips={[...selectedUsers]}
                isLoading={this.props.appLoading}
                onClose={this.handleChipClose}
                color='#dadcde'
                className={'RBAC_AssignPage_Tag'}
                style={{ color: '#24292d' }}
                closable
              />
            </div>
            <Card className={'vbox flex-box-fill'} style={{ marginLeft: 24, marginTop: 5, borderColor: '#e8e8e8' }}
              bodyStyle={{ flex: 1, display: 'flex', padding: 0 }}>
              <PANPage sidePanelProps={{ width: 240, className: 'RBAC__assign_role_side_panel' }}
                mainContentProps={{ style: { backgroundColor: 'white' } }}
                sidePanel={this.assignSidePanel()} mainContent={this.assignMainPanel()} />
            </Card>
            {selectedUsers.length > 0 ? <Row type={'flex'} justify={'end'} style={{ height: 60 }}>
              <Col>
                <Button onClick={this.handleCancelBtn}>Cancel</Button>
                <Button type={'primary'} style={{ margin: 5 }}
                  loading={this.props.appLoading}
                  disabled={!Boolean(createdRoles.length || rolesNeedsToBeDeleted.length)}
                  onClick={this.openConfirmDialog}>Save</Button>
              </Col>
            </Row> : null}

          </Col>
        </Row>
        <ConfirmationDialog
          title={RBAC_CONFIRM_DIALOG_TITLE}
          width='50%'
          bodyStyle={{ maxHeight: 300, overflowY: 'auto' }}
          visible={isConfirmDiagOpen}
          onOk={this.handleRBAC_V2Save}
          confirmLoading={this.props.appLoading}
          onCancel={this.closeConfirmDialog}>
          {this.getDialogBody_RBAC_V2()}
        </ConfirmationDialog>
        <CustomRoleDialog title={RBAC_ASSIGN_CUSTOM_PERMISSION}
          schemaPerApp={schemaPerApp}
          width={800}
          roleDef={roleDef}
          userRoleTenant_id={userRoleTenant_id}
          tenantSkus={tenantSkus}
          createCustomRole={createCustomRole}
          schemaTree={schemaTree}
          roleToBeView={roleToBeView}
          visible={isAssignCustomPerDiagOpen}
          dialogActionType={'create'}
          hideForm={hideForm}
          onTreeChecked={this.props.onTreeChecked}
          onSaveAsNewRole={this.onSaveAsNewRole}
          onOk={() => this.props.openAssignCustomPermission(false)}
          onCancel={() => {
            this.setState({ hideForm: true }, () => this.props.openAssignCustomPermission(false))
          }} />
      </Fragment>
    )
  }
}

/**
 *
 * @type {{defaultAppRoles: *, autoCompleteSelection: *, selectedUsers: *, defaultCloudRoles: *, appLoading: *, history: *, rbacCustomRole: *, assign_roles: *, doUpdateUsers: *, orig_app_list: *, createdRoles: *, rbac_entitlements: *, removeTagFromSelection: *}}
 */
AssignRoles.propTypes = {
  rbac_entitlements: PropTypes.array,
  selectedUsers: PropTypes.array,
  defaultCloudRoles: PropTypes.object,
  defaultAppRoles: PropTypes.object,
  assign_roles: PropTypes.object,
  autoCompleteSelection: PropTypes.array,
  createdRoles: PropTypes.array,
  doUpdateUsers: PropTypes.func,
  appLoading: PropTypes.bool,
  history: PropTypes.object,
  removeTagFromSelection: PropTypes.func,
  orig_app_list: PropTypes.array,
  rbacCustomRole: PropTypes.bool,
  isAssignCustomPerDiagOpen: PropTypes.bool,
  /**
   * Existing user with role
   */
  existingUsersWithRoles: PropTypes.array,
  openAssignCustomPermission: PropTypes.func,
  /**
   * Gey all the role for app
   */
  getRolesDefPerApp: PropTypes.func,
  /**
   * roleDefMap object will contain custom role and predefine role if that app support custom role.
   * { xdr: [role map array ]}
   */
  roleDefMap: PropTypes.object,
  /**
   * Currenlty api gives all schema in one shot hence we need to get schema for specific app
   * this object will contain schema per app.
   * This is happen when user select or navigate different app and filter to the app
   */
  schemaPerApp: PropTypes.object,
  /**
   * When there is predefine scope per role we need to show checkbox selected for schemaTree, This object
   * use for render selected tree.
   */
  roleToBeView: PropTypes.object,

  /**
   * Create schemaTree by selecting per app. We need to create schemaTree runtime for each app while user
   * select menu or any app
   */
  selectSchemaPerApp: PropTypes.func,
  /**
   * Schema tree that render checkbox tree for each app type.
   */
  schemaTree: PropTypes.array,

  /**
   * createCustomRole is function that use for creating custom role for customRoleDailog
   */
  createCustomRole: PropTypes.func,

  /**
   * userRoleTenant_id: used when user click on the Assign Permission menu that time we need to crate role and assign that custom role to tenant
   *
   */
  userRoleTenant_id: PropTypes.string,

  /**
   * tenantSkus: used to filter tenant skus mismatch with rbac schema scope skus
   *
   */
  tenantSkus: PropTypes.array,
  rolesNeedsToBeDeleted: PropTypes.array,
  /**
   * When ever user checked tree node
   */
  onTreeChecked: PropTypes.func,
  /**
   * Create as new role
   */
  createAsNewRole: PropTypes.func,
  /**
   * Selected custom role when user save as new role we need to assign also same role to tenant id automatically
   */
  selectedCustomRole: PropTypes.object,

  /**
   * Send notification
   */
  sendNotification: PropTypes.func,

  /**
   * Role defination per app
   */
  roleDef: PropTypes.array,

  /**
   * tenantCache are cached tenants ids with app mapping
   */
  tenantCache: PropTypes.object,

  /**
   * Show warning message
   */
  appPortalWarningOccurred: PropTypes.func,

  /**
   * Show error message
   */

  appPortalErrorHasOccurred: PropTypes.func,

}

/**
 *
 * @type {{autoCompleteSelection: [], orig_app_list: [], selectedUsers: [], defaultCloudRoles: {}, removeTagFromSelection: {}, appLoading: boolean, rbacCustomRole: boolean}}
 */
AssignRoles.defaultProps = {
  selectedUsers: [],
  autoCompleteSelection: [],
  removeTagFromSelection: {},
  defaultCloudRoles: {},
  appLoading: false,
  orig_app_list: [],
  rbacCustomRole: false,
  isAssignCustomPerDiagOpen: false,
  openAssignCustomPermission: () => {},
  getRolesDefPerApp: () => {},
  roleDefMap: {},
  roleToBeView: {},
  selectSchemaPerApp: () => {},
  schemaTree: [],
  createCustomRole: () => {},
  rolesNeedsToBeDeleted: [],
  existingUsersWithRoles: [],
  onTreeChecked: () => {},
  createAsNewRole: () => {},
  selectedCustomRole: {},
  sendNotification: () => {},
  appPortalWarningOccurred: () => {},
  appPortalErrorHasOccurred: () => {},
  tenantCache: {},
}
export default AssignRoles
