import React, { PureComponent } from 'react'
import { Layout, Menu, Radio, RadioGroup } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import './AppAccessSideBar.scss'
import './../../common.scss'
import ListItem from '../../components/ListItem'
const { ContainerDimensions } = Layout
const { SubMenu, Item, ItemGroup } = Menu

/**
 * Sidebar component that manage all the roles and app, Recursive create radio button
 */

const createMenuFactory = (item) => {
  /**
   * some of the filter being passed from reducer itself when we construct entitlements for rbac
   * we are passing this filter in value for radio button so when radio button being selected that time
   * we can extract or parse this filter and passed as query for get users based on this filter
   *
   * please look at rbacReducer.js for how filter being generated
   */
  const { instances, hasInstanceChild, filter } = item
  const filterQ = filter !== undefined || null ? filter : item.name || item.tenant_id
  if (instances && instances.length) {
    return (
      <ItemGroup title={<Radio value={`${item.uniqueId}-${filterQ}`} >{item.name || item.tenant_id }</Radio>}
        style={{ marginBottom: 0, paddingLeft: 15 }} key={item.uniqueId}>
        {instances.map(instance => {
          return createMenuFactory(instance)
        })}
      </ItemGroup>
    )

  }
  else {
    const borderStyle = hasInstanceChild !== undefined && !hasInstanceChild ? 'rbac-submenu-border' : null
    return <Item style={{ marginBottom: 0 }} className={ borderStyle } key={item.uniqueId}>
      <Radio value={`${item.uniqueId}-${filterQ}`} >
        <ListItem toolTipProps={{ placement: 'bottom' }}>{item.name || item.tenant_id }</ListItem>
      </Radio>
    </Item>
  }

}

class AppAccessSideBar extends PureComponent {
  constructor(props) {
    super(props)
    this.handleRadioChange = this.handleRadioChange.bind(this)
  }

  subMClick = e => {
    e.stopPropagation()
  }

  getSubMenuItem(role) {
    const { name, display_name, uniqueId, access, tenant_id, filter, doRender } = role
    const filterQ = filter ? filter : name || tenant_id
    if (access && doRender) {
      const menuTitle = <Radio value={`${uniqueId}-${filterQ}`} onClick={this.subMClick}>{ display_name || name}</Radio>
      return (
        <SubMenu title={menuTitle} key={uniqueId} className={'rbac-submenu-border'}>
          { access.map(item => {
            if (item && item && item.doRender === true) {
              return createMenuFactory(item)
            }
            return null

          })}
        </SubMenu>
      )
    }
    else {
      return createMenuFactory(role)
    }
  }

  getChildren() {
    const { roles } = this.props
    return roles.map((role) => {
      if (role && role.doRender) {
        return this.getSubMenuItem(role)
      }
      return null

    })
  }

  /**
   *
   * @param e
   * Change radio selection
   */
  handleRadioChange(e) {
    const { value } = e.target
    /*
     we are splitting value by - because after - (hyphen), it contain our filter value which is already constructed while entitlements
     loaded. we not need to reconstruct those filter again. This filter now passed to /getuser
     */
    const arr = value.split('-')
    const filterQ = arr.length > 0 ? arr[1] : ''
    const query = { filter: filterQ }
    this.props.getAllRoles(query)
    this.props.handleFilterChange(value)
    e.stopPropagation()
  }

  render() {
    const _selectedValue = this.props.roles.length > 0 ? `${this.props.roles[0].uniqueId}-${this.props.roles[0].filter}` : 0
    const { selectedFilter } = this.props
    return (
      <ContainerDimensions>
        { ({ width, height }) => {
          return <div style={{ height }} className={'rbac-radio-group'}>
            <RadioGroup size={'small'} value={selectedFilter || _selectedValue} onChange={this.handleRadioChange} >
              <Menu mode='inline' className={'rbac-menu flex-box-fill'}>
                {
                  this.getChildren()
                }
              </Menu>
            </RadioGroup>
          </div>
        } }
      </ContainerDimensions>

    )
  }
}

/**
 *
 * @type {{children: *, roles: *, getAllRoles: *, RoleAccessQuery: *, selectedFilter: *, handleFilterChange: *}}
 * @roles is responsible for entitlements list and left side panel for RBAC
 */
AppAccessSideBar.propTypes = {
  children: PropTypes.any,
  roles: PropTypes.array,
  getAllRoles: PropTypes.func,
  RoleAccessQuery: PropTypes.func,
  selectedFilter: PropTypes.string,
  handleFilterChange: PropTypes.func
}

/**
 *
 * @type {{roles: Array}}
 */
AppAccessSideBar.defaultProps = {
  roles: [],
  handleFilterChange: () => {}
}

export default AppAccessSideBar
