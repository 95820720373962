import React from 'react'
import PropTypes from 'prop-types'
import { capitalize, castArray, isEmpty } from 'lodash'
import { LinkedinIcon, FacebookIcon, TwitterIcon } from '@pan/cortex-panw-footer'

const SocialLink = ({ link, icon }) => (
  !link ? '' : <a href={link} rel='noopener noreferrer' target='_blank'>{icon}</a>
)

SocialLink.propTypes = {
  link: PropTypes.string,
  icon: PropTypes.node,
}

export default function AppRequirement({
  developer,
  developer_link,
  first_release,
  latest_release,
  system_requirements,
  categories,
  tac_link,
  privacy_link,
  contact_link,
  social_media,
}) {
  const cats = categories ? capitalize(castArray(categories).join(', ')) : ''
  return (
    <div className="app_requirements_outline">
      <div className="app_requirements_wrapper">
        <div className="app_requirements">
          <p>
            <strong>Developer:</strong><br />
            <a href={developer_link}
              rel="external noopener noreferrer"
              target="_blank">{developer}</a>
          </p>
          <p>
            <strong>First Release:</strong><br />
            {first_release}
          </p>
          <p>
            <strong>Latest Release:</strong><br />
            {latest_release}
          </p>
          <p>
            <strong>System Requirements:</strong><br />
            {system_requirements}
          </p>
          {cats.length > 0 &&
            <p>
              <strong>Categories:</strong><br />
              {cats}
            </p>
          }
          <p>
            <a href={tac_link}
              rel="external noopener noreferrer"
              target="_blank">Terms and conditions</a>
            <br />
            <a href={privacy_link}
              rel="external noopener noreferrer"
              target="_blank">Privacy</a>
            <br />
            <a href={contact_link}
              rel="external noopener noreferrer"
              target="_blank">Contact</a>
          </p>
          {!isEmpty(social_media) &&
            <ul className='list-inline social mt-4'>
              <li><SocialLink icon={<LinkedinIcon />} link={social_media.linkedin} /></li>
              <li><SocialLink icon={<FacebookIcon />} link={social_media.facebook} /></li>
              <li><SocialLink icon={<TwitterIcon />} link={social_media.twitter} /></li>
            </ul>
          }
        </div>
      </div>
    </div>
  )
}

AppRequirement.propTypes = {
  developer: PropTypes.string,
  developer_link: PropTypes.string,
  first_release: PropTypes.string,
  latest_release: PropTypes.string,
  system_requirements: PropTypes.string,
  categories: PropTypes.arrayOf(PropTypes.string),
  tac_link: PropTypes.string,
  privacy_link: PropTypes.string,
  contact_link: PropTypes.string,
  social_media: PropTypes.shape({
    facebook: PropTypes.string,
    linkedin: PropTypes.string,
    twitter: PropTypes.string,
    google: PropTypes.string,
  })
}
AppRequirement.defaultProps = {
  developer: '',
  developer_link: '',
  first_release: '',
  latest_release: '',
  system_requirements: '',
  categories: [],
  tac_link: 'https://www.paloaltonetworks.com/legal/eula',
  privacy_link: 'https://www.paloaltonetworks.com/legal-notices/privacy',
  contact_link: 'https://www.paloaltonetworks.com/company/contact-sales',
}
