import React from 'react'
import { Link } from 'react-router-dom'
import { FT } from '@pan/cloud-appframework'
import { LOGGING_SERVICE_DISPLAY_NAME, LOGGING_SERVICE_DISPLAY_NAME2 } from '../../constants/AppConstants'
import diagram from '../../images/sample/cortex_data_lake_diagram.png'

export default {
  name: 'Logging Service',
  display_name: FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : LOGGING_SERVICE_DISPLAY_NAME,
  description: 'Enable AI-based innovations for cybersecurity.',
  developer: 'Palo Alto Networks, Inc.',
  developer_link: 'https://www.paloaltonetworks.com/',
  summary: FT('STRATA_LOGGING_SERVICE_REBRAND') ? 'Strata Logging Service enables AI-based innovations for cybersecurity with the industry’s only approach to normalizing and stitching together your enterprise’s data.' : 'Cortex Data Lake enables AI-based innovations for cybersecurity with the industry’s only approach to normalizing and stitching together your enterprise’s data.',
  product_link: FT('STRATA_LOGGING_SERVICE_REBRAND') ? 'https://www.paloaltonetworks.com/sls/strata-logging-service' : 'https://www.paloaltonetworks.com/cortex/cortex-data-lake',
  first_release: 'Sep 26, 2017',
  latest_release: 'Feb 26, 2019',
  system_requirements: FT('STRATA_LOGGING_SERVICE_REBRAND') ? 'See Strata Logging Service Requirements' : 'See Cortex Data Lake Requirements',
  moreLinks: <Link className='btn btn-link' to={FT('STRATA_LOGGING_SERVICE_REBRAND') ? '/sls-sizing-estimator' : '/cortex-sizing-estimator'}>Storage Estimator</Link>,
  heading: 'Enable innovative security apps',
  body: [{
    paragraphs: [
      FT('STRATA_LOGGING_SERVICE_REBRAND') ?
        'Strata Logging Service enables AI-based innovations for cybersecurity with the industry’s only approach to normalizing and stitching together your enterprise’s data. Get public cloud scale and locations with assurance of the security and privacy of your data. Significantly improve the accuracy of security outcomes with trillions of multi-source artifacts for analytics.' :
        'Cortex Data Lake enables AI-based innovations for cybersecurity with the industry’s only approach to normalizing and stitching together your enterprise’s data. Get public cloud scale and locations with assurance of the security and privacy of your data. Significantly improve the accuracy of security outcomes with trillions of multi-source artifacts for analytics.',
    ]
  }, {
    heading: 'Key Benefits',
    content: {
      ul: [
        'Radically simplify your security operations by collecting, integrating, and normalizing your enterprise’s security data.',
        'Effortlessly run advanced AI and machine learning with cloud-scale data and compute.',
        'Constantly learns from new data sources to evolve your defenses.',
      ]
    }
  }, {
    heading: 'Never Worry About Complexity or Scale Again',
    paragraphs: [
      'Deploying massive data collection, storage, and analysis infrastructure is complex. You need to plan for space, power, compute, networking and high availability needs, increasing costs, and operational burden. Once deployed, the infrastructure needs ongoing maintenance and monitoring, taking time away from activities that drive your business forward.',

      FT('STRATA_LOGGING_SERVICE_REBRAND') ? 'Strata Logging Service is built to benefit from public cloud scale and locations. The cloud-based service is ready for elastic scale from the start, eliminating the need for local compute and storage. As your needs grow, you can add more capacity with the push of a button. The public cloud architecture lets you take advantage of global locations to solve local data residency and privacy requirements. Infrastructure—including storage and compute—is handled for you, letting you focus on solving security challenges.' : 'Cortex Data Lake is built to benefit from public cloud scale and locations. The cloud-based service is ready for elastic scale from the start, eliminating the need for local compute and storage. As your needs grow, you can add more capacity with the push of a button. The public cloud architecture lets you take advantage of global locations to solve local data residency and privacy requirements. Infrastructure—including storage and compute—is handled for you, letting you focus on solving new security challenges with apps built on Cortex.',
    ],
    image: FT('STRATA_LOGGING_SERVICE_REBRAND') ? null : diagram,
  }, {
    heading: FT('STRATA_LOGGING_SERVICE_REBRAND') ? 'Strata Logging Service Requirements' : 'Cortex Data Lake Requirements',
    content: [
      { p: FT('STRATA_LOGGING_SERVICE_REBRAND') ? 'Palo Alto Networks next-generation firewalls and Strata Access:' : 'Palo Alto Networks next-generation firewalls and GlobalProtect cloud service:' },
      {
        ul: [
          'Next-generation firewalls and Panorama™ network security management with the ability to connect to the cloud service.',
          'Next-generation firewalls and Panorama running PAN-OS® 8.0.5+.',
          'Panorama with the cloud services plugin installed.',
        ]
      },
      ...FT('STRATA_LOGGING_SERVICE_REBRAND') ? [] : [
        {
          p: 'Palo Alto Networks Traps™ endpoint protection and response and Cortex XDR:' },
        {
          ul: [
            'Palo Alto Networks Traps Advanced Endpoint Protection running version 5.0+ with Traps management service.'
          ]
        }
      ],
    ]
  }]
}
