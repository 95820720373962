/**
 *
 * Created by vsuthar on 9/28/18 File AccountInfoPage
 * Project: App Portal ©PaloAlto Networks
 */

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AccountInfo from './AccountInfo'
import * as RolesAction from '../../config/actions/rbacActions'
import { getSchemaDef } from '../../config/actions/custom_roles/customRoleActions'

const mapStateToProps = state => {
  const { rbac } = state
  return { ...rbac }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...RolesAction, getSchemaDef }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo)

