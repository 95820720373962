/**
 *
 * Created by vsuthar on 10/8/18 File UserAutoComplete
 * Project: App Portal ©PaloAlto Networks
 */
import React, { Component } from 'react'
import { Select } from '@pan/cloud-base'
import { debounce } from 'lodash'
import './UserAutoComplete.css'
import './../../common.scss'
import PropTypes from 'prop-types'

const { Option } = Select
class UserAutoComplete extends Component {
  constructor() {
    super()
    this.state = {
      selection: []
    }
    this.handleAutoCompleteChange = this.handleAutoCompleteChange.bind(this)
    this.throttleAutoComplte = debounce(this.makeAutoComplete.bind(this), 300)
    this.handleAutoSearch = this.handleAutoSearch.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
  }


  /**
   * Resetting local state from props.
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(this.props.value) !== JSON.stringify(prevProps.value)) {
      this.setState({
        selection: this.props.value
      })
    }
  }
  /**
   *
   * @param value
   * Throttle auto complete request
   */
  handleAutoCompleteChange(value) {
    /**
     * when value is empty clear selection
     */
    if (!value) {
      this.setState({
        selection: []
      })
    }
    this.throttleAutoComplte(value)
  }

  /**
   *
   * @param value
   * make autocomplete call when text is 3 char. This is for reduce server call time
   */
  makeAutoComplete(value) {
    if (value.length > 2) {
      this.props.autoCompleteUsers({ query: value })
    }
  }

  /**
   *
   * @param value
   */
  handleSelection(value) {
    const { selection } = this.state
    this.setState({
      selection: selection.concat([value])
    }, () => {
      this.props.handleAutoCompleteSelection(this.state.selection)
    })
  }

  handleAutoSearch(value) {
    // console.log('=====')
    // console.log(value)
    // this.props.autoCompleteUsers(value)
  }
  /**
   *
   * @returns {*}
   * Populate list for auto complete
   */
  getDataSource() {
    const { autoComplete } = this.props
    if (autoComplete.length) {
      return autoComplete.map(d => <Option key={d.email} className='hbox space-between'>
        {`${d.first_name} ${d.last_name} - ${d.email}`}
      </Option>)
    }
    return <Option key={'__nothing-found'} disabled>
      Nothing Found
    </Option>

  }

  render() {
    const { autoComplete, isAutoCompleteLoading, ...rest } = this.props
    return (
      <div>
        <div className={isAutoCompleteLoading ? 'RBAC__search-container' : 'RBAC__no_search-container'}>
          <div className={'RBAC__search-bar'} />
        </div>
        <Select mode={'multiple'}
          allowClear={true}
          className={'RBAC__autocomplete'}
          dropdownClassName={'RBAC__dropdownClassName'}
          notFoundContent={isAutoCompleteLoading ? 'Searching...' : null }
          onSelect={this.handleSelection}
          filterOption={false}
          onSearch={this.handleAutoCompleteChange} {...rest} >
          {this.getDataSource()}
        </Select>
      </div>
    )
  }
}

/**
 *
 * @type {{autoCompleteUsers: *, handleAutoCompleteSelection: *, autoComplete: *}}
 */
UserAutoComplete.propTypes = {
  autoCompleteUsers: PropTypes.func,
  handleAutoCompleteSelection: PropTypes.func,
  autoComplete: PropTypes.array,
  value: PropTypes.array,
  isAutoCompleteLoading: PropTypes.bool
}

/**
 *
 * @type {{autoComplete: Array, handleAutoCompleteSelection: UserAutoComplete.defaultProps.handleAutoCompleteSelection}}
 */
UserAutoComplete.defaultProps = {
  autoComplete: [],
  isAutoCompleteLoading: false,
  autoCompleteUsers: () => {},
  value: [],
  handleAutoCompleteSelection: () => {}
}
export default UserAutoComplete
