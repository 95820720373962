/**
 *
 * Created by vsuthar on 9/28/18 File AccountInfo
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import { Row, Col, PageHeader, Card, Tabs } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import AssingRoles from './../Roles/assignRoles/AppAssignRole'

import './../../common.scss'
import './AccountInfo.css'
import { RBAC_NO_PERMISSION_ACCOUNT_INFO } from '../../constants/AppConstants'

const { TabPane } = Tabs

class AccountInfo extends PureComponent {

  componentDidMount() {
    /**
     * APPORTAL-4093
     * Delayed call till the credential call made.
     * TODO: do it better way
     */
    setTimeout(() => {
      this.props.getAccountRoles()
      this.props.getSchemaDef()
    }, 700)

  }
  /**
   * Iterate over all the entitlements and render data table based on the entitlements
   */
  getAccountData() {
    const { accountAdminEntitlements } = this.props
    const noRole = <div className='vbox flex-box-fill align-items-center justify-center'>
      <h1 style={{ opacity: 0.5 }}>{RBAC_NO_PERMISSION_ACCOUNT_INFO}</h1>
    </div>
    if (accountAdminEntitlements.length) {
      return accountAdminEntitlements.map(item => {
        if (item.hasOwnProperty('isServiceApp') && item.isServiceApp) {
          return <AssingRoles useHeight={false} readOnly={true} app={item} key={item.uniqueId} />
        }
        else {
          return noRole
        }
      })
    }
    else {
      return noRole

    }

  }

  render() {
    const cardBodyStyle = {
      padding: 0, overflow: 'auto', display: 'flex', flex: 1, flexDirection: 'column'
    }
    return (
      <Row type={'flex'} justify={'center'} className={'flex-box-fill'}>
        <Col xs={24} sm={24} md={18} lg={16} xl={16} xxl={12} className={'vbox'}>
          <PageHeader head={'Account Info'} />
          <Card style={{ margin: 15 }} bodyStyle={cardBodyStyle} className={'vbox flex-box-fill'}>
            <Tabs type={'card'} className={'RBAC__accountInfo'}
              tabBarStyle={{ backgroundColor: '#F2F2F2', borderRadius: 0, borderBottom: 0 }}>
              <TabPane tab="Roles" key={1} >
                {this.getAccountData()}
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    )
  }
}

/**
 *
 * @type {{accountAdminEntitlements: *, getAccountRoles: *}}
 */
AccountInfo.propTypes = {
  accountAdminEntitlements: PropTypes.array,
  getAccountRoles: PropTypes.func,
  getSchemaDef: PropTypes.func,
}

/**
 *
 * @type {{accountAdminEntitlements: Array, getAccountRoles: AccountInfo.defaultProps.getAccountRoles}}
 */
AccountInfo.defaultProps = {
  accountAdminEntitlements: [],
  getAccountRoles: () => {},
  getSchemaDef: () => {}
}

export default AccountInfo
