import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Button } from '@pan/cloud-base'
import { utils } from '@pan/cloud-appframework'
import { allowAppAdmin } from '../../utils/rbacUtils'
import { ACTIVATE } from '../../constants/RouteConstants'

const ACTIVATE_TEXT = 'Activate'
const BTN_CLS = 'ant-btn pan-button pan-btn-alt'
const BTN_PROP = { className: 'pan-btn-alt' }

const redirectToLoginThenActivate = (redirect) => utils.redirectToLogin(redirect || { pathname: '/activate' })

const disabledActivateBtn = <Button disabled={true} {...BTN_PROP}>{ACTIVATE_TEXT}</Button>

export const ActivateButton = ({ app, isAuthenticated, history, showAuthCodeModal }) => {
  if (!app || !app.activatable || app.flags?.use_license_hash_only) {
    return null
  }
  if (isAuthenticated && !allowAppAdmin(app)) { // disabled
    return disabledActivateBtn
  }

  if (app.has_auth_code) {
    return <Link
      className={BTN_CLS}
      to={ACTIVATE}
      onClick={isAuthenticated ? (e) => {
        e.preventDefault()
        showAuthCodeModal()
      } : (e) => {
        e.preventDefault()
        redirectToLoginThenActivate()
      }}
    >{ACTIVATE_TEXT}</Link>
  } // otherwise no authcode

  if (isAuthenticated && app.isCob) { // free app
    return <Link
      className={BTN_CLS}
      to={{
        pathname: ACTIVATE,
        state: { referer: history.location.pathname, appName: app.name },
      }}
    >{ACTIVATE_TEXT}</Link>
  }

  return disabledActivateBtn
}

ActivateButton.propTypes = {
  app: PropTypes.shape({
    name: PropTypes.string,
    url_name: PropTypes.string,
    activatable: PropTypes.bool,
    has_auth_code: PropTypes.bool,
    flags: PropTypes.object,
    isCob: PropTypes.bool,
  }),
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object,
  showAuthCodeModal: PropTypes.func,
}

export default ActivateButton
