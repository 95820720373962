import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from '@pan/cloud-base'
import { useDeepMemo } from '../../../utils/activateUtils'

const CGX_DEPLOY_SIZE_FIELD = 'deployment_size'
const AppExtraFields = ({
  editableOnly,
  initialValues,
  appInfoMap,
  getFieldDecorator,
  getFieldError,
  isFieldValidating,
  isFieldTouched,
  ...restProps
}) => {
  const deploySizeFieldCfg = editableOnly && appInfoMap.single?.setup?.fields?.[CGX_DEPLOY_SIZE_FIELD]
  const deploySizeInitValue = deploySizeFieldCfg && initialValues?.[CGX_DEPLOY_SIZE_FIELD]
  const deploySizeDecorator = useDeepMemo(() => {
    return deploySizeFieldCfg && getFieldDecorator(CGX_DEPLOY_SIZE_FIELD, {
      initialValue: deploySizeInitValue,
      rules: [
        { required: true, message: `${deploySizeFieldCfg.label} is required` }
      ],
    })
  }, [deploySizeFieldCfg, deploySizeInitValue])

  if (deploySizeFieldCfg) {
    const err = getFieldError(CGX_DEPLOY_SIZE_FIELD)
    const validateStatus = isFieldValidating(CGX_DEPLOY_SIZE_FIELD) ? 'validating' : err ? 'error' : ''
    const errMsg = validateStatus === 'error' ? err : ''
    return <Form.Item
      key={CGX_DEPLOY_SIZE_FIELD}
      {...restProps}
      label={deploySizeFieldCfg.label}
      validateStatus={validateStatus}
      help={errMsg}
    >
      {deploySizeDecorator(<Select allowClear>
        {deploySizeFieldCfg.options?.map(({ display, value }) => {
          return <Select.Option key={value} value={value}>{display}</Select.Option>
        }) || null}
      </Select>)}
    </Form.Item>
  }

  return null
}

AppExtraFields.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  editableOnly: PropTypes.bool,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  isFieldValidating: PropTypes.func,
  isFieldTouched: PropTypes.func,
}

export default AppExtraFields
