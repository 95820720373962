import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '@pan/cloud-base'
import ListItem from '../../ListItem'

const UrlDisplayField = ({
  initialValues,
  ...restProps
}) => {
  const initValue = initialValues.url || initialValues.domain
  if (!initValue) {
    return null
  }
  const url = initValue.startsWith('http') ? initValue : `https://${initValue}`
  return <Form.Item
    {...restProps}
    label='URL'
    style={{ whiteSpace: 'nowrap' }}
  >
    <ListItem>
      <a
        rel='external noopener noreferrer'
        target='_blank'
        href={url}
      >{url}</a>
    </ListItem>
  </Form.Item>
}

UrlDisplayField.propTypes = {
  initialValues: PropTypes.object,
}

export default React.memo(UrlDisplayField)
