export default {
  name: 'prisma_access',
  display_name: 'Prisma™ Access',
  developer: 'Palo Alto Networks, Inc.',
  developer_link: 'https://www.paloaltonetworks.com/',
  summary: 'Prisma™ Access helps organizations deliver consistent security to remote networks and mobile users. It uses a cloud-delivered architecture that connects and protects all users, whether at branch offices or on the road, to cloud and data center applications as well as the internet. Prisma Access inspects all traffic across all ports in a consistent manner and provides the bidirectional networking to enable branch-to-branch and branch-to-headquarters traffic as well.',
  product_link: 'https://www.paloaltonetworks.com/sase/access',
  first_release: 'July 2019',
  latest_release: 'July 2019',
  system_requirements: 'NA',
  custom_activate_btn: null, // default
  body: [{
    heading: 'Prisma Access for networks',
    paragraphs: [
      'Many branch offices and stores are geographically spread out and do not have full-time IT staff, which makes the logistics of deploying, managing, and upgrading hardware security appliances challenging.',
      'Use Prisma Access to connect your remote networks over a standard IPsec connection (using any existing router, SD-WAN edge device, or firewall that supports IPsec) to secure traffic, protect confidential information, and address data privacy compliance requirements.',
    ],
  }, {
    heading: 'Prisma Access for users',
    paragraphs: [
      'Mobile users need consistent security to access data center and cloud applications as well. Remote access VPN falls short because users typically connect to a gateway for access to data center applications, and then disconnect from the VPN in order to get better performance (but less security) when accessing cloud and internet applications.',
      'With Prisma Access, all users have secure, fast access to all applications, whether in the cloud, on the internet, or in the data center. Your users automatically connect to Prisma Access over an IPsec/SSL VPN tunnel for access to cloud and internet applications without the backhaul to headquarters. Users can access data center applications as well, without having to connect to a separate standalone remote access VPN.',
    ],
  }]
}
