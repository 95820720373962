import React from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from '@pan/cloud-base'

const RegionDisplayField = ({
  initialValues,
  appInfoMap,
  regions: metaDataRegions,
  getFieldDecorator,
  ...restProps
}) => {
  if (!initialValues.region) {
    return null
  }
  return <Form.Item
    {...restProps}
    label='Region'
  >
    <Select
      disabled
      value={initialValues.region_display || initialValues.region}
    />
  </Form.Item>
}

RegionDisplayField.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  regions: PropTypes.arrayOf(PropTypes.object),
  getFieldDecorator: PropTypes.func,
}

export default React.memo(RegionDisplayField)
