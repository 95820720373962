import React from 'react'
import PropTypes from 'prop-types'
import { connect, batch } from 'react-redux'
import { Modal } from '@pan/cloud-base'
import { appframeworkActions } from '@pan/cloud-appframework'
import InstanceRecordForm from './InstanceRecordForm'
import EditForm from '../Activate/EditForm'

const InstanceRecordModal = ({
  visible,
  title,
  selectedAccount,
  entitledAppsList,
  tenant_id,
  application_name,
  hideInstanceRecordModal,
  resetInstanceRecordModal,
  onFinished,
  onlyFields,
}) => (<Modal
  width={800}
  visible={visible}
  title={title}
  footer={null}
  closable={Boolean(tenant_id)}
  destroyOnClose={true}
  className={'v2'}
  maskClosable={false}
  keyboard={false}
  afterClose={resetInstanceRecordModal}
  onCancel={hideInstanceRecordModal}
>
  {tenant_id ?
    <EditForm
      tenant_id={tenant_id}
      application_name={application_name}
      entitledAppsList={entitledAppsList}
      selectedAccount={selectedAccount}
      onCancel={hideInstanceRecordModal}
      onFinished={onFinished}
      onlyFields={onlyFields}
    /> : <InstanceRecordForm />
  }
</Modal>)

InstanceRecordModal.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool,
  tenant_id: PropTypes.string,
  application_name: PropTypes.string,
  onFinished: PropTypes.func,
  entitledAppsList: PropTypes.array,
  selectedAccount: PropTypes.number,
  onlyFields: PropTypes.array,
  currentInstanceRecordModal: PropTypes.object,
  setInstanceRecordModal: PropTypes.func,
  hideInstanceRecordModal: PropTypes.func,
  resetInstanceRecordModal: PropTypes.func,
  hideRecordFormModalHandler: PropTypes.func,
}

const getSelectedInstanceAppDisplayName = ({ selectedInstance = {}, currentInstanceRecordModal = {}, entitledAppsList }) => {
  const appName = currentInstanceRecordModal.application_name ||
    selectedInstance.application_name || selectedInstance.application
  if (!appName) {
    return ''
  }
  if (!entitledAppsList.has(appName)) {
    return appName
  }
  const { display_name = appName } = entitledAppsList.get(appName) || {}
  return display_name
}

const mapStateToProps = ({
  entitledAppsList,
  isLoading,
  selectedAccount,
  selectedInstance,
  recordFormModalVisible,
  currentInstanceRecordModal,
}) => ({
  entitledAppsList,
  isLoading,
  selectedAccount,
  selectedInstance,
  ...currentInstanceRecordModal,
  visible: recordFormModalVisible || currentInstanceRecordModal.visible,
  title: getSelectedInstanceAppDisplayName({
    entitledAppsList,
    currentInstanceRecordModal,
    selectedInstance,
  }),
})

const mapDispatchToProps = (dispatch) => ({
  onFinished: (options) => {
    batch(() => {
      dispatch({ type: 'IS_LOADING_TRUE' })
      dispatch({ type: 'HIDE_INSTANCE_RECORD_MODAL' })
    })
    return dispatch(appframeworkActions.fetchEntitlements(options))
  },
  hideInstanceRecordModal: () => dispatch({ type: 'HIDE_INSTANCE_RECORD_MODAL' }),
  resetInstanceRecordModal: () => {
    batch(() => {
      dispatch({ type: 'RESET_INSTANCE_RECORD_MODAL' })
      dispatch({ type: 'UPDATE_SELECTED_INSTANCE', selectedInstance: {} })
    })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(InstanceRecordModal)
