const authCodeModal = (state = { visible: false }, action) => {
  switch (action.type) {
    case 'SHOW_AUTHCODE_ACTIVATE_MODAL':
      return {
        visible: true,
        authcode: action.authcode,
        ...(action.appName && { appName: action.appName, displayName: action.displayName }),
        ...(action.error && { error: action.error }),
        ...(action.onDiscovery && { onDiscovery: action.onDiscovery }),
      }
    case 'HIDE_AUTHCODE_ACTIVATE_MODAL':
      return {
        visible: false,
        authcode: state.authcode,
      }
    case 'RESET_AUTHCODE_ACTIVATE_MODAL':
      return { visible: false }
    default:
      return state
  }
}

export default authCodeModal
