/**
 *
 * Created by vsuthar on 11/14/18 File RoleTable
 * Project: App Portal ©PaloAlto Networks
 */
import React from 'react'
import CheckboxTable from '../../components/RBAC/roleTable/RoleTable'
import PropTypes from 'prop-types'
import * as AppConst from '../../constants/AppConstants'
import { Checkbox } from '@pan/cloud-base'
import { hasRole } from '../../utils/rbacUtils'
import { isEmpty } from 'lodash'
import './RoleTable.scss'
import {
  ACCOUNT_SUPER_USER, RBAC_ACCOUNT_SUPER_USER_V2,
} from '../../constants/AppConstants'
import { roleColumns } from './RoleColumns'


const NoDataComponent = () => {
  return <div className={'vbox align-middle'}>
    <div>{AppConst.RBAC_ADD_USER}</div>
  </div>

}

NoDataComponent.propTypes = {
  error: PropTypes.object,
  selectedFilter: PropTypes.string,
  onButtonClick: PropTypes.func,
  loading: PropTypes.bool,
  originalEntitlements: PropTypes.array
}

class RoleTable extends React.Component {
  constructor(args) {
    super(args)
    this.columns = roleColumns
  }


  render() {
    const { rbac, onButtonClick, ...rest } = this.props
    const selectAllCheckbox = ({ label, ...rest }) => {
      const { onClick, id, row } = rest
      const isAllowed = hasRole(ACCOUNT_SUPER_USER, rbac.rbacRoles)
      return <Checkbox {...rest} disabled={!isAllowed} onClick={(e) => {
        const shiftKey = e.shiftKey
        e.stopPropagation()
        onClick(id, shiftKey, row)
      }}/>
    }

    const isSuperUser = (roles) => {
      if (roles) {
        if (Array.isArray(roles) && roles.length) {
          return roles.every(role => isSuperUser(role))
        }
        const { role } = roles
        return role && role.toLowerCase() === RBAC_ACCOUNT_SUPER_USER_V2
      }
      return false
    }

    const checkBox = ({ label, ...rest }) => {
      const { onClick, id, row } = rest
      const { roles = [] } = row || {}
      let isAllowed = false
      const numberSupport = 99
      if (isEmpty(rbac?.rbacRoles)) {
        isAllowed = false
      }
      else if (rbac.selectedUsers.length > numberSupport && !rbac.selectedUsers.includes(row?.email)) {
        isAllowed = false
      }
      else if (roles.includes('') && !isEmpty(rbac?.rbacRoles)) {
        isAllowed = true
      }
      else if (isSuperUser(roles)) {
        isAllowed = hasRole(roles, rbac.rbacRoles)
      }
      else {
        isAllowed = roles.every(eachRole => {
          const { app = '', role = '', tenant_id } = eachRole
          // only App admin and instance admin are allow to change their
          return tenant_id === '*' ? hasRole(`${app}.${role}`) : hasRole(`${app}:Instance Admin:${tenant_id}`)
        })
      }
      return <Checkbox {...rest} disabled={!isAllowed} onClick={(e) => {
        const shiftKey = e.shiftKey
        e.stopPropagation()
        onClick(id, shiftKey, row)
      }}/>
    }
    return <CheckboxTable
      perPageSelection={true}

      checkboxProps={{
        SelectInputComponent: checkBox,
        SelectAllInputComponent: selectAllCheckbox
      }}
      styles={{
        trGroup: {
          flex: '0 0 auto',
          minHeight: 'none'
        },
        noData: {
          top: '20%',
          pointerEvents: 'inherit'
        }
      }}
      showPageSizeOptions={false}
      showTotal={true}
      columns={this.columns}
      {...rest}
      noDataText={<NoDataComponent {...{
        error: rbac.error,
        selectedFilter: rbac.selectedFilter,
        originalEntitlements: rbac._orig_rbac_entitlements
      }} onButtonClick={onButtonClick}/>}
    />

  }
}

RoleTable.propTypes = {
  rbac: PropTypes.object,
  entitledAppsList: PropTypes.array,
  onButtonClick: PropTypes.func,
  lastUpdate: PropTypes.number,
  loading: PropTypes.bool
}

export default RoleTable
