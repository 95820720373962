import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'
import { Button, Spin, Message, Modal, Select, Input } from '@pan/cloud-base'
import { appframeworkActions } from '@pan/cloud-appframework'
import {
  LOGGING_SERVICE_DISPLAY_NAME,
  APP_PORTAL_DISPLAY_INLINE,
  MANAGE_APPS_DEBUG_INFO_LABEL,
  MANAGE_APPS_DEBUG_DESCRIPTION,
} from '../../constants/AppConstants'
import { toAppId } from '../../utils/common'
const { fetchEntitlements } = appframeworkActions
const { Option } = Select
const CLENAUP = 'force-cleanup-ignore-csp'
// const { TextArea } = Input

export class InstanceActionModal extends PureComponent {
  constructor(props) {
    super(props)
    this.handleAction = this.handleAction.bind(this)
    this.state = { reason: undefined, loading: false }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.visible || this.props.action !== prevProps.action) {
      this.setState({ reason: undefined, loading: false })
    }
  }

  sendAction() {
    const {
      action,
      app_name,
      region,
      tenant_id,
      platform_id,
      tenant_instance_name,
      selectedAccount,
      isCob,
    } = this.props

    if (!isCob) {
      return Promise.reject(new Error('Actions no longer supoprted for non-COB app instances'))
    }

    const reason = (action === 'delete' || action === 'capture') ? (this.state.reason || '') : undefined

    if (action === 'stop' || action === 'resume') {
      return axios.post('/hub/v2/instance/operation', {
        app_id: toAppId(app_name),
        tenant_id,
        op: action,
        selectedAccount,
      })
    }

    return axios.post(`/hub/v2/${action}`, { // delete and capture
      application_name: app_name,
      reason,
      region,
      tenant_id,
      platform_id,
      tenant_instance_name,
      selectedAccount,
    })
  }

  async handleAction() {
    const {
      action,
      app_display_name,
      fetchEntitlements,
      showGlobalLoading,
      hideInstanceActionModal,
    } = this.props

    this.setState({ loading: true })
    try {
      const resp = await this.sendAction()
      if (resp.data.ok && resp.data.reference_id) {
        hideInstanceActionModal()
        Modal.success({
          title: 'Debug Info Captured',
          content: <span>
            If you’ve filed a support case for this product, please provide this Reference ID <strong>{resp.data.reference_id}</strong> to the <a
              href='https://supportcases.paloaltonetworks.com/'
              target='_blank'
              rel='noopener noreferrer'
            >Support Case</a> to facilitate resolution.
          </span>
        })
      }
      else if (resp.data.ok && resp.data.job_id) {
        hideInstanceActionModal()
        // Message.loading(`Job ${resp.data.job_id}: ${app_display_name} instance is in process.`, 1)
      }
      else if (resp.data.issuccess || resp.data.ok) {
        const wordMapping = {
          delete: 'deleted',
          resume: 'resumed',
          stop: 'stopped',
        }
        Message.info(`${app_display_name} instance successfully ${wordMapping[action]}.`, 3)
        hideInstanceActionModal()
        showGlobalLoading()
        fetchEntitlements()
      }
      else {
        fetchEntitlements()
        Message.error(resp.data.error_message || resp.data.message ||
          `Fail to ${action} ${app_display_name} instance.`, 3)
      }
    }
    catch (error) {
      Message.error(error?.response?.data?.message || error.message ||
        'service temporarily unavailable', 3)
    }
    finally {
      this.setState({ loading: false })
    }
  }

  getMsg() {
    const {
      action,
      app_name,
      app_display_name,
      isThirdParty,
      extra,
    } = this.props

    if (extra?.instance_action_message?.[action]) {
      const {
        message = extra.instance_action_message[action], // if it is string
        confirmation = `Are you sure you want to ${action} this instance?`
      } = extra.instance_action_message[action]
      if (message && _.isString(message)) {
        return <p>You are about to {action} this instance of {app_display_name} from the {APP_PORTAL_DISPLAY_INLINE}. {message}<br /><br /><strong>{confirmation}</strong></p>
      }
    }

    if (action === 'capture') {
      return <Fragment>
        <p>{MANAGE_APPS_DEBUG_INFO_LABEL}</p>
        <p>{MANAGE_APPS_DEBUG_DESCRIPTION}</p>
      </Fragment>
    }

    let msg
    if (isThirdParty && action === 'delete') {
      msg = `will no longer have access to the data on the ${LOGGING_SERVICE_DISPLAY_NAME}. This operation cannot be undone.`
    }
    else if (app_name === 'Directory Sync' && action === 'delete') {
      msg = 'will no longer have access to active directory data. This operation cannot be undone.'
    }
    else {
      switch (action) {
        case 'delete':
          msg = 'will be stopped and destroyed. The associated configuration will be deleted. This operation cannot be undone.'
          break
        case 'stop':
          msg = 'will be stopped and resource will be freed. While the associated configuration will be retained. You can resume this instance later.'
          break
        case 'resume':
          msg = 'will be resumed with existing configuration. You have to wait for a while until the instance is up and running.'
          break
        default:
          return ''
      }
    }
    return <p>You are about to {action} this instance of {app_display_name} from the {APP_PORTAL_DISPLAY_INLINE}. If you proceed, this instance of {app_display_name} {msg}<br />Are you sure you want to {action} this instance?</p>
  }

  renderDeleteReason(deletable) {
    const reasons = deletable === CLENAUP ? ['FORCE CLEANUP FOR TESTING AND DEVELOPMENT PURPOSE'] : [
      'No longer needed',
      'Switching to a different account',
    ]
    return <div className='instance-delete-reason-wrap'>
      <div className='ant-form-item-label'>
        <label className='ant-form-item-required'>Reason</label>
      </div>
      <Select
        autoFocus={true}
        defaultActiveFirstOption={false}
        placeholder='Please select a reason below'
        style={{ width: 500 }}
        value={this.state.reason}
        getPopupContainer={el => el.parentNode}
        onChange={reason => this.setState({ reason })}
      >
        {reasons.map(reason => <Option value={reason} key={reason}>{reason}</Option>)}
      </Select>
    </div>
  }

  renderCaptureReason() {
    return <div className='instance-capture-message-wrap'>
      <div className='ant-form-item-label'>
        <label className='ant-form-item-required'>Description</label>
      </div>
      <Input
        autoFocus={true}
        maxLength={255}
        style={{ width: 500 }}
        value={this.state.reason}
        onChange={e => this.setState({ reason: e.target.value })}
      />
    </div>
  }

  renderReasonField(action, deletable) {
    if (action === 'delete') {
      return this.renderDeleteReason(deletable)
    }
    if (action === 'capture') {
      return this.renderCaptureReason()
    }
  }

  render() {
    const {
      visible,
      action,
      tenant_id,
      serial_number,
      tenant_instance_name,
      isLoading,
      hideInstanceActionModal,
      resetInstanceActionModal,
      deletable,
    } = this.props
    const loading = this.state.loading || isLoading
    const actionTitle = action === 'capture' ? 'Capture Debug Info for' : _.capitalize(action)
    const buttonText = action === 'capture' ? 'Capture' : actionTitle
    const closable = !loading
    const hasReason = (action === 'delete') || (action === 'capture')

    return (
      <Modal
        width={800}
        maskClosable={closable}
        closable={closable}
        keyboard={closable}
        visible={visible}
        afterClose={resetInstanceActionModal}
        onCancel={hideInstanceActionModal}
        title={`${actionTitle} ${tenant_instance_name || serial_number || tenant_id || ''}?`}
        footer={
          <div className='form-footer'>
            <Button
              disabled={loading}
              onClick={hideInstanceActionModal}
            >Cancel</Button>
            <Button
              disabled={hasReason && !this.state.reason}
              style={{ marginLeft: '8px' }}
              type={action === 'delete' ? 'danger' : 'primary'}
              loading={loading}
              onClick={this.handleAction}
            >{buttonText}</Button>
          </div>
        }
      >
        <Spin spinning={loading}>
          {this.getMsg()}
          {hasReason && this.renderReasonField(action, deletable)}
        </Spin>
      </Modal>
    )
  }
}

InstanceActionModal.propTypes = {
  action: PropTypes.oneOf(['delete', 'stop', 'resume', 'capture']),
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
  isThirdParty: PropTypes.bool,
  isCob: PropTypes.bool,
  deletable: PropTypes.string,
  selectedAccount: PropTypes.number,
  tenant_instance_name: PropTypes.string,
  tenant_id: PropTypes.string,
  serial_number: PropTypes.string,
  platform_id: PropTypes.string,
  region: PropTypes.string,
  app_name: PropTypes.string,
  app_display_name: PropTypes.string,
  fetchEntitlements: PropTypes.func,
  hideInstanceActionModal: PropTypes.func,
  resetInstanceActionModal: PropTypes.func,
  showGlobalLoading: PropTypes.func,
  extra: PropTypes.object,
}

InstanceActionModal.defaultProps = {
  visible: false,
  isLoading: false,
  isThirdParty: false,
  app_display_name: '',
}

const mapStateToProps = ({ isLoading, currentInstanceActionModal, entitledAppsList, selectedAccount }) => ({
  isLoading,
  currentInstanceActionModal,
  entitledAppsList,
  selectedAccount,
})

const mapDispatchToProps = (dispatch) => ({
  hideInstanceActionModal: () => dispatch({ type: 'HIDE_INSTANCE_ACTION_MODAL' }),
  resetInstanceActionModal: () => dispatch({ type: 'RESET_INSTANCE_ACTION_MODAL' }),
  showGlobalLoading: () => dispatch({ type: 'FETCH_ENTITLEMENTS_START' }),
  fetchEntitlements: (options) => dispatch(fetchEntitlements(options)),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { currentInstanceActionModal, entitledAppsList, ...restProps } = stateProps
  const appId = currentInstanceActionModal?.id || currentInstanceActionModal?.app_name
  const app = appId && entitledAppsList?.get(appId)
  return {
    ...ownProps,
    ...restProps,
    ...currentInstanceActionModal,
    ...dispatchProps,
    extra: app?.extra,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InstanceActionModal)
