import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import OneApp from './OneApp'

export default class AppsViewer extends PureComponent {
  render() {
    return (
      <div>
        <h3>{this.props.title}</h3>
        {this.props.apps.length > 0 && this.props.apps.map(app =>
          <OneApp
            {...app}
            isAuthenticated={this.props.isAuthenticated}
            selectedAccount={this.props.selectedAccount}
            key={app.name}
            displayName={app.icon_display_name || app.display_name || app.name}
            url={app.marketplace_content?.url || `/marketplace/${app.url_name || app.app_id}`}
            appClassName={'app'}
          />
        )}
      </div>
    )
  }
}

AppsViewer.propTypes = {
  isAuthenticated: PropTypes.bool,
  selectedAccount: PropTypes.number,
  title: PropTypes.string,
  apps: PropTypes.array,
  tenants: PropTypes.array,
  children: PropTypes.any
}

AppsViewer.defaultProps = {
  isAuthenticated: false,
  selectedAccount: 0,
  apps: [],
  tenants: []
}
