import React, { Fragment, PureComponent } from 'react'
import { Row, Col, Card } from '@pan/cloud-base'
import CountUp from 'react-countup'
import Proptypes from 'prop-types'
import { formatBytes } from '../../utils/ByteBase'
import { CORTEX_XDR, LOGGING_SERVICE_DISPLAY_NAME, RECOMMEND_PURCHASE } from '../../constants/AppConstants'
import './Summary.css'

class Summary extends PureComponent {
  constructor(props) {
    super(props)
    this.isTrapCheck = false
    this.isNGFWCheck = false
    this.total = 0 //summing up total for all the app total calculation
    this.cortexXDRTotal = 0
  }

  getTotalSummary(numb) {
    return <CountUp end={Number(numb)}/>
  }

  /**
   *
   * @returns {any[]}
   * get selected app name and their total
   */
  /*
    @TODO Revisit this logic and better way to decide
   */
  getAppSummary() {
    this.total = 0 // set total to 0
    this.isNGFWCheck = false
    this.isTrapCheck = false
    this.cortexXDRTotal = 0
    this.xdrClientCheck = false
    return this.props.selectedApps.map(app => {
      const { name, checked, appId, totalStorage, doRender, avgLogRate, hasChild } = app
      if (checked && doRender) {
        /**
         *
         * @type {null}
         * APPORTAL-1400 check if it has child. We need to check it here first because if child is present then we need
         * to account child total to main total (this.total)
         */
        // debugger
        this.total += totalStorage
        let child = null
        if (app.hasOwnProperty('hasChild') && hasChild) {
          const { name: childName, totalProperty } = hasChild
          const childStorage = app[totalProperty]
          this.total += childStorage || 0
          // console.log(`${childName} => ${childStorage}`)
          this.xdrClientCheck = childStorage > 0
          child =
            <Row key={childName} style={{ marginTop: 12, flexFlow: 'row' }} type='flex' justify={'space-between'} align='middle'>
              <Col style={{ width: 155 }}>
                <span className='summary-total-name'>{childName}</span>
              </Col>
              <Col className={'summary-unit'}>
                <div>{this.getDataFromFormatBytes(childStorage)}</div>
              </Col>
            </Row>
        }
        if (appId === 'ngf') {
          this.isNGFWCheck = true
          this.avgLogRate = avgLogRate
        }
        // do cortex xdr total
        if (appId === 'ngf' && app.formula) {
          const totalCortex = app.formula({ ...app, retentionPrd: 30 }, true)
          this.cortexXDRTotal += totalCortex
        }
        // console.log(`cortex NGF ${this.cortexXDRTotal}`)

        if (appId === 'traps') {
          this.isTrapCheck = true
          const { numberOfXDRClients = 0 } = app

          this.cortexXDRTotal += (numberOfXDRClients * 30 * (174.4 / 1024))
          // console.log(`cortex TRAPS ${this.cortexXDRTotal}`)
        }

        if (appId === 'gpcsRemoteNetworks' || appId === 'gpcsMobileUsers') {
          this.cortexXDRTotal += totalStorage
        }

        /**
         * If @cortexXDRTotal less 1024 then make it 1TB
         */
        /*console.log(`Before ${this.cortexXDRTotal}`)
        /!* keeping debug log for debug *!/
        console.log(`DataLake ${this.total}`)
        console.log(`Cortex ${this.cortexXDRTotal}`)// console.log(`DataLake ${this.total}`)
        console.log('===============\n')// console.log(`Cortex ${this.cortexXDRTotal}`)*/

        return (
          <Fragment key={appId}>
            {appId === 'traps' ? null : <Row style={{ marginTop: 12, flexFlow: 'row' }} type='flex' justify={'space-between'} align='middle'>
              <Col style={{ width: 155 }}>
                <span className='summary-total-name'>{name}</span>
              </Col>
              <Col className={'summary-unit'}>
                <div>{this.getDataFromFormatBytes(totalStorage)}</div>
              </Col>
            </Row>
            }
            {child}
          </Fragment>
        )
      }
      return null
    })
  }

  getDataFromFormatBytes(bytes) {
    const number = formatBytes(bytes, 2, 'GB', 'TB')
    if (number.indexOf('Invalid Value Entered') > -1) {
      return <span style={{ fontSize: 14 }}>Values Entered Invalid</span>
    }
    return number
  }

  /**
   *
   * @returns {string}
   * @TODO Find better way to do this.
   */
  getTotal() {
    this.unit = 'GB'
    // check for firewall first. Firewall has to be 1T
    let totalByBw = 0
    if (this.isNGFWCheck === true && this.total > 0 && this.total < 1024) {
      this.total = 1024
    }
    else if (this.isNGFWCheck === true) {
      totalByBw = Math.ceil(this.avgLogRate / 1000) * 1024
    }
    else if (this.isTrapCheck) {
      if (this.xdrClientCheck && this.total > 0 && this.total < 1024) {
        this.total = 1024
      }
      else if (this.total > 0 && this.total < 100) {
        this.total = 100
      }
      else if (this.total > 0 && this.total < 1024) {
        this.total = 1024
      }
    }
    else if (this.total > 0 && this.total < 1024) {
      this.total = 1024
    }
    // return formatBytes(Math.max(this.total, totalByBw), 2, 'GB')
    const total = formatBytes(Math.max(this.total, totalByBw), 2, 'GB', 'TB')
    const numb = Math.ceil(parseFloat(total))
    this.unit = total.split(' ')[1]
    if (total.indexOf('Invalid Value Entered') > -1) {
      this.unit = ''
      return <span style={{ fontSize: 14, whiteSpace: 'normal' }}>Exceeded maximum value. - Space requirements calculation based on Average Log Rate and Retention Period exceeds 1024^8</span>
    }
    return this.getTotalSummary(numb)
  }

  getCortexXDR() {
    this.cortextUnit = 'GB'
    this.cortexXDRTotal = this.cortexXDRTotal > 0 && this.cortexXDRTotal < 1024 ? 1024 : this.cortexXDRTotal
    const total = this.getDataFromFormatBytes(this.cortexXDRTotal)
    const numb = Math.ceil(parseFloat(total))
    this.cortextUnit = total.split(' ')[1]
    return this.getTotalSummary(numb)
  }


  render() {
    const { style, loggingServiceName, isMagnifierChecked } = this.props
    return (
      <Card style={style} header={<h2 style={{ color: 'white' }}>{RECOMMEND_PURCHASE}</h2>} className='collapsible-card lsc-summary'>
        {this.getAppSummary()}
        <Row type='flex' justify='space-between' align={'middle'}>
          <Col xs={24}>
            <label className='summary-total-class'>TOTAL</label>
          </Col>
          <Col xs={12}>
            <label className='summary-total-tag-line'>{loggingServiceName} Storage to Order</label>
          </Col>
          <Col>
            <label className='summary-total-unit'>
              {this.getTotal()}
              <span> {this.unit}</span>
            </label>
          </Col>
          { isMagnifierChecked ? <Fragment>
            <Col xs={12}>
              <label className='summary-total-tag-line'>{CORTEX_XDR} to Order</label>
            </Col>
            <Col>
              <label className='summary-total-unit'>{this.getCortexXDR()} {this.cortextUnit}</label>
            </Col>
          </Fragment> : null }
          <Col xs={24} style={{ lineHeight: '12px' }}>
            {this.isTrapCheck && this.total > 0 && this.total <= 100 ? <span style={{ fontSize: 12 }}>The Traps management service (TMS) includes 100GB of logging storage on activation of the service. For more details on activation, refer to <a
              style={{ textDecoration: 'underline', color: 'white' }}
              target='_blank'
              href='https://www.paloaltonetworks.com/documentation/traps/tms/traps-management-service-admin/get-started-with-tms/license-the-tms'
              rel='noopener noreferrer'
            >Activate the Traps Management Service</a></span> : null }
          </Col>
        </Row>
      </Card>
    )
  }
}

/**
 *
 * @type {{selectedApps: *, isMagnifierChecked: *, style: *, loggingServiceName: *}}
 */
Summary.propTypes = {
  loggingServiceName: Proptypes.string,
  selectedApps: Proptypes.array,
  isMagnifierChecked: Proptypes.bool,
  style: Proptypes.object
}

/**
 *
 * @type {{selectedApps: Array}}
 */
Summary.defaultProps = {
  loggingServiceName: LOGGING_SERVICE_DISPLAY_NAME,
  selectedApps: [],
  style: {},
  isMagnifierChecked: false,
}
export default Summary
