/**
 *
 * Created by vsuthar on 9/24/18 File AssignRolePage
 * Project: App Portal ©PaloAlto Networks
 */
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import AssignRole from './AssignRoles'
import * as RolesAction from '../../../config/actions/rbacActions'
import { sendNotification, appPortalWarningOccurred, appPortalErrorHasOccurred } from '../../../config/actions'
import { getRolesDefPerApp } from '../../../config/actions/custom_roles/customRoleActions'
import { openAssignCustomPermission, createCustomRole, editPermission, onTreeChecked, createAsNewRole } from '../../../config/actions/custom_roles/customRoleActions'

/**
 *
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
  const { rbac, supportedAccountIds, selectedAccount, appLoadingState, rbacCustomRole } = state
  return { ...rbac, supportedAccountIds, selectedAccount, ...appLoadingState, ...rbacCustomRole }
}

/**
 *
 * @param dispatch
 * @returns {{getAllRoles?}}
 */
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...RolesAction,
    openAssignCustomPermission,
    getRolesDefPerApp,
    createCustomRole,
    editPermission,
    onTreeChecked,
    createAsNewRole,
    sendNotification,
    appPortalWarningOccurred,
    appPortalErrorHasOccurred,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AssignRole)
