/**
 * Application level constants are declared here.
 */

export const STORAGE_INCLUDED = 'Storage Included'
export const NEVER = 'Never'
export const NA = 'N/A'
export const NONE = 'none'
export const NEW = 'new'
export const UPGRADE = 'upgrade'
export const KEY_EXPIRED = 'Key Expired! Please click Refresh'
export const KEY_GENERATING = 'Key is generating...'
export const FAILED_GENERATING = 'Failed to generate key'
export const CONTACT_SUPPORT_URL = 'https://support.paloaltonetworks.com'

// below are app names, NOT for display
// should NOT be changed if the display name need to change
export const LOGGING_SERVICE = 'Logging Service'
export const LGS_ID = 'logging_service'
export const DLP = 'dlp'
export const CGX = 'cgx'
export const NG_CASB = 'ng_casb'
export const LOG_FORWARDING = 'Log Forwarding'
export const APP_PORTAL = 'App Portal'
export const DIRECTORY_SYNC = 'Directory Sync'
export const DSS_ID = 'directory_sync'
export const CIE = 'Cloud Identity Engine'
export const TRAPS = 'Traps'
export const MAGNIFIER = 'Magnifier'
export const XDR = 'XDR'
export const ZINGBOX = 'zingbox'
export const OKYO = 'OKYO'
export const IOT = 'iot'
export const ZINGBOX_NGFW = 'zingbox_ngfw'
export const ZINGBOX_NGFW_DRDL = 'zingbox_ngfw_drdl'
export const ZINGBOX_3P_ADDON = 'zingbox_3p_addon'
export const PRISMA_CLOUD = 'prisma_cloud'
export const PRISMA_CLOUD_DISPLAY_NAME = 'Prisma Cloud'
export const PRISMA_ACCESS = 'prisma_access'
export const PRISMA_ACCESS_DISPLAY_NAME = 'Prisma Access'
export const STRATA_ACCESS_DISPLAY_NAME = 'Strata Access'
export const PRISMA_SAAS = 'aperture'
export const SAAS = 'saas'
export const PRISMA_SAAS_NGFW = 'aperture_ngfw'
export const AIOPS = 'aiops'
export const STRATA_INSIGHTS = 'strata_insights'
export const STRATA_INSIGHTS_FREE = 'strata_insights_free'
export const STRATA_INSIGHTS_NGFW = 'strata_insights_ngfw'
export const PRISMA_SAAS_DISPLAY_NAME = 'Prisma SaaS'
export const PANORAMA = 'panorama'
export const NGFW = 'ngfw'
export const ELA = 'ela'
export const PRISMA_ACCESS_PANORAMA = 'prisma_access_panorama'
export const PRISMA_ACCESS_EDITION = 'prisma_access_edition'
export const PRISMA_ACCESS_MOBILE_USERS = 'prisma_access_for_mobile_users'
export const PRISMA_ACCESS_REMOTE_NETWORKS = 'prisma_access_for_remote_networks'
export const PRISMA_ACCESS_FOR_CLEAN_PIPE = 'prisma_access_for_clean_pipe'
export const AUTONOMOUS_DEM = 'adem'
export const PRISMA_ACCESS_INSIGHTS = 'insights_prod'
export { LOGGING_SERVICE as LGS, DIRECTORY_SYNC as DSS, PRISMA_ACCESS_PANORAMA as PA_PANORAMA }
export const PA_EXT_APPS = [PRISMA_ACCESS_EDITION, PRISMA_ACCESS_MOBILE_USERS, PRISMA_ACCESS_REMOTE_NETWORKS, PRISMA_ACCESS_FOR_CLEAN_PIPE]
export const _ONPREM = '_onprem'
export const PA_ONPREM_EXT_APPS = PA_EXT_APPS.map(n => `${n}${_ONPREM}`)
export const PA_ALL_EXT_APPS = PA_EXT_APPS.concat(PA_ONPREM_EXT_APPS)
export const PA_PRIMARY_APP_IDS = [PRISMA_ACCESS, PRISMA_ACCESS_PANORAMA]
export const PA_GEN_OTP = '\0pa-keygen\n'

/** RBAC CONSTANTS **/
export const ASSIGN_ROLES = 'Assign Roles'
export const ACCESS_MANAGEMENT = 'Access Management'
export const FILTER_USERS_WITHOUT_ROLES = 'Users without Roles'
export const RBAC_SEARCH_USER = 'Search User'
export const RBAC_ROLE = 'Role'
export const ASSIGN_ROLES_SUB_HEADER = 'View and assign roles to users for the customer support account (not apply for tenant-based IAM access)'
export const RBAC_LOGGING_SERVICE = 'LOGGING_SERVICE'
export const RBAC_DIRECTORY_SYNC = 'DIRECTORY_SYNC'
export const RBAC_LOG_FORWARDING = 'LOG_FORWARDING'
export const RBAC_TRAPS = 'TRAPS'
export const RBAC_MAGNIFIER = 'MAGNIFIER'
export const APP_ADMIN = 'App Admin'
export const ACCOUNT_SUPER_USER = 'Account Super User'
export const ACCOUNT_SUPER_USER_HELP_TEXT_2 = 'The user(s) have full access to all app instances installed for the account. Account Administrator can assign roles for any app in your organization, and it can also activate any app instances.'
export const RBAC_APP_ADMIN_HELP_TEXT = 'The user(s) have administrator permission for specific app(s), including all instances added of the app(s) in the future'
export const RBAC_APP_ADMIN_HELP_TEXT_2 = 'The user(s) have full access to the given app(s), including all instances added of the app(s) in the future. App Administrator can assign roles for app instances, and it can also activate app instances specific to that app.'
export const RBAC_NOT_APP_ADMIN_HELP_TEXT = 'User(s) do not have administrator permission for any instances of this app, unless the user(s) have Account or Instance Administrator access.'
export const RBAC_INSTANCE_ADMIN_HELP_TEXT = 'The user(s) have administrator permission for this specific app instance.'
export const RBAC_INSTANCE_ADMIN_HELP_TEXT_2 = 'The user(s) have full access to the app instance for which this role is assigned. The Instance Administrator can also make other users an Instance Administrator for the app instance. If the app has predefined or custom roles, the Instance Administrator can assign those roles to other users.'
export const RBAC_NO_ROLE_HELP_TEXT = 'The user(s) do not have administrator permission for this specific app instance, unless the user(s) have Account or App Administrator access.'
export const RBAC_ALL_INSTANCES = 'All Instances'
export const RBAC_ALL_APPS = 'All Apps'
export const RBAC_ADD_USER = 'You have specified a search filter that matches no users. Either correct your search string, or add the user(s).'
export const RBAC_INSTANCE_ADMIN = 'Instance Administrator'
export const RBAC_INSTANCE_LIMITED_ADMIN = 'Instance Limited User'
export const RBAC_NO_ROLE = 'No Role'
export const RBAC_NONE = 'None'
export const RBAC_DISPLAY_ACCOUNT_SUPER_USER = 'Account Administrator'
export const RBAC_ADMIN = 'App Administrator'
export const RBAC_NOT_APP_ADMIN = 'Remove Administrator'
export const RBAC_NOT_INSTANCE_ADMIN = 'Remove Instance Admin'
export const RBAC_NOT_ACCOUNT_SUPER_USER = 'Remove Account Administrator'
export const RBAC_APP_INSTANCES = 'App Instance(s)'
export const RBAC_ROLE_INSTANCE_ADMIN = 'Instance Admin' // Internal use for rbac management its not display anywhere
export const RBAC_CONFIRM_DIALOG_TITLE = 'Confirm Role Assignments'
export const RBAC_NO_PERMISSION_ACCOUNT_INFO = 'You do not currently have any roles assigned to you.'
export const RBAC_MIX_ROLE = 'Combination of privileges'
export const RBAC_PRISMA_SAAS_LINK = 'https://docs.paloaltonetworks.com/aperture/aperture-admin/get-started-with-aperture/manage-aperture-administrators.html#'
export const RBAC_PRISMA_CLOUD_LINK = 'https://docs.paloaltonetworks.com/redlock/redlock-admin/manage-redlock-administrators.html'
export const RBAC_CUSTOMIZE_ROLES = 'Manage Roles'
export const RBAC_CORTEX_XDR_ROLES_DESC = 'These are your app roles. You can create custom roles for more granular access permissions as well as clone and delete roles.'
export const RBAC_ADD_CUSTOM_ROLE = 'Create Role'
export const RBAC_ROLE_NAME = 'Name'
export const RBAC_ROLE_DESCRIPTION = 'Description'
export const RBAC_ROLE_SELECT_PERMISSION = 'Permissions'
export const RBAC_ASSIGN_CUSTOM_PERMISSION = 'Assign Custom Permissions'
export const RBAC_CUSTOM_PERMISSION_ASSIGN = 'Custom Permissions Assigned'
export const RBAC_CUSTOM_PERMISSIONS = 'Custom Permission(s)'
export const RBAC_PERMISSIONS_ASSIGNED = 'Permissions Assigned'
export const RBAC_INSTANCE_PENDING = 'setup-pending'

//RBAC V2
export const R_TYPE = 'type'
export const R_CSP_ACCOUNT_ID = 'csp_account_id'
export const R_VERSION = 'version'
export const R_APP = 'app'
export const R_ROLE = 'role'
export const R_SCOPE = 'scope'
export const R_CUSTOM = 'custom'
export const R_USER = 'user'
export const R_DESCRIPTION = 'description'
export const R_TENANT_ID = 'tenant_id'
export const R_ROLE_LABEL = 'role_label'
export const R_NO_ROLE_ENUM = 'no_role'
export const VIEW_ALL_SELECTED_USERS = 'View all selected users'


export const RBAC_ACCOUNT_SUPER_USER_V2 = 'account_superuser'
export const RBAC_NOT_ACCOUNT_SUPER_USER_V2 = 'not_account_superuser'
export const RBAC_APP_ADMIN_V2 = 'app_superuser'
export const RBAC_APP_ADMINISTRATOR = 'app_administrator'
export const RBAC_ROLE_MIX = 'mix'
export const RBAC_ROLE_DEFINITION = 'Role Definitions'
export const RBAC_ROLE_VIEW_ROLE = 'View Role'
export const RBAC_ROLE_EDIT_PERMISSIONS = 'Edit Permissions'
export const RBAC_ROLE_CLONE = 'Clone'
export const RBAC_DELETE_ROLE = 'Delete'
export const RBAC_ROLE_USER_CREATED_ROLE = 'User Created Role'
export const RBAC_CUSTOM_ROLE_ALREADY_USED = 'This role name has already been used'
export const RBAC_ROLE_TOOL_TIP = 'This role no longer exists in this instance. Please assign a new role to this user.'



/** CORTEX DATA LAKE CALCULATOR CONSTANTS **/
export const APPLICATION_FRAMEWORK_DISPLAY_NAME = 'Cortex'
export const LOGGING_SERVICE_DISPLAY_NAME = 'Cortex Data Lake'
export const LOGGING_SERVICE_DISPLAY_NAME2 = 'Strata Logging Service'
export const MAGNIFIER_DISPLAY_NAME = 'Cortex XDR - Analytics'
export const MAGNIFIER_LSC_DISPLAY_NAME = 'Cortex XDR'
export const APP_PORTAL_DISPLAY_NAME = 'Hub'
export const PANW_APP_PORTAL_DISPLAY_NAME = APP_PORTAL_DISPLAY_NAME
export const APP_PORTAL_DISPLAY_INLINE = APP_PORTAL_DISPLAY_NAME.toLowerCase()
export const XDR_DISPLAY_NAME = 'Cortex XDR - Investigation & Response'
export const CORTEX_SIZING_CAL = 'Cortex Sizing'
export const CORTEX_XDR = 'Cortex XDR'
export const CORTEX_XDR_AGENTS = `${CORTEX_XDR} Agents`
export const IOT_SECURITY = 'IoT Security'
export const RECOMMEND_PURCHASE = 'Recommend Purchase'
export const NUMBER_OF_USERS = 'Total Cortex XDR Clients'
export const RETENTION_PRD = 'Retention Period (in days)'
export const NUMBER_OF_USERS_XDE_CLIENTS = 'Total Clients Forwarding Cortex XDR Data'
export const XDR_PRO_END_POINT_FWD_CDL = 'Total XDR Pro Endpoints Forwarding Cortex XDR Data'
export const XDR_CLIENTS = 'XDR Clients'
export const XDR_PRO_END_POINT = 'XDR Pro Endpoints'

/** Dashboard Constants **/


/** Manage Apps **/
export const MANAGE_APPS = 'Manage Apps'
export const MANAGE_APPS_SUB_HEAD = 'Configure details and shared services for your apps in the customer support account (excluding tenant-based instances)'
export const MANAGE_APPS_DEBUG_INFO_LABEL = 'This option gathers debug information on this app to facilitate troubleshooting.'
export const MANAGE_APPS_DEBUG_DESCRIPTION = 'Please provide a brief description on the issue that you are facing with this app.'

/** APPORTAL-LANDING-PAGE **/
export const ACTIVATE_APP = 'Activate App'
export const SETUP_PENDING_STATUS = 'setup-pending'

export const EMPTY_SVG = 'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg"/>'


/** NOTIFICATION ENUM **/
export const NOTIFICATION_SUCCESS = 'SUCCESS'

/** SETUP ONBOARDING **/
export const LABEL_CUSTOMER_SUPPORT_ACCOUNT_SELECTION = 'Customer Support Account Selection'
export const LABEL_SELECT_PRODUCTS = 'Activate your products'
export const NOT_ALLOWED_ACTIVATE_ALONE_APPS = [PRISMA_ACCESS_PANORAMA, PANORAMA, NGFW] // need to be synced in server
export const NOT_ALLOWED_ACTIVATE_ALONE_APPS_SET = new Set(NOT_ALLOWED_ACTIVATE_ALONE_APPS)

export const SETUP_CTX_PRISMA_ACCESS = PRISMA_ACCESS
export const SETUP_CTX_IOT = 'iot'
export const SETUP_CTX_CDL = 'cdl'
export const SETUP_CTX_CGX = 'cgx'
export const SETUP_CTX_PRISMA_SAAS = 'saas'
export const SETUP_CTX_AIOPS = 'aiops'
export const SETUP_CTX_IOT_APP_ID = ZINGBOX
export const SETUP_CREATE_NEW = 'new'
export const SETUP_USE_EXISTING_PANORAMA = `${PRISMA_ACCESS_PANORAMA}:`
export const SETUP_USE_EXISTING_ADELPHI = `${PRISMA_ACCESS}:`
export const SETUP_NEW_ADELPHI = `${PRISMA_ACCESS}:${SETUP_CREATE_NEW}`
export const SETUP_NEW_PANORAMA = `${PRISMA_ACCESS_PANORAMA}:${SETUP_CREATE_NEW}`
export const SETUP_ZINGBOX_NEW = `${ZINGBOX}:${SETUP_CREATE_NEW}`
export const SETUP_PRISMA_SAAS_NEW = `${PRISMA_SAAS}:${SETUP_CREATE_NEW}`
export const SETUP_SELECT_PRODUCTS = 'SETUP_SELECT_PRODUCTS'
export const SETUP_CUSTOMER_SUPPORT_ACCOUNT_SELECTION = 'CUSTOMER_SUPPORT_ACCOUNT_SELECTION'
export const SETUP_CTX_PRIMARY_APPS = {
  [SETUP_CTX_PRISMA_ACCESS]: PA_PRIMARY_APP_IDS,
  [SETUP_CTX_IOT]: [ZINGBOX],
  [SETUP_CTX_CDL]: [LGS_ID],
  [SETUP_CTX_CGX]: [CGX],
  [NG_CASB]: [NG_CASB]
}
export const SETUP_ADDON_APP_DLP = DLP
export const SETUP_ADDON_APP_IOT = ZINGBOX
export const SETUP_ADDON_OKYO = OKYO
export const SETUP_ADDON_APP_SAAS_INLINE = PRISMA_SAAS
export const SETUP_ADDON_APP_CASB = 'CASB'
export const SETUP_CAPACITY = 'Capacity'
export const SETUP_PAE_LABEL = {
  [PRISMA_ACCESS_REMOTE_NETWORKS]: 'Net',
  [PRISMA_ACCESS_MOBILE_USERS]: 'Mobile Users',
  [ZINGBOX_3P_ADDON]: 'Plan',
}
export const SETUP_CAPACITY_LABEL_USE_LICENSE_DISPLAY = [CGX]

//Setup component type
export const SET_UP_TYPE_LINK = 'SET_UP_LINK'
export const SET_UP_TYPE_SIMPLE_TEXT = 'SETUP_SIMPLE_TEXT'

export const SETUP_CAPACITY_LABEL_ENUM = {
  IOTBASIC: 'Basic',
  IOTADV: 'Advanced',
  IOTADVEVAL: 'Advanced',
}
export const RUNNING = 'running'
export const PROVISIONING = 'provisioning'
export const PENDING = 'pending'
export const FAILED = 'failed'
export const WARNING = 'warning'

export const ACTIVATE_NEW = 'Activate New'
export const SETUP_SERVICE_LOCATION = 'Service Location'
export const SETUP_SERVICE_LOCATION_VAL_GBL = 'Worldwide'
export const SETUP_SERVICE_LOCATION_VAL_LCL = 'Local'
export const SETUP_LICENSED_QUANTITY = 'Licensed Quantity'
export const SETUP_DONT_SUPPORT_IoT = 'IoT Option is not available for Cloud-based Prisma Access. Please use Panorama environment.'
export const SETUP_NGPA = 'This version of Prisma Access architecture only supports Cloud Management'
export const SETUP_FEDRAMP_PAE = 'Fedramp is not available for Cloud-based Prisma Access. Please use Panorama environment'

/** User Registration **/
export const REGISTRATION_CREATE_NEW_ACCOUNT = 'Create New Account'
export const REGISTRATION_NEXT = 'Submit'
export const REGISTRATION_SUBMIING = 'Submit...'
export const REGISTRATION_CANCEL = 'Cancel'
export const REGISTRATION_QUIT = 'Quit'
export const REGISTRATION_NAME = 'Name'
export const REGISTRATION_FIRST_NAME = 'First Name'
export const REGISTRATION_LAST_NAME = 'Last Name'
export const REGISTRATION_PASSWORD = 'Password'
export const REGISTRATION_RE_ENTER_PASSWORD = 'Reenter Password'
export const REGISTRATION_COMPANY = 'Company (optional)'
export const REGISTRATION_EMAIL = 'Email'
export const REGISTRATION_PASSWORD_MUST_HAVE = 'Password must have:'
export const REGISTRATION_SUPPORT_ACCOUNT = 'Support Account'


/** IOT ONBOARDING **/
export const IOT_FIREWALL_LIST_TITLE = 'Firewalls'
export const IOT_FIREWALL_LIST_SUBTITLE = 'The following is a list of your firewalls in the __ACCOUNT_NAME__ and any data lakes associated with them. To activate IoT Security on a firewall when they are each associated with different data lakes, follow the __STEPS_LINK__ here'
export const IOT_FIREWALL_SUBSCRIBED_TITLE = 'Subscribed Firewalls'
export const IOT_FIREWALL_SUBSCRIBED_SUBTITLE = 'Details of firewalls subscribed to IoT Security'
export const SEARCH_PLACE_HOLDER = 'Search serial number or device name'
export const EVAL = 'eval'
export const TRIAL = 'trial'
export const PROD = 'prod'
export const NFR = 'nfr'
export const LAB = 'lab'
export const MIXED = 'mixed'
export const INSTALLED = 'Installed'
export const ABSENT = 'Absent'
export const LCAAS_CONFIG_ERROR = 'Configuration Error'
export const LCAAS_CONFIG_ERROR_TOOLTIP = `${LCAAS_CONFIG_ERROR}: Certificate not set on firewall`
export const LCAAS_UNAVAILABLE_STATUS = 'lcaas_unavailable'
export const LICENSE_GRACE_PERIOD = 'The length of the subscription term might be different from the purchased term if the license was activated after its grace period expired. For more information, see'
export const PANW_GRACE_PERIOD_LINK = 'https://www.paloaltonetworks.com/services/support/support-policies/grace-period'
export const CDL_FW_ONBOARD__HELP_LINK = 'https://docs.paloaltonetworks.com/aiops/aiops-for-ngfw/get-started-with-aiops/onboard-devices'
export const PANW_GRACE_PERIOD = 'Palo Alto Networks Grace Periods'
export const PREVIOUSLY_ACTIVATED_NOTE = 'Note: Each previously activated license shows the remaining time that it\'s valid.'
export const IOT_APP_REGION = 'IoT App Region'
export const DEVICE_LICENSE_MATRIX = {
  [IOT]: {
    [NFR]: [TRIAL, NFR, PROD],
    [LAB]: [TRIAL, LAB, PROD],
    [PROD]: [TRIAL, PROD],
    [EVAL]: [EVAL],
  },
  [SAAS]: {
    [NFR]: [NFR],
    [LAB]: [TRIAL, LAB],
    [PROD]: [TRIAL, PROD],
    [EVAL]: [EVAL]
  },
  [AIOPS]: {
    [NFR]: [NFR],
    [LAB]: [TRIAL, LAB],
    [PROD]: [TRIAL, EVAL, PROD],
    [EVAL]: [EVAL]
  },
}

export const LICENSE_DEVICE_MATRIX = {
  [IOT]: {
    [TRIAL]: [NFR, LAB, PROD],
    [EVAL]: [EVAL],
    [NFR]: [NFR],
    [LAB]: [LAB],
    [PROD]: [NFR, LAB, PROD],
  },
  [SAAS]: {
    [TRIAL]: [LAB, PROD],
    [EVAL]: [EVAL],
    [NFR]: [NFR],
    [LAB]: [LAB],
    [PROD]: [PROD]
  },
  [AIOPS]: {
    [TRIAL]: [LAB, PROD],
    [EVAL]: [EVAL],
    [NFR]: [NFR],
    [LAB]: [LAB],
    [PROD]: [PROD],
  }
}

export const DEVICE_LICENSE_CONVERSION_INITIAL_MATRIX = {
  [IOT]: {
    [LAB]: [EVAL, TRIAL], // lab device can have initial eval|trial license
    [NFR]: [TRIAL], // nfr device can have initial trial license
    [PROD]: [EVAL, TRIAL] // prod device can have initial eval|trial license
  },
  [SAAS]: {
    [LAB]: [TRIAL],
    [PROD]: [TRIAL]
  },
  [AIOPS]: {
    [LAB]: [TRIAL], // lab device can have initial trial license
    [PROD]: [TRIAL] // prod device can have initial trial license
  }
}

export const DEVICE_LICENSE_CONVERSION_UPGRADE_MATRIX = {
  [IOT]: {
    [LAB]: [LAB, PROD], // lab device can upgrade from initial eval|trial license to lab license
    [NFR]: [PROD], // NFR device can upgrade from initial trial license to prod license
    [PROD]: [PROD] // prod device can upgrade from initial eval|trial license to prod license
  },
  [SAAS]: {
    [LAB]: [LAB], // lab device can upgrade from initial trial license to lab license
    [PROD]: [PROD] // prod device can upgrade from initial trial license to prod license
  },
  [AIOPS]: {
    [LAB]: [LAB], // lab device can upgrade from initial trial license to lab license
    [PROD]: [PROD] // prod device can upgrade from initial trial license to prod license
  }
}

export const INGEST_AND_STORAGE = 'INGEST_AND_STORE'
export const INGEST = 'INGEST'

export const CHOOSE_LICENSES_FIELD = 'license-product'
export const CHOOSE_HOW_TO_MANAGE_FIELD = 'choose-how-to-manage'
export const ZINGBOX_FIELD = CHOOSE_HOW_TO_MANAGE_FIELD
export const PANORAMA_INSTANCE_FIELD = 'panorama-serial-number'
export const SUBDOMAIN_FIELD = 'subdomain'
export const CDL_INSTANCE_FIELD = 'confirmation-data-lake'
export const REGION_FIELD = 'confirmation-data-lake-region'
export const APP_REGION_FIELD = 'confirmation-app-region'
export const PAE_ADDON_ZINGBOX_TNT = 'pae-iot-addon-tenant'
export const PAE_ADDON_SAAS_TNT = 'pae-saas-addon-tenant'
export const PAE_ADDON_DLP_TNT = 'pae-dlp-addon-tenant'
export const FLS_DEVICES_FIELD = `extra.${LGS_ID}.devices`

export const SELECT = 'Select'
export const CLEAR = 'Clear'
export const RESET = 'Reset'
export const MONTHS = 'month'
export const DAYS = 'day'
export const NEW_LABEL = 'New'
export const PREV_LABEL = 'Previously Activated'
export const IOT_PACKAGE_1 = '1.0'
export const IOT_PACKAGE_2 = '2.0'
export const DEFAULT_NGFW_VERSION = '1'

export const LICENSE_GROUP_LABEL = {
  [IOT_PACKAGE_1]: 'IoT',
  [IOT_PACKAGE_2]: 'IoT-DRDL',
  [DEFAULT_NGFW_VERSION]: '',
}
export const IOT_FIREWALL_SUBSCRIBED_COLOR_CODES = {
  'running': '#6fcf97',
  'expired': '#d4d4d4',
  'warning': '#f2c94c',
  'error': '#eb5757',
  'default': '#d4d4d4',
  'lcaas_unavailable': '#eb5757',
  'thermite_available': '#6fcf97',
  'thermite_unavailable': '#d4d4d4'
}

export const DATALAKE_STATUS_COLOR = {
  'running': '111,207,151',
  'stopped': '212, 212, 212',
  'expired': '212, 212, 212',
  'warning': '251, 210, 65',
  'error': '235, 87, 87',
}

export const OTP_DESCRIPTION = 'Firewalls running PAN-OS 10.0 or later use device certificates to authenticate themselves when accessing cloud services. For instructions on using the one-time password to generate device certificates for your firewall, see'
export const OTP_TECH_DOC_LABEL = 'Install the Device Certificate for Managed Firewalls.'
export const OTP_TECH_DOC_LINK = 'https://docs.paloaltonetworks.com/panorama/10-0/panorama-admin/manage-firewalls/install-the-device-certificate-for-managed-firewalls.html'

export const PSK_DESCRIPTION = 'Entering the pre-shared key (PSK) on your firewalls enables them to connect securely with the customer support portal and download a data lake certificate. This certificate allows the firewalls to connect securely with Cortex Data Lake so they can forward logs to it. For instructions on entering the PSK on a firewall, see'
export const PSK_TECH_DOC_LABEL = 'Connect Firewalls to Cortex Data Lake.'
export const PSK_TECH_DOC_LINK = 'https://docs.paloaltonetworks.com/cortex/cortex-data-lake/cortex-data-lake-getting-started/get-started-with-cortex-data-lake/onboard-firewalls.html'


/** PRISMA SAAS ONBOARDING **/
export const PRISMA_SAAS_FIREWALL_LIST_TITLE = 'Firewalls'
export const PRISMA_SAAS_FIREWALL_LIST_SUBTITLE = 'The following is a list of your firewalls in the __ACCOUNT_NAME__ and any data lakes associated with them. To activate Saas Security Inline on a firewall when they are each associated with different data lakes, follow the __STEPS_LINK__ here'
export const PRISMA_SAAS_REST_ACCOUNT_MESSAGE = 'The selected account already has SaaS API Security and cannot activate another one'
export const SAAS_REGION = 'SaaS Region'
export const SAAS_REST = 'rest'
export const SAAS_INLINE = 'inline'
export const SAAS_API = 'saas_api'


/** AIOps ONBOARDING **/
export const AIOPS_FIREWALL_LIST_TITLE = 'Firewalls'
export const AIOPS_FIREWALL_LIST_SUBTITLE = 'The following is a list of your firewalls in the __ACCOUNT_NAME__ and any data lakes associated with them. To activate AIOps on a firewall when they are each associated with different data lakes, follow the __STEPS_LINK__ here'
export const AIOPS_FIREWALL_SUBSCRIBED_TITLE = 'Subscribed Firewalls'
export const AIOPS_FIREWALL_SUBSCRIBED_SUBTITLE = 'Details of firewalls subscribed to AIOps'
export const AIOPS_APP_REGION = 'AIOps App Region'
export const DIRECTORY_SYNC_REGION = 'Cloud Identity Region'
export const DIRECTORY_SYNC_FIELD = 'directory-sync-tenant'
export const DIRECTORY_SYNC_APP_REGION_FIELD = 'confirmation-dss-app-region'
export const AIOPS_PREMIUM_INSTANCE_TYPE = 'paid'
export const AIOPS_FREE_INSTANCE_TYPE = 'free'

export const SETUP_CTX_PRIMARY_APP_REGION_LABELS = {
  [SETUP_CTX_PRISMA_ACCESS]: 'Prisma Access Region',
  [SETUP_CTX_IOT]: IOT_APP_REGION,
  [SETUP_CTX_CDL]: 'Region',
  [SETUP_CTX_PRISMA_SAAS]: SAAS_REGION,
  [SETUP_CTX_AIOPS]: AIOPS_APP_REGION,
}
