/**
 *
 * @type {string}
 * Route related constants
 */

export const APPS = '/apps'
export const MARKET_PLACE = '/marketplace'
export const MARKET_PLACE_NAME = `${MARKET_PLACE}/:name`
export const ACTIVATE = '/activate'
export const SETUP_LINK = '/setup/:context/:hash'
export const ELA_SETUP_LINK = '/:mode(ela)/setup/:context/:hash'
export const SETUP = '/setup'
export const SETTINGS = '/settings'
export const LOGGING_SERVICE_CALC = '/logging-service-calculator'
export const CORTEX_SIZING_CALCULATOR = '/cortex-sizing-calculator'
export const CORTEX_SIZING_ESTIMATOR = '/cortex-sizing-estimator'
export const STRATA_SIZING_ESTIMATOR = '/sls-sizing-estimator'
export const ROLES = '/roles'
export const ASSIGN_ROLES = '/assign-roles'
export const ACCOUNT_INFO = '/account-info'
export const ROLE_DEF = '/role-def'
export const CONTEXT_SESSION = '/:context(prisma_access|iot|cdl|cgx|saas|aiops)/:session'
export const SETUP_REGISTRATION = '/registration'
export const SPARKY_SHELL = '/hub'
