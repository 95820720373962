export const lowUtilizationValue = 1.5
export const mediumUtilization = 10
export const highUtilization = 18

export const ngFwModels = [
  {
    name: 'VM-2 (VM-100)',
    type: 'VM100',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2329,
  },
  {
    name: 'VM-4 (VM-300)',
    type: 'VM300',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 4122,
  },
  {
    name: 'VM-8 (VM-500)',
    type: 'VM500',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 11423,
  },
  {
    name: 'VM-16 (VM-700)',
    type: 'VM700',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 20751,
  },
  {
    name: 'PA-220',
    type: 'PA220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 100,
    default: true
  },
  {
    name: 'PA-220R',
    type: 'PA220R',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 100,
    default: true
  },
  {
    name: 'PA-410',
    type: 'PA410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 760,
  },
  {
    name: 'PA-415',
    type: 'PA415',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 760,
  },
  {
    name: 'PA-440',
    type: 'PA440',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1300,
  },
  {
    name: 'PA-445',
    type: 'PA445',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1300,
  },
  {
    name: 'PA-450',
    type: 'PA450',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 3400,
  },
  {
    name: 'PA-460',
    type: 'PA460',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 4860,
  },
  {
    name: 'PA-500',
    type: 'PA500',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 409
  },
  {
    name: 'PA-820',
    type: 'PA820',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 340
  },
  {
    name: 'PA-850',
    type: 'PA850',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2300
  },
  {
    name: 'PA-1410',
    type: 'PA1410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 30000
  },
  {
    name: 'PA-1420',
    type: 'PA1420',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 45000
  },
  {
    name: 'PA-3020',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3020',
    cps: 1900
  },
  {
    name: 'PA-3050',
    type: 'PA3050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1935
  },
  {
    name: 'PA-3060',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3060',
    cps: 1845
  },
  {
    name: 'PA-3220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3220',
    cps: 4650
  },
  {
    name: 'PA-3250',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3250',
    cps: 5600
  },
  {
    name: 'PA-3260',
    type: 'PA3260',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 8200
  },
  {
    name: 'PA-3410',
    type: 'PA3410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 46905
  },
  {
    name: 'PA-3420',
    type: 'PA3420',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 67084
  },
  {
    name: 'PA-3430',
    type: 'PA3430',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 81725
  },
  {
    name: 'PA-3440',
    type: 'PA3440',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 97851
  },
  {
    name: 'PA-5020',
    type: 'PA4020',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 3090
  },
  {
    name: 'PA-5050',
    type: 'PA5050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2950
  },
  {
    name: 'PA-5060',
    type: 'PA5060',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 5200
  },
  {
    name: 'PA-5220',
    type: 'PA5220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 16200
  },
  {
    name: 'PA-5250',
    type: 'PA5250',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 38800
  },
  {
    name: 'PA-5260',
    type: 'PA5260',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 58800
  },
  {
    name: 'PA-5280',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5280',
    cps: 58800
  },
  {
    name: 'PA-5410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5410',
    cps: 144966
  },
  {
    name: 'PA-5420',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5420',
    cps: 178483
  },
  {
    name: 'PA-5430',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5430',
    cps: 201977
  },
  {
    name: 'PA-5440',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5440',
    cps: 194652
  },
  {
    name: 'PA-5450',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 6,
    type: 'PA5450',
    cps: 51600
  },
  {
    name: 'PA-7050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 6,
    type: 'PA7050',
    cps: 17800
  },
  {
    name: 'PA-7080',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 10,
    type: 'PA7080',
    cps: 17800
  }
]

export const getDefaultNgFwDevice = () => {
  const device = ngFwModels.find(each => each?.default === true) || ngFwModels[0]
  return { ...device }
}

export const newCpsNGFWModels = [
  {
    name: 'VM-2 (VM-100)',
    type: 'VM100',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 625,
  },
  {
    name: 'VM-4 (VM-300)',
    type: 'VM300',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2000,
  },
  {
    name: 'VM-8 (VM-500)',
    type: 'VM500',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2000,
  },
  {
    name: 'VM-16 (VM-700)',
    type: 'VM700',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2500,
  },
  {
    name: 'PA-220',
    type: 'PA220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 300,
    default: true
  },
  {
    name: 'PA-220R',
    type: 'PA220R',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 300,
    default: true
  },
  {
    name: 'PA-410',
    type: 'PA410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 700,
  },
  {
    name: 'PA-415',
    type: 'PA415',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 700,
  },
  {
    name: 'PA-440',
    type: 'PA440',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 3750,
  },
  {
    name: 'PA-445',
    type: 'PA445',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1500,
  },
  {
    name: 'PA-450',
    type: 'PA450',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 3750,
  },
  {
    name: 'PA-460',
    type: 'PA460',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 3750,
  },
  {
    name: 'PA-500',
    type: 'PA500',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 163
  },
  {
    name: 'PA-820',
    type: 'PA820',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1250
  },
  {
    name: 'PA-850',
    type: 'PA850',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1250
  },
  {
    name: 'PA-1410',
    type: 'PA1410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 6250
  },
  {
    name: 'PA-1420',
    type: 'PA1420',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 10000
  },
  {
    name: 'PA-3020',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3020',
    cps: 2500
  },
  {
    name: 'PA-3050',
    type: 'PA3050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2500
  },
  {
    name: 'PA-3060',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3060',
    cps: 2500
  },
  {
    name: 'PA-3220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3220',
    cps: 3500
  },
  {
    name: 'PA-3250',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3250',
    cps: 7500
  },
  {
    name: 'PA-3260',
    type: 'PA3260',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 7500
  },
  {
    name: 'PA-3410',
    type: 'PA3410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 11250
  },
  {
    name: 'PA-3420',
    type: 'PA3420',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 11250
  },
  {
    name: 'PA-3430',
    type: 'PA3430',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 15000
  },
  {
    name: 'PA-3440',
    type: 'PA3440',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 21250
  },
  {
    name: 'PA-5020',
    type: 'PA4020',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2500
  },
  {
    name: 'PA-5050',
    type: 'PA5050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2500
  },
  {
    name: 'PA-5060',
    type: 'PA5060',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2500
  },
  {
    name: 'PA-5220',
    type: 'PA5220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 15000
  },
  {
    name: 'PA-5250',
    type: 'PA5250',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 25000
  },
  {
    name: 'PA-5260',
    type: 'PA5260',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 25000
  },
  {
    name: 'PA-5280',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5280',
    cps: 25000
  },
  {
    name: 'PA-5410',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5410',
    cps: 22500
  },
  {
    name: 'PA-5420',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5420',
    cps: 23750
  },
  {
    name: 'PA-5430',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5430',
    cps: 25000
  },
  {
    name: 'PA-5440',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5440',
    cps: 25000
  },
  {
    name: 'PA-5450',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 6,
    type: 'PA5450',
    cps: 125000
  },
  {
    name: 'PA-7050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 6,
    type: 'PA7050',
    cps: 55000
  },
  {
    name: 'PA-7080',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 10,
    type: 'PA7080',
    cps: 55000
  }
]

export const getDefaultNewCpsNGFWModels = () => {
  const device = newCpsNGFWModels.find(each => each?.default === true) || ngFwModels[0]
  return { ...device }
}
