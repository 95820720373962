import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ActivateLink from './ActivateLink'
import { hasRole, AUTHCODE_APP_ADMIN } from '../../../utils/rbacUtils'
import { AddTileDotted } from '../../../images/svg-icons'
import './NoAppsPlaceholder.scss'

const NoAppsPlaceholder = ({ loading, hasAnyApp, isAuthenticated }) => {
  const showActivate = useMemo(() => (
    !loading && isAuthenticated && hasRole(AUTHCODE_APP_ADMIN)
  ), [loading, isAuthenticated])
  return <div className='no-apps-placeholder'>
    <AddTileDotted className='dashed' />
    {showActivate ? <ActivateLink /> : <AddTileDotted className='dashed' />}
    <AddTileDotted className='dashed' />
    {!showActivate && !loading &&
      <div className='no-role-warning'>
        <>Welcome to the hub. </>
        {hasAnyApp ? <>You currently do not have access to any apps.<br />
          To access apps, please contact the people in your organization who are responsible for managing app roles in the hub.</> : <>
            No apps are currently activated for your organization.<br />
            Please contact the people in your organization who are responsible for managing apps in the hub.</>}
      </div>
    }
  </div>
}

NoAppsPlaceholder.propTypes = {
  loading: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  hasAnyApp: PropTypes.bool,
}

export default NoAppsPlaceholder
