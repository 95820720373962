import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Lightbox from './Lightbox'

const renderTextWithLineBreaks = (text) => {
  if (text.includes && text.includes('\n')) {
    return text.split('\n').reduce((arr, line, k) => {
      arr.push(line, <br key={`br${k}`} />)
      return arr
    }, []).slice(0, -1)
  }
  return text
}

const INLINE_TAGS = new Set(['strong', 'small', 'em', 'i', 'sub', 'sup', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'])

export const renderContent = (content, i) => {
  if (React.isValidElement(content)) {
    return React.cloneElement(content, { key: `c${i}` })
  }
  if (_.isString(content)) {
    return renderTextWithLineBreaks(content)
  }
  if (Array.isArray(content)) {
    return content.map(renderContent)
  }
  if (Object.keys(content).length !== 1) {
    return null // not supported
  }
  if (content.p) {
    if (Array.isArray(content.p)) {
      return content.p.map((p, j) => renderContent({ p }, j))
    }
    return <p key={`p${i}`}>{renderContent(content.p, i)}</p>
  }
  const renderList = (list) => {
    if (React.isValidElement(list)) {
      return list
    }
    if (Array.isArray(list)) {
      return list.map((li, k) => <li key={`li${k}`}>{renderContent(li)}</li>)
    }
    return <li>{renderContent(list)}</li>
  }
  const tag = Object.keys(content)[0]
  const isListTag = tag === 'ul' || tag === 'ol'
  if (isListTag || tag === 'span' || tag === 'div' || (INLINE_TAGS.has(tag) && _.isString(content[tag]))) {
    const renderer = isListTag ? renderList : renderContent
    return React.createElement(tag, { key: `${tag}${i}` }, renderer(content[tag], i))
  }
  // content not supported
  return null
}

const BodyRenderer = ({ body = [] }) => body.map((body, i) => {
  if (body && React.isValidElement(body)) {
    return body
  }
  const { row, heading, paragraphs, content, image } = body
  return (
    <div key={`s${i}`} className='row'>
      {row && React.isValidElement(row) ? row : <div className='col-12'>
        {heading && <h4>{heading}</h4>}
        {content && renderContent(content)}
        {paragraphs && Array.isArray(paragraphs) &&
          paragraphs.map((p, j) => <p key={`p${j}`}>
            {_.isString(p) ? renderTextWithLineBreaks(p) : p}
          </p>)
        }
        {image && <Lightbox img={image.src || image}
          alt='diagram' className='diagram' style={{ maxWidth: '100%' }} />
        }
      </div>
      }
    </div>
  )
})

BodyRenderer.propTypes = {
  body: PropTypes.array,
}

export default BodyRenderer
