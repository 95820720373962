import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Form, Button } from '@pan/cloud-base'
import { formItemLayout } from '../../utils/activateUtils'
import { ExclamationIcon } from '../../images/svg-icons'
import './ActivatedForm.scss'

const convert = ({
  support_account_name,
  application_name,
  application_display_name,
  tenant_instance_name,
  serial_number,
  license_expiration,
  domain,
  apps,
  quota,
}) => _.omitBy({
  company_account: support_account_name,
  app: application_display_name || application_name,
  name: tenant_instance_name,
  apps,
  serial_number,
  license_expiration: !license_expiration ? 'Never' :
    license_expiration === 'null' ? null :
      /^\w\w\w-\d\d-\d{4}$/.test(license_expiration) ? license_expiration :
        moment(license_expiration).format('MMM-DD-YYYY'),
  domain,
  quota,
}, _.isNil)

const renderValue = (value) => {
  if (!value.includes('\n') || !_.isString(value)) {
    return value
  }
  const lines = []
  value.split('\n').forEach((line, i) => {
    lines.push(line, <br key={i} />)
  })
  lines.pop() // remove last <br/>
  return lines
}

const renderQuota = (value) => {
  if (_.isString(value)) {
    return renderValue(value)
  }
  return <>
    {renderValue(value.summary)}
    {value.warning && <div className='activated-quota-warning ant-form-explain'>
      <ExclamationIcon className='activated-warning-icon' />
      {value.warning}
    </div>}
    {value.url && <div>
      <Button className='btn-manage-quota' size='small' href={value.url} target='_blank'>Manage Quota</Button>
    </div>}
  </>
}

const renderUrl = (value) => (
  <a href={value} title={value} target='_blank' rel='external noopener noreferrer'>{value}</a>
)

const renderers = {
  quota: renderQuota,
  url: renderUrl,
  defaults: renderValue,
}

const renderData = (data) => {
  const rows = []
  _.forOwn(convert(data), (value, key) =>
    value != null && rows.push(
      <Form.Item
        key={key}
        {...formItemLayout}
        label={_.startCase(key)}
      >
        <span className='ant-form-text'>
          {(renderers[key] || renderers.defaults)(value)}
        </span>
      </Form.Item>
    )
  )
  return rows
}

const ActivatedForm = ({ data, loading, extra, btnProps = {}, children = null }) => {
  const { text, children: buttonChildren = text, ...bProps } = btnProps
  const rows = renderData(data)
  return (
    <Form className='activate-form activated-form'>
      {rows}
      {children}
      <div className='form-footer'>
        {extra}
        <Button
          type='primary'
          loading={loading}
          {...bProps}
        >{buttonChildren}</Button>
      </div>
    </Form>
  )
}

ActivatedForm.propTypes = {
  data: PropTypes.object,
  children: PropTypes.node,
  extra: PropTypes.node,
  loading: PropTypes.bool,
  btnProps: PropTypes.object,
}

export default React.memo(ActivatedForm)
