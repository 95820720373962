import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { isValidElementType } from 'react-is'
import AppIcon from '../Apps/AppIcon'
import PageWrapper from '../PageWrapper'
import AppRequirement from './AppRequirement'
import SimpleGallery from './SimpleGallery'
import ActivateButton from './ActivateButton'
import './AppDetails.scss'

export class AppDetails extends Component {
  componentDidMount() {
    this.props.setWrapperClassName('portal app_details v2')
    window.scrollTo(0, 0)
  }
  componentWillUnmount() {
    this.props.setWrapperClassName('')
  }
  render() {
    const props = this.props
    const { app = {}, isAuthenticated, history, showAuthCodeModal } = props
    const btnProps = { isAuthenticated, app, history, showAuthCodeModal }
    const getActivateBtn = () => {
      if (props.custom_activate_btn != null) { // use custom one if it is given, '' is acceptable
        if (!props.custom_activate_btn) {
          return props.custom_activate_btn
        }
        if (React.isValidElement(props.custom_activate_btn)) {
          return React.cloneElement(props.custom_activate_btn, btnProps)
        }
        else if (isValidElementType(props.custom_activate_btn)) {
          const CustomButton = props.custom_activate_btn
          return <CustomButton {...btnProps} />
        }
        return props.custom_activate_btn
      } // else use default one
      return <ActivateButton {...btnProps} />
    }
    return (
      <PageWrapper id='app'>
        <div className='page-header v2-blue-bkg'>
          <div className='app_wrapper'>
            <div className='icon_shadow'>
              <AppIcon {...app} logo={props.logo || app.logo} />
            </div>
          </div>
          {props.name &&
            <div className='app_header_info'>
              <h1>{props.name}</h1>
              <div className='byline'>
                By {props.developer}
              </div>
              <div className='app_summary'>
                <p>{props.summary || props.descripton}</p>
              </div>
              {getActivateBtn()}
              {this.props.moreLinks || null}
              <a className='btn btn-link'
                href={props.product_link}
                rel='external noopener noreferrer'
                target='_blank'>Learn More</a>
            </div>
          }
          {props.video_url &&
            <div className='page-header-video'>
              <aside>
                <iframe
                  src={props.video_url}
                  title={props.name}
                  frameBorder='0'
                  allowFullScreen={true}
                ></iframe>
              </aside>
            </div>
          }
        </div>
        {props.name &&
          <div className='page-body'>
            <div className='app_content'>
              {props.heading.length > 0 &&
                <div className='row'>
                  <div className='col-lg-12'>
                    <h1>{props.heading}</h1>
                  </div>
                </div>
              }
              <div className='row'>
                <div className='col-lg-8'>
                  {props.gallery_image_urls && <SimpleGallery images={props.gallery_image_urls} />}
                  <div className='container app_desc'>
                    {props.children}
                  </div>
                </div>
                <div className='col-lg-4 right_col'>
                  {props.developer && <AppRequirement {...props} />}
                </div>
              </div>
            </div>
          </div>
        }
      </PageWrapper>
    )
  }
}

AppDetails.propTypes = {
  app: PropTypes.object,
  name: PropTypes.string,
  logo: PropTypes.string,
  developer: PropTypes.string,
  summary: PropTypes.node,
  descripton: PropTypes.string,
  social_media: PropTypes.object,
  product_link: PropTypes.string,
  contact_link: PropTypes.string,
  privacy_link: PropTypes.string,
  tac_link: PropTypes.string,
  video_url: PropTypes.string,
  custom_activate_btn: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  gallery_image_urls: PropTypes.arrayOf(PropTypes.string),
  heading: PropTypes.node,
  children: PropTypes.node,
  isAuthenticated: PropTypes.bool,
  setWrapperClassName: PropTypes.func,
  moreLinks: PropTypes.node,
  history: PropTypes.object,
  showAuthCodeModal: PropTypes.func,
}
AppDetails.defaultProps = {
  name: '',
  logo: '',
  developer: '',
  summary: '',
  product_link: '',
  video_url: '',
  custom_activate_btn: null,
  gallery_image_urls: [],
  heading: '',
  children: null,
  isAuthenticated: false,
  moreLinks: '',
  setWrapperClassName: () => { },
}

export default AppDetails
