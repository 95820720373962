import React from 'react'
import ErrorNotice from './ErrorNotice'

const ComingSoon = () => (
  <ErrorNotice
    title='Coming Soon'
    content='Unfortunately, the page you are looking for is not yet available. Please try again later.'
  />
)

export default ComingSoon
