const recordFormModalVisible = (state = false, action) => {
  switch (action.type) {
    case 'HIDE_RECORD_FORM_MODAL': {
      return false
    }
    case 'SHOW_RECORD_FORM_MODAL': {
      return true
    }
    case 'TOGGLE_RECORD_FORM_MODAL': {
      return !state
    }
    default: {
      return state
    }
  }
}

export default recordFormModalVisible
