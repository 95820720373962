import React, { useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Select } from '@pan/cloud-base'
import { useValueRef } from '../../../utils/activateUtils'

const FIELD_NAME = 'region_data'
const RegionField = ({
  initialValues,
  appInfoMap,
  regions,
  selectedAccount,
  getFieldDecorator,
  getFieldValue,
  getFieldError,
  isFieldTouched,
  isFieldValidating,
  setFields,
  validateFields,
  disabled,
  placeholder,
  accountIsFederal,
  ...restProps
}) => {
  const initialValue = initialValues[FIELD_NAME]
  const label = 'Region'

  const regionDecorator = useMemo(() => {
    return getFieldDecorator(FIELD_NAME, {
      initialValue,
      getValueProps: initialValue ? () => ({
        value: initialValue,
        disabled: true,
      }) : undefined,
      validateFirst: true,
      rules: [
        { required: true, message: 'Region is required' },
      ],
    })
  }, [initialValue, getFieldDecorator])

  const regionOptions = useMemo(() => {
    const warnings = {}
    const options = regions.map((region) => { // regions should be already filtered
      const value = `${region.name}|${region.logging_service_region}`
      const warning = region?.extra?.warning
      warnings[value] = warning ? <span style={{ color: '#d84d50' }}>{warning}</span> : null
      return <Select.Option
        className={accountIsFederal ? 'field-is-federal' : ''}
        key={region.name}
        value={value}
      >{region.display}</Select.Option>
    })
    options.warnings = warnings
    const defaultRegion = regions.find(r => r.default_selected || r.extra?.default_selected)
    if (defaultRegion) {
      options.defaultValue = `${defaultRegion.name}|${defaultRegion.logging_service_region}`
    }
    return options
  }, [accountIsFederal, regions])

  const clearFieldFnRef = useValueRef(useCallback(() => {
    if (initialValue) {
      return
    }
    setFields({ [FIELD_NAME]: { value: regionOptions.defaultValue } }, () => {
      validateFields([FIELD_NAME], { force: true })
    })
  }, [initialValue, setFields, validateFields, regionOptions.defaultValue]))

  useEffect(() => clearFieldFnRef.current(regionOptions), [regionOptions, clearFieldFnRef])
  // value is name
  const err = (initialValue || isFieldTouched(FIELD_NAME)) && getFieldError(FIELD_NAME)
  const warning = regionOptions.warnings[getFieldValue(FIELD_NAME)]
  return <>
    <Form.Item
      {...restProps}
      className={disabled ? 'pan-form-item-disabed' : ''}
      label={label}
      validateStatus={isFieldValidating(FIELD_NAME) ? 'validating' : err ? 'error' : ''}
      help={err || warning || ''}
    >
      {regionDecorator(<Select
        className='region-field-select'
        disabled={disabled}
        placeholder={placeholder || 'Choose a Region'}
      >
        {regionOptions}
      </Select>)}
    </Form.Item>
  </>
}

RegionField.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  regions: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  selectedAccount: PropTypes.number,
  accountIsFederal: PropTypes.bool,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  isFieldTouched: PropTypes.func,
  isFieldValidating: PropTypes.func,
  setFields: PropTypes.func,
  validateFields: PropTypes.func,
}

export default RegionField
