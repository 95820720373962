import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getAvailableAccountsProps, allowAppInstance, allowAppAdmin } from '../../../utils/activateUtils'
import { Form, Select } from '@pan/cloud-base'

const FIELD_NAME = 'support_account_id'
const AccountNameField = ({
  support_account_id,
  support_account_name,
  getFieldDecorator,
  display_name,
  tenant_id,
  canSwitchAccount,
  available_account_ids,
  supportAccounts,
  appInfoMap,
  switchAccount,
  setFields,
  validateFields,
  ...restProps
}) => {
  const availableAccountsProps = useMemo(() => {
    const allowAppFn = (app, rbacRoles) => (
      app.isNova && tenant_id ? allowAppInstance(app, tenant_id, rbacRoles) :
        app.has_auth_code || allowAppAdmin(app, rbacRoles)
    )
    const notAvailFn = available_account_ids ? (cspId) => {
      const isAccountAvail = Array.isArray(available_account_ids) && available_account_ids.includes(+cspId)
      return !isAccountAvail
    } : undefined
    const propsArray = getAvailableAccountsProps({ apps: appInfoMap, supportAccounts, notAvailFn, allowAppFn })
    const disabledMap = propsArray.reduce((all, { value, disabled }) => {
      all[value] = disabled
      return all
    }, {})
    const validate = (cspId) => (
      disabledMap[cspId] ? 'You are restricted to activate this app. Please try another account to activate.' : undefined
    )
    return Object.defineProperties(propsArray, {
      validate: {
        value: validate
      },
      validator: {
        value: (rule, value, callback) => {
          callback(validate(value))
        }
      }
    })
  }, [appInfoMap, available_account_ids, supportAccounts, tenant_id])

  const descorator = useMemo(() => getFieldDecorator(FIELD_NAME, {
    validateFirst: true,
    rules: [
      { validator: availableAccountsProps.validator },
      { required: true },
    ]
  }), [availableAccountsProps, getFieldDecorator])

  const input = useMemo(() => {
    // if (editableOnly) {
    //   return <span className='ant-form-text'>{support_account_name}</span>
    // }
    return (
      <Select
        className='account-name-select'
        disabled={!canSwitchAccount}
        placeholder={'Choose an Account'}
        onChange={(v, opt) => {
          const selectedAccount = +opt?.props?.index
          if (selectedAccount >= 0) {
            switchAccount(selectedAccount)
          }
        }}
      >
        {availableAccountsProps.map(({ index, value, children, disabled, is_federal }) =>
          <Select.Option
            className={is_federal ? 'field-is-federal' : ''}
            key={`${index}:${value}`}
            index={index}
            value={value}
            disabled={disabled}
          >{children}</Select.Option>
        )}
      </Select>
    )
  }, [availableAccountsProps, canSwitchAccount, switchAccount])

  const msgProps = useMemo(() => {
    const errorMsg = availableAccountsProps.validate(support_account_id)
    if (errorMsg) {
      return {
        validateStatus: 'error',
        help: errorMsg,
      }
    }
    return {
      validateStatus: '',
      help: '',
      extra: canSwitchAccount ?
        'Once you activate this app, you cannot move it to a different account. Please change account prior to activation.' :
        'Be aware that you cannot move this app to a different account. Force change account will cancel the activation process.'
    }
  }, [availableAccountsProps, support_account_id, canSwitchAccount])

  useEffect(() => {
    if (support_account_id) {
      setFields({ [FIELD_NAME]: { value: String(support_account_id) } }, () => {
        validateFields([FIELD_NAME], { force: true })
      })
    }
  }, [setFields, support_account_id, validateFields])

  return (
    <Form.Item
      {...restProps}
      label='Company Account'
      className='activate-form-account-field'
      {...msgProps}
    >
      {descorator(input)}
    </Form.Item>
  )
}

AccountNameField.propTypes = {
  editableOnly: PropTypes.bool,
  canSwitchAccount: PropTypes.bool,
  appInfoMap: PropTypes.object,
  supportAccounts: PropTypes.array,
  support_account_id: PropTypes.number,
  support_account_name: PropTypes.string,
  tenant_id: PropTypes.string,
  display_name: PropTypes.string,
  available_account_ids: PropTypes.array,
  switchAccount: PropTypes.func,
  getFieldDecorator: PropTypes.func,
  setFields: PropTypes.func,
  validateFields: PropTypes.func,
}

export default AccountNameField
