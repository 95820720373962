import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { isClcsOrTelemetryInstance, filterRegions, useMemoizeValue } from '../../../utils/activateUtils'
import { LGS, LGS_ID, PRISMA_ACCESS, _ONPREM, CGX, STRATA_INSIGHTS, AIOPS_PREMIUM_INSTANCE_TYPE } from '../../../constants/AppConstants'
import RegionField from './RegionField'
import RegionDisplayField from './RegionDisplayField'
import SubdomainField from './SubdomainField'
import UrlDisplayField from './UrlDisplayField'
import AssociationInstanceField from './AssociationInstanceField'
import CdlInstanceField from './CdlInstanceField'
import CdlUpgradeField from './CdlUpgradeField'
import { FT } from '@pan/cloud-appframework'
import { isNonemptyArray } from '../../../utils/common'

const REGION_FIELD = 'region_data'
const SUBDOMAIN_FIELD = 'subdomain'
const FT_PAE_WITH_FAWKES = FT('PAE_WITH_FAWKES')

export const EMPTY_ONLY_FIELDS = Object.freeze(Object.defineProperties([], {
  has: { value: () => true },
}))

const RegionAssociationsFields = (props) => {
  const {
    appInfoMap,
    regions: metaDataRegions,
    initialValues,
    editableOnly,
    setFields,
    selectedAccount,
    getFieldValue,
    validateFields,
    onlyFields,
    accountIsFederal,
  } = props
  const [regionDisabled, setRegionDisabled] = useState(false)
  const hasInitialValues = !initialValues.isEmpty
  const regionFieldValue = getFieldValue(REGION_FIELD)
  const isMultiRegionAIOPS = FT('AIOPS_MULTI_REGION') && appInfoMap.single?.name === STRATA_INSIGHTS
  const [region, regionData] = useMemo(() => {
    const regionData = regionFieldValue || initialValues[REGION_FIELD] || initialValues.region
    const [region] = regionData ? regionData.split('|', 2) : []
    return [region, regionData]
  }, [regionFieldValue, initialValues])
  const regions = useMemoizeValue(useMemo(() => {
    const filterEasyOnboarding = region => !region?.extra?.easy_onboarding_only

    if (metaDataRegions && Array.isArray(metaDataRegions)) {
      if (!appInfoMap.metaRegions) {
        appInfoMap.metaRegions = metaDataRegions
      }
      return filterRegions(metaDataRegions, accountIsFederal).filter(filterEasyOnboarding)
    }
    if (appInfoMap.isBundle) {
      throw new Error('merged regions for bundle is not supported')
    }
    return filterRegions(appInfoMap.getRegions(appInfoMap.single.name), accountIsFederal).filter(filterEasyOnboarding)
  }, [metaDataRegions, appInfoMap, accountIsFederal]))
  const domainPostfix = useMemo(() => {
    if (!appInfoMap.hasSubdomainField) {
      return undefined
    }
    const defaultPostfix = appInfoMap.domain_postfix || regions.find(r => r.domain_postfix)?.domain_postfix
    if (!defaultPostfix) {
      return undefined
    }
    if (!region) {
      return defaultPostfix
    }
    return regions.find(r => (r.name || r.value) === region)?.domain_postfix || defaultPostfix
  }, [appInfoMap.domain_postfix, appInfoMap.hasSubdomainField, region, regions])
  const instanceRegionFilter = useMemo(() => {
    const [region, logging_service_region] = (regionData || '').split('|')
    // find current app mapped CDL region from manifest
    const cdlRegion = regions?.find(r => r.name === region)?.logging_service_region
    let tenantRegions = cdlRegion ? [cdlRegion] : []
    let filterByRegion = region //Filter tenants based on the APP region
    if (isMultiRegionAIOPS) {
      if (initialValues?.license_type === AIOPS_PREMIUM_INSTANCE_TYPE) {
        //AIOPS premium tenants
        filterByRegion = initialValues?.associations?.[LGS_ID]?.region //Filter tenants based on the CDL region
        tenantRegions = []
      }
      else {
        //AIOPS Free tenants
        const regionInfo = regions?.find(r => r.name === region)
        tenantRegions = (regionInfo?.platform_regions && isNonemptyArray(regionInfo.platform_regions)) ? regionInfo.platform_regions : [regionInfo?.logging_service_region]
      }
    }

    return (tenant) => (
      filterByRegion === tenant.region || (logging_service_region &&
        logging_service_region === (tenant.associations?.[LGS_ID]?.region || tenant.lcaas_region || tenant.region)
      ) || (isNonemptyArray(tenantRegions) && tenantRegions.includes(tenant.associations?.[LGS_ID]?.region || tenant.lcaas_region || tenant.region))
    )
  }, [regions, regionData, initialValues, isMultiRegionAIOPS])
  const { associations, regionAssignFieldName, regionPlaceholder, freeTelemetryRegionMap } = useMemo(() => {
    if (appInfoMap.single?.name === LGS) {
      const upgradableDlRegionMap = {}
      if (!editableOnly) {
        const instances = _.filter(appInfoMap.getInstances(LGS), isClcsOrTelemetryInstance)
        for (const { region } of instances) {
          if (region) {
            upgradableDlRegionMap[region] = true
          }
        }
      }
      return { associations: [], freeTelemetryRegionMap: upgradableDlRegionMap }
    }
    const associations = editableOnly ?
      appInfoMap.associations.filter(a => a.editable && !a.internal) :
      appInfoMap.associations.filter(a => !a.internal)
    const lgsConfig = associations.find(p => p.app === LGS || p.app_id === LGS_ID)
    if (lgsConfig) {
      if (lgsConfig.quota_enforced) { // turn off quota_enforced behavior
        lgsConfig.quota_enforced = false
      }
    }
    let regionAssignFieldName
    let regionPlaceholder = 'Choose Region...'
    const regionAssignInstFieldIdx = associations.findIndex(p => p.region_assignment)
    if (regionAssignInstFieldIdx >= 0) {
      const config = { ...associations[regionAssignInstFieldIdx] }
      config.region_assignment_cb = hasInitialValues ? undefined : (region, selection, app_id) => {
        const current = getFieldValue(REGION_FIELD)
        const targetRegion = regions && regions.find(app_id === LGS || app_id === LGS_ID ?
          r => (r.logging_service_region || r.value || r.name) === region :
          r => (r.value || r.name) === region || r.logging_service_region === region
        )
        if (targetRegion) {
          const value = `${targetRegion.name || targetRegion.value}|${targetRegion.logging_service_region}`
          if (current !== value) {
            setFields({ [REGION_FIELD]: { value } })
          }
          setRegionDisabled(true)
        }
        else {
          if (!selection) {
            setFields({ [REGION_FIELD]: { value: undefined } })
          }
          setRegionDisabled(!selection)
        }
      }
      config.region_matching = false // stop matching region field
      // instead, make region field match cdl
      associations.splice(regionAssignInstFieldIdx, 1)
      associations.unshift(config)
      const displayName = appInfoMap.getDisplayName(config.app)
      regionPlaceholder = `Please Choose a ${displayName} First`
      regionAssignFieldName = `associations.${config.app_id || config.app}`
    }
    return { associations, regionAssignFieldName, regionPlaceholder }
  }, [appInfoMap, editableOnly, hasInitialValues, getFieldValue, regions, setFields])

  useEffect(() => {
    setRegionDisabled(Boolean(regionAssignFieldName))
    if (regionAssignFieldName && selectedAccount >= 0) {
      return () => {
        setFields({ [REGION_FIELD]: { value: undefined } })
      }
    }
  }, [selectedAccount, regionAssignFieldName, setFields])

  const validateRegionValue = appInfoMap.hasSubdomainField && !regionDisabled && Boolean(regionFieldValue)
  useEffect(() => {
    if (validateRegionValue && domainPostfix) {
      validateFields([SUBDOMAIN_FIELD], { force: true })
    }
  }, [domainPostfix, validateRegionValue, validateFields])

  const regionField = editableOnly ?
    <RegionDisplayField key='region' {...props} regions={regions} /> :
    <RegionField
      key='region'
      {...props}
      regions={regions}
      placeholder={regionDisabled ? regionPlaceholder : undefined}
      disabled={regionDisabled}
      accountIsFederal={accountIsFederal}
    />

  const subdomainField = appInfoMap.hasSubdomainField && !editableOnly &&
    <SubdomainField
      key='subdomain'
      {...props}
      appInfoMap={appInfoMap}
      selectedAccount={selectedAccount}
      initialValues={initialValues}
      domainPostfix={domainPostfix}
    />

  const fields = FT_PAE_WITH_FAWKES && editableOnly && initialValues?.tenant_id?.endsWith(_ONPREM) && appInfoMap.single?.app_id === PRISMA_ACCESS ? [
  ] : associations.filter(association =>
    appInfoMap.getAnyApp(association.app_id)?.enabled &&
    onlyFields.has(`associations.${association.app_id}`)
  ).map(config => {
    const fieldProps = {
      ...props,
      key: config.app,
      config,
      regions,
      instanceRegionFilter,
      editableOnly,
    }
    if (config.editable === 'once' && _.has(initialValues.associations, config.app_id)) {
      fieldProps.config.disabled = true
    }
    if (initialValues.app_id === CGX) {
      config.instanceFilterFn = (instance) => (!instance.tenant_id.endsWith(_ONPREM))
    }
    return config.app === LGS || config.app_id === LGS_ID ? <CdlInstanceField {...fieldProps} /> : <AssociationInstanceField {...fieldProps} />
  })

  // for free to paid conversion
  if (appInfoMap.single?.name === LGS && region && !editableOnly) {
    if (region && freeTelemetryRegionMap[region]) { // if has existing free tier telemetry
      fields.push(<CdlUpgradeField
        {...props}
        key={LGS_ID}
        regions={regions}
        instanceRegionFilter={instanceRegionFilter}
      />)
    }
  }

  if (regionAssignFieldName) { // insert region field below cdl
    fields.splice(1, 0, regionField)
  }
  else { // put region field in front
    fields.unshift(regionField)
  }

  if (subdomainField) {
    fields[editableOnly ? 'push' : 'unshift'](subdomainField)
  }
  else if (editableOnly) {
    fields.push(<UrlDisplayField key='domain' {...props} initialValues={initialValues}/>)
  }

  return fields
}

RegionAssociationsFields.propTypes = {
  appInfoMap: PropTypes.instanceOf(Map),
  initialValues: PropTypes.object,
  editableOnly: PropTypes.bool,
  regions: PropTypes.array,
  selectedAccount: PropTypes.number,
  onlyFields: PropTypes.array,
  accountIsFederal: PropTypes.bool,
  getFieldDecorator: PropTypes.func,
  getFieldError: PropTypes.func,
  getFieldValue: PropTypes.func,
  isFieldValidating: PropTypes.func,
  newActivate: PropTypes.func,
}

RegionAssociationsFields.defaultProps = {
  onlyFields: EMPTY_ONLY_FIELDS,
}

export default RegionAssociationsFields
