import React from 'react'
import PropTypes from 'prop-types'
import { Popover, Icon } from '@pan/cloud-base'

const tokenPopupOver = {
  width: '30%',
  maxWidth: '250px',
  lineHeight: '1.4',
}
const TokenTooltip = ({ label, description }) => (
  <>
    <span>{label}</span>
    <Popover content={description} trigger="hover" overlayStyle={tokenPopupOver}>
      <Icon type='info-circle-o' style={{ 'vertical-align': 'initial', padding: '3px' }} />
    </Popover>
  </>
)

TokenTooltip.propTypes = {
  label: PropTypes.node,
  description: PropTypes.node,
}

export default TokenTooltip
