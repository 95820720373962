import _ from 'lodash'
import axios from 'axios'
import { useRef, useMemo, useEffect, useState, useCallback } from 'react'

export const useMemoForm = (obj) => {
  const ref = useRef({})
  if (!_.isEqual(ref.current, obj)) {
    Object.assign(ref.current, obj)
  }
  return ref.current
}

export const useMemoizeValue = (value) => {
  const ref = useRef()
  if (!_.isEqual(ref.current, value)) {
    ref.current = value
  }
  return ref.current
}

export const useMemoizeValueRef = (value) => {
  const ref = useRef()
  if (!_.isEqual(ref.current, value)) {
    ref.current = value
  }
  return ref
}

export const useValueRef = (value) => {
  const ref = useRef()
  ref.current = value
  return ref
}

export const useEffectValueRef = (value) => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref
}

export const useDeepMemo = (fn, keys) => {
  return useMemo(fn, useMemoizeValue(keys)) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useDeepEffect = (fn, keys) => {
  return useEffect(fn, useMemoizeValue(keys)) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useMemoState = (initState) => {
  const [state, setState] = useState(initState)
  return [useMemoizeValue(state), setState]
}

export const useMemoFieldDecorator = (id, options, form) => {
  // usually getFieldDecorator never change, but form changes
  const { getFieldDecorator = form } = form // in case form is the getFieldDecorator fn
  const opts = useMemoizeValue(options) // make sure options wont change every time
  return useMemo(() => getFieldDecorator(id, opts), [opts, id, getFieldDecorator])
}

export const useDebouncedCallback = (cb, ms, keys) => {
  return useMemo(() => _.debounce(cb, ms), keys.concat(ms)) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useAxiosRequest = () => {
  const cancelSource = useMemo(() => axios.CancelToken.source(), [])
  const [states, setStates] = useState({ loading: false })
  useEffect(() => { // during unmount
    return () => cancelSource.cancel('unmount')
  }, [cancelSource])
  const load = useCallback((options) => {
    const { skipLoading, ...req } = options
    // console.error('loading true', req.url)
    if (!skipLoading) {
      setStates({ loading: true })
    }
    return axios.request({
      ...req,
      cancelToken: cancelSource.token,
    }).then((resp) => {
      if (cancelSource.token.reason) {
        return // skip, canceled for unmount
      }
      const result = { loading: false, data: resp.data, status: resp.status }
      setStates(result)
      // console.error('loading false', req.url, result)
      return result
    }, (err) => {
      if (cancelSource.token.reason) {
        return // skip, canceled for unmount
      }
      if (err.response) {
        Object.assign(err, { data: err.response.data, status: err.response.status })
        delete err.response
      }
      const result = {
        loading: false,
        hasError: true,
        error: err,
        data: err.data || { message: err.message },
        status: err.status,
      }
      setStates(result)
      // console.error('loading err', req.url, result)
      throw err
    })
  }, [cancelSource.token])
  const reset = useCallback(() => {
    setStates({ loading: false })
  }, [])
  return { ...states, load, reset }
}

export const useInterval = (callback, interval) => {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // start & clear interval.
  useEffect(() => {
    if (interval > 0) {
      const id = setInterval(() => {
        savedCallback.current()
      }, interval)
      return () => {
        clearInterval(id)
      }
    }
  }, [interval])
}
