import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@pan/cloud-base'

export default class Lightbox extends Component {
  constructor(props) {
    super(props)
    this.onClick = this.onClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.state = {
      visible: false
    }
  }
  onClick(e) {
    e.preventDefault()
    this.setState({
      visible: true
    })
  }
  handleCancel() {
    this.setState({
      visible: false
    })
  }
  render() {
    return (
      <Fragment>
        <a href={this.props.img} className={this.props.className}
          style={this.props.style} onClick={this.onClick}>
          <img className='marketplace-lightbox-image' src={this.props.img} alt={this.props.alt} />
        </a>
        <Modal
          visible={this.state.visible}
          onCancel={this.handleCancel}
          handleCancel={this.handleCancel}
          width='90%'
          bodyStyle={{ padding: 10, height: '100%' }}
          footer={null}
          destroyOnClose={true}
          closable={false}
          style={{ top: 70, textAlign: 'center' }}
        >
          <img src={this.props.img} alt={this.props.alt}
            style={{ maxWidth: '100%' }} onClick={this.handleCancel} />
        </Modal>
      </Fragment>
    )
  }
}
Lightbox.propTypes = {
  img: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
}
Lightbox.defaultProps = {
  img: '',
  alt: '',
  className: '',
  style: {},
}
