import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'
import { Button, Tooltip, Message, Modal } from '@pan/cloud-base'
import { getRecordInfo } from '../../actions'
import InstanceStatus from './InstanceStatus'
import { appframeworkActions } from '@pan/cloud-appframework'
import { isNonemptyArray, getNovaSetupPathState, toAppId } from '../../utils/activateUtils'
import ControlledButton from '../RBAC/ControlledButton'
import { SETUP_PENDING_STATUS, EMPTY_SVG, LGS_ID, DSS_ID, NEVER, PENDING, PROVISIONING, RUNNING, STRATA_INSIGHTS } from '../../constants/AppConstants'
import { ACTIVATE, SETTINGS } from '../../constants/RouteConstants'
import { ToolIcon, TrashIcon, StopIcon, PlayIcon, TelemetryIcon } from '../../images/svg-icons'
import './InstanceTable.scss'

const { fetchEntitlements } = appframeworkActions

const FIXED_MAX_COLUMNS = 6
const STOPPED = 'stopped'


const SVG_ICONS = {
  wrench: <ToolIcon />,
  trash: <TrashIcon />,
  'stop-circle': <StopIcon />,
  'play-circle': <PlayIcon />,
}

export class InstanceTable extends Component {
  constructor(props) {
    super(props)
    const { app, entitledAppsList } = props
    this.columns = [
      {
        name: 'tenant_instance_name',
        label: 'Instance',
        renderer: this.getNameRenderer.bind(this)
      },
      {
        name: 'instance_status',
        label: 'Status',
        renderer: this.statusRenderer.bind(this)
      },
      {
        name: 'expiration',
        label: 'License Expires',
        renderer: this.expirationRenderer.bind(this)
      },
      {
        name: 'region_display',
        label: 'Region',
        renderer: ({ region_display, region }) => region_display || region,
        //colSpan: 3
      },
    ]
    if (isNonemptyArray(app.associations)) {
      const apps = [app]
      const associationSet = apps.reduce((set, app) => {
        _.each(app.associations, (asso) => {
          const assoApp = entitledAppsList.get(asso.app) || entitledAppsList.get(asso.app_id)
          if (assoApp && (asso.app_id ? asso.app_id !== app.app_id : asso.app !== app.name)) {
            set.add(assoApp)
          }
        })
        return set
      }, new Set())
      associationSet.forEach((app) => {
        const displayName = app.display_name
        const fixAppId = app.app_id !== app.name && app.name
        this.columns.push({
          name: `associations.${app.app_id}`,
          label: displayName,
          renderer({ associations }) {
            if (!associations) {
              return ''
            }
            const assoication = associations[app.app_id] || (fixAppId && associations[fixAppId])
            if (!assoication) {
              return ''
            }
            return assoication.tenant_instance_name || assoication.serial_number || assoication.tenant_id
          }
        })
      })
    }
    if (this.columns.length > FIXED_MAX_COLUMNS) {
      // console.warn('too many columns', app.name, this.columns.length)
      this.columns = this.columns.slice(0, FIXED_MAX_COLUMNS)
    }
    if (this.columns.length < FIXED_MAX_COLUMNS) {
      const lastCol = _.last(this.columns)
      lastCol.colSpan = FIXED_MAX_COLUMNS - this.columns.length + 1
    }
    this.getImageHeader = this.getImageHeader.bind(this)
  }

  isExpired(expiration) {
    const expiredHours = moment().diff(expiration, 'hour')
    if (expiredHours > 24) { //older than one day
      return expiredHours
    }
    return false
  }

  expirationRenderer = ({ expiration, is_telemetry }, { app }) => {
    if (expiration) {
      const expirationDate = moment(expiration).format('MMM-DD-YYYY')
      const expiredHours = this.isExpired(expiration)
      if (!expiredHours) {
        return expirationDate
      }
      return <span className='status-error'>Expired {Math.floor(expiredHours / 24)} days ago</span>
    }
    //telemetry tenants
    else if (is_telemetry) {
      return NEVER
    }
    return app.has_auth_code ? '' : NEVER
  }

  statusRenderer = ({ instance_status, status_message, tenant_id, url, expiration }, { app }) => {
    const { no_app_tile_ui, extension_to } = app
    const props = {
      url: url || (no_app_tile_ui || extension_to ? '-' : ''),
      tenant_id,
      instance_status,
      status_message,
      expired: Boolean(this.isExpired(expiration)),
    }
    return <InstanceStatus {...props} />
  }

  novaSetup = (tenant) => {
    const { selectedAccount, app } = this.props
    const pathInfo = getNovaSetupPathState(tenant, app, selectedAccount)
    if (pathInfo) {
      this.props.history.push(ACTIVATE, {
        ...pathInfo,
        referer: SETTINGS,
      })
    }
  }

  getNameRenderer(tenant, {
    app,
    isExtension,
    selectedAccount,
    disabledTenants,
    entitledAppsList,
    showRecordFormModalHandler,
    showInstanceActionModalHandler,
  }) {
    const { tenant_instance_name, tenant_display_name, serial_number, tenant_id, platform_id, region, instance_status, app_id, is_telemetry, provisioning_url } = tenant
    const tenantDisabled = disabledTenants?.has(tenant_id)
    let nameLink = tenant_display_name || tenant_instance_name || serial_number || tenant_id
    if (!tenantDisabled && tenant.editable !== false && !app.flags.uneditable &&
      (app.isCob || app.upsertable || tenant_instance_name)) {
      if (instance_status === RUNNING || instance_status === STOPPED) {
        nameLink = <a tabIndex={0} onClick={() =>
          showRecordFormModalHandler(tenant, isExtension ? app_id : app.name, app.upsertable && !tenant_instance_name)
        }>{nameLink}</a>
      }
      else if (provisioning_url && (app.extension_to || [PENDING, PROVISIONING].includes(instance_status))) {
        nameLink = <a target='_blank' rel='noopener noreferrer' href={provisioning_url}>{nameLink}</a>
      }
    }
    else if (instance_status === SETUP_PENDING_STATUS) {
      nameLink = <a tabIndex={0} onClick={() => this.novaSetup(tenant)}>{nameLink}</a>
    }

    const options = {
      app_name: app.name, // TODO: app_id for extention
      app_display_name: app.display_name || app.name,
      tenant_id,
      serial_number,
      platform_id,
      tenant_instance_name,
      region,
    }
    const checkUsedPropName = {
      [DSS_ID]: 'dirsync_tenant_id',
      [LGS_ID]: 'platform_id'
    }[app.app_id]
    const showUsingMsg = (using) => {
      if (!using.length) {
        return false
      }
      const usedByName = _.get(using.find(inst => inst.tenant_instance_name), 'tenant_instance_name', using[0].tenant_id)
      Message.warn(`This instance ${tenant_instance_name} cannot be deleted because it is used by
        ${usedByName} ${using.length > 1 ? (` and ${using.length - 1} more`) : ''}`, 3)
      return true
    }
    const hasBeenUsed = checkUsedPropName ? () => {
      const using = _.flatMap(entitledAppsList, app => app.tenants || [])
        .filter(({ [checkUsedPropName]: tid, associations }) => {
          if (tid) {
            return Array.isArray(tid) ? tid.includes(tenant_id) : tid === tenant_id
          }
          else if (associations) {
            return associations?.[app.app_id]?.tenant_id === tenant_id
          }
          return false
        })
      return showUsingMsg(using)
    } : isNonemptyArray(tenant.extensions) ? () => showUsingMsg(tenant.extensions) : () => false // TODO: support cob asso usage
    const checkCaptured = async () => {
      const captured = await axios.post('/hub/v2/instance/captured', {
        selectedAccount,
        tenant_id
      }).then(
        record => record?.data?.captured,
        (e) => {
          const status = e?.response?.status
          if (status === 404) {
            showInstanceActionModalHandler('capture', options, app)
          }
          else if (status !== 400) {
            Message.error(e.message)
          } // return undefined
        }
      )
      if (captured) {
        return Modal.info({
          title: 'Debug Info Captured',
          content: <>
            <p>We have captured the debug information on <em>{tenant_instance_name || tenant_id}</em>:</p>
            <div>Reference ID: <strong>{captured._id}</strong></div>
            <div>Description: {captured.description}</div>
            <div>Captured: {new Date(captured.captured).toLocaleString()}</div>
          </>
        })
      }
    }
    const onActionClick = (name) => {
      if (name === 'capture') {
        checkCaptured()
      } // delete/stop need to check usage
      else if (name === 'resume' || !hasBeenUsed()) {
        showInstanceActionModalHandler(name, options, app)
      }
    }
    const genAction = (name, iconType, title = _.capitalize(name)) => (
      <Tooltip
        key={name}
        title={title}
        placement='right'
        mouseEnterDelay={0.3}
        overlayClassName='instance-action-tooltip'
        getPopupContainer={node => node.parentNode}
      >
        <Button
          type='transparent'
          shape='circle'
          className={`${name}_instance instance-action-icon`}
          onClick={() => onActionClick(name)}
        >
          {SVG_ICONS[iconType]}
        </Button>
      </Tooltip>
    )

    const actions = []
    if (!tenantDisabled && app.isCob) {
      if ((app.isThirdParty ? app.deletable !== false : app.deletable) && (app.deletable === 'always' || instance_status != null)) {
        actions.push(genAction('delete', 'trash'))
      }
      if (app.stoppable) {
        if (instance_status === STOPPED) {
          actions.push(genAction('resume', 'play-circle'))
        }
        else if (instance_status === RUNNING) {
          actions.push(genAction('stop', 'stop-circle'))
        }
      }
    }
    actions.push(genAction('capture', 'wrench', 'Capture Debug Info'))

    if (serial_number && nameLink !== serial_number) {
      nameLink = <Tooltip
        title={`Serial Number: ${serial_number}`}
        placement='bottom'
        mouseEnterDelay={0.3}
        overlayClassName='instance-name-tooltip'
        getPopupContainer={node => node.parentNode}
      >{nameLink}</Tooltip>
    }

    return <>
      {nameLink}
      {is_telemetry &&
        <span className='telemetry'><TelemetryIcon /></span>
      }
      <div className='instance-actions'>{actions}</div>
    </>
  }
  getImageHeader(app) {
    const ActivateButton = ControlledButton({ allowedRoles: `${app.role}.App Admin`, rules: ['hide'] })
    return <tr>
      <th colSpan={FIXED_MAX_COLUMNS} className='table-header'>
        <img className='instance-app-logo' src={app.logo || EMPTY_SVG} alt={app.name} />
        {app.icon_display_name || app.display_name || app.name}
        {(app.isThirdParty || (app.is_panw && !app.has_auth_code && !app.isNova && !app.isFake && app.activatable !== false && !app.flags?.use_license_hash_only && app.name !== STRATA_INSIGHTS)) &&
          <ActivateButton
            key='button'
            type='primary'
            className='instance-add-btn'
            onClick={this.addInstanceHandler}
          >Add Instance</ActivateButton>
        }
      </th>
    </tr>
  }
  addInstanceHandler = () => {
    const { showAddInstanceModalHandler } = this.props
    showAddInstanceModalHandler()
  }
  renderRow(tenant, options) {
    const cls = options.isExtension ? 'instance-extension-row' : 'instance-row'
    return <tr key={tenant.tenant_id} data-tenant-id={tenant.tenant_id} className={cls}>
      {this.columns.map((col) =>
        <td
          className={col.name === 'tenant_instance_name' ? 'editable' : ''}
          colSpan={col.colSpan || 1}
          key={col.name}
        >
          {_.isFunction(col.renderer) ?
            col.renderer(tenant, options) :
            tenant[col.name]
          }
        </td>
      )}
    </tr>
  }
  render() {
    const {
      app,
      selectedAccount,
      disabledTenants,
      entitledAppsList,
      showRecordFormModalHandler,
      showInstanceActionModalHandler,
    } = this.props
    const options = {
      app,
      selectedAccount,
      disabledTenants,
      entitledAppsList,
      showRecordFormModalHandler,
      showInstanceActionModalHandler,
    }
    const extOpt = {
      ...options,
      app: {},
      isExtension: true,
    }

    const tenants = app.tenants?.filter(t => !t.is_clcs && !t.hidden) || []

    return isNonemptyArray(tenants) && <Fragment key={app.name}>
      <thead>
        {this.getImageHeader(app)}
        <tr>
          {this.columns.map((col) =>
            <th
              colSpan={col.colSpan || 1}
              key={col.name}
            >{col.hidden ? '' : col.label}</th>
          )}
        </tr>
      </thead>
      <tbody>
        {tenants.map((tenant) =>
          <Fragment key={tenant.tenant_id}>
            {this.renderRow(tenant, options)}
            {Array.isArray(tenant.extensions) && tenant.extensions.map(ext =>
              this.renderRow(ext, {
                ...extOpt,
                app: entitledAppsList.get(ext.application_name),
              })
            )}
          </Fragment>
        )}
      </tbody>
      <tbody><tr><td colSpan={FIXED_MAX_COLUMNS} className='tabel-spacer'></td></tr></tbody>
    </Fragment>
  }
}

InstanceTable.propTypes = {
  app: PropTypes.object,
  disabledTenants: PropTypes.object,
  selectedAccount: PropTypes.number,
  entitledAppsList: PropTypes.array,
  showRecordFormModalHandler: PropTypes.func,
  showAddInstanceModalHandler: PropTypes.func,
  showInstanceActionModalHandler: PropTypes.func,
  fetchEntitlements: PropTypes.func,
  history: PropTypes.object,
}

const mapStateToProps = ({ disabledTenants, entitledAppsList, selectedAccount }) => ({
  disabledTenants,
  entitledAppsList,
  selectedAccount,
})

const mapDispatchToProps = (dispatch) => ({
  fetchEntitlements: (options) => dispatch(fetchEntitlements(options)),
  showRecordFormModalHandler: (selectedAccount, tenant, application_name, isCob, upsert) => (isCob ?
    dispatch({ type: 'SHOW_INSTANCE_RECORD_MODAL', instance: { ...tenant, application_name } }) :
    dispatch(getRecordInfo(selectedAccount, tenant, application_name, upsert))
  ),
  showAddInstanceModalHandler: (appName) => dispatch({ type: 'SHOW_APP_ACTIVATE_MODAL', appName }),
  showAppActivate: (appName, appDisplayName) => dispatch({ type: 'APP_ACTIVATE_NO_AUTHCODE', appName, appDisplayName }),
  showInstanceActionModalHandler: (action, instance, { name, app_id = toAppId(name), display_name, isThirdParty, isCob, deletable }) => dispatch({
    type: 'SHOW_APP_INSTANCE_ACTION_MODAL', action, instance: {
      app_id,
      ...instance,
      app_name: name,
      app_display_name: display_name,
      isThirdParty,
      isCob,
      deletable: action === 'delete' ? String(deletable) : undefined,
    }
  }),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { app } = ownProps
  const { selectedAccount } = stateProps
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    showRecordFormModalHandler: (tenant, app_name = app.name, upsert) => dispatchProps.showRecordFormModalHandler(selectedAccount, tenant, app_name, app.isCob, upsert),
    showAddInstanceModalHandler: () => {
      if (app.isCob) {
        ownProps.history.push(ACTIVATE, { referer: SETTINGS, appName: app.name })
      }
      else {
        dispatchProps.showAddInstanceModalHandler(app.name)
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(InstanceTable)
