import React, { PureComponent } from 'react'
import { Modal, Button, Select, Input, InputNumber, Row, Col } from '@pan/cloud-base'
import HeaderWithTip from '../../components/LoggingCalculator/HeaderWithTip'
import { getFirewallSelection, remoteFirewallSelection } from '../../config/actions'
import PropTypes from 'prop-types'
import { LSCLabel } from './LSCLabel'
import './NGFMoreDialog.css'
import './LoggingCalculatorPage.css'
import HelpText from './HelpText'
import { FT } from '@pan/cloud-appframework'
import { ngFwModels, getDefaultNgFwDevice, lowUtilizationValue, mediumUtilization, highUtilization, newCpsNGFWModels, getDefaultNewCpsNGFWModels } from './NgfwLogRateDetails'
const Option = Select.Option

/**
 * This dialog box for calculation for the firewall total storage based on different models
 */
const utilizationArray = [
  { title: 'Standard', utilization: 'low', utilizationValue: lowUtilizationValue },
  { title: 'High', utilization: 'medium', utilizationValue: mediumUtilization },
  { title: 'Very High', utilization: 'high', utilizationValue: highUtilization },
  { title: 'CUSTOM', utilization: 'custom', utilizationValue: 0 }]

let firewallModels = FT('CALC_NEW_FW') ? ngFwModels : [
  {
    name: 'VM-2 (VM-100)',
    type: 'VM100',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2329,
  },
  {
    name: 'VM-4 (VM-300)',
    type: 'VM300',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 4122,
  },
  {
    name: 'VM-8 (VM-500)',
    type: 'VM500',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 11423,
  },
  {
    name: 'VM-16 (VM-700)',
    type: 'VM700',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 20751,
  },
  {
    name: 'PA-220',
    type: 'PA220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 339,
  },
  {
    name: 'PA-500',
    type: 'PA500',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 409
  },
  {
    name: 'PA-820',
    type: 'PA820',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1083
  },
  {
    name: 'PA-850',
    type: 'PA850',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1371
  },
  {
    name: 'PA-3020',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3020',
    cps: 1900
  },
  {
    name: 'PA-3050',
    type: 'PA3050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 1935
  },
  {
    name: 'PA-3060',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3060',
    cps: 1845
  },
  {
    name: 'PA-3220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3220',
    cps: 3959
  },
  {
    name: 'PA-3250',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA3250',
    cps: 5280
  },
  {
    name: 'PA-3260',
    type: 'PA3260',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 8100
  },
  {
    name: 'PA-5020',
    type: 'PA4020',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 3090
  },
  {
    name: 'PA-5050',
    type: 'PA5050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 2950
  },
  {
    name: 'PA-5060',
    type: 'PA5060',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 5200
  },
  {
    name: 'PA-5220',
    type: 'PA5220',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 14800
  },
  {
    name: 'PA-5250',
    type: 'PA5250',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 35200
  },
  {
    name: 'PA-5260',
    type: 'PA5260',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    cps: 52800
  },
  {
    name: 'PA-5280',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    type: 'PA5280',
    cps: 52800
  },
  {
    name: 'PA-7050',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 6,
    type: 'PA7050',
    cps: 15811
  },
  {
    name: 'PA-7080',
    utilizationValue: lowUtilizationValue,
    utilization: 'low',
    bladeCounts: 10,
    type: 'PA7080',
    cps: 15811
  }

]


firewallModels = FT('CALC_NEW_FW_CPS') ? newCpsNGFWModels : firewallModels
const dummyObj = { index: 0, type: 'PA220',
  name: 'PA-220',
  quantity: 0,
  utilization: 'low',
  utilizationValue: lowUtilizationValue,
  totalStorage: 0,
  cps: 339
}

export { dummyObj }

export const OK_BTN = 'okBtn'
export const CANCEL_BTN = 'cancelBtn'

class NGFWMoreDialog extends PureComponent {

  constructor(props) {
    super(props)
    this.counter = 1
    this.handleCancelBtn = this.handleCancelBtn.bind(this)
    this.handleOkBtn = this.handleOkBtn.bind(this)
    this.addModel = this.addModel.bind(this)
  }

  handleOkBtn() {
    this.props.handleDialogBtnClick(OK_BTN)
  }

  handleCancelBtn() {
    this.props.handleDialogBtnClick(CANCEL_BTN)
  }

  /**
   *
   * @returns {any[]}
   * Iterate over firewall model types
   */
  getFirewallTypes() {
    return firewallModels.map(({ type, name }) => {
      return <Select.Option key={type} value={type}>{name}</Select.Option>
    })
  }

  /**
   *
   * @param index
   * @param value
   * @param option
   * change selection type for each model type
   */

  handleSelect(index, value, option) {
    const { children } = option.props
    const { cps, bladeCounts } = firewallModels.find(fType => fType.type === value)
    const selectedObj = this.props.firewallSelectionType[index]
    if (selectedObj) {
      const logRate = this.calculateLogRate({ ...selectedObj, cps, bladeCounts }, null, null) // set correct cps
      const objType = Object.assign(selectedObj, { type: value, name: children, index, cps, logRate, bladeCounts })
      this.props.dispatch(getFirewallSelection(objType))
    }

  }

  /**
   *
   * @param selectedObj
   * @param value
   * @param typeFor
   * @returns {number}
   *
   * calculate log rate
   *
   */

  calculateLogRate(selectedObj, value, typeFor) {
    let { cps } = selectedObj
    const { bladeCounts } = selectedObj
    // do bladeCount in consideration when type 750XX
    if (bladeCounts > 0) {
      if (typeFor === 'bladeCounts') {
        cps = cps * value
      }
      else {
        cps = cps * bladeCounts
      }
    }
    // cps = type === '7050' || type === '7080' ? cps * bladeCounts : cps
    let { utilizationValue, quantity } = selectedObj
    utilizationValue = typeFor === 'utilizationValue' ? value : utilizationValue // override
    quantity = typeFor === 'quantity' ? value : quantity //override
    const totalCPS = Math.round(cps * 1.68 * (utilizationValue / 100))
    return totalCPS * quantity
  }

  /**
   *
   * @param index
   * @param type
   * @param value
   * change value for utilization and quantity based on type and index
   */
  handleInputChange(index, type, value) {
    value = parseInt(value, 10)
    value = isNaN(value) ? '' : value
    const selectedObj = this.props.firewallSelectionType[index]
    if (selectedObj) {
      const logRate = this.calculateLogRate(selectedObj, value, type)
      const objType = Object.assign(this.props.firewallSelectionType[index], { [type]: value, index, logRate })
      this.props.dispatch(getFirewallSelection(objType))
    }

  }

  /**
   * Add another model will add dummy value for firewall index counter is incremental
   */

  addModel() {
    let defaultDevice = FT('CALC_NEW_FW') ? getDefaultNgFwDevice() : dummyObj
    defaultDevice = FT('CALC_NEW_FW_CPS') ? getDefaultNewCpsNGFWModels() : defaultDevice
    const addObj = { ...defaultDevice, index: this.counter++ }
    this.props.dispatch(getFirewallSelection(addObj))
  }


  /**
   *
   * @param index
   * Delete selected row by index
   */
  handleOnDeleteRows(index) {
    const toBeDeleteObj = this.props.firewallSelectionType[index]
    this.props.dispatch(remoteFirewallSelection(toBeDeleteObj))
  }

  onUtilizationChange = index => (utilization) => {
    const selectionIndex = utilizationArray.findIndex(item => item.utilization === utilization)
    const { utilizationValue } = utilizationArray[selectionIndex]
    // we have to do this because it can be zero
    if (utilizationValue != null && utilization !== undefined) {
      const selectedObj = this.props.firewallSelectionType[index]
      const logRate = this.calculateLogRate(selectedObj, utilizationValue, 'utilizationValue')
      const objType = Object.assign(this.props.firewallSelectionType[index], { utilization }, { utilizationValue }, { index }, { logRate })
      this.props.dispatch(getFirewallSelection(objType))
    }
  }

  getUtilization({ utilization, index }) {
    return (
      <Select style={{ width: 120 }} defaultValue={'low'} value={utilization} onChange={this.onUtilizationChange(index)}>
        { utilizationArray.map((item, idx) => {
          const { title, utilization } = item
          return <Option key={item + idx} value={utilization}>{title}</Option>
        })}
      </Select>
    )
  }

  /**
   *
   * @returns {any[]}
   * get firewall from redux store
   */
  getFireWall() {
    const inputStyle = { width: 90 }
    /**
     * hasBladeCount determine if 70XX series selected
     * isCustomSelected determine if custom utilization selected. We using this flag only for reformat table row cell
     * purely cosmetic
     */
    const { hasBladeCount, isCustomSelected } = this.props
    return this.props.firewallSelectionType.map((fType, index) => {
      const { type, name, quantity, utilization, utilizationValue, bladeCounts } = fType
      const originalBladeCount = firewallModels.find(each => each.type === type)?.bladeCounts

      return (
        <tr key={`${index}-${name}`} className='ngf-dialog-row'>
          <td width='35'>
            { this.props.firewallSelectionType.length > 1 ?
              <Button icon='delete' size={'small'} shape='circle' onClick={() => this.handleOnDeleteRows(index)} /> :
              null
            }
          </td>
          <td width='165'>
            <Select placeholder='Model Number' style={{ width: 160 }}
              value={type}
              onChange={(value, option) => this.handleSelect(index, value, option)}>
              {this.getFirewallTypes()}
            </Select>
          </td>
          <td width='95'>
            <Input value={quantity} style={inputStyle}
              placeholder={'0'}
              onChange={ e => this.handleInputChange(index, 'quantity', e.target.value) } />
          </td>
          <td width={ isCustomSelected ? '220' : ''}>
            { this.getUtilization({ utilization, index })}
            { utilization === 'custom' ?
              <InputNumber value={utilizationValue}
                placeholder={'0'}
                style={{ marginLeft: 5 }}
                suffix={'%'}
                min={1}
                max={100}
                onChange={ value => this.handleInputChange(index, 'utilizationValue', value) } /> :
              <span style={{ width: 70 }} /> }
          </td>
          { hasBladeCount && <td width='90'>
            { originalBladeCount ? <InputNumber
              value={bladeCounts}
              style={inputStyle}
              placeholder={'0'}
              max={+originalBladeCount}
              min={1}
              disabled={bladeCounts === undefined}
              onChange={ value => this.handleInputChange(index, 'bladeCounts', value) } /> : <span style={{ width: 90 }}>N/A</span>}
          </td>
          }
        </tr>
      )
    })
  }

  getTipDetails(type) {
    switch (type) {
      case 'utilization':
        return (
          <Row>
            <Col>
              <p><b>Utilization</b>: Indicates how your firewall resources are being used with an enterprise traffic mix
                with logging enabled for most log types.</p>
              <p>{`The predefined categories represent a sustained connections per second rate over 24/7/365; Standard is ${lowUtilizationValue}%;
                High is ${mediumUtilization}%; Very High is ${highUtilization}%; Custom you can measure peak connections per second and divide by 3 for an estimate.`}</p>
            </Col>
          </Row>
        )
      default:
        return null
    }

  }

  getModalBody() {
    const { hasBladeCount, isCustomSelected } = this.props
    const tipStyle = { color: '#999999', fontSize: 14 }
    return (
      <table className='ngf-table'>
        <thead>
          <tr className='ngf-dialog-row'>
            <td width='35'></td>
            <td width='165'>
              <HeaderWithTip>
                <LSCLabel>Firewall Model</LSCLabel>
              </HeaderWithTip>
            </td>
            <td width='95'>
              <HeaderWithTip tooltipProps={{ title: HelpText('quantity') }} tipIconProps={ { style: tipStyle }}>
                <LSCLabel>Quantity</LSCLabel>
              </HeaderWithTip>
            </td>
            <td width={ isCustomSelected ? '220' : '120'}>
              <HeaderWithTip tooltipProps={{ title: this.getTipDetails('utilization') }} tipIconProps={ { style: tipStyle }}>
                <LSCLabel>Utilization(%)</LSCLabel>
              </HeaderWithTip>
            </td>
            {hasBladeCount ? <td>
              <HeaderWithTip tooltipProps={{ title: HelpText('bladeCount') }} tipIconProps={ { style: tipStyle }}>
                <LSCLabel>Blade Count</LSCLabel>
              </HeaderWithTip>
            </td> : null}

          </tr>
        </thead>
        <tbody>
          {this.getFireWall()}
        </tbody>
      </table>
    )
  }

  render() {
    const { visible } = this.props
    return (
      <Modal centered visible={visible} closable={false} width={630}
        className={'LSC__ngf_dialog'}
        title={<span>Tell us more about your firewalls<br/>so we can help you estimate the log rate.</span>}
        footer={[
          <Button key='anotherId' icon='plus' onClick={this.addModel} >Add another Model</Button>,
          <span key='flexId' style={{ flex: 1 }} />,
          <Button key='cancel' onClick={this.handleCancelBtn}>Cancel</Button>,
          <Button key='estimateKey' type='primary' onClick={this.handleOkBtn}>Estimate Log Rate</Button>
        ]}>
        {this.getModalBody()}
      </Modal>
    )
  }
}

/**
 *
 * @type {{visible: *, firewallSelectionType: *, handleDialogBtnClick: *, hasBladeCount: *, dispatch: *}}
 */
NGFWMoreDialog.propTypes = {
  visible: PropTypes.bool,
  isCustomSelected: PropTypes.bool,
  firewallSelectionType: PropTypes.array,
  handleDialogBtnClick: PropTypes.func,
  hasBladeCount: PropTypes.bool,
  dispatch: PropTypes.func
}
/**
 *
 * @type {{visible: boolean, hasBladeCount: boolean, firewallSelectionType: Array, handleDialogBtnClick: NGFWMoreDialog.defaultProps.handleDialogBtnClick}}
 */
NGFWMoreDialog.defaultProps = {
  visible: false,
  hasBladeCount: false,
  isCustomSelected: false,
  firewallSelectionType: [],
  handleDialogBtnClick: () => { }
}
export default NGFWMoreDialog
