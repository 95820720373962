import React from 'react'
import ErrorNotice from './ErrorNotice'

const NotFound = () => (
  <ErrorNotice
    title='Not Found'
    content='Unfortunately, the page you are looking for is not available.'
  />
)

export default NotFound
