/**
 *
 * Created by vsuthar on 9/20/18 File PANSidePanel
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import { Layout } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import './PANContent.css'

const { Sider } = Layout

/**
 * PANSidePanel.
 * All other props will be apply to **sider**
 * [Look Here](https://ant.design/components/layout/)
 */
class PANSidePanel extends PureComponent {
  render() {
    return (
      <Sider className={'PAN__vbox PAN__Sidebar'} theme={'light'} {...this.props} >
        {this.props.children}
      </Sider>
    )
  }
}

PANSidePanel.propTypes = {
  /**
   * **children** React Children
   */
  children: PropTypes.any
}

export default PANSidePanel
