import React, { useState, useEffect } from 'react'
import { Menu, Layout } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as CustomRoleAction from './../../../config/actions/custom_roles/customRoleActions'
import PANPage from '../../../components/PANPage'
import RoleForm from './RoleForm'
import { getViewRole } from './../../../config/reducers/custom_roles/customRolesReducer'
import { renderRole } from './RolePageComponent'

const { Item } = Menu
const { ContainerDimensions } = Layout

const getSidePanel = (roleDef = [], schemaPerApp = {}, setSelectedRole) => {
  const _roleArr = roleDef
  const handleClick = ({ key }) => {
    const selectedRole = getViewRole({ }, _roleArr[key])
    setSelectedRole(selectedRole)
  }
  return <ContainerDimensions>
    {
      ({ height }) => {
        return <div style={{ height }}>
          <Menu mode={'inline'} defaultSelectedKeys={['0']} style={{ border: 'none' }} onClick={handleClick} >
            {
              _roleArr.map((eachRole, idx) => {
                const { role_label, type, role } = eachRole
                if (type !== 'user') {
                  return <Item key={idx}>{renderRole({ original: eachRole, value: role_label || role })} </Item>
                }
                return null
              }).filter(Boolean)
            }
          </Menu>
        </div>
      }
    }
  </ContainerDimensions>
}

/**
 *
 * @param selectedRole
 * @param schemaTree
 * @returns {*}
 */
const getRolePage = (selectedRole, schemaTree, schemaPerApp) => {
  return <RoleForm roleToBeView={selectedRole} schemaTree={schemaTree} schemaPerApp={schemaPerApp}/>
}
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const RoleDefPage = ({ roleDef, schemaPerApp, schemaTree }) => {
  let defaultSelectedRole = roleDef[0]
  if (schemaPerApp.uniqueId === 'superuser_account_id') {
    defaultSelectedRole = schemaPerApp?.app_predefined_roles[0]
    roleDef = schemaPerApp?.app_predefined_roles
  }
  const [selectedRole, setSelectedRole] = useState(defaultSelectedRole)
  useEffect(() => {
    setSelectedRole(defaultSelectedRole)
  }, [defaultSelectedRole])

  return (
    <PANPage
      style={{ flex: 1 }}
      mainContentProps={{
        showPageHeader: false,
        style: { backgroundColor: 'white' }
      }}
      sidePanelProps={{ style: { overflow: 'auto', display: 'flex', flex: 1 } }}
      sidePanel={getSidePanel(roleDef, schemaPerApp, setSelectedRole)}
      mainContent={getRolePage(selectedRole, schemaTree, schemaPerApp)}
    >
    </PANPage>
  )
}

/**
 *
 * @type {{roleDef: *, getRolesDefPerApp: *}}
 */
RoleDefPage.propTypes = {
  roleDef: PropTypes.array,
  getRolesDefPerApp: PropTypes.func,
  schemaPerApp: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  schemaTree: PropTypes.array
}

/**
 * Default func for prop validation
 */
const nope = () => {}
/**
 *
 * @type {{roleDef: [], getRolesDefPerApp: *}}
 */
RoleDefPage.defaultProps = {
  roleDef: [],
  selectedApp: {},
  getRolesDefPerApp: nope(),
  schemaTree: []
}
const mapStateToProps = state => {
  const { rbacCustomRole } = state
  return {
    ...rbacCustomRole
  }
}

const mapDispatchToProps = { ...CustomRoleAction }
export default connect(mapStateToProps, mapDispatchToProps)(RoleDefPage)
