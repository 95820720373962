import Axios from 'axios'
import { Message } from '@pan/cloud-base'

export const getRecordInfo = (selectedAccount, tenant, application_name, upsert) => async (dispatch) => {
  try {
    const { tenant_id } = tenant
    dispatch({
      type: 'UPDATE_SELECTED_INSTANCE',
      selectedInstance: {
        tenant_id,
        application_name
      }
    })
    /* dispatch({
      type: 'SHOW_RECORD_FORM_MODAL'
    }) */
    dispatch({
      type: 'IS_LOADING_TRUE'
    })
    const record = await Axios.post('/hub/v2/instance/get', {
      selectedAccount,
      tenant_id
    }).then(resp => resp.data && resp.data.ok && resp.data.record, err => {
      const status = err.response && err.response.status
      if (upsert && status === 404) {
        return {}
      }
    })
    if (record) {
      if (upsert && !Object.keys(record).length) { // empty
        Object.assign(record, {
          upsert: true,
          _id: tenant_id,
          tenant_id,
          application: application_name,
          application_name,
          ...tenant,
        })
      }
      dispatch({
        type: 'UPDATE_SELECTED_INSTANCE',
        selectedInstance: {
          tenant_id,
          tenant_instance_name: record.tenant_instance_name,
          original: record,
          application_name,
          ...record,
        }
      })
      dispatch({
        type: 'SHOW_RECORD_FORM_MODAL'
      })
    }
    else {
      Message.warn('Selected record not found.', 3)
      dispatch({
        type: 'HIDE_RECORD_FORM_MODAL'
      })
    }

  }
  catch (error) {
    Message.error('Fail to load selected record.', 3)
  }
  finally {
    dispatch({
      type: 'IS_LOADING_FALSE'
    })
  }
}
