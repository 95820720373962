import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Icon, Tooltip, Popover } from '@pan/cloud-base'
import './HeaderWithTip.css'

class HeaderWithTip extends PureComponent {

  getHelperText() {
    const { tooltipProps, actionable, tipIconProps } = this.props
    const popupProps = { ...tooltipProps, title: '', content: tooltipProps.title }
    if (actionable) {
      return <Popover {...popupProps} overlayClassName={'header-with-tip-popup'} >
        <Icon type='info-circle-o' {...tipIconProps} />
      </Popover>
    }
    else {
      return <Tooltip {...tooltipProps}>
        <Icon type='info-circle-o' {...tipIconProps} />
      </Tooltip>
    }
  }

  render() {
    return (
      <div className='header-with-tip'>
        {this.props.children}
        {Object.keys(this.props.tooltipProps).length ?
          <span className='header-with-tip-margin'>
            {this.getHelperText()}
          </span> :
          null
        }
      </div>
    )
  }
}

/**
 *
 * @type {{tooltipProps: *, children: *, tipIconProps: *, actionable: *}}
 */
HeaderWithTip.propTypes = {
  tooltipProps: PropTypes.object,
  children: PropTypes.any,
  tipIconProps: PropTypes.object,
  actionable: PropTypes.bool
}

/**
 * When actionable is true that time component use popup over then tooltip.
 * @type {{tooltipProps: {}, tipIconProps: {}, actionable: boolean}}
 */
HeaderWithTip.defaultProps = {
  tooltipProps: {},
  tipIconProps: {},
  actionable: false
}

export default HeaderWithTip
