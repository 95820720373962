import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from '@pan/cloud-base'
import './ActivateAlertBanner.scss'

const ActivateAlertBanner = (props) => (
  props.message ? <Alert.AntAlert
    className='activate-alert-banner'
    closable
    banner
    showIcon
    {...props}
  /> : null
)

ActivateAlertBanner.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
}

export default ActivateAlertBanner
