import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import './SimpleCard.css'

/**
 * Simple Card used in Logging Service Calculator
 */
class SimpleCard extends PureComponent {

  render() {
    const { className, style, title, children } = this.props
    return (
      <section className={`${className} simple-card`} style={style}>
        {title}
        <div className='simple-card-child'>
          {children}
        </div>
      </section>
    )
  }
}

SimpleCard.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object
}

export default SimpleCard
