import React, { Component, Fragment } from 'react'
import { Row, Col } from '@pan/cloud-base'
import HeaderWithTip from '../../components/LoggingCalculator/HeaderWithTip'
import PropTypes from 'prop-types'
import NGFWMoreDialog, { OK_BTN } from './NGFWMoreDialog'
import { LSCLabel } from './LSCLabel'
import './LoggingCalculatorPage.css'
import HelpText from './HelpText'
import { LOGGING_SERVICE_DISPLAY_NAME, LOGGING_SERVICE_DISPLAY_NAME2 } from '../../constants/AppConstants'
import NumberInput from '../../components/LoggingCalculator/NumberInput'
import { FT } from '@pan/cloud-appframework'

const nope = () => {
}

class NGFWCmp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toolTipVisible: false,
      avgLogRate: null, //init average log rate to 0
      retentionPrd: 30, //init retention period to 30
      totalStorage: 0,  // init total storage to 0
      storageDetailLog: 0, //init total storage to 0
      openDialog: false, // set firewall calculation dialog false
    }
    this.handleFirewallDialog = this.handleFirewallDialog.bind(this)
    this.handleDialogClick = this.handleDialogClick.bind(this)
  }


  calculateTotalStorage() {
    const { retentionPrd = 0, avgLogRate = 0 } = this.state
    const { isMagnifierChecked, isIoTChecked } = this.props
    const primeRetentionPrd = retentionPrd === 0 && this.props.retentionPrd > 0 ? this.props.retentionPrd : retentionPrd
    /*
      if magnifier checked then use different formula
     */

    /**
     * TODO: We should put this logic to center place. Currently same logic is used in redux when magnifier clicked
     */

    const storageDetailLog = isMagnifierChecked || isIoTChecked ? (avgLogRate * 86400 * primeRetentionPrd * 2100) / Math.pow(1024, 3) :
      (avgLogRate * 86400 * primeRetentionPrd * 1500) / Math.pow(1024, 3)

    const totalStorage = FT('CALC_UPDATE_FORMULA') ? storageDetailLog : (storageDetailLog / 0.6)

    this.setState({
      storageDetailLog,
      totalStorage,
      retentionPrd: primeRetentionPrd
    }, () => {
      this.reportToParent()
    })
  }

  /**
   * @param type Can be avgLogRate or retentionPrd
   * @param value passed by Input component
   */
  handleChange(type, e) {
    let value = Boolean(e?.target?.value) ? e.target.value : e
    value = Math.abs(value) // we need un-sign int
    // value = type === 'retentionPrd' && value < 5 ? 5 : value // make min 5 for retention period
    this.setState({
      [type]: isNaN(parseInt(value, 10)) ? '' : parseInt(value, 10)
    }, this.calculateTotalStorage.bind(this))
  }

  handleFirewallDialog() {
    this.setState({
      openDialog: true,
    })
  }

  handleDialogClick(type) {
    let { avgLogRate = 0 } = this.state
    if (type === OK_BTN) {
      /**
       *
       * @type {number}
       * iterate over each firewall type log rate and put it under avgLogRate
       */
      avgLogRate = 0 // reset to zero
      this.props.firewallSelectionType.forEach(item => {
        avgLogRate += item.logRate || 0
      })
    }
    this.setState({
      avgLogRate,
      openDialog: false,
    }, () => {
      this.calculateTotalStorage()
    })
  }

  reportToParent() {
    this.props.totalData({ appId: 'ngf', ...this.state })
  }


  getToolTip(type) {
    const linkStyle = { textDecoration: 'underline', color: '#0E9AC8', fontSize: 12 }
    switch (type) {
      case 'avgLogRate': {
        return (
          <Row>
            <Col>
              {`The aggregated log rate per second across all the firewalls that will send logs to the ${FT('STRATA_LOGGING_SERVICE_REBRAND') ? LOGGING_SERVICE_DISPLAY_NAME2 : LOGGING_SERVICE_DISPLAY_NAME}.`}
            </Col>
            <br/>
            <Col>
              To estimate, click the <a style={{ color: '#0E9AC8' }} onClick={this.handleFirewallDialog}>I don’t know the log rate</a>
            </Col>
            <br/>
            <Col>
              To calculate the value accurately for your network traffic. See <a
                style={linkStyle}
                href='https://knowledgebase.paloaltonetworks.com/KCSArticleDetail?id=kA10g000000ClVMCA0'
                target='_blank'
                rel='noopener noreferrer'
              >Link</a>
            </Col>
          </Row>
        )
      }
      default: return null
    }
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const { openDialog } = this.state
    const { firewallSelectionType, dispatch, hasBladeCount, isCustomSelected, others } = this.props
    const { retentionPrd = 0, avgLogRate } = others
    const linkStyle = { textDecoration: 'underline', color: '#0E9AC8', fontSize: 12 }

    const tipStyle = { color: '#999999', fontSize: 14 }
    /**
     * if global retention period is present then we should use this.props.retentionPrd unless component overrides it
     */
    return (
      <Fragment>
        <Row type='flex' align='middle'>
          <Col xs={24} sm={24} md={24} lg={12}>
            <HeaderWithTip actionable tooltipProps={{ title: this.getToolTip('avgLogRate') }} tipIconProps={ { style: tipStyle }}>
              <LSCLabel>Average Log Rate</LSCLabel>
            </HeaderWithTip>
            <NumberInput
              value={avgLogRate}
              suffix='per second'
              placeholder='10'
              onChange={this.handleChange.bind(this, 'avgLogRate')}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <HeaderWithTip tooltipProps={{ title: HelpText('retentionPrd') }} tipIconProps={ { style: tipStyle }} >
              <LSCLabel>Retention Period</LSCLabel>
            </HeaderWithTip>
            <NumberInput
              defaultValue={30}
              min={30}
              value={retentionPrd}
              suffix='day(s)'
              onChange={this.handleChange.bind(this, 'retentionPrd')}/>
          </Col>
          <Col>
            <a style={linkStyle} onClick={this.handleFirewallDialog}>I don’t know the log rate</a>
          </Col>
        </Row>
        <NGFWMoreDialog dispatch={dispatch}
          firewallSelectionType={firewallSelectionType}
          visible={openDialog}
          isCustomSelected={isCustomSelected}
          hasBladeCount={hasBladeCount}
          handleDialogBtnClick={this.handleDialogClick}/>
      </Fragment>
    )
  }
}

/**
 *
 * @type {{totalData: *, daysFormatter: *, logRateFormatter: *, retentionPrd: *, dispatch: *, firewallSelectionType: *}}
 */
NGFWCmp.propTypes = {
  totalData: PropTypes.func,
  daysFormatter: PropTypes.func,
  logRateFormatter: PropTypes.func,
  retentionPrd: PropTypes.number,
  dispatch: PropTypes.func,
  isMagnifierChecked: PropTypes.bool,
  hasBladeCount: PropTypes.bool,
  isCustomSelected: PropTypes.bool,
  others: PropTypes.object,
  firewallSelectionType: PropTypes.array,
  isIoTChecked: PropTypes.bool,
}

/**
 *
 * @type {{totalData: (function(): nope), daysFormatter: (function(): nope), logRateFormatter: (function(): nope), retentionPrd: number, firewallSelectionType: Array}}
 */
NGFWCmp.defaultProps = {
  totalData: () => nope,
  daysFormatter: () => nope,
  logRateFormatter: () => nope,
  retentionPrd: 0,
  others: {
    avgLogRate: 0,
    retentionPrd: 0,
    totalStorage: 0,
    storageDetailLog: 0,
  },
  firewallSelectionType: [],
}

export default NGFWCmp
