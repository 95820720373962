// import React from 'react'
// import svg from '../../images/sample/explore2_diagram.svg'
import screenshot1 from '../../images/sample/explore2_screenshot1.png'
import screenshot2 from '../../images/sample/explore2_screenshot2.png'

export default {
  name: 'Reporting App',
  display_name: 'Explore',
  summary: 'The Explore app provides realtime visibility into network-wide traffic and threat statistics across your Palo Alto Networks Next-Generation Firewalls and Prisma™ Access.',
  first_release: 'April, 2019',
  latest_release: 'January 9, 2020',
  system_requirements: 'PAN-OS® 8.1+',
  developer: 'Palo Alto Networks, Inc.',
  developer_link: 'https://www.paloaltonetworks.com/',
  product_link: 'https://docs.paloaltonetworks.com/cortex/explore',
  video_url: '',
  // gallery_image_urls: [screenshot1, screenshot2],
  heading: 'Gain realtime visibility into your network traffic and threat statistics across multiple Next-Generation Firewalls',
  custom_activate_btn: '', // no button
  body: [
    {
      paragraphs: [
        'The Explore app delivers a searchable view of network events across all your traffic, including threat intelligence from Palo Alto Networks Threat Prevention and URL Filtering services. A cloud-powered search enables network administrators to find millions of events across multiple firewalls in real time.'
      ]
    },
    {
      content: [
        { strong: 'Benefits' },
        {
          ul: [
            'Gain realtime visibility of network-wide traffic and threats across multiple Next-Generation Firewalls.',
            'Maintain low opex, with the cost included in Cortex Data Lake.',
            'Leverage cloud-based logging with no hardware costs, no deployment needed.',
            'Take advantage of scalability as you grow, available from anywhere.',
          ]
        },
        // <img key='svg' src={svg} style={{ maxWidth: '100%', maxHeight: 500 }} alt='diagram' />
      ],
    },
    { image: screenshot1 },
    { image: screenshot2 },
  ]
}
