import _ from 'lodash'
import { connect } from 'react-redux'
import {
  EMPTY_LOC_STATE,
  EMPTY_INIT_VALUES,
  REFRESH_ENTITLEMENTS_AFTER,
  fetchEntitlements,
  fetchCredential,
  allowAppInstance,
  switchAccountIndex,
  getSelectedAccountInfo,
} from '../../utils/activateUtils'
import ActivatePage from './ActivatePage'

// ensure the same names input the same ref array back
const getMemoAppNames = (appNames) => {
  const cache = getMemoAppNames.cache
  const key = (appNames?.length && appNames.join(',')) || ''
  const arr = cache[key] || (cache[key] = appNames)
  return arr
}
getMemoAppNames.cache = { '': [] }

const getCachedDisplayName = (appNames, entitledAppsList, display_name) => {
  if (!appNames || !appNames.length) {
    return ''
  }
  if (display_name) {
    return display_name
  }
  if (appNames.displayName) {
    return appNames.displayName
  }
  appNames.displayName = appNames.map(name => entitledAppsList.get(name)?.display_name || name).join(', ')
  return appNames.displayName
}

const wrapInitValues = initialValues => (
  _.isEmpty(initialValues) ? EMPTY_INIT_VALUES : Object.defineProperties(initialValues, { isEmpty: { value: false } })
)

const mapStateToProps = ({
  isAuthenticated,
  isLoading,
  supportAccountIds,
  entitledAppsList,
  selectedAccount,
  user_email,
  globalLoadingMask: isGlobalLoading,
  appActivate: {
    result: activateResult,
    progress: activateProgress,
    entitlementsLoaded,
    initialValues,
    ...appActivateState
  },
}) => ({
  isAuthenticated,
  isLoading,
  ...getSelectedAccountInfo(supportAccountIds, selectedAccount),
  initialValues: wrapInitValues(initialValues),
  supportAccountIds,
  entitledAppsList,
  user_email,
  ...appActivateState,
  activateResult,
  activateProgress,
  isGlobalLoading,
  entitlementsLoaded,
  available: Boolean(isAuthenticated && selectedAccount >= 0 && entitlementsLoaded),
})

const mapDispatchToProps = (dispatch) => ({
  fetchEntitlements: (options) => dispatch(fetchEntitlements(options)),
  setIsLoading: (isLoading = true) => dispatch({ type: 'SET_IS_LOADING', isLoading }),
  showAppActivateModal: (appName) => dispatch({ type: 'SHOW_APP_ACTIVATE_MODAL', appName }),
  resetActivate: () => dispatch({ type: 'RESET_APP_ACTIVATE' }),
  activateWithAuthcode: (data) => dispatch({ type: 'APP_ACTIVATE_VIA_AUTHCODE', data }),
  setInitialValues: (initialValues) => Promise.resolve(dispatch({ type: 'APP_ACTIVATE_SET_INIT_VALUES', initialValues })),
  showActivateProgress: (progress = 0, status = '', steps) => {
    const ret = dispatch({ type: 'APP_ACTIVATE_SET_PROGRESS', progress, status, steps })
    return steps ? Promise.resolve(ret) : ret // ensure a delay after set steps
  },
  hideActivateProgress: () => dispatch({ type: 'APP_ACTIVATE_HIDE_PROGRESS' }),
  resetActivateProgress: () => dispatch({ type: 'APP_ACTIVATE_RESET_PROGRESS' }),
  resetActivateResult: () => dispatch({ type: 'APP_ACTIVATE_SET_RESULT' }),
  showAlertBanner: (msg) => {
    dispatch({ type: 'APP_ACTIVATE_SET_MSG', msg })
    window.scrollTo(0, 0)
  },
  switchAccount: (idx) => {
    dispatch(switchAccountIndex(idx))
  },
  fetchCredentialIfNeeded: ({ application_name, tenant_id } = {}) => {
    if (!application_name || !tenant_id) {
      return
    }
    dispatch((dispatch, getState) => {
      const { entitledAppsList, roles = {} } = getState()
      const app = entitledAppsList?.get(application_name)
      if (app && !allowAppInstance(app, tenant_id, null, roles)) { // not support role mapping
        dispatch(fetchCredential())
      }
    })
  },
  showAuthCodeModal: ({ authcode = '', error, appName, displayName, onDiscovery }) => dispatch({
    type: 'SHOW_AUTHCODE_ACTIVATE_MODAL',
    authcode,
    error,
    appName,
    displayName,
    onDiscovery,
  }),
})

const ignoredFetchingEntitlements = () => Promise.resolve()
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { available, display_name, entitledAppsList } = stateProps
  const { history } = ownProps
  const { state = EMPTY_LOC_STATE } = history.location
  let appNames = getMemoAppNames(stateProps.appNames)
  if (available && !appNames.length && !state.activated) {
    const { appName, isNova } = state
    const appInfo = appName && entitledAppsList.get(appName)
    if (appInfo && (isNova ? appInfo.isNova : !appInfo.has_auth_code)) {
      appNames = getMemoAppNames([appName])
    }
  }
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    appNames,
    displayName: getCachedDisplayName(appNames, entitledAppsList, display_name),
    entitlementsLoaded: Boolean(stateProps.entitlementsLoaded),
    refreshEntitlements: Date.now() - stateProps.entitlementsLoaded > REFRESH_ENTITLEMENTS_AFTER ?
      dispatchProps.fetchEntitlements : ignoredFetchingEntitlements
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ActivatePage)
