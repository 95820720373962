/**
 *
 * Created by vsuthar on 2019-04-29 File ErrorBoundary
 * Project: App Portal ©PaloAlto Networks
 */
import React from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'

/**
 * Component Error Boundary. It is advice that wrap each new class render with this component to
 * gracefully capture error without failing whole component
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.debounceTime = null
    this.state = {
      hasError: false,
    }
  }


  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true }
  }


  /**
   *
   * @param errorInfo
   * @param error
   */
  logUIError(errorInfo, error) {
    const { componentStack } = errorInfo
    const { message, stack } = error
    axios.post('/api/v1/log-ui-error', {
      message, stack, componentStack
    })
      .then((res) => {
        // console.log(res) // eslint-disable-line no-console
      }).catch(err => {
        // console.log(err) // eslint-disable-line no-console
      })
  }

  /**
   *
   * @param error
   * @param errorInfo
   * Send AJAX request to server to log react level error.
   */
  componentDidCatch(error, errorInfo) {
    clearTimeout(this.debounceTime)
    this.debounceTime = setTimeout(() => {
      this.logUIError(errorInfo, error)
    }, 1500)

  }

  render() {
    /**
     * @TODO lets expose props that accept fallback widget when component failed to render
     * we should render fall back UI to get error or rather pass component
     */
    if (this.state.hasError) {
      //meaning full code or tell user what went wrong
      // TODO: Ask UX to provide nice UI
      console.warn('Failed to render widget') // eslint-disable-line no-console
      return this.props.component || <div></div>
    }
    else {
      return this.props.children
    }

  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  component: PropTypes.node,
}


export default ErrorBoundary
