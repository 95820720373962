const kValue = 1024
const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

export const formatBytes = (bytes, decimals, startUnit, endUnit) => {
  if (bytes === 0) {
    return `0 ${startUnit}`
  }
  const k = kValue
  const dm = isNaN(decimals) ? 2 : decimals
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  const idx = sizes.indexOf(startUnit)
  const startIdx = idx === -1 ? 0 : idx
  let unit = i + startIdx
  // logic to control endUnit
  if (endUnit && sizes.indexOf(endUnit) > 0 && unit > sizes.indexOf(endUnit)) {
    unit = unit - (sizes.indexOf(sizes[unit]) - sizes.indexOf(endUnit))
    i = 1
  }
  if (i + startIdx < 0) {
    return `${bytes} Bytes`
  }
  if (!sizes[unit]) {
    return 'Invalid Value Entered'
  }
  return `${+(bytes / (k ** i)).toFixed(dm)} ${sizes[unit]}`
}
