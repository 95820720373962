import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Icon, Steps, Modal } from '@pan/cloud-base'

const ActivateProgressModal = ({ visible, title, steps, progress, statuses, afterClose }) => {
  const progressMapping = useMemo(() => {
    if (!Array.isArray(steps)) {
      return null
    }
    let n = 0
    const mapping = steps.map(title => (title ? n++ : n))
    mapping[steps.length] = steps.length // finished
    return mapping
  }, [steps])
  return progressMapping && <Modal
    visible={visible}
    title={title}
    footer={null}
    closable={false}
    afterClose={afterClose}
  >
    <Steps direction='vertical' current={progressMapping[progress]} status={statuses[progress]}>
      {
        steps.map((title, i) => title && <Steps.Step
          key={i}
          icon={progress === i && statuses[i] !== 'error' ? <Icon type='loading' /> : undefined}
          title={title}
          status={statuses[i] || undefined}
        />)
      }
    </Steps>
  </Modal>
}
ActivateProgressModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  progress: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string),
  statuses: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  afterClose: PropTypes.func,
}
ActivateProgressModal.defaultProps = {
  visible: false,
  title: 'Activate Cortex XDR ...',
  progress: 0,
  statuses: {},
  steps: [
    'Activating the Auth Code',
    'Starting Cortex Data Lake',
    'Setting Quota for Logs',
    'Starting Cortex XDR',
  ]
}

export default React.memo(ActivateProgressModal)
