/**
 *
 * setWrapperClassName will set wrap class to top level element which responsible to render
 * gradient blue.
 * @param value
 */
import Axios from 'axios'
import React from 'react'
import { formatBytes } from './../../utils/ByteBase'
import { Message, Icon } from '@pan/cloud-base'
import Alert from 'react-s-alert'
import { ErrorCode } from '../../constants/ErrorCode'

let debounceTimer

export const setWrapperClassName = value => {
  return {
    type: 'SET_WRAPPER_CLASS_NAME',
    value
  }
}

export const getLoggigCalcAppList = () => async (dispatch) => {
  dispatch({
    type: 'GET_LOGGING_CALC_APP_LIST',
    loggingServiceCalAppList: []
  })
}

export const checkedLoggingCalcApp = (appType) => async (dispatch) => {
  dispatch({
    type: 'CHECKED_LOGGING_APP_TYPE',
    appType
  })

  if (appType.itemID === 'magnifier') {
    // modified object because calculateUserData use appID not itemID
    dispatch(calculateUserData({ 'appId': appType.itemID }))
  }

}

export const loggingCalCardCollapsed = appId => async dispatch => {
  dispatch({
    type: 'LOGGING_CALC_CARD_COLLAPSED',
    appId
  })
}

export const setTotalDataLoggingSrv = appData => (dispatch) => {

  dispatch({
    type: 'SET_LOGGING_SERVICE_TOTAL_DATA',
    appData
  })
  if (appData.totalStorage > 0) {
    dispatch(calculateUserData(appData))
  }
}

export const getFirewallSelection = selection => dispatch => {
  dispatch({
    type: 'GET_FIREWALL_SELECTION',
    selection
  })
}

export const remoteFirewallSelection = selection => dispatch => {
  dispatch({
    type: 'DELETE_FIREWALL_SELECTION',
    selection
  })
}

const calculateUserData = appData => (dispatch, getState) => {
  const { loggingServiceCalAppList } = getState().loggingServiceCal
  const { user, user_email } = getState()
  let toBeRecordedObj
  clearInterval(debounceTimer)
  // doing special things for magnifier because it re-calculate whole total storage formula
  if (appData.appId === 'magnifier') {
    toBeRecordedObj = { apps: {} }
    loggingServiceCalAppList.forEach(item => {
      toBeRecordedObj.apps[item.appId] = formatBytes(item.totalStorage, 2, 'GB', 'TB')
      toBeRecordedObj['appId'] = 'magnifier'
    })
  }
  else {
    const foundRecord = loggingServiceCalAppList.find(item => item.appId === appData.appId)
    //reformat total storage
    const { hasChild } = foundRecord
    const children_apps = []
    if (hasChild && hasChild.hasOwnProperty('name') && hasChild.hasOwnProperty('totalProperty')) {
      const { name, totalProperty } = hasChild
      children_apps.push({
        'appId': name,
        'totalStorage': foundRecord[totalProperty]
      })
    }
    toBeRecordedObj = {
      'totalStorage': formatBytes(foundRecord['totalStorage'], 2, 'GB', 'TB'),
      'appId': foundRecord.appId,
      'children_apps': children_apps
    }
  }
  // send request if object is present
  if (toBeRecordedObj) {
    debounceTimer = setTimeout(() => {
      Axios.post('/api/v1/log/loggingServiceCal', { ...toBeRecordedObj, user, user_email })
    }, 1000)
  }
}

/**
 *
 * @param messageObj
 * @param isFullBanner
 * @returns {function(...[*]=)}
 */
export const appPortalErrorHasOccurred = (messageObj, isFullBanner) => (dispatch) => {

  // when error occur we need to show this
  if (isFullBanner) {
    Message.v2()
  }
  const { errorCode, message = 'Something Went Wrong!' } = messageObj
  if (errorCode || message) {
    const messageStr = errorCode ? `${errorCode}: ${ErrorCode[errorCode] || message}` : message
    dispatch({
      type: 'ERROR_OCCURRED',
      messageObj
    })
    Message.error(messageStr, 4)
  }
}

/**
 *
 * @param messageObj
 * @param isFullBanner
 * @returns {function(...[*]=)}
 */
export const appPortalWarningOccurred = (messageObj, isFullBanner) => (dispatch) => {

  if (isFullBanner) {
    Message.v2()
  }
  // when error occur we need to show this
  if (messageObj && messageObj.message) {
    const { message } = messageObj
    dispatch({
      type: 'ERROR_OCCURRED',
      message
    })
    Message.warning(message, 4)
  }
}

/**
 *
 * @param notifyObj
 * @returns {function(...[*]=)}
 */
export const sendNotification = (notifyObj) => (dispatch) => {
  const { message } = notifyObj
  if (message) {
    dispatch({
      type: 'RBAC_NOTIFICATION',
      message
    })
    Alert.success(<span><Icon type="check-circle" style={{ marginRight: 5 }}/>  {message}</span>, {
      position: 'top',
      offset: 50
    })
  }

}

/**
 *
 * @param isLoading
 * @returns {Function}
 * This is loading state is global and used in Service layer to trigger loading when request is made
 */

export const appPortalSetLoadingState = (isLoading) => (dispatch) => {
  dispatch({
    type: 'APP_LOADING_STATE',
    isLoading
  })
}
