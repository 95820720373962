import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Message, Modal } from '@pan/cloud-base'
import { FT } from '@pan/cloud-appframework'
import { isNonemptyArray } from '../../../utils/common'
import { DSS_ID, DSS, CIE, PA_PANORAMA, PRISMA_ACCESS, PA_GEN_OTP, RUNNING, _ONPREM } from '../../../constants/AppConstants'

const FT_PAE_WITH_FAWKES = FT('PAE_WITH_FAWKES')
export default class SubMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.subMenuRef = React.createRef()
  }

  operateAction = async (action) => {
    const { selectedAccount, name, display_name, isThirdParty, isCob, upsertable } = this.props
    const { tenant_id, tenant_instance_name } = this.props.instance
    // console.log(this.props.instance)

    if (action === 'capture') {
      const captured = await axios.post('/hub/v2/instance/captured', {
        selectedAccount,
        tenant_id
      }).then(
        record => record?.data?.captured,
        (e) => {
          const status = e?.response?.status
          if (status === 404) {
            this.props.showInstanceActionModalHandler('capture', this.props.instance, { name, display_name, isThirdParty, isCob })
          }
          else if (status !== 400) {
            Message.error(e.message)
          } // return undefined
        }
      )
      if (captured) {
        return Modal.info({
          title: 'Debug Info Captured',
          content: <>
            <p>We have captured the debug information on <em>{tenant_instance_name || tenant_id}</em>:</p>
            <div>Reference ID: <strong>{captured._id}</strong></div>
            <div>Description: {captured.description}</div>
            <div>Captured: {new Date(captured.captured).toLocaleString()}</div>
          </>
        })
      }
    }
    else if (action === 'edit') {
      this.props.showRecordFormModalHandler(selectedAccount, this.props.instance, name, isCob, upsertable && !tenant_instance_name)
    }
    else if (action === 'add') {
      const onlyFields = [`associations.${DSS_ID}`]
      this.props.showRecordFormModalHandler(selectedAccount, this.props.instance, name, isCob, upsertable && !tenant_instance_name, onlyFields)
    }
    else if (action === 'otp') {
      const { tenant_id, serial_number: sn = tenant_id, url } = this.props.instance
      this.props.history.push(this.props.history.pathname, { [PA_GEN_OTP]: { sn, url } })
    }
  }
  onActionClick = (name) => {
    this.operateAction(name)
    this.props.onVisibleChange(false)
  }

  handleClick = (e) => {
    if (!this.subMenuRef.current.contains(e.target)) {
      // return visibility to parent node
      this.props.onVisibleChange(false)
    }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  render() {
    const { origin, isVisible, instance, hasDSS, upsertable, isCob, name, uneditable } = this.props
    const isEditable = !uneditable && instance.instance_status === RUNNING && instance.url && instance.editable !== false && (isCob || upsertable || instance.tenant_instance_name)
    let showDSS = hasDSS && !(instance.associations?.[DSS]?.tenant_id || instance.associations?.[DSS_ID]?.tenant_id || isNonemptyArray(instance.dirsync_tenant_id)) && isEditable
    if (showDSS && FT_PAE_WITH_FAWKES && name === PRISMA_ACCESS && instance.tenant_id.endsWith(_ONPREM)) {
      showDSS = false
    }
    return (
      <div className='dropdown-submenu__container'>
        <div>
          <div className='dropdown-submenu' ref={this.subMenuRef} style={{ top: origin.y, left: origin.x }}>
            <ul className='dropdown-submenu__ul' style={{ display: isVisible ? 'block' : 'none' }}>
              {showDSS &&
                <li className='dropdown-submenu__item' onClick={() => this.onActionClick('add')}>Add {CIE}</li>
              }
              {name === PA_PANORAMA && isEditable && <li className='dropdown-submenu__item' onClick={() => this.onActionClick('otp')}>Generate OTP</li>}
              <li className={`dropdown-submenu__item ${isEditable ? '' : 'dropdown-submenu__item--disabled'}`} onClick={isEditable ? () => this.onActionClick('edit') : undefined} >Edit</li>
              <li className='dropdown-submenu__item' onClick={() => this.onActionClick('capture')} >Capture Debug Info</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

SubMenu.propTypes = {
  isVisible: PropTypes.bool,
  origin: PropTypes.object,
  instance: PropTypes.object,
  hasDSS: PropTypes.bool,
  onVisibleChange: PropTypes.func,
  showInstanceActionModalHandler: PropTypes.func,
  showRecordFormModalHandler: PropTypes.func,
  selectedAccount: PropTypes.number,
  name: PropTypes.string,
  display_name: PropTypes.string,
  isThirdParty: PropTypes.bool,
  isCob: PropTypes.bool,
  upsertable: PropTypes.bool,
  uneditable: PropTypes.bool,
  history: PropTypes.object,
}
