import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import axios from 'axios'
import { connect } from 'react-redux'
import { Form, PageBody, Button, Spin, Message, Input, Select } from '@pan/cloud-base'
import { appframeworkActions } from '@pan/cloud-appframework'

const { fetchEntitlements } = appframeworkActions
const FormItem = Form.Item
const { TextArea } = Input
const SelectOption = Select.Option

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}
export class InstanceRecordForm extends Component {
  state = { loading: false }
  constructor() {
    super()
    this.okHandler = this.okHandler.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.cancelModalHandler = this.cancelModalHandler.bind(this)
    this.remoteValidator = this.remoteValidator.bind(this)
  }
  handleReset() {
    this.props.form.resetFields()
    this.props.form.validateFields()
  }
  cancelModalHandler() {
    this.handleReset()
    this.props.hideRecordFormModalHandler()
  }
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.handleReset()
  }
  formatPostData(values, selectedInstance, selectedAccount, hasDirSyncField, form) {
    let dirsync_tenant_id = null
    const { application_name, application = application_name, tenant_id, original } = selectedInstance
    const touchedValues = {}
    _.forEach(values, (value, key) => {
      if (form.isFieldTouched(key) && value !== original[key]) {
        touchedValues[key] = value
      }
    })
    if (hasDirSyncField && form.isFieldTouched('dirsync_tenant_id')) {
      dirsync_tenant_id = touchedValues.dirsync_tenant_id ? [].concat(touchedValues.dirsync_tenant_id) : []
    }
    const postData = _.omitBy(_.defaults({
      dirsync_tenant_id,
    }, touchedValues), _.isNull)
    return _.defaults(postData, {
      selectedAccount,
      tenant_id,
      application,
      ...(selectedInstance.upsert && { upsert: true })
    })
  }
  okHandler(e) {
    e.preventDefault()
    const url = '/instance/v1/update' // no cob support
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        try {
          const data = this.formatPostData(values, this.props.selectedInstance, this.props.selectedAccount, this.props.hasDirSyncField, this.props.form)
          const resp = await axios.post(url, data)
          if (resp.data.issuccess || resp.data.ok) {
            this.props.refreshEntitlements()
            this.props.hideRecordFormModalHandler()
            Message.info('Instance successfully updated.', 3)
          }
          else {
            Message.error(resp.data.error_message || 'Fail to update instance.', 3)
          }
        }
        catch (error) {
          const { message } = error?.response?.data || {}
          Message.error(message || 'service temporarily unavailable', 3)
        }
        finally {
          this.setState({ loading: false })
        }
      }
    })
  }
  valueToDisplay(value) {
    return _.startCase(value)
  }
  getFormElement(value) {
    return <span className="ant-form-text">{value}</span>
  }
  async remoteValidator(rule, value, callback) {
    const { selectedAccount, selectedInstance } = this.props
    if (rule.remote && value !== selectedInstance.original[rule.field] && !_.isEmpty(value.trim())) {
      try {
        await axios.post(rule.remote, { [rule.field]: value, selectedAccount })
        callback()
      }
      catch (error) {
        if (error.response.status === 404) {
          callback()
        }
        const { message } = error?.response?.data || error
        return callback(new Error(message))
      }
    }
    callback()
  }
  render() {
    const { getFieldDecorator } = this.props.form
    return (<PageBody>
      <Spin spinning={this.state.loading}>
        <Form onSubmit={this.okHandler}>
          {this.props.selectedInstance._id && <FormItem
            {...formItemLayout}
            label='Name'
          >
            {
              getFieldDecorator('tenant_instance_name', {
                validate: [
                  {
                    trigger: ['onChange', 'onBlur'],
                    validateFirst: true,
                    rules: [
                      { whitespace: true, required: true, message: 'Name is required' },
                      { max: 63, message: 'Name can have at most 63 characters' }
                    ]
                  },
                  {
                    trigger: ['onBlur'],
                    rules: [{
                      validator: this.remoteValidator,
                      remote: this.props.selectedInstance.application === 'Traps' ? '/onboarding/v1/traps/validate/tenant_instance_name' : null,
                      statusString: 'nameValidateStatus'
                    }],
                  }
                ]
              })(
                <Input autoFocus />)
            }
          </FormItem>}
          {
            getFieldDecorator('tenant_id', {
            })(<div></div>)
          }
          {this.props.selectedInstance._id && <FormItem
            {...formItemLayout}
            label='Description'
          >
            {
              getFieldDecorator('description', {
                rules: [
                  { max: 1023, message: 'Description can have at most 1023 characters' }
                ]
              })(
                <TextArea rows={4} />)
            }
          </FormItem>}
          {
            this.props.hasDirSyncField &&
            <FormItem
              {...formItemLayout}
              label='Directory Sync'
            >
              {
                getFieldDecorator('dirsync_tenant_id', {
                })(
                  <Select
                    allowClear
                    notFoundContent='No available instances'
                  >
                    {
                      _.defaults(_.find(this.props.entitledAppsList, { name: 'Directory Sync' }), { tenants: [] })
                        .tenants
                        .filter((tenant) => tenant.instance_status === 'running')
                        .filter((tenant) => this.props.selectedInstance.lcaas_region && tenant.region === this.props.selectedInstance.lcaas_region)
                        .map((tenant) => <SelectOption
                          key={tenant.tenant_id}
                          value={tenant.tenant_id}>
                          {tenant.tenant_instance_name || `Instance ${tenant.tenant_id}`}
                        </SelectOption>)
                    }
                  </Select>)
              }
            </FormItem>
          }
          {this.props.selectedInstance.region &&
            <FormItem
              {...formItemLayout}
              label='Region'
            >
              <span>{this.props.selectedInstance.region_display || this.props.selectedInstance.region}</span>
            </FormItem>
          }
          <div className='form-footer'>
            <div className='ant-form-item-required col-required-legend'></div>
            <Button onClick={() => this.cancelModalHandler()}>Cancel</Button>
            {this.props.selectedInstance._id && <Button
              style={{ marginLeft: '8px', minWidth: 80 }}
              htmlType='submit'
              type='primary'
              disabled={hasErrors(this.props.form.getFieldsError())}
            >
              OK
            </Button>}
          </div>
        </Form>
      </Spin>
    </PageBody>)
  }
}

InstanceRecordForm.propTypes = {
  entitledAppsList: PropTypes.array,
  hasDirSyncField: PropTypes.bool,
  form: PropTypes.object,
  hideRecordFormModalHandler: PropTypes.func,
  selectedAccount: PropTypes.number,
  selectedInstance: PropTypes.object,
  dispatch: PropTypes.func,
  refreshEntitlements: PropTypes.func,
}

const mapStateToProps = ({ entitledAppsList, isLoading, selectedAccount, selectedInstance }) => ({
  entitledAppsList,
  isLoading, // must keep, or form will not rerender
  selectedAccount,
  selectedInstance,
})

const mapDispatchToProps = (dispatch) => ({
  fetchEntitlements: (options) => dispatch(fetchEntitlements(options)),
  showLoading: () => dispatch({ type: 'IS_LOADING_TRUE' }),
  setSelectedInstance: (data, selectedInstance) => dispatch({
    type: 'UPDATE_SELECTED_INSTANCE',
    selectedInstance: Object.assign(selectedInstance, data)
  }),
  hideRecordFormModalHandler: () => dispatch({ type: 'HIDE_RECORD_FORM_MODAL' }),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { selectedInstance, entitledAppsList } = stateProps
  let hasDirSyncField = false
  if (selectedInstance && selectedInstance.application_name) {
    const appDef = entitledAppsList.get(selectedInstance.application_name) || {}
    // this form only support manifest v1 apps, not support v2 with associations
    if (!appDef.isThirdParty && appDef.scopes) { // for now 3rd party app does not support edit dir sync
      const scopeSet = new Set(appDef.scopes)
      hasDirSyncField = scopeSet.has('directory-sync-service:read')
    }
  }
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    hasDirSyncField,
    setSelectedInstance: (data) => dispatchProps.setSelectedInstance(data, selectedInstance),
    refreshEntitlements: () => {
      dispatchProps.showLoading()
      return dispatchProps.fetchEntitlements()
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Form.create({
  mapPropsToFields(props) {
    return {
      tenant_instance_name: Form.createFormField({
        value: props.selectedInstance.tenant_instance_name
      }),
      description: Form.createFormField({
        value: props.selectedInstance.description
      }),
      tenant_id: Form.createFormField({
        value: props.selectedInstance.tenant_id
      }),
      dirsync_tenant_id: Form.createFormField({
        value: props.selectedInstance.dirsync_tenant_id ||
          (props.selectedInstance.directoryService && props.selectedInstance.directoryService.dirsync_tenant_id[0])
      }),
      lcaas_data: Form.createFormField({
        value: props.selectedInstance.lcaas_data
      }),
    }
  },
  onFieldsChange(props, fields) {
    const data = {}
    for (const key in fields) {
      if (fields[key].touched) {
        data[key] = fields[key].value
      }
    }
    props.setSelectedInstance(data)
  }
})(InstanceRecordForm))
