import React, { useLayoutEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Modal, Message, Spin } from '@pan/cloud-base'
import { useAxiosRequest, useDeepMemo } from '../../utils/hooks'
import { PA_GEN_OTP } from '../../constants/AppConstants'
import OTP from './OTP'

const EMPTY_RESULT = {}
const OTPModal = ({ selectedAccount }) => {
  const history = useHistory()
  const otpState = history.location.state?.[PA_GEN_OTP]
  const otpReq = useDeepMemo(() => {
    if (selectedAccount >= 0 && otpState?.sn) {
      const { sn, url } = otpState
      const session = url?.replace(/^.*\/|#.*/g, '') || undefined
      return { sn, session, selectedAccount }
    }
    return undefined
  }, [otpState, selectedAccount])
  const { loading, data = EMPTY_RESULT, load } = useAxiosRequest()
  useLayoutEffect(() => {
    if (otpReq?.sn) {
      load({
        method: 'POST',
        url: '/hub/v2/licenses/otp',
        data: otpReq,
      }).then((result) => {
        Message.info('The generated OTP will be valid within 10 minutes', 2)
        return result
      })
    }
  }, [load, otpReq])

  const hideModal = useCallback(() => {
    history.replace(history.location.pathname) // remove state
  }, [history])

  return <Modal
    width={500}
    className='otp-modal'
    title={'Generate One-Time Password'}
    visible={Boolean(otpReq)}
    onCancel={hideModal}
    maskClosable={false}
    destroyOnClose={true}
    closable={!loading}
    keyboard={!loading}
    footer={null}
  >
    <Spin spinning={loading}>
      {data.error || null}
      {loading && <OTP
        inputProps={{ placeholder: 'Generating...' }}
        btnProps={{ disabled: true }}
      />}
      {data.pri ? <OTP
        label={data.sec ? 'Copy One-time Password for Primary Panorama' : 'Copy One-time Password'}
        otp={data.pri.otp}
        otp_expiration={data.pri.otp_expiration}
        showLabel={true}
      /> : null}
      {data.sec ? <OTP
        label={'Copy One-time Password for Secondary Panorama'}
        otp={data.sec.otp}
        otp_expiration={data.sec.otp_expiration}
        showLabel={false}
      /> : null}
    </Spin>
  </Modal>
}

OTPModal.propTypes = {
  selectedAccount: PropTypes.number,
}

export default OTPModal
