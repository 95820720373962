import React from 'react'
import PropTypes from 'prop-types'

const labelStyle = {
  color: '#999',
  marginBottom: '.5rem',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontSize: 14

}
export const LSCLabel = (props) => <label style={{ ...labelStyle, ...props.style }}>{props.children}</label>

LSCLabel.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object
}
