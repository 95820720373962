import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { PageHeader, PageBody, Button, Message, Spin } from '@pan/cloud-base'
import {
  ACTIVATE, APPS, SETTINGS, ROLES, EMPTY_LOC_STATE,
  decodeAuthCode, genCancelSource, wait,
  sortSupportAccounts,
} from '../../utils/activateUtils'
import ActivatedForm from './ActivatedForm'
import ActivateForm from './ActivateForm'
import ActivateAlertBanner from './ActivateAlertBanner'
import { discovery } from './AuthCodeModal'
import ActivateProgressModal from './ActivateProgressModal'

const Loading = ({ isLoading = true }) => <div className='activation-page v2'>
  <Spin spinning={isLoading} style={{ height: '100%' }}></Spin>
</div>
Loading.propTypes = { isLoading: PropTypes.bool }

const ActivatePage = ({
  history,
  available,
  isAuthenticated,
  isGlobalLoading,
  appNames,
  displayName,
  license_type,
  is_eval,
  support_account_id,
  support_account_name,
  supportAccountIds,
  initialValues,
  entitledAppsList,
  selectedAccount,
  user_email,
  auth_code,
  meta_data,
  alertBanner,
  showAlertBanner,
  resetActivate,
  activateWithAuthcode,
  setInitialValues,
  setIsLoading,
  switchAccount,
  refreshEntitlements,
  fetchEntitlements,
  showAuthCodeModal,
  showAppActivateModal,
  showActivateProgress,
  hideActivateProgress,
  activateProgress,
  activateResult,
  resetActivateProgress,
  resetActivateResult,
  fetchCredentialIfNeeded,
}) => {
  const { state = EMPTY_LOC_STATE } = history.location
  const noAppName = !appNames.length
  const [isLoading, setLoading] = useState(null) // use null instead of false
  const [entitlementsPromise, setEntitlementsPromise] = useState(null)
  const clearAlertBanner = useCallback(() => showAlertBanner(null), [showAlertBanner])
  const cancelSource = useMemo(genCancelSource, [])
  const supportAccounts = useMemo(() => sortSupportAccounts(supportAccountIds), [supportAccountIds])
  const accountIsFederal = useMemo(() => {
    const currentAccount = supportAccountIds.find(({ accountid }) => accountid === support_account_id)
    return Boolean(currentAccount?.is_federal)
  }, [supportAccountIds, support_account_id])
  const rediscovery = useCallback(async (authcode, cancel = _.noop) => {
    try {
      setLoading(true)
      const [data] = await Promise.all([
        discovery(authcode, selectedAccount, cancelSource.token),
        refreshEntitlements(), // ensure have latest instances and meta
      ])
      if (data.redirects) {
        if (data.redirects.startsWith('/')) {
          history.replace(data.redirects)
        }
        return
      }
      activateWithAuthcode(data)
    }
    catch (error) {
      if (cancelSource.token.reason || cancel === 'silent') { // canceled
        return // skip doing anything
      }
      if (error.status === 400) {
        Message.error(error.message || 'Invalid auth code input.', 3)
      }
      else {
        Message.error(error.message || 'service temporarily unavailable', 3)
      }
      cancel()
    }
    finally {
      if (!cancelSource.token.reason) {
        setLoading(false)
      }
    }
  }, [selectedAccount, cancelSource.token, refreshEntitlements, history, activateWithAuthcode])

  const getReferer = useCallback((toOrigin, defaults = APPS) => {
    let referer = history.location.state?.referer || defaults
    if (!referer) {
      return defaults
    }
    if (toOrigin === true) {
      while (referer.state?.referer) {
        referer = referer.state.referer
      }
      if (referer === ACTIVATE) {
        return defaults
      }
    }
    if (referer.pathname) {
      return referer
    }
    else if (_.isString(referer)) {
      return referer
    }
    return defaults
  }, [history])

  const onCancel = useCallback((all) => {
    resetActivate()
    if (history.location.pathname !== ACTIVATE) {
      return
    }
    const referer = getReferer(all === true)
    if (referer.pathname) {
      history.push(referer.pathname, referer.state)
    }
    else {
      history.push(referer || APPS)
    }
  }, [getReferer, history, resetActivate])

  const goBack = useCallback((all, defaults) => {
    resetActivate()
    if (history.location.pathname !== ACTIVATE) {
      return
    }
    const referer = getReferer(all, defaults)
    if (referer.pathname) {
      history.replace(referer.pathname, referer.state)
    }
    else if (referer) {
      history.replace(referer)
    }
    // else if (history.length > 1) { // not working
    //   history.goBack() // may go back out of app portal
    // }
    else {
      history.replace(APPS)
    }
  }, [getReferer, history, resetActivate])

  useEffect(() => { // only run once on mount and become available
    if (!isAuthenticated || !available || state.activated) { // but not activated
      return
    }
    if (noAppName) {
      const savedAuthCode = state.reference && decodeAuthCode(state.reference)
      const goBackShowAuthCodeModel = () => {
        goBack(Boolean(auth_code), '')
        showAuthCodeModal(auth_code === true ? {} : { authcode: auth_code })
      }

      if (savedAuthCode) { // discovery when saved authcode but no appNames
        rediscovery(savedAuthCode, goBackShowAuthCodeModel)
      }
      else {
        goBackShowAuthCodeModel()
      }
    }
    else if (!auth_code) { // refetch entitlements during mount if not come from authcode discovery
      refreshEntitlements()
    }
  }, [available, selectedAccount, state.activated, state.reference]) // eslint-disable-line react-hooks/exhaustive-deps

  const onActivated = useCallback((activated, msg) => {
    if (msg && msg.message) {
      showAlertBanner({ type: 'success', ...msg })
    }
    fetchCredentialIfNeeded(activated)
    history.replace(ACTIVATE, {
      selectedAccount, // ensure go back when switch account
      activated,
      continue: state.referer?.pathname === ACTIVATE && state.referer.state, // needs continue
    })
    // force refresh entitlements after 1s, remove if push entitlements is available
    setEntitlementsPromise(wait(1000).then(fetchEntitlements).then(() => {
      if (!cancelSource.token.reason) {
        setEntitlementsPromise(null)
      }
    }))
    setTimeout(resetActivate, 500) // a little delay
  }, [cancelSource.token.reason, fetchCredentialIfNeeded, fetchEntitlements, history, resetActivate, selectedAccount, showAlertBanner, state.referer])

  const onActivateFailed = useCallback(async (err) => { // ensure return promise
    if (err.message) {
      showAlertBanner({
        type: 'error',
        message: `Activation failed. ${err.message}` || 'Activation failed',
      })
    }
    await auth_code ? rediscovery(auth_code, 'silent') : fetchEntitlements()
  }, [auth_code, rediscovery, fetchEntitlements, showAlertBanner])

  useEffect(() => { // prevent switch account after activated or nova
    const { state = EMPTY_LOC_STATE } = history.location
    if (selectedAccount >= 0 && state.selectedAccount >= 0 && state.selectedAccount !== selectedAccount) {
      goBack(true)
    }
  }, [selectedAccount, history, goBack])

  useEffect(() => cancelSource.unmountCallback, [cancelSource]) // cancel pending request when unmount
  useEffect(() => resetActivate, [resetActivate]) // ensure reset when unmount

  const activatedInfo = useMemo(() => {
    if (!state.activated) {
      return {}
    }
    let buttonText = 'Manage Apps'
    let continueDisplay
    let extraBtn
    let message = 'The instance is being activated now and will be available shortly. '
    if (state.continue) {
      const { display, appName } = state.continue
      continueDisplay = display || (appName && entitledAppsList.get(appName)?.display_name)
      buttonText = continueDisplay ? `Continue Activating ${continueDisplay}` : 'Continue Activation'
      message += continueDisplay ? `Please continue activating ${continueDisplay}.` : 'Please continue activation.'
    }
    else {
      message += 'Please check Manage Apps page for details.'
      extraBtn = <Button
        href={ROLES} onClick={(e) => {
          e.preventDefault()
          history.push(ROLES)
        }}
      >Manage Roles</Button>
      if (state.activated.application_name) {
        const { display_name, extentions } = entitledAppsList.get(state.activated.application_name) || {}
        if (Array.isArray(extentions) && extentions.some(ext => ext.has_auth_code)) {
          extraBtn = <>
            <Button
              href={ACTIVATE} onClick={(e) => {
                e.preventDefault()
                showAuthCodeModal({})
              }}
            >Activate {display_name} Subscription</Button>
            {extraBtn}
          </>
        }
      }
    }
    return {
      text: buttonText,
      extra: extraBtn,
      msg: { type: 'success', message },
    }
  }, [entitledAppsList, history, state.activated, state.continue, showAuthCodeModal])

  if (!available) {
    return isGlobalLoading ? null : <Loading />
  }
  const progressModal = displayName ? <ActivateProgressModal
    key='activate-progress'
    title={`Activating ${displayName} ...`}
    visible={activateProgress.visible}
    progress={activateProgress.progress}
    statuses={activateProgress.statuses}
    steps={activateProgress.steps}
    afterClose={resetActivateProgress}
  /> : null
  if (state.activated) {
    // console.error('activated', state.activated)
    return <div className={`activation-page v2 ${accountIsFederal ? 'account-is-federal' : ''}`}>
      <ActivateAlertBanner
        {...(alertBanner || activatedInfo.msg)}
        onClose={clearAlertBanner}
      />
      <PageHeader
        head='Activation Details'
        subhead='Here are the details of the apps we are activating.'
      />
      <PageBody>
        <ActivatedForm
          data={state.activated}
          loading={isLoading}
          btnProps={{
            text: activatedInfo.text,
            onClick: async () => {
              if (state.continue) { // continue
                setLoading(true)
                await entitlementsPromise || fetchEntitlements() // force
                setLoading(false)
                history.push(ACTIVATE, state.continue)
              }
              else { // done
                if (entitlementsPromise) {
                  setLoading(true)
                  await entitlementsPromise
                  setLoading(false)
                }
                else {
                  setIsLoading(true)
                  fetchEntitlements()
                }
                history.push(SETTINGS)
              }
            }
          }}
          extra={activatedInfo.extra}
        />
      </PageBody>
      {progressModal}
    </div>
  }
  else if (noAppName && isLoading === null) { // initializing
    return isGlobalLoading ? null : <Loading />
  }
  else if (noAppName && isLoading && available) {
    return <Loading isLoading={isLoading} />
  }
  const activateFormProps = {
    appNames,
    displayName,
    license_type,
    history,
    support_account_id,
    support_account_name,
    initialValues,
    entitledAppsList,
    selectedAccount,
    supportAccounts,
    is_eval,
    user_email,
    auth_code,
    ...(state.isNova && {
      tenant_id: state.tenant_id,
      serial_number: state.serial_number,
      bundled_tenant: state.bundled_tenant,
      available_account_ids: state.support_account_ids,
    }),
    ...meta_data,
    activateProgress,
    activateResult,
    alertBanner,
    resetActivate,
    resetActivateResult,
    switchAccount,
    showAuthCodeModal,
    showAppActivateModal,
    showAlertBanner,
    showActivateProgress,
    hideActivateProgress,
    setInitialValues,
    onActivated,
    onActivateFailed,
    onActivateClicked: clearAlertBanner,
    onCancel,
    accountIsFederal,
  }
  const header = state.isNova && state.serial_number ? <>
    Activate {displayName} <span className='page-header-extra'>(Serial Number: {state.serial_number})</span>
  </> : `Activate ${displayName}`
  return <div className={`activation-page v2 ${accountIsFederal ? 'account-is-federal' : ''}`}>
    {alertBanner && <ActivateAlertBanner
      message={alertBanner.message}
      type={alertBanner.type || 'error'}
      onClose={clearAlertBanner}
    />}
    <PageHeader
      head={header}
      subhead={'Please provide the following information to set up the app.'}
    />
    <PageBody>
      <ActivateForm {...activateFormProps} />
    </PageBody>
    {progressModal}
  </div>
}

ActivatePage.propTypes = {
  history: PropTypes.object,
  available: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isGlobalLoading: PropTypes.bool,
  appNames: PropTypes.arrayOf(PropTypes.string),
  initialValues: PropTypes.object,
  displayName: PropTypes.string,
  auth_code: PropTypes.string,
  is_eval: PropTypes.bool,
  entitledAppsList: PropTypes.array,
  license_type: PropTypes.string,
  user_email: PropTypes.string,
  selectedAccount: PropTypes.number,
  supportAccountIds: PropTypes.array,
  support_account_id: PropTypes.number,
  support_account_name: PropTypes.string,
  meta_data: PropTypes.object,
  alertBanner: PropTypes.object,
  refreshEntitlements: PropTypes.func,
  fetchEntitlements: PropTypes.func,
  activateProgress: PropTypes.object,
  activateResult: PropTypes.object,
  setIsLoading: PropTypes.func,
  switchAccount: PropTypes.func,
  resetActivate: PropTypes.func,
  discovery: PropTypes.func,
  activateWithAuthcode: PropTypes.func,
  setInitialValues: PropTypes.func,
  showAlertBanner: PropTypes.func,
  showAuthCodeModal: PropTypes.func,
  showAppActivateModal: PropTypes.func,
  showActivateProgress: PropTypes.func,
  hideActivateProgress: PropTypes.func,
  resetActivateProgress: PropTypes.func,
  resetActivateResult: PropTypes.func,
  fetchCredentialIfNeeded: PropTypes.func,
}

ActivatePage.defaultProps = {
  appNames: [],
  supportAccountIds: [],
  displayName: '',
  history: {
    location: window.location
  }
}

export default ActivatePage
