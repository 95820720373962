import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { FT } from '@pan/cloud-appframework'
import './HubToggle.scss'

const HUB_NEW_URL = '/hub'
const DEFAULT_VALUE = true

const prefetchToggleHub = () => {
  if (document.getElementById('hub-prefetch')) {
    return
  }
  window.requestIdleCallback?.(() => {
    if (document.getElementById('hub-prefetch')) {
      return
    }
    const link = document.createElement('link')
    link.id = 'hub-prefetch'
    link.rel = 'prefetch'
    link.type = 'document'
    link.href = HUB_NEW_URL
    document.head.appendChild(link)
  }, { timeout: 15000 }) // 15s
}

const prepareToggleHub = () => {
  if (document.getElementById('hub-prerender')) {
    return
  }
  const link = document.createElement('link')
  link.id = 'hub-prerender'
  link.rel = 'prerender'
  link.href = HUB_NEW_URL
  document.head.appendChild(link)
}
const toggleHub = () => {
  window.location.href = HUB_NEW_URL
}

const HubToggle = () => {
  const [checked, setChecked] = useState(DEFAULT_VALUE)
  const onInternalClick = useCallback((e) => {
    e.preventDefault()
    setChecked(!DEFAULT_VALUE)
    setTimeout(toggleHub, 120)
  }, [])

  useEffect(() => {
    prefetchToggleHub()
  })

  const FT_ENABLED = FT('HUB_TSG_IAM') && FT('HAS_MSP')
  const enabled = useSelector(state => FT('DEFAULT_TSG') || (FT_ENABLED && state.msp?.has_msp))

  if (!enabled) { // to show the leading pipe if HAS_MSP is on
    return FT('HAS_MSP') ? <div className='sparky-hub-toggle-wrap'></div> : null
  }

  const classname = classNames('sparky-hub-toggle', {
    'sparky-hub-toggle-checked': checked,
  })

  return (
    <div id='sparky-hub-toggle' className='sparky-hub-toggle-wrap' onMouseOver={prepareToggleHub}>
      <button
        type='button'
        role='switch'
        aria-checked={checked}
        className={classname}
        onClick={onInternalClick}
      ></button>
      View by Support Account
    </div>
  )
}

HubToggle.displayName = 'HubToggle'

export default HubToggle
