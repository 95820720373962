export default {
  name: 'aperture',
  display_name: 'Prisma™ SaaS',
  developer: 'Palo Alto Networks, Inc.',
  developer_link: 'https://www.paloaltonetworks.com/',
  summary: 'Prisma™ SaaS reins in the risks of SaaS applications through advanced data protection and compliance assurance across SaaS applications. Offering advanced capabilities in risk discovery, data loss prevention, compliance assurance, data governance, user behavior monitoring and advanced threat prevention you can regain control of SaaS applications, maintain compliance and ensure business-critical data is secure.',
  product_link: 'https://www.paloaltonetworks.com/network-security/saas-security',
  first_release: 'May 2015',
  latest_release: 'July 2019',
  system_requirements: 'NA',
  custom_activate_btn: '', // should show purchase button later
  heading: 'Regain control of SaaS and secure business-critical data',
  body: [{
    content: [{
      p: [
        'Since software as a service is so easy, use of SaaS applications is exploding. Because of that simplicity, though, the security risks of SaaS are also on the rise. Unsanctioned SaaS apps can expose sensitive data and propagate malware, and even sanctioned SaaS adoption can increase the risk of data exposure, breaches and noncompliance.',
        'By offering advanced data protection and consistency across applications, Prisma™ SaaS reins in the risks. It addresses your cloud access security broker needs and provides advanced capabilities in risk discovery, data loss prevention, compliance assurance, data governance, user behavior monitoring and advanced threat prevention. Now you can maintain compliance while preventing data leaks and business disruption through a fully cloud-delivered CASB deployment.',
      ]
    }, {
      ul: [
        'Deep cloud risk visibility: Easy-to-navigate SaaS usage dashboards and detailed reporting help rein in shadow IT. Discover risks at a deeper level with complete visibility into all user, folder, and file activity across SaaS applications, generating detailed analysis that helps you quickly determine the presence of any data risk- or compliance-related policy violations.',
        'Data protection and leakage prevention: Define granular, context-aware policy control to drive enforcement as well as quarantine users and data as soon as violations occur. Prisma SaaS can secure your applications by classifying data and monitoring usage through machine learning and an advanced data loss prevention (DLP) engine.',
        'Granular and adaptive access control: Simple-to-manage policies give you granular control over access to SaaS applications to define which are allowed and acceptable behavior within them. This lets you block access for unsanctioned applications while maintaining control of tolerated applications.',
        'Data governance and compliance assurance: You can quickly and easily satisfy data risk compliance requirements, such as those related to GDPR, PCI, PII, or PHI data, while maintaining the benefits of cloud-based applications.',
        'User behavior monitoring: Heuristic-based user behavior monitoring and alerting enables you to easily identify suspicious behavior, such as logins from unexpected regions, unusually large usage activity, or multiple failed logins, that may indicate credential theft.',
        'Advanced threat prevention: Block known malware, plus identify and block unknown malware within SaaS applications.',
      ]
    }, {
      p: [
        'The Prisma SaaS application integrates with Cortex by combining data from the Cortex Data lake with data pulled directly from the SaaS applications themselves to correlate and report on SaaS usage across your entire organization. The organizational level visibility of SaaS usage allows a greater understanding of risk and so that more effective governance and usage policies can be defined and data exposure prevention and compliance assurance can be enforced automatically.',
      ]
    }]
  }]
}
