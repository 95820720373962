import { PRISMA_ACCESS } from '../../constants/AppConstants'

const msp = (state = '', action) => {
  switch (action.type) {
    case 'FETCH_CREDENTIALS_START':
      return { ...state, has_msp: false }
    case 'FETCH_CREDENTIALS_SUCCESS':
      return { ...state, has_msp: action.data?.has_msp }
    case 'FETCH_APPS_SUCCESS':
    case 'SET_ENTITLED_APPS': {
      const fawkes = Array.isArray(action.apps) ?
        action.apps.find(app => app.name === PRISMA_ACCESS) :
        action.apps?.[PRISMA_ACCESS]
      const { sase_portal_url } = fawkes?.extra || {}
      if (sase_portal_url) {
        return { ...state, sase_portal_url }
      }
      return state
    }
    default:
      return state
  }
}

export default msp
