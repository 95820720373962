/**
 *
 * Created by vsuthar on 9/20/18 File PANPage
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import { Layout } from '@pan/cloud-base'
import PANSidePanel from './PANSidePanel'
import PropTypes from 'prop-types'
import PANContent from './PANContent'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/slide.css'

/**
 * PANPage is template that provide horizontal split layout
 */
class PANPage extends PureComponent {
  render() {

    const { sidePanelProps, mainContentProps, sidePanel, mainContent, hideSidePanel = false, ...others } = this.props
    return (
      <Layout {...others}>
        {!hideSidePanel ? <PANSidePanel { ...sidePanelProps } >
          {sidePanel}
        </PANSidePanel> : null }

        <PANContent { ...mainContentProps}>
          {mainContent}
        </PANContent>
        <Alert stack={{ limit: 1 }} />
      </Layout>
    )
  }
}

/**
 *
 * @type {{sidePanel: *, mainContent: *, sidePanelProps: *}}
 */
PANPage.propTypes = {
  sidePanel: PropTypes.any,
  /**
   *
   */
  mainContent: PropTypes.any,
  /**
   * @sidePanelProps [Sider]https://ant.design/components/layout/#components-layout-demo-side
   * this will be all sider props in antd
   */
  sidePanelProps: PropTypes.object,

  /**
   * @mainContentProps
   */
  mainContentProps: PropTypes.object,
  /**
   * @hideSidePanel hide side panel. default false
   */
  hideSidePanel: PropTypes.bool
}

/**
 *
 * @type {{mainContentProps: {showPageHeader: boolean, header: string, subHeader: string}}}
 * make showPageHeader false by default. User needs to enabled showPageHeader in order to render header
 */
PANPage.defaultProps = {
  mainContentProps: {
    showPageHeader: false,
    header: 'Main Header',
    subHeader: 'Sub Header',
  },
  hideSidePanel: false
}

export default PANPage
