import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Input } from '@pan/cloud-base'
import { RightArrowCircledIcon } from '../../images/svg-icons'

/**
 *
 */
class SearchInput extends React.PureComponent {

  /**
   *
   * @returns {*}
   */
  render() {
    return <Input
      className='search-bar'
      v2={true}
      prefix={<Icon type="search" />}
      suffix={<RightArrowCircledIcon />}
      {...this.props}
      style={{ ...this.props.style, border: 0, borderRadius: 10 }}
    />
  }
}

/**
 *
 * @type {{style: *}}
 */
SearchInput.propTypes = {
  style: PropTypes.object
}
export default SearchInput

