/**
 *
 * @type {string}
 * All actions related constants should go here
 */


/* ============== RBAC ================ */
export const IS_MIXED_HAPPEN = 'IS_MIXED_HAPPEN'
export const GET_ALL_ROLES = 'GET_ALL_ROLES'
export const AUTO_COMPLETE_GET_USERS = 'AUTO_COMPLETE_GET_USERS'
export const APP_LOADING_STATE = 'APP_LOADING_STATE'
export const ERROR_OCCURRED = 'ERROR_OCCURRED'
export const SET_ENTITLED_APPS = 'SET_ENTITLED_APPS'
export const SELECTED_USERS = 'SELECTED_USERS'
export const GET_CLOUD_ROLES = 'GET_CLOUD_ROLES'
export const ASSIGN_INSTANCE_ROLES = 'ASSIGN_INSTANCE_ROLES'
export const PATCH_UPDATE_USERS = 'PATCH_UPDATE_USERS'
export const FILTER_CHANGE = 'FILTER_CHANGE'
export const AUTO_COMPLETE_USER_SELECTION = 'AUTO_COMPLETE_USER_SELECTION'
export const REMOVE_SELECTED_USER_TAG = 'REMOVE_SELECTED_USER_TAG'
export const INPUT_TABLE_SEARCH = 'INPUT_TABLE_SEARCH'
export const TABLE_SEARCH = 'TABLE_SEARCH'
export const AUTO_COMPLETE_LOADING = 'AUTO_COMPLETE_LOADING'
export const CLEAR_ASSIGN_ROLE_CACHE = 'CLEAR_ASSIGN_ROLE_CACHE'
export const GET_USER_ACCOUNT_ROLE = 'GET_USER_ACCOUNT_ROLE'
export const GET_SELECTED_USERS_ROLES = 'GET_SELECTED_USERS_ROLES'
export const OPEN_ASSIGN_CUSTOM_PERMISSIONS = 'OPEN_ASSIGN_CUSTOM_PERMISSIONS'
// ======== CUSTOM ROLE ======= //
export const GET_ROLE_DEF = 'GET_ROLE_DEF'
export const GET_RBAC_SCHEMA = 'GET_RBAC_SCHEMA'
export const RBAC_SEARCH_ROLE = 'RBAC_SEARCH_ROLE'
export const RBAC_SEARCH_ROLE_SEARCHING = 'RBAC_SEARCH_ROLE_SEARCHING'
export const RBAC_SELECT_APP_SUCCESS = 'RBAC_SELECT_APP_SUCCESS'
export const RBAC_CREATE_CUSTOM = 'RBAC_CREATE_CUSTOM_ROLE'
export const RBAC_DELETE_CUSTOM_ROLE_ACTION = 'RBAC_DELETE_CUSTOM_ROLE_ACTION'
export const RBAC_CUSTOM_ROLE_DIALOG = 'RBAC_CUSTOM_ROLE_DIALOG'
export const RBAC_VIEW_ROLE = 'RBAC_VIEW_ROLE'
export const RBAC_EDIT_ROLE = 'RBAC_EDIT_ROLE'
export const RBAC_MERGE_ROLE_DEF = 'RBAC_MERGE_ROLE_DEF'
export const RBAC_ROLE_TABLE_LOADING = 'RBAC_ROLE_TABLE_LOADING'
export const RBAC_CONFIRMATION_DIALOG = 'RBAC_CONFIRMATION_DIALOG'
export const RBAC_REFRESH_TENANT = 'RBAC_REFRESH_TENANT'
export const RBAC_SELECTED_ROLE = 'RBAC_SELECTED_ROLE'
export const RBAC_SKU_LOOKUP = 'RBAC_SKU_LOOKUP'
export const RBAC_ACTION_ROLE_DEF = 'RBAC_ROLE_DEF'
/* ============== RBAC ================ */

/* ============== DASHBOARD ============ */
export const DASHBOARD_LOGGING_SERVICE_INST_CHANGE = 'DASHBOARD_LOGGING_SERVICE_INST_CHANGE'
export const DASHBOARD_PRISMA_CLOUD_INST_CHANGE = 'DASHBOARD_PRISMA_CLOUD_INST_CHANGE'
export const DASHBOARD_PRISMA_SAAS_CLOUD_INST_CHANGE = 'DASHBOARD_PRISMA_SAAS_CLOUD_INST_CHANGE'

/** =========== SETUP ================= **/
export const SET_UP_NEXT_STEP = 'SET_UP_NEXT_STEP'
export const SET_UP_BACK_STEP = 'SET_UP_BACK_STEP'
export const SET_UP_INIT_LICENSE = 'SET_UP_INIT_LICENSE'
export const PRODUCT_SELECT = 'PRODUCT_SELECT'
export const SETUP_ACCOUNT_SELECT = 'SETUP_ACCOUNT_SELECT'
export const SET_CDL_SELECTION = 'SET_CDL_SELECTION'
export const SET_REGION = 'SET_REGION'
export const CHOOSE_HOW_TO_MANAGE = 'CHOOSE_HOW_TO_MANAGE'
export const PRESMA_ACCESS_SELECTION = 'PRESMA_ACCESS_SELECTION'
export const HELP_DRAWER = 'HELP_DRAWER'
export const APPS_LIST_UPDATE = 'APPS_LIST_UPDATE'
export const SET_DEVIECS = 'SET_DEVIECS'
export const OVERRIDE_CONTEXT = 'OVERRIDE_CONTEXT'
export const SET_NEW_PANORAMA_SN = 'SET_NEW_PANORAMA_SN'
export const FIREWALL_LIST_VISIBLE = 'FIREWALL_LIST_VISIBLE'
export const SET_SUBSCRIBED_FIREWALL_POLL_TIME = 'SET_SUBSCRIBED_FIREWALL_POLL_TIME'
export const SET_SELECTED_FIREWALL_DEVICES = 'SET_SELECTED_FIREWALL_DEVICES'
export const SET_ERROR = 'SET_ERROR'
export const SET_NEW_CLCS_TENANT = 'SET_NEW_CLCS_TENANT'
export const SET_UP_SET_SG_ID = 'SET_UP_SET_SG_ID'
