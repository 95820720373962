/**
 * Created by vsuthar on 5/11/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Row, Col, Select, Checkbox, Button, Icon } from '@pan/cloud-base'
import {
  REGISTRATION_EMAIL,
  REGISTRATION_FIRST_NAME,
  REGISTRATION_LAST_NAME,
  REGISTRATION_NEXT,
  REGISTRATION_PASSWORD,
  REGISTRATION_PASSWORD_MUST_HAVE,
  REGISTRATION_QUIT,
  REGISTRATION_RE_ENTER_PASSWORD,
  REGISTRATION_SUBMIING,
  REGISTRATION_SUPPORT_ACCOUNT,
} from '../../constants/AppConstants'
import { useMemoizeValue } from '../../utils/hooks'

const RegistrationForm = ({ supportAccountMsg = '', supportAccountIds = [], isLoading, form, onRegisterUser, handleQuite }) => {

  const { getFieldDecorator, getFieldsValue, getFieldsError } = form
  const { password = '' } = useMemoizeValue(getFieldsValue(['password']))

  /**
   * @TODO remove common code. Same code is there in SetupBase
   */
  const errors = getFieldsError()
  const validateError = useMemo(() => {
    const validateError = []
    const errosArr = Object.keys(errors)
    errosArr.forEach(eachErr => {
      const err = errors[eachErr]
      if (err && Array.isArray(err)) {
        validateError.push(...err)
      }
    })
    return validateError
  }, [errors])

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    form.validateFields((err, values) => {
      if (!err) {
        const data = {
          ...values
        }
        onRegisterUser(form, data)

      }
      // console.log(err)
    })
  }, [form, onRegisterUser])

  const red = {
    color: 'red'
  }
  const green = {
    color: 'green'
  }
  const comparePassword = useCallback((rule, value, callback) => {
    const password = form.getFieldValue('password')
    const reEnterPass = form.getFieldValue('re-enter-password')
    if (reEnterPass && reEnterPass.length && password !== reEnterPass) {
      callback('Those passwords didn\'t match. Try again!')
    }
    callback()
  }, [form])

  const confirmPassword = useCallback((rule, value, callback) => {
    form.validateFields(['re-enter-password'], { force: true })
    callback()
  }, [form])

  return <Form style={{ padding: '0 25px' }} onSubmit={handleSubmit}>
    <Row gutter={12}>
      <Col xxl={12} lg={12} md={12}>
        <Form.Item label={REGISTRATION_FIRST_NAME}>
          {getFieldDecorator('firstName', { rules: [{ required: true, message: 'First name is required.' }] })(<Input />)}
        </Form.Item>
        <Form.Item label={REGISTRATION_LAST_NAME}>
          {getFieldDecorator('lastName', { rules: [{ required: true, message: 'Last name is required.' }] })(<Input />)}
        </Form.Item>
        <Form.Item label={REGISTRATION_PASSWORD}>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Password is required.', pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/ }, { validator: confirmPassword }
            ]
          })(<Input type={'password'} />)}
        </Form.Item>
        <Form.Item label={REGISTRATION_RE_ENTER_PASSWORD}>
          {getFieldDecorator('re-enter-password', {
            rules: [{ required: true, message: 'Re-Enter Password is required.' }, { validator: comparePassword }]
          })(<Input type={'password'} />)}
        </Form.Item>
      </Col>
      <Col xxl={12} lg={12} md={12}>
        <Form.Item label={REGISTRATION_EMAIL}>
          {getFieldDecorator('email', {
            rules: [
              { type: 'email', message: 'The input is not valid E-mail!' },
              { required: true, message: 'Please input your E-mail!' }]
          })(<Input />)}
        </Form.Item>
        <ul className={'password-check'}>
          <span><b>{REGISTRATION_PASSWORD_MUST_HAVE}</b></span>
          <li>{/.{15,}/.test(password) ? <Icon type="check" style={green} /> : <Icon type="close" style={red} />} At least fifteen characters </li>
          <li>{/(?=.*?[A-Z])/.test(password) ? <Icon type="check" style={green} /> : <Icon type="close" style={red} />} At least one uppercase </li>
          <li>{/(?=.*?[a-z])/.test(password) ? <Icon type="check" style={green} /> : <Icon type="close" style={red} />} At least one lowercase </li>
          <li>{/(?=.*?[0-9])/.test(password) ? <Icon type="check" style={green} /> : <Icon type="close" style={red} />} At least one number </li>
          <li>{/(?=.*?[#?!@$%^&*-])/.test(password) ? <Icon type="check" style={green} /> : <Icon type="close" style={red} />} At least one special character </li>
        </ul>
      </Col>
      <Col sm={24}>
        <Form.Item label={REGISTRATION_SUPPORT_ACCOUNT} extra={supportAccountMsg}>
          {getFieldDecorator('supportAccountId', { rules: [{ required: true, message: 'Please select Support Account' }] })(
            <Select placeholder={'Support Accounts'} >
              { supportAccountIds.map(({ support_account_id, support_account_name }) => {
                return <Select.Option key={support_account_id} value={support_account_id.toString()} >{support_account_name}</Select.Option>
              })}
            </Select>
          )
          }
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('confirm', { rules: [{ required: true, message: 'Agree Term and Conditions' }] })(<Checkbox>I agree to the Terms and Conditions</Checkbox>)}
        </Form.Item>
      </Col>
    </Row>
    <Row className='setup-base-footer' type={'flex'} justify={'space-between'} align={'middle'}>
      <Col>
        <Button v2={false} onClick={handleQuite}>{REGISTRATION_QUIT}</Button>
      </Col>
      <Col>
        <Button disabled={validateError.length}
          loading={isLoading}
          style={{ marginLeft: 5 }}
          htmlType={'submit'}
          type={'primary'}
          v2={false}>{isLoading ? REGISTRATION_SUBMIING : REGISTRATION_NEXT}</Button>
      </Col>
    </Row>
  </Form>
}

RegistrationForm.propTypes = {
  form: PropTypes.object,
  onRegisterUser: PropTypes.func,
  isLoading: PropTypes.bool,
  handleQuite: PropTypes.func,
  supportAccountIds: PropTypes.array,
  supportAccountMsg: PropTypes.string,
}

RegistrationForm.defaultProps = {
  isLoading: false
}

export default Form.create()(RegistrationForm)
