import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withRouter } from 'react-router-dom'
import { Menu, Dropdown } from '@pan/cloud-base'
import MyOneAppBadge from './MyOneAppBadge'
import AppIcon from '../AppIcon'
import { canNovaSetup, allowInstanceAdmin, getNovaSetupPathState } from '../../../utils/activateUtils'
import { ACTIVATE, APPS, SETTINGS } from '../../../constants/RouteConstants'
import { SETUP_PENDING_STATUS, DSS, DSS_ID, RUNNING } from '../../../constants/AppConstants'
import { DotsIcon } from '../../../images/svg-icons'
import SubMenu from './SubMenu'
import './MyOneApp.scss'

const { Item } = Menu
const EDIT_URL = '#edit'

const preventDefault = e => e.preventDefault()
const isDSSApp = (asso) => asso.app_id === DSS_ID || asso.app === DSS
const getBadgeStatus = status => (status === 'setup' ? 'error' : status)
const determinePendingProvisioningStatus = ({ instance_status }) => (
  instance_status === 'provisioning' || instance_status === 'pending'
)
const determineRunningStatus = ({ url, instance_status }) => typeof url === 'string' && instance_status === RUNNING
// const isHidden = t => t.no_app_tile_ui
const getOnlyActiveInstance = (instances) => {
  if (instances.length === 1 && determineRunningStatus(instances[0])) {
    return instances[0]
  }
  return undefined
}

class MyOneApp extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDropdownVisible: false,
      isSubMenuVisible: false,
      isSubMenuLoaded: false,
      isDotVisible: false,
      subMenuRect: {},
      currentOpenedSubMenuIndex: -1,
      currentOpenedSubMenuInstance: {},
    }
    this.subMenuRef = React.createRef()
  }

  onVisibleChange = (flag) => {
    this.setState({ isDropdownVisible: flag })
  }

  onSubMenuVisibleChange = (flag) => {
    this.setState({ isSubMenuVisible: flag })
  }

  edit = (tenant) => {
    const { selectedAccount, name, isCob, upsertable } = this.props
    this.props.showRecordFormModalHandler(selectedAccount, tenant, name, isCob, upsertable && !tenant.tenant_instance_name)
  }

  novaSetup = (tenant) => {
    const pathInfo = getNovaSetupPathState(tenant, this.props, this.props.selectedAccount)
    if (pathInfo) {
      this.props.history.push(ACTIVATE, {
        ...pathInfo,
        referer: APPS,
      })
    }
  }

  onClickSubmenuBtn = (e, i, instance) => {
    const SUBMENU_WIDTH = 152
    if (!this.state.isSubMenuLoaded) {
      this.setState({ isSubMenuLoaded: true })
    }
    const subMenuRect = {}
    // use parent because of considering scroll bar
    const dropdownEl = e.target.parentNode.parentNode.parentNode
    const dropdownOffsetX = dropdownEl.offsetLeft
    const dropdownOffsetY = dropdownEl.offsetTop
    const dropdownWidth = dropdownEl.offsetWidth
    const offset = dropdownEl.getBoundingClientRect().top - dropdownOffsetY
    const liRect = e.target.parentNode.getBoundingClientRect()

    // check if display on the right will overflow the window
    const viewportWidth = document.querySelector('#root').clientWidth
    const dropdownAbsoluteRight = dropdownEl.getBoundingClientRect().right
    if (dropdownAbsoluteRight + SUBMENU_WIDTH > viewportWidth) {
      // overflowed
      subMenuRect.x = dropdownOffsetX - SUBMENU_WIDTH
    }
    else {
      subMenuRect.x = dropdownOffsetX + dropdownWidth - 3
    }
    subMenuRect.y = liRect.y - offset - 5

    if (i === this.state.currentOpenedSubMenuIndex) {
      // clicked same subMenu, do toggle
      this.setState((prevState) => ({
        isSubMenuVisible: !prevState.isSubMenuVisible,
        subMenuRect,
      }))
    }
    else {
      // clicked different one, always open
      this.setState({ isSubMenuVisible: true, subMenuRect })
    }
    this.setState({
      currentOpenedSubMenuIndex: i,
      currentOpenedSubMenuInstance: instance,
    })
  }

  onSingleDotClick = (e, singleActiveInstance) => {
    if (!this.state.isSubMenuLoaded) {
      this.setState({ isSubMenuLoaded: true })
    }
    if (singleActiveInstance) {
      const subMenuRect = {
        x: -12,
        y: 68,
      }
      this.setState({
        isSubMenuVisible: true,
        subMenuRect,
        currentOpenedSubMenuInstance: singleActiveInstance,
      })
    }
  }
  tenantFilterFn = (t) => {
    if (t.no_app_tile_ui) {
      return false
    }
    if (determinePendingProvisioningStatus(t)) {
      return true
    }
    if (determineRunningStatus(t)) {
      return true
    }
    const { isNova, role, name } = this.props
    if (canNovaSetup(t, { isNova }) && allowInstanceAdmin({ name, role }, t)) {
      return true
    }
    return false
  }

  renderAppWithoutInstances = (url) => {
    return <div className={`${this.props.appClassName}`} data-app-id={this.props.app_id}>
      <a className={classNames('icon_shadow')} href={url}><AppIcon {...this.props} /></a>
      <p><span className='icon_caption'>{this.props.display_name || this.props.name}</span></p>
    </div>
  }

  render() {
    if (this.props.app_url) { // on presence of app_url will bypass instances rendering
      return this.renderAppWithoutInstances(this.props.app_url)
    }

    const { isDotVisible } = this.state
    const instances = this.props.tenants.filter(this.tenantFilterFn)
    if (!instances.length) { // && this.props.tenants.every(isHidden) // APPORTAL-10196 stop showing "No active instance"
      // APPORTAL-3476 only Panorama has registered status
      return null // hide icon if all instances are no_app_tile_ui
    } // do this logic on ui only
    // because if no_app_tile_ui set to app, the market tile will be hidden

    const onlyActiveInstance = getOnlyActiveInstance(instances)
    const getLinkProps = (tenant) => {
      if (tenant.instance_status === SETUP_PENDING_STATUS) {
        return {
          href: ACTIVATE,
          onClick: (e) => {
            e.preventDefault()
            this.novaSetup(tenant)
          },
        }
      }
      if (determinePendingProvisioningStatus(tenant)) {
        if (tenant.provisioning_url || tenant.url?.startsWith('/')) {
          const url = tenant.provisioning_url || tenant.url
          return {
            className: 'instance-provisioning',
            href: url,
            onClick: (e) => {
              e.preventDefault()
              this.props.history.push(url)
            }
          }
        }
        return {
          className: 'instance-provisioning-disabled',
          href: SETTINGS,
          onClick: preventDefault,
        }
      }
      if (tenant.url === EDIT_URL) {
        return {
          href: EDIT_URL,
          onClick: (e) => {
            e.preventDefault()
            this.edit(tenant)
          },
        }
      }
      if (tenant.url?.startsWith('/')) {
        return {
          href: tenant.url,
          onClick: (e) => {
            e.preventDefault()
            this.props.history.push(tenant.url)
          }
        }
      }
      return { href: tenant.url }
    }
    const instanceDropdownMenu = <Menu
      style={{ maxHeight: '175px', overflow: 'auto' }}
    >{
        instances.map((tenant, i) => {
          return <Item key={tenant.tenant_id} className={'dropdown-submenu__menu'} >
            <a {...getLinkProps(tenant)}>
              {tenant.tenant_display_name || tenant.tenant_instance_name || tenant.serial_number || tenant.tenant_id}
            </a>
            {tenant.message && <MyOneAppBadge dot={true} status={getBadgeStatus(tenant.message.status)} />}
            <button className='dropdown-submenu__btn' onClick={(e) => {
              e.preventDefault()
              this.onClickSubmenuBtn(e, i, tenant)
            }}>
              <DotsIcon />
            </button>
          </Item>
        })
      }
      {instances.length === 0 && <Item disabled>No active instance</Item>}
    </Menu>
    const tenantMsgCount = instances.filter(t => t.message).length
    const {
      text: msgText = '',
      count: msgCount = tenantMsgCount,
      status: msgStatus = msgCount ? 'warning' : undefined
    } = (tenantMsgCount && this.props.message) || {}

    return (
      <div className={`${this.props.appClassName}`} data-app-id={this.props.app_id}>
        {
          !onlyActiveInstance ? (
            <Dropdown
              overlay={instanceDropdownMenu}
              trigger={['click']}
              placement='bottomCenter'
              getPopupContainer={el => el.parentNode}
              onVisibleChange={this.onVisibleChange}
              visible={this.state.isDropdownVisible}
            >
              <MyOneAppBadge count={msgCount} title={msgText} status={getBadgeStatus(msgStatus)} dot={false}>
                <a className={classNames('icon_shadow', { open: this.state.open })}>
                  <AppIcon {...this.props} />
                </a>
              </MyOneAppBadge>
            </Dropdown>
          ) : (<>
            <MyOneAppBadge count={msgCount} title={msgText} status={msgStatus} dot={false}>
              <a
                className='icon_shadow'
                {...getLinkProps(onlyActiveInstance)}
                onMouseEnter={() => this.setState({ isDotVisible: true })}
                onMouseLeave={() => this.setState({ isDotVisible: false })}
              >
                <AppIcon {...this.props} />
              </a>
            </MyOneAppBadge>
            {onlyActiveInstance.tenant_id &&
              <button
                className='dropdown-submenu__btn--single'
                style={{ opacity: isDotVisible ? 1 : 0 }}
                onMouseEnter={() => this.setState({ isDotVisible: true })}
                onMouseLeave={() => this.setState({ isDotVisible: false })}
                onClick={(e) => {
                  e.preventDefault()
                  this.onSingleDotClick(e, onlyActiveInstance)
                }}
              >
                <DotsIcon />
              </button>
            }
          </>)
        }
        <p>
          <span className='icon_caption'>{this.props.display_name || this.props.name}</span>
        </p>
        {this.state.isSubMenuLoaded &&
          <SubMenu
            onVisibleChange={this.onSubMenuVisibleChange}
            isVisible={this.state.isSubMenuVisible}
            origin={this.state.subMenuRect}
            instance={this.state.currentOpenedSubMenuInstance}
            hasDSS={this.props.associations?.some(isDSSApp)}
            showInstanceActionModalHandler={this.props.showInstanceActionModalHandler}
            showRecordFormModalHandler={this.props.showRecordFormModalHandler}
            selectedAccount={this.props.selectedAccount}
            name={this.props.name}
            display_name={this.props.display_name}
            isThirdParty={this.props.isThirdParty}
            isCob={this.props.isCob}
            upsertable={this.props.upsertable}
            uneditable={this.props.flags?.uneditable}
            history={this.props.history}
          />
        }
      </div>
    )
  }
}

MyOneApp.propTypes = {
  tenants: PropTypes.array,
  name: PropTypes.string.isRequired,
  app_id: PropTypes.string,
  display_name: PropTypes.string,
  role: PropTypes.string,
  entitled: PropTypes.bool,
  isNova: PropTypes.bool,
  usePubSub: PropTypes.bool,
  isThirdParty: PropTypes.bool,
  isCob: PropTypes.bool,
  logo: PropTypes.any,
  flags: PropTypes.object,
  upsertable: PropTypes.bool,
  description: PropTypes.string,
  appClassName: PropTypes.string,
  selectedAccount: PropTypes.number,
  message: PropTypes.object,
  history: PropTypes.object,
  associations: PropTypes.array,
  showInstanceActionModalHandler: PropTypes.func,
  showRecordFormModalHandler: PropTypes.func,
  app_url: PropTypes.string,
}

MyOneApp.defaultProps = {
  selectedAccount: 0,
  tenants: [],
  description: 'App description goes here. This is not real copy. In fact, this is not really copy at all.',
  entitled: false
}

export default withRouter(MyOneApp)
