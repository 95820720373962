/**
 *
 * Created by vsuthar on 2018-12-05 File ControlledButton
 * Project: App Portal ©PaloAlto Networks
 */
import { Authorization } from '@pan/cloud-appframework'
import { Button } from '@pan/cloud-base'
export { AUTHCODE_APP_ADMIN, APP_ADMIN } from '../../utils/rbacUtils'
export { ACCOUNT_SUPER_USER } from '../../constants/AppConstants'

const ControlledButton = (props) => {
  const { allowedRoles, rules = ['hide'], replaceComponentWith = null } = props
  return Authorization(Button, allowedRoles, rules, replaceComponentWith)
}
export default ControlledButton
