import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Badge, Tooltip, Animate } from '@pan/cloud-base'
import './MyOneAppBadge.scss'

const MyOneAppBadge = ({ count, dot, title, status, className, children, ...props }) => {
  if (count && title === count) {
    title = ''
  }
  let badge
  const cls = classNames(className, 'pan-badge ant-badge', count && `pan-badge-status-${status}`)
  if (count > 0 || dot) {
    badge = <Badge
      {...props}
      title={null}
      dot={dot}
      count={count}
      status={dot ? status : ''}
      className={cls}
    >{children}</Badge>
  }
  else if (count) { // is string, re-impl ant-badge until antd upgraded to support count as node
    badge = <span {...props} className={cls}>
      {children}
      <Animate
        component=''
        showProp='data-show'
        transitionName={children ? 'ant-badge-zoom' : ''}
        transitionAppear
      >
        <sup data-show='true' className='pan-badge-text ant-scroll-number ant-badge-count ant-badge-multiple-words'>{count}</sup>
      </Animate>
    </span>
  }
  else { // count is 0 or not given
    badge = <span {...props} className={cls}>{children}</span>
  }
  return title ? (
    <Tooltip title={title} getPopupContainer={el => el.parentNode}>{badge}</Tooltip>
  ) : badge
}

export default MyOneAppBadge

MyOneAppBadge.propTypes = {
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string,
  dot: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}
MyOneAppBadge.defaultProps = {
  count: 0,
  status: 'warning',
  dot: false,
  title: '',
}
