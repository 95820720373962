/******************************************
 *  Author : vsuthar
 *  Created On : Wed Sep 01 2021
 *  File : NumberInput.js
 *  ©PaloAlto Networks
 *******************************************/
import React from 'react'
import PropTypes from 'prop-types'
import { InputNumber } from '@pan/cloud-base'
import './NumberInput.scss'

const NumberInput = ({ suffix, ...rest }) => {
  return (
    <div className='hbox middle space-between number-input-calc'>
      <InputNumber {...rest} />
      {suffix && <span className='suffix'>{suffix}</span> }
    </div>
  )
}
NumberInput.propTypes = {
  suffix: PropTypes.string
}
export default NumberInput

