/**
 *
 * Created by vsuthar on 2019-01-04 File ConfirmationRoleAssignments
 * Project: App Portal ©PaloAlto Networks
 */
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@pan/cloud-base'

const OK_BUTTON = 'Yes'
const CANCEL_BUTTON = 'No'
class ConfirmationDialog extends PureComponent {
  render() {
    return (
      <Modal {...this.props} >
        {this.props.children}
      </Modal>
    )
  }
}
ConfirmationDialog.propTypes = {
  children: PropTypes.node
}

ConfirmationDialog.defaultProps = {
  okText: OK_BUTTON,
  cancelText: CANCEL_BUTTON,
}

export default ConfirmationDialog
