import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PageHeader, PageBody, Col, Spin } from '@pan/cloud-base'
import InstanceTable from './InstanceTable'
import InstanceRecordModal from './InstanceRecordModal'
import InstanceActionModal from './InstanceActionModal'
import { filterAppEntitlementsByNoAccessFlag } from '../../utils/rbacUtils'
import { isNonemptyArray } from '../../utils/common'
import { appframeworkActions } from '@pan/cloud-appframework'
import { MANAGE_APPS, MANAGE_APPS_SUB_HEAD } from './../../constants/AppConstants'

const { fetchEntitlements } = appframeworkActions

class Settings extends Component {
  componentDidMount() {
    this.fetchEntitlementsStatusInterval = setInterval(() => {
      this.props.fetchEntitlements() // This handler keep updating so, function being called every time.
    }, 30000)
  }
  componentWillUnmount() {
    if (this.fetchEntitlementsStatusInterval) {
      this.fetchEntitlementsStatusInterval = clearInterval(this.fetchEntitlementsStatusInterval)
    }
  }
  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props)
  }
  renderTables(tables) {
    if (!tables?.length) {
      if (this.props.entitlementFetching) {
        return ''
      }
      const { ANY, ...roles } = this.props.roles || {}
      let msg
      if (!Object.keys(roles).length) {
        msg = 'You currently do not have access to any apps. To access apps, please contact the people in your organization who are responsible for managing app roles in the hub.'
      }
      if (!this.props.entitledAppsList.filter((app) => app.entitled && !app.extension_to).length) {
        msg = 'No apps are currently activated for your organization. Please either activate your app(s), or contact the people in your organization who are responsible for managing apps in the hub.'
      }
      else {
        msg = 'You currently do not have access to any apps, either because you do not have the proper roles or because your organization has not yet activated your app(s). To access apps, please contact the people in your organization who are responsible for managing apps and/or app roles in the hub.'
      }
      return <p className='alert alert-warning'>{msg}</p>
    }
    return <div className='instance-table-wrap'>
      <table className='table services-table instance-table'>
        {tables}
      </table>
    </div>
  }
  render() {
    return <Col span={24}>
      <Spin spinning={this.props.isLoading}>
        <PageHeader
          head={MANAGE_APPS}
          subhead={MANAGE_APPS_SUB_HEAD}
        />
        <PageBody>
          {this.renderTables(
            filterAppEntitlementsByNoAccessFlag(this.props.entitledAppsList.filter((app) =>
              app.entitled && !app.extension_to && !app.flags.no_app_manage_ui
            )).filter((app) =>
              isNonemptyArray(app.tenants) && // filter out no instance
              app.tenants.some(t =>
                t.tenant_id && !t.is_clcs && !t.hidden && +t.tenant_id !== 0
              ) // filter out app with only fake/hidden/clcs instances
            ).map((app) => {
              return <InstanceTable
                key={app.name}
                app={app}
                history={this.props.history}
              />
            })
          )}
        </PageBody>
        <InstanceRecordModal />
        <InstanceActionModal />
      </Spin>
    </Col>
  }
}

Settings.propTypes = {
  entitledAppsList: PropTypes.array,
  fetchEntitlements: PropTypes.func,
  isLoading: PropTypes.bool,
  entitlementFetching: PropTypes.bool,
  roles: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = ({
  selectedInstance,
  entitledAppsList,
  isAuthenticated,
  isLoading,
  entitlementFetching,
  roles,
}) => ({
  selectedInstance,
  entitledAppsList,
  isAuthenticated,
  isLoading,
  entitlementFetching,
  roles,
})

const mapDispatchToProps = (dispatch) => ({
  fetchEntitlements: (options) => {
    dispatch(fetchEntitlements(options))
  },
})

const ignoredFetchingEntitlements = () => Promise.resolve()
const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    fetchEntitlements: stateProps.isAuthenticated ?
      dispatchProps.fetchEntitlements : ignoredFetchingEntitlements,
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(Settings)
