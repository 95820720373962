import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dropdown, Menu, Icon } from '@pan/cloud-base'
import { useAxiosRequest, getSelectedAccountInfo, FT, indexArray, isNonemptyArray, useMemoizeValue } from '../utils/activateUtils'
import { allowAppAdmin } from '../utils/rbacUtils'
import './ElaActivation.scss'

export const ElaActivation = ({ isAuthenticated, selectedAccount, supportAccountIds, entitledAppsList }) => {
  const { load } = useAxiosRequest()
  const [elaApps, setElaApps] = useState([])
  const supportAccountId = useMemo(() => getSelectedAccountInfo(supportAccountIds, selectedAccount)?.support_account_id, [supportAccountIds, selectedAccount])
  const apps = useMemoizeValue(useMemo(() => {
    if (!isAuthenticated) {
      return []
    }
    return entitledAppsList.filter(each => each.flags.ela).map(({ name, role, app_id, display_name }) => (
      { name, role, app_id, display_name }
    ))
  }, [isAuthenticated, entitledAppsList]))

  const filterApps = useMemo(() => apps.filter(each => allowAppAdmin(each, supportAccountIds[selectedAccount]?.rbacRoles)), [apps, selectedAccount, supportAccountIds])

  useEffect(() => {
    if (isNonemptyArray(filterApps)) {
      const getUrl = (elaData, appId) => {
        const url = elaData[appId].url
        if (appId === 'strata_insights') {
          return url?.replace(/ela\/setup/g, 'activation') // overwrite with new tsg activation path
        }
        else if (FT('IOT_ELA_TSG') && appId === 'zingbox') {
          return url?.replace(/ela\/setup/g, 'activation') // overwrite with new tsg activation path
        }
        return url // saas inline
      }

      const constructData = (elaData = {}) => {
        const indexedApps = indexArray(filterApps, 'app_id')
        return Object.keys(elaData)?.reduce((result, appId) => {
          if (indexedApps.has(appId) && elaData[appId]?.url) {
            result.push({
              appId,
              display: indexedApps.get(appId).display_name,
              url: getUrl(elaData, appId)
            })
          }
          return result
        }, [])
      }
      load({
        method: 'post',
        url: '/hub/v2/user_account',
        data: {
          support_account_id: supportAccountId,
        }
      }).then((response) => {
        if (response.data) {
          setElaApps(constructData(response.data.ela))
        }
      }).catch((error) => {
        if (!error.data || error.data?.statusCode !== 404) {
          const message = error.data?.message || error.message || error
          // eslint-disable-next-line no-console
          console.error(`user_account:${message}`)
        }
        setElaApps([])
      })
    }
    else {
      setElaApps([])
    }
  }, [isAuthenticated, load, setElaApps, apps, supportAccountId, selectedAccount, supportAccountIds, filterApps])

  const menuItems = useMemo(() => <Menu>
    {
      elaApps.map(app => <Menu.Item key={app.appId}>
        <a target='_blank' rel='noopener noreferrer' href={app.url}>{app.display}</a>
      </Menu.Item>)
    }
  </Menu>, [elaApps])

  return elaApps.length ? <div className='ela-activation'>
    <Dropdown overlay={menuItems} trigger={['click']}>
      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
        ELA Activations <Icon type="down" />
      </a>
    </Dropdown>
  </div> : null
}

ElaActivation.propTypes = {
  isAuthenticated: PropTypes.bool,
  entitledAppsList: PropTypes.array,
  selectedAccount: PropTypes.number,
  supportAccountIds: PropTypes.array,
}

const mapStateToProps = ({
  isAuthenticated,
  selectedAccount,
  supportAccountIds,
  entitledAppsList,
}) => ({
  isAuthenticated,
  entitledAppsList,
  selectedAccount,
  supportAccountIds,
})

export default connect(mapStateToProps)(ElaActivation)
