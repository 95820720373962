import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { find } from 'lodash'
// import { FT } from '@pan/cloud-appframework'
// import cortex_xdr from './cortex_xdr'
import logging_service from './logging_service'
// import directory_sync from './directory_sync'
import explore2 from './explore2'
import prisma_cloud from './prisma_cloud'
import prisma_saas from './prisma_saas'
import prisma_access from './prisma_access'
// import cortexxsoar from './cortexxsoar'

import ActivateButton from './ActivateButton'
import AppDetails from './AppDetails'
import BodyRenderer from './BodyRenderer'
import { ManifestAppDetails } from './ManifestAppDetails'
import ComingSoon from '../ComingSoon'
import NotFound from '../NotFound'
import { ErrorNotice } from '../ErrorNotice'

const builtInAppDetail = (data) => (
  <AppDetails {...data} name={data.display_name || data.name}>
    {React.isValidElement(data.body) ? data.body : <BodyRenderer body={data.body} />}
  </AppDetails>
)
const appDetailFactory = (builtInAppInfo) => {
  const fn = (props) => builtInAppDetail({ ...props, ...builtInAppInfo })
  fn.appName = builtInAppInfo.name
  return fn
}
const redirectFactory = (app_id) => {
  const Comp = () => null
  Comp.appName = app_id
  Comp.redirect = true
  return Comp
}
// const redirectUrlFactory = (url) => {
//   const Comp = () => null
//   Comp.redirectUrl = url
//   return Comp
// }

const Redirecting = () => <ErrorNotice title='' content='Redirecting to the product homepage.' />

const mapping = {
  logging_service: appDetailFactory(logging_service),
  // directory_sync: appDetailFactory(directory_sync),
  cortex_xdr: redirectFactory('cortex_xdr'),
  cortexxsoar: redirectFactory('cortex_demisto'),
  explore: appDetailFactory(explore2),
  prisma_access: appDetailFactory(prisma_access),
  prisma_cloud: appDetailFactory(prisma_cloud),
  prisma_saas: appDetailFactory(prisma_saas),
}
// aliases
Object.assign(mapping, {
  aperture: mapping.prisma_saas,
  cortex_data_lake: mapping.logging_service,
  strata_logging_service: mapping.logging_service,
  cdl: mapping.logging_service,
  xdr: mapping.cortex_xdr,
  magnifier: mapping.cortex_xdr,
  traps: mapping.cortex_xdr,
})

export const MarketPlace = ({
  match,
  history,
  entitledAppsList,
  isAuthenticated,
  setWrapperClassName,
  showAuthCodeModal,
}) => {
  const name = match.params.name
  if (mapping[name]) {
    const AppDetailPage = mapping[name]
    if (AppDetailPage.redirectUrl) {
      window.location.replace(AppDetailPage.redirectUrl)
      return <Redirecting />
    }
    const app = entitledAppsList?.get(AppDetailPage.appName)
    if (AppDetailPage.redirect && app?.marketplace_content?.url) {
      window.location.replace(app.marketplace_content.url)
      return <Redirecting />
    }
    return <AppDetailPage
      setWrapperClassName={setWrapperClassName}
      isAuthenticated={isAuthenticated}
      app={app}
      logo={app && app.logo}
      history={history}
      showAuthCodeModal={showAuthCodeModal}
    />
  }
  const app = find(entitledAppsList, app => app.url_name === name || app.app_id === name)
  if (!app) {
    return entitledAppsList.isLoading ? <AppDetails
      setWrapperClassName={setWrapperClassName}
    /> : <NotFound />
  }
  if (app.marketplace_content && app.developer) {
    if (app.marketplace_content.url) {
      window.location.replace(app.marketplace_content.url)
    }
    return <ManifestAppDetails
      setWrapperClassName={setWrapperClassName}
      isAuthenticated={isAuthenticated}
      custom_activate_btn={ActivateButton}
      app={app}
      history={history}
      showAuthCodeModal={showAuthCodeModal}
    />
  }
  return <ComingSoon />
}

MarketPlace.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      name: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  entitledAppsList: PropTypes.array,
  history: PropTypes.object,
  isAuthenticated: PropTypes.bool,
  setWrapperClassName: PropTypes.func,
  showAuthCodeModal: PropTypes.func,
}

MarketPlace.defaultProps = {
  entitledAppsList: [],
}

const mapStateToProps = ({ entitledAppsList, isAuthenticated }) => ({
  entitledAppsList,
  isAuthenticated,
})

const mapDispatchToProps = (dispatch) => ({
  setWrapperClassName: (value) => dispatch({ type: 'SET_WRAPPER_CLASS_NAME', value }),
  showAuthCodeModal: (authcode = '') => dispatch({ type: 'SHOW_AUTHCODE_ACTIVATE_MODAL', authcode }),
})

export default connect(mapStateToProps, mapDispatchToProps)(MarketPlace)
