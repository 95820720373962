/**
 * Created by vsuthar on 5/5/20
 * Project: App Portal ©Palo Alto Networks
 */
import React, { useRef, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, Tooltip, Icon } from '@pan/cloud-base'
import TokenTooltip from '../common/TokenTooltip'
import './otp.scss'
import { OTP_DESCRIPTION, OTP_TECH_DOC_LABEL, OTP_TECH_DOC_LINK } from '../../constants/AppConstants'

const preventDefault = (e) => {
  e.preventDefault()
}

const OTP = (props) => {
  const { inputProps, btnProps, otp = '', otp_expiration = '', label = 'Copy One-time Password', showLabel, hasFormError } = props
  const [isCopied, setCopied] = useState(false)
  const textAreaRef = useRef(null)
  const handleClick = useCallback(() => {
    textAreaRef.current.select()
    document.execCommand('copy')
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }, [])
  const selectText = useCallback((e) => {
    e.preventDefault()
    e.target.select()
  }, [])
  const help = useMemo(() => {
    const date = otp_expiration ? new Date(otp_expiration) : null
    return +date ? `Valid until ${new Date(otp_expiration).toLocaleTimeString()}` : null
  }, [otp_expiration])

  const formItemStyle = hasFormError ? 'style-2 gray-color' : 'style-1 white'
  const textStyle = { color: hasFormError ? '#808080' : '#fff' }

  const description = showLabel !== false ? <>
    <span>{OTP_DESCRIPTION}</span>
    <a rel='external noopener noreferrer' target='_blank' style={{ padding: '2px' }} href={`${OTP_TECH_DOC_LINK}`}>{OTP_TECH_DOC_LABEL}</a>
  </> : undefined

  const infoTooltip = description && <TokenTooltip label={label} description={description} />

  return (
    <div style={{ padding: '0px 5px 0 18px' }}>
      <Form.Item className={`otp-item ${formItemStyle}`} label={infoTooltip} help={help}>
        <div className={'hbox middle otp'}>
          <input className='otp-text-input' readOnly ref={textAreaRef} onChange={preventDefault} onFocus={selectText} {...inputProps} value={otp} />
          <Tooltip visible={isCopied} title={'Copied!'} placement={'right'}>
            <button style={textStyle} {...btnProps} className={`otp-btn center ${btnProps?.className || ''}`} onClick={handleClick}>
              <Icon type={'copy'} theme={'filled'} style={textStyle} />
            </button>
          </Tooltip>
        </div>
      </Form.Item>
    </div>
  )
}

OTP.propTypes = {
  label: PropTypes.string,
  inputProps: PropTypes.object,
  btnProps: PropTypes.object,
  showLabel: PropTypes.bool,
  otp: PropTypes.string,
  otp_expiration: PropTypes.string,
  hasFormError: PropTypes.bool,
}
export default OTP

