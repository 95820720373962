import React from 'react'
import PropTypes from 'prop-types'
import { Form } from '@pan/cloud-base'

const SerialNumberField = ({
  serial_number,
  ...restProps
}) => {
  return (
    <Form.Item
      {...restProps}
      className='activate-form-display-field'
      label='Serial Number'
    >
      <span className='ant-form-text'>{serial_number}</span>
    </Form.Item>
  )
}

SerialNumberField.propTypes = {
  serial_number: PropTypes.string,
}

export default React.memo(SerialNumberField)
