const isLoading = (state = false, action) => {
  switch (action.type) {
    case 'TOGGLE_IS_LOADING': {
      return !state
    }
    case 'SET_IS_LOADING': {
      return !!action.isLoading
    }
    case 'IS_LOADING_TRUE': {
      return true
    }
    case 'IS_LOADING_FALSE': {
      return false
    }
    case 'FETCH_ENTITLEMENTS_FAILURE':
    case 'FETCH_ENTITLEMENTS_SUCCESS':
    case 'SET_ENTITLED_APPS': {
      return false
    }
    default: {
      return state
    }
  }
}

export default isLoading
