/**
 *
 * Created by vsuthar on 10/8/18 File URLs
 * Project: App Portal ©PaloAlto Networks
 */


// RBAC
export const RBAC_GET_USERS = '/hub/v2/users/getusers'
export const RBAC_AUTOCOMPLETE = '/hub/v2/users/autocomplete'
export const RBAC_UPDATE_USERS = '/hub/v2/users/updateuser'
export const RBAC_GET_CUSTOM_ROLE = 'hub/v2/custom_roles'
export const RBAC_GET_SCHEMA = 'hub/v2/auth/rbac/schema'
export const RBAC_CREATE_CUSTOM_ROLE = 'hub/v2/auth/rbac/custom_role/csp_account_id'
export const RBAC_CREATE_USER_ROLE = 'hub/v2/auth/rbac/user_role/csp_account_id'
export const RBAC_ROLE_LOOKUP = 'hub/v2/auth/rbac/membership/lookup'
export const RBAC_GET_ROLE_DEF = 'hub/v2/auth/rbac/role_def'

// USER REGISTRATION
export const USER_REGISTRATION = '/register_user'
export const USER_GET_ACCOUNTS = '/get_accounts'
