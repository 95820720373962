import React from 'react'
import { Dropdown, Menu } from '@pan/cloud-base'
import { NavLink } from 'react-router-dom'
import { SettingsIcon } from '../images/svg-icons'
import { ACCESS_MANAGEMENT } from '../constants/AppConstants'

const MenuItem = Menu.Item

const SettingsDropDown = () => {
  return <Dropdown
    overlay={
      <Menu
        selectable={false}
        id='pan-settings-menu'
      >
        <MenuItem key='manage-apps'>
          <NavLink id='pan-settings-link' to='/settings'>Manage Apps</NavLink>
        </MenuItem>
        <MenuItem key='assign-roles'>
          <NavLink id='pan-roles-link' to='/roles'>{ACCESS_MANAGEMENT}</NavLink>
        </MenuItem>
      </Menu>
    }
    trigger={['click']}
    getPopupContainer={el => el.parentNode.parentNode}
  >
    <a
      className='nav-link dropdown-toggle'
      href='/'
      id='pan-settings-tab'
      data-toggle='dropdown'
      style={{ marginRight: 0, paddingRight: 0 }}
    >
      <SettingsIcon width='16px' height='16px' />
    </a>
  </Dropdown>
}

export default SettingsDropDown
