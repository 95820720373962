/**
 * Created by vsuthar on 2/19/20
 * Project: App Portal ©Palo Alto Networks
 */

/**
 * Please keep sync with Server code
 */
export const ErrorCode = {
  RBAC_5002: 'has been successfully created as a new role',
  RBAC_5003: 'has been deleted successfully',
  RBAC_5004: 'Invalid Scope',
  RBAC_5005: 'Invalid Schema',
  RBAC_5006: 'Required Field not supply',
  RBAC_5007: 'Invalid App Version or Schema not present for app',
  RBAC_5008: 'CSP Account Id not supplied',
  RBAC_5009: 'Invalid special_predefined Role',
  RBAC_5010: 'Could Not Remove Role',
  RBAC_5011: 'Invalid App Type',
  RBAC_5012: 'Invalid Type',
  RBAC_5013: 'Invalid Email Address',
  RBAC_5014: 'Invalid App Predefined Scopes',
  RBAC_5015: 'Scopes not supplied',
  RBAC_5016: 'App Predefined Scopes not supplied',
  RBAC_5017: 'Can not create duplicate role',
  RBAC_5018: 'Sorry, Something went wrong!',
  RBAC_5019: 'Not have enough Privilege'
}

export const ERROR_TYPES = {
  FIREWALL_NOT_FOUND: 'firewall_not_found',
  FIREWALL_LICENSE_INCOMPATIBLE: 'firewall_license_incompatible',
  LICENSE_NOT_FOUND: 'license_not_found'
}

/** Error Messages*/
export const SERVICE_UNAVAILABLE = 'service temporarily unavailable, please try again'
export const UNHANDLED_EXCEPTION = 'Error: Unhandled Exception'
