import _ from 'lodash'
const DEFAULT_PROGRESS_STATUS = { visible: false, progress: undefined, statuses: {} }
const DEFAULT_STATE = { progress: DEFAULT_PROGRESS_STATUS, result: undefined }
const appActivate = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'FETCH_ENTITLEMENTS_SUCCESS':
      return {
        ...state,
        entitlementsLoaded: action.timestamp,
      }
    case 'APP_ACTIVATE_VIA_AUTHCODE':
      return {
        ...state,
        ...DEFAULT_STATE,
        ...action.data,
      }
    case 'APP_ACTIVATE_NO_AUTHCODE':
      return {
        ...state,
        ...DEFAULT_STATE,
        appNames: [action.appName],
        display_name: action.appDisplayName,
      }
    case 'APP_ACTIVATE_SET_INIT_VALUES':
      return {
        ...state,
        ...DEFAULT_STATE,
        initialValues: action.initialValues,
      }
    case 'APP_ACTIVATE_SET_RESULT':
      return {
        ...state,
        result: (action.error && {
          error: action.error,
          message: action.message,
          bkgId: action.bkgId,
        }) || (action.data && {
          data: action.data,
          message: action.message,
          bkgId: action.bkgId,
        }) || undefined
      }
    case 'APP_ACTIVATE_SET_MSG':
      return {
        ...state,
        alertBanner: action.msg,
      }
    case 'APP_ACTIVATE_SET_PROGRESS': {
      return {
        ...state,
        progress: {
          visible: true,
          progress: +action.progress || 0,
          statuses: {
            ...(state.progress && state.progress.statuses),
            ...(action.status && _.isString(action.status) ? { [action.progress]: action.status } : action.status)
          },
          steps: action.steps || (state.progress && state.progress.steps),
        }
      }
    }
    case 'APP_ACTIVATE_HIDE_PROGRESS': {
      return {
        ...state,
        progress: {
          ...state.progress,
          visible: false,
        }
      }
    }
    case 'APP_ACTIVATE_RESET_PROGRESS': {
      return {
        ...state,
        ...DEFAULT_STATE,
        result: state.result, // keep it
      }
    }
    case 'RESET_APP_ACTIVATE':
      return {
        ...DEFAULT_STATE,
        entitlementsLoaded: state.entitlementsLoaded
      }
    default:
      return state
  }
}
export default appActivate
