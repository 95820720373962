import _ from 'lodash'

export const isNonemptyArray = arr => Array.isArray(arr) && arr.length > 0

export const indexArray = (arr, key, { clone, cached = true } = {}) => {
  if (cached && Array.isArray(arr) && arr._map && arr.get) {
    return arr
  }
  const array = Array.isArray(arr) ? arr : []
  const reIndex = () => indexArray(array, key, { clone: false, cached: false })
  if (!isNonemptyArray(array)) {
    return Object.defineProperties(array, {
      _map: { configurable: true, value: new Map() },
      has: { configurable: true, value: () => false },
      get: { configurable: true, value: () => undefined },
      reIndex: { configurable: true, value: reIndex },
    })
  }
  const isFn = typeof key === 'function'
  const map = new Map(array.map(a => [isFn ? key(a, array) : a[key], a]))
  return Object.defineProperties(clone ? [...array] : array, {
    _map: { configurable: true, value: map },
    has: { configurable: true, value: id => map.has(id) },
    get: { configurable: true, value: id => map.get(id) },
    reIndex: { configurable: true, value: reIndex },
  })
}

export const wait = ms => new Promise(r => setTimeout(r, ms))

export const toAppId = appName => appName && String(appName).trim().toLowerCase().replace(/\W+/g, '_')

export const uniq = arr => (!arr?.length ? [] : Array.from(new Set(Array.isArray(arr) ? arr : Array.from(arr))))

export const isMissingValue = value => value == null || value === '' // eslint-disable-line no-eq-null
export const omitEmptyValues = o => _.omitBy(o, isMissingValue)
export const cleanObj = o => _.omitBy(o, _.isNil)

export const hyphenOnEmptyValue = (value) => value || '-'
