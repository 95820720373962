import React, { PureComponent } from 'react'
import { Row, Col, Button, Icon } from '@pan/cloud-base'
import PropTypes from 'prop-types'
import { ASSIGN_ROLES, RBAC_CUSTOMIZE_ROLES } from '../../constants/AppConstants'
import RoleTable from './RoleTable'
import { ASSIGN_ROLES as ASSIGN_ROUTE, ROLE_DEF } from '../../constants/RouteConstants'
import InputSearchBar from '../../components/common/InputSearchBar'
import SearchAssignRole from './assignRoles/SearchAssignRole'
import { FT } from '@pan/cloud-appframework'
import { chunk } from 'lodash'
import { RightArrowCircledIcon } from '../../images/svg-icons'
import './../../common.scss'
import './AssignRoleContent.scss'
import { hasRole, INSTANCE_ADMIN_ROLE } from '../../utils/rbacUtils'

const SUBMIT = 'SUBMIT'
const CANCEL = 'CANCEL'

/**
 * RBAC right side content
 */

class AssignRoleContent extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      selection: [], // set init table selection,
      visible: false, // set search and assign role visible to false
      sorted: [{ id: 'app', sort_order: 'asc' }]
    }
    this.handleAddUserClick = this.handleAddUserClick.bind(this)
  }

  /*
  clear redux store selection when component mount.
  that mean when user come back on this page from another page we clear the redux store selection. hence we prevent
  user to select same user again.
  */

  componentDidMount() {
    this.props.doSelectedUsers([])
    this.props.handleAutoCompleteSelection([])
  }


  /**
   *
   * @param selection
   * callback when table checkbox being selected. Selection will container all the uuid
   */
  getSelectedValue = (selection) => {
    this.setState({
      selection
    }, () => {
      this.props.doSelectedUsers(selection)
    })
  }

  /**
   * When user select assign page. Redirect to ASSIGN_ROLES
   */
  handleRedirectToAssignRoles = () => {
    const { selection } = this.state
    this.props.clearAssignRoleCache()
    this.props.history.push(ASSIGN_ROUTE, { selection })
  }


  /**
   * when user click on the cortex xdr button.
   */
  handleRedirectToRoleDef = () => {
    const { selectedApp } = this.props
    this.props.history.push(ROLE_DEF, selectedApp)
  }

  /**
   *
   * @param value
   * make selection when end-user select from auto complete not only from table
   */

  handleAutoCompleteSelection = value => {
    this.props.handleAutoCompleteSelection(value)
  }


  /**
   *
   * @param value
   * Remove email from list when user deselect them from autocomplete tag or list.
   */
  handleDeselect = value => {
    const arr = this.props.autoCompleteSelection
    const findIdx = arr.indexOf(value)
    if (findIdx > -1) {
      arr.splice(findIdx, 1)
      this.props.handleAutoCompleteSelection(arr)
    }

  }

  /**
   *
   * @param newSorted
   * @param column
   * @param shiftKey
   *
   * Sort func
   */
  handleSortChange = (newSorted, column, shiftKey) => {
    if (newSorted && newSorted.length) {
      const { id, desc } = newSorted[0]
      const sortOrder = { sort_by: id, sort_order: desc ? 'desc' : 'asc' }
      if (id === 'email') {
        this.props.getAllRoles(sortOrder)
      }
      if (id === 'app') {
        this.props.getAllRoles(sortOrder)
      }
    }
    this.setState({ sorted: newSorted })

  }


  handleAddUserClick() {
    const { accountid } = this.props.selectedCSPAccount || {}
    if (accountid) {
      const url = FT('ADD_USER_PROD') ? `https://support.paloaltonetworks.com/SupportAccount/AccountUsers/accountid/${accountid}` : `http://qasupport.paloaltonetworks.com/SupportAccount/AccountUsers/${accountid}`
      window.open(url, '_blank')
    }
  }

  handleAssignRolePopup(type) {
    this.props.handleAutoCompleteSelection([])
    if (type === SUBMIT) {
      const { createdRoles } = this.props
      if (createdRoles && createdRoles.length) {
        this.props.doUpdateUsers(createdRoles, true)
      }
    }

    this.setState({
      visible: false
    })
  }

  pageChange = (page) => {
    this.setState({ page })
  }

  /**
   *
   * @returns {*}
   * Return RBAC user list table
   */
  getRoleTable() {
    const { selection, sorted, page = 0 } = this.state
    const { users, searchUsers, isTableSearching, rbac, tenantCache } = this.props
    // we determine which data we going to use filter or non filter one by looking @isTableSearching flag
    const _data = isTableSearching ? searchUsers.data : users.data
    const chunkData = chunk(_data, 100)
    const _lastUpdate = isTableSearching ? searchUsers.lastUpdate : users.lastUpdate
    return <RoleTable
      className='RoleTable'
      pages={chunkData.length}
      tenantCache={tenantCache}
      totalRows={_data.length}
      manual
      paginationLeftCmp={selection.length > 0 ? `${selection.length} OF ${_data.length} USERS` : null}
      onSelect={this.getSelectedValue}
      onSortedChange={this.handleSortChange}
      data={chunkData[page] || []}
      onPageChange={this.pageChange}
      sorted={sorted}
      loading={rbac.isUserTableLoad}
      lastUpdate={_lastUpdate}
      rbac={rbac}
    />
  }

  render() {
    const { selectedUsers,
      autoCompleteSelection,
      autoCompleteUsers,
      autoComplete,
      selectedApp,
      selectedInstanceRole,
      selectedFilter,
      searchUsers,
      selectedCSPAccount,
      isAutoCompleteLoading
    } = this.props
    const { visible } = this.state
    const leftPadStyle = { padding: 30 }
    const cardBodyStyle = {
      flex: 1,
      padding: 0,
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column'
    }
    const { roles } = selectedCSPAccount
    const _roles = []
    roles.forEach(loggedInRole => {
      const [app,, tId] = loggedInRole.split(':')
      if (app === 'cortex_xdr' && tId) {
        _roles.push(`${app}:${INSTANCE_ADMIN_ROLE}:${tId}`)
      }
    })
    const isAllowed = [..._roles, 'cortex_xdr'].some(role => hasRole(role))
    const { rbac_role } = selectedApp || {}
    const disabled = !((selectedApp && selectedApp.isDirty) && autoCompleteSelection.length > 0)
    const isAssignBtnDisabled = [...selectedUsers, ...autoCompleteSelection].length === 0
    return (
      <Col type='flex' style={leftPadStyle} className='vbox flex-box-fill'>
        <Col xs={12}>
          <Row type='flex' style={{ marginBottom: '1rem' }}>
            <Col>
              <Button disabled={isAssignBtnDisabled}
                type='primary'
                onClick={this.handleRedirectToAssignRoles}>
                {ASSIGN_ROLES}</Button>
              { rbac_role?.support_custom_role && isAllowed ? <Button style={{ marginLeft: '1rem' }} onClick={this.handleRedirectToRoleDef}>{RBAC_CUSTOMIZE_ROLES}</Button> : null }
              { FT('ADD_USER') ? <Button style={{ fontStyle: 'underline', marginLeft: '1rem' }} onClick={this.handleAddUserClick}>Add User</Button> : null}
            </Col>
          </Row>
        </Col>
        <Col xs={24} className='vbox flex-box-fill'>
          <div style={cardBodyStyle} className='vbox flex-box-fill'>
            <InputSearchBar
              className={'RBAC__autocomplete'}
              searchTerm={searchUsers.searchTerm}
              handleInputSearch={this.props.handleInputSearch}
              v2={true}
              prefix={<Icon type="search"/>}
              handleSuffixIcon={ (searching) => {
                return searching ? <Icon key={'spint___rbac'} type="sync" spin /> : <RightArrowCircledIcon />
              }}
            />
            <div className='vbox flex-box-fill'>
              { this.getRoleTable() }
            </div>
          </div>
        </Col>
        <SearchAssignRole visible={visible}
          width={700}
          selectedApp={selectedApp}
          selectedFilter={selectedFilter}
          okText={'Submit'}
          onOk={() => this.handleAssignRolePopup(SUBMIT)}
          onCancel={() => this.handleAssignRolePopup(CANCEL)}
          okButtonProps={{ disabled }}
          usersAutoCompleteProps={{
            isAutoCompleteLoading,
            value: autoCompleteSelection,
            selectedInstanceRole,
            handleAutoCompleteSelection: this.handleAutoCompleteSelection,
            onDeselect: this.handleDeselect,
            autoCompleteUsers,
            autoComplete
          }}/>
      </Col>
    )
  }
}

/**
 *
 * @type {{children: *, history: *, users: *, appLoading: *, error: *, selectedUsers: *, autoCompleteSelection: *, autoComplete: *, getAllRoles: *, handleAutoCompleteSelection: *, autoCompleteUsers: *, doSelectedUsers: *, handleInputSearch: *, selectedApp: *, searchUsers: *, isTableSearching: *, rbac: *, doUpdateUsers: *, createdRoles: *, selectedInstanceRole: *, selectedFilter: *}}
 */
AssignRoleContent.propTypes = {
  children: PropTypes.any, // default children
  history: PropTypes.object, // history to re-route
  users: PropTypes.object, // list of users when user load
  appLoading: PropTypes.bool, // global flag when users make any AJAX call
  error: PropTypes.object, // global error, when any error happen during http request this object being filled
  selectedUsers: PropTypes.array, // table checkbox selectedusers
  autoCompleteSelection: PropTypes.array,
  autoComplete: PropTypes.array,
  getAllRoles: PropTypes.func,
  handleAutoCompleteSelection: PropTypes.func,
  autoCompleteUsers: PropTypes.func,
  doSelectedUsers: PropTypes.func, //
  handleInputSearch: PropTypes.func,
  selectedApp: PropTypes.object, // when user select left side radio button with PAN app.
  searchUsers: PropTypes.object,
  searchTerm: PropTypes.string,
  isTableSearching: PropTypes.bool,
  rbac: PropTypes.object,
  isAutoCompleteLoading: PropTypes.bool, // Auto search loading
  doUpdateUsers: PropTypes.func, // when user click on Submit button in patch assign role
  createdRoles: PropTypes.array, // when user select role from Selected Menu that time this role mapping created per user
  selectedInstanceRole: PropTypes.func, // when user change role like App Admin, Instance Admin from Select Menu
  selectedFilter: PropTypes.string, // left sidebar radio selection value
  clearAssignRoleCache: PropTypes.func, // clear redux cache,
  selectedCSPAccount: PropTypes.object,
  tenantCache: PropTypes.object, // tenant cache

}

/**
 *
 * @type {{users: {data: Array}, selectedFilter: string, selectedUsers: Array, isAutoCompleteLoading: boolean, autoComplete: Array, isTableSearching: boolean, searchUsers: {data: Array}, autoCompleteSelection: Array, createdRoles: Array, doSelectedUsers: AssignRoleContent.defaultProps.doSelectedUsers, doUpdateUsers: AssignRoleContent.defaultProps.doUpdateUsers, selectedInstanceRole: AssignRoleContent.defaultProps.selectedInstanceRole, handleAutoCompleteSelection: AssignRoleContent.defaultProps.handleAutoCompleteSelection, autoCompleteUsers: AssignRoleContent.defaultProps.autoCompleteUsers, handleInputSearch: AssignRoleContent.defaultProps.handleInputSearch, getAllRoles: AssignRoleContent.defaultProps.getAllRoles}}
 */
AssignRoleContent.defaultProps = {
  users: { data: [] },
  selectedFilter: '',
  selectedUsers: [],
  isAutoCompleteLoading: false,
  autoComplete: [],
  isTableSearching: false,
  searchUsers: { data: [] },
  searchTerm: '',
  autoCompleteSelection: [],
  createdRoles: [],
  doSelectedUsers: () => {},
  doUpdateUsers: () => {},
  selectedInstanceRole: () => {},
  handleAutoCompleteSelection: () => {},
  autoCompleteUsers: () => {},
  handleInputSearch: () => {},
  getAllRoles: () => {},
  clearAssignRoleCache: () => {},
  selectedCSPAccount: {},
  tenantCache: {},
}

export default AssignRoleContent
