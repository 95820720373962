import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import { appframeworkActions, utils, FT } from '@pan/cloud-appframework'
const { fetchEntitlements } = appframeworkActions
const { showLoginDialog } = utils

const SSE_CONN_IDLE_TIMEOUT = 300000 // 5min

// const DSS_DELETE_TYPE = 'instance/v1/delete/dss'
const CSP_COB_ACTIVATE_TYPE = 'activate/csp-cob'

const parseJobError = (errMsg) => {
  const [status, code, message = code || status] = errMsg.split(':')
  return Object.assign(new Error(message), { status: +status || 0, error: code })
}

export class EventWatcher extends PureComponent {
  reconnect = () => this.connect()
  connect() {
    if (this.unmounted || !FT('UI_JOB_QUEUE')) {
      this.eventSource = undefined
      return this
    }
    if (FT('NO_PUSH_EVENTS')) {
      const cspId = +this.props.support_account_id
      if (cspId) {
        axios.post('/hub/v2/entitlements', {
          support_account_id: cspId,
          use_cache: 'only',
        }, {
          timeout: cspId === 245 ? 3000 : 1500,
          // cancelToken: source.token,
          // validateStatus: status => status === 200 || (hash && status === 304)
        }).then((resp) => {
          if (resp?.data?.entitlements) {
            this.props.updateEntitlements(resp.data.entitlements)
          }
        }, _.noop)
      }
      this.eventSource = undefined
      return this
    }

    const reconnect = this.reconnect
    const {
      isAuthenticated,
      selectedAccount,
      // jobs,
      // setJobs,
      // updateJob,
      // removeJob,
      disableTenants,
      // setActivateSuccess,
      // setActivateError,
      setSetupProgress,
      // showActivateProgress,
      // hideActivateProgress,
      debouncedFetchEntitlements,
      updateEntitlements,
    } = this.props
    if (this.eventSource) {
      this.eventSource.close()
      this.eventSource = undefined
    }

    if (!isAuthenticated) {
      return this
    }

    const url = `/api/v1/events?selectedAccount=${selectedAccount}`
    const eventSource = NativeEventSource ? new NativeEventSource(url) :
      new EventSourcePolyfill(url, { Transport: XMLHttpRequest })
    // currently the polyfill using `fetch` in Edge cannot close connection!
    // @see https://github.com/Yaffle/EventSource/issues/120
    // const EventSource = NativeEventSource || EventSourcePolyfill
    // const eventSource = new EventSource(url)

    eventSource.onerror = () => { // always reconnect on error
      if (this.eventSource.readyState === 2) {
        // reconnect with new object
        this.eventSource.close()
        this.eventSource = null
        setTimeout(reconnect, 5000) // 5s
      }
    }
    const closeFn = eventSource.close.bind(eventSource)
    eventSource.close = () => {
      if (eventSource.idleTimeout) {
        clearTimeout(eventSource.idleTimeout)
      }
      return closeFn()
    }

    eventSource.addEventListener('unauthorized', () => {
      eventSource.close()
      showLoginDialog({
        title: 'Session Expired',
        content: 'You browser session has expired. Please sign in again.',
      }).catch(_.noop)
    })

    eventSource.addEventListener('end', eventSource.close.bind(eventSource))
    eventSource.addEventListener('reconnect', reconnect)

    const activateCobSetupProgressHandler = ({ progress, status, success, error, uuid, data }) => {
      // console.error('event progress', { progress, status, success, error, uuid, data })
      if (success) {
        setSetupProgress(uuid, progress || 100, status, { success, ...data })
      }
      else if (error) {
        let message = error.message || error
        message = _.isString(message) ? message : 'Failed to activate the authcode'
        setSetupProgress(uuid, progress, status, { error: message })
      }
      else {
        setSetupProgress(uuid, progress >= 0 ? progress : 0, status)
      }
    }

    // eventSource.addEventListener('messages', ({ data }) => {})
    // const msgJobIdSet = new Set()
    const jobDataHandlers = FT('UI_JOB_QUEUE') && {
      jobs: (jobs = []) => {
        // setJobs(jobs)
        // msgJobIdSet.clear()
        const shouldBeDisabled = jobs.filter(j => j.disableInstance).map(j => j.tenant_id)
        if (shouldBeDisabled.length) {
          disableTenants(new Set(shouldBeDisabled))
        }
      },
      'job:waiting': ({ id, tenant_id, type, disableInstance } = {}) => {
        const status = 'waiting'
        const job = {
          id, tenant_id, status,
        }
        // updateJob(job)

        if (disableInstance) {
          disableTenants({ [job.tenant_id]: true })
        }

        // if (type === DSS_DELETE_TYPE && !msgJobIdSet.has(id)) {
        //   Message.loading(`Job ${id} waiting for deleting Directory Sync instance.`)
        //   msgJobIdSet.add(id)
        // }
      },
      'job:started': ({ id, tenant_id, type, status, uuid, disableInstance, ...others } = {}) => {
        const job = {
          id, tenant_id, progress: 0, status, disableInstance,
        }
        // updateJob(job)

        if (disableInstance) {
          disableTenants({ [job.tenant_id]: true })
        }

        if (type === CSP_COB_ACTIVATE_TYPE) {
          activateCobSetupProgressHandler({ progress: 0, status, uuid, ...others })
        }
        // else if (type === DSS_DELETE_TYPE && !msgJobIdSet.has(id)) {
        //   Message.info(`Job ${id} started for deleting Directory Sync instance.`)
        //   msgJobIdSet.add(id)
        // }
      },
      'job:completed': ({ id, type, tenant_id, status, message, refreshEntitlements, disableInstance, result, uuid, ...others } = {}) => {
        const job = {
          id, tenant_id, progress: 100, status, disableInstance,
        }
        // updateJob(job)
        // TODO: also deal with jobs
        if (type === CSP_COB_ACTIVATE_TYPE) {
          activateCobSetupProgressHandler({ status, data: result, uuid, ...others, success: true })
        }
        // else if (type === DSS_DELETE_TYPE) {
        //   Message.success(`Job ${id} ${message || 'Completed'}`, 3)
        //   msgJobIdSet.delete(id)
        // }

        if (refreshEntitlements) {
          debouncedFetchEntitlements()
        }

        if (disableInstance) {
          disableTenants({ [job.tenant_id]: true })
        }
      },
      'job:progress': ({ id, type, tenant_id, progress, status, disableInstance, uuid, ...others } = {}) => {
        // updateJob({
        //   id, tenant_id, progress, status, disableInstance,
        // })
        if (type === CSP_COB_ACTIVATE_TYPE) {
          activateCobSetupProgressHandler({ progress, status, uuid, ...others })
        }
      },
      'job:failed': ({ tenant_id, type, error, id, status, uuid, ...others }) => {
        const err = parseJobError(error)
        if (type === CSP_COB_ACTIVATE_TYPE) {
          activateCobSetupProgressHandler({ ...others, status, error: err, uuid, success: false })
        }
        // leave as deleting status for now until more type of jobs are supported
        if (err.status === 400) {
          // invalid job
          // removeJob({ id })
          disableTenants({ [tenant_id]: false })
          // if (type === DSS_DELETE_TYPE) {
          //   Message.warn(`Job ${id} failed. ${err.message}`, 3)
          //   msgJobIdSet.delete(id)
          // }
          return
        }
        // updateJob({
        //   id, tenant_id, error, status, progress: -1,
        // })
        // if (type === DSS_DELETE_TYPE) {
        //   // Message.error(`Job ${id} failed ${error}`)
        //   msgJobIdSet.delete(id)
        // }
      },
    }
    const dataHandlers = {
      ...jobDataHandlers,
      entitlements: ({ entitlements }) => {
        if (entitlements) {
          updateEntitlements(entitlements)
        }
      }
      // DO NOT add end or any internal events here
    }

    _.each(dataHandlers, (fn, name) => {
      eventSource.addEventListener(name, ({ data }) => {
        if (this.unmounted) {
          return
        }
        const parsed = JSON.parse(data)
        // console.log(name, parsed)
        fn(parsed)

        if (name !== 'heartbeat') {
          eventSource.idleTimeout = eventSource.idleTimeout && clearTimeout(eventSource.idleTimeout)
          eventSource.idleTimeout = setTimeout(reconnect, SSE_CONN_IDLE_TIMEOUT)
        }
      })
      // TODO: watching for heartbeat, if did not receive it for long time, reconnect
    })

    this.eventSource = eventSource
    return this
  }

  componentDidMount() {
    this.connect()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isAuthenticated !== this.props.isAuthenticated ||
      prevProps.selectedAccount !== this.props.selectedAccount) {
      this.reconnect()
    }
    if (prevProps.disabledTenants !== this.props.disabledTenants) {
      this.props.applyDisabledTenants(this.props.disabledTenants)
    }
  }

  componentWillUnmount() {
    if (this.eventSource) {
      this.eventSource.close()
      this.eventSource = undefined
      this.unmounted = true
    }
  }

  render() {
    return null
  }
}

EventWatcher.propTypes = {
  isAuthenticated: PropTypes.bool,
  selectedAccount: PropTypes.number,
  support_account_id: PropTypes.number,
  jobs: PropTypes.object,
  disabledTenants: PropTypes.instanceOf(Set),
  setJobs: PropTypes.func,
  updateJob: PropTypes.func,
  removeJob: PropTypes.func,
  disableTenants: PropTypes.func,
  applyDisabledTenants: PropTypes.func,
  showActivateProgress: PropTypes.func,
  hideActivateProgress: PropTypes.func,
  setActivateSuccess: PropTypes.func,
  setActivateError: PropTypes.func,
  setSetupProgress: PropTypes.func,
  debouncedFetchEntitlements: PropTypes.func,
  updateEntitlements: PropTypes.func,
}

const mapStateToProps = ({
  isAuthenticated,
  supportAccountIds,
  selectedAccount,
  disabledTenants,
  jobs,
}) => ({
  isAuthenticated,
  supportAccountIds,
  selectedAccount,
  support_account_id: (selectedAccount >= 0 && supportAccountIds[selectedAccount]?.accountid) || undefined,
  disabledTenants,
  jobs,
})

const mapDispatchToProps = (dispatch) => ({
  updateEntitlements(apps, supportAccountIds, selectedAccount) {
    dispatch({
      type: 'UPDATE_ROLES',
      data: { supportAccountIds, selectedAccount }
    })
    dispatch({
      type: 'SET_ENTITLED_APPS',
      apps
    })
  },
  // setJobs(jobs) {
  //   dispatch({
  //     type: 'SET_JOBS',
  //     jobs,
  //   })
  // },
  // updateJob(job) {
  //   dispatch({
  //     type: 'UPDATE_JOB',
  //     job,
  //   })
  // },
  // removeJob(job) {
  //   dispatch({
  //     type: 'REMOVE_JOB',
  //     job,
  //   })
  // },
  disableTenants(tenants) {
    if (_.isSet(tenants)) {
      dispatch({ type: 'SET_DISABLED_TENANTS', tenants })
    }
    else {
      const map = _.isMap(tenants) ? tenants : new Map(Object.entries(tenants))
      dispatch({ type: 'UPDATE_DISABLED_TENANTS', tenants: map })
    }
  },
  applyDisabledTenants(tenants) {
    dispatch({ type: 'APPLY_DISBALED_TENANTS', tenants })
  },
  setActivateSuccess(data, message, bkgId) {
    dispatch({ type: 'APP_ACTIVATE_SET_RESULT', data, message, bkgId })
  },
  setActivateError(error, message, bkgId) {
    dispatch({ type: 'APP_ACTIVATE_SET_RESULT', error, message, bkgId })
  },
  setSetupProgress(uuid, progress = 0, status = '', data) {
    dispatch({ type: 'SET_SETUP_PROGRESS', uuid, progress, status, data })
  },
  showActivateProgress(progress = 0, status = '', steps) {
    dispatch({ type: 'APP_ACTIVATE_SET_PROGRESS', progress, status, steps })
  },
  hideActivateProgress() {
    dispatch({ type: 'APP_ACTIVATE_HIDE_PROGRESS' })
  },
  debouncedFetchEntitlements: _.debounce(() => {
    dispatch(fetchEntitlements())
  }, 300),
})

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    updateEntitlements: apps =>
      dispatchProps.updateEntitlements(apps, stateProps.supportAccountIds, stateProps.selectedAccount)
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventWatcher)
